import genericApi from './genericApi';
import Employee from '../models/Employee';
import UpdateEmployee from '../models/UpdateEmployee';
import CreateEmployee from '../models/CreateEmployee';
import CreatedEmployee from '../models/CreatedEmployee';

const baseUrl: string = 'employees';

const employeesReqHandler = {
  loadEmployees: (includeInactive?: boolean, orderBy?: string, orderByType?: string, officeId?: string): Promise<Array<Employee>> => {
    let url = baseUrl;
    if (includeInactive || orderBy) {
      url += '?';
    }
    if (includeInactive) {
      url += 'filterIsActive=0';
    }
    if (orderBy) {
      if (url.length > `${baseUrl}?`.length) {
        url += '&';
      }
      url += 'sort=' + orderBy + ',' + (orderByType ? orderByType : 'ASC');
    }
    if (officeId) {
      url += `&filterOfficeId=${officeId}`;
    }
    return genericApi.get(url);
  },
  loadEmployee: (id: number): Promise<Employee> => {
    return genericApi.get(`${baseUrl}/${id}`);
  },
  createEmployee: (employee: CreateEmployee): Promise<CreatedEmployee> => {
    return genericApi.post(baseUrl, employee);
  },
  updateEmployee: (id: number, employee: UpdateEmployee): Promise<void> => {
    return genericApi.put(`${baseUrl}/${id}`, employee);
  },
  deleteEmployee: (id: number): Promise<Employee> => {
    return genericApi.get(`${baseUrl}/${id}`);
  },

  getEmpAnnualPaidVacs: (empId?: number, year?: string): Promise<any> => {
    let url = 'emp_annual_paid_vacs?'
    if(year) {
      url += `filterYear=${year}&`
    }
    if(empId) {
      url += `filterByEmpIds=${empId}`
    }
    return genericApi.get(url);
  },

  getEmpRemainingVacs: (empId?: number, year?: string): Promise<any> => {
    let url = 'emp_remaining_vacs?'
    if(year) {
      url += `filterYear=${year}&`
    }
    if(empId) {
      url += `filterEmpIds=${empId}`
    }
    return genericApi.get(url);
  }
};

export default employeesReqHandler;
