import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Customer from '../../../models/Customer';
import EditRowProps from '../../../models/EditRowProps';
import { compare } from '../../../utils/string';
import SaraDeleteRow from '../../common/SaraDeleteRow';
import SaraTable from '../../common/SaraTable';
import Columns from './columns';

interface CustomersListProps {
  customers: Array<Customer>;
  isLoading: boolean;
  onDetailsClick: (event: any, data: Customer) => void;
  onEditClick: (event: any, data: Customer) => void;
  canReadCustomer: boolean;
  canWriteCustomer: boolean;
  onDelete: (data: Customer) => Promise<void>;
}

const CustomersList: React.FC<CustomersListProps> = (props: CustomersListProps) => {
  const { t } = useTranslation();

  const actions = [];

  if (props.canReadCustomer && !props.canWriteCustomer) {
    actions.push({
      icon: () => <VisibilityIcon />,
      onClick: (event: any, data: Customer | Customer[]) => props.onDetailsClick(event, data as Customer),
      tooltip: t('view'),
    });
  }

  if (props.canWriteCustomer) {
    actions.push({
      icon: () => <CreateIcon />,
      onClick: (event: any, data: Customer | Customer[]) => props.onEditClick(event, data as Customer),
      tooltip: t('edit'),
    });
  }

  return (
    <div className="tableWithActionsContainer">
      <SaraTable<Customer>
        isLoading={props.isLoading}
        items={props.customers.sort((first, second) => compare(first.customerName, second.customerName))}
        columns={Columns(useTranslation())}
        sorting={true}
        isEditable={false}
        actions={actions}
        isDeletable={true}
        onDelete={props.onDelete}
        components={{
          EditRow: (erProps: EditRowProps<Customer>) => (
            <SaraDeleteRow
              columnsBefore={2}
              confirmationMessage="deleteCustomerConfirmation"
              onCancel={() => erProps.onEditingCanceled('delete', erProps.data)}
              onSave={() => erProps.onEditingApproved('delete', erProps.data, erProps.data)}
            />
          ),
        }}
      />
    </div>
  );
};

export default CustomersList;
