import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";

const returnedData: ApiResult<any> = {
    "messages": [],
    "result": [
        {
            "formViewCd": "CUSTOMER",
            "formViewDescr": null
        },
        {
            "formViewCd": "MENU_TASKS",
            "formViewDescr": null
        },
        {
            "formViewCd": "MY_PROJECT",
            "formViewDescr": null
        },
        {
            "formViewCd": "MY_TASK",
            "formViewDescr": null
        },
        {
            "formViewCd": "WORK_TIME",
            "formViewDescr": null
        },
        {
            "formViewCd": "MENU_CALENDAR",
            "formViewDescr": null
        },
        {
            "formViewCd": "CALENDAR_V",
            "formViewDescr": null
        },
        {
            "formViewCd": "CALENDAR_H",
            "formViewDescr": null
        },
        {
            "formViewCd": "EMP_ANNUAL_PAID_VAC",
            "formViewDescr": null
        },
        {
            "formViewCd": "MENU_ADMINISTRATION",
            "formViewDescr": null
        },
        {
            "formViewCd": "OFFICES",
            "formViewDescr": null
        },
        {
            "formViewCd": "LOCATIONS",
            "formViewDescr": null
        },
        {
            "formViewCd": "APP_CONFIG",
            "formViewDescr": null
        },
        {
            "formViewCd": "APP_USER",
            "formViewDescr": null
        },
        {
            "formViewCd": "APP_GROUP",
            "formViewDescr": null
        },
        {
            "formViewCd": "APP_USER_GROUP",
            "formViewDescr": null
        },
        {
            "formViewCd": "FORM_VIEW_CONTROL",
            "formViewDescr": null
        },
        {
            "formViewCd": "CUST_COMPANIES_DATA_ACCESS",
            "formViewDescr": null
        },
        {
            "formViewCd": "PROJECTS_DATA_ACCESS",
            "formViewDescr": null
        },
        {
            "formViewCd": "MENU_REPORTS",
            "formViewDescr": null
        },
        {
            "formViewCd": "REPORTS_BY_EMP",
            "formViewDescr": null
        },
        {
            "formViewCd": "REPORTS_BY_PRJ",
            "formViewDescr": null
        },
        {
            "formViewCd": "PROFILE_PREFERENCE",
            "formViewDescr": null
        },
        {
            "formViewCd": "CUST_PERSON",
            "formViewDescr": null
        },
        {
            "formViewCd": "CUST_OFFICE",
            "formViewDescr": null
        },
        {
            "formViewCd": "CUST_COMPANIES",
            "formViewDescr": null
        },
        {
            "formViewCd": "EMP_VAC_REQ",
            "formViewDescr": null
        },
        {
            "formViewCd": "EMPLOYEES",
            "formViewDescr": null
        },
        {
            "formViewCd": "PROJECT",
            "formViewDescr": null
        },
        {
            "formViewCd": "TASK",
            "formViewDescr": null
        },
        {
            "formViewCd": "WORK_TIME",
            "formViewDescr": null
        },
        {
            "formViewCd": "REPORTS_BY_PRJ_SIMPLE",
            "formViewDescr": null
        }
    ]
}

export const FormViewsGet: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /form_views/,
    method: 'GET'

}
