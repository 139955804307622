import genericApi from './genericApi';
import { FormViewUserControl } from '../models/FormViewUserControl';

const formViewUserControlsReqHandler = {
  loadFormViewUserControls: (filter?: string): Promise<Array<FormViewUserControl>> => {
    return genericApi.get('form_view_user_controls' + (filter ? '?filterFormViewCd=' + encodeURIComponent('^(' + filter + ')$') : ''));
  }
};

export default formViewUserControlsReqHandler;
