import { createStyles, DialogActions, Theme, WithStyles, withStyles } from '@material-ui/core';
import React, { Component, ReactNode, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApiResult } from '../../../api/apiResult';
import CustomersReqHandler from '../../../api/customersRemote';
import Customer from '../../../models/Customer';
import { DialogMode } from '../../../models/DialogMode';
import {
  getCustomer,
  getIsDirty,
  getIsValid,
  getCustomerAppGroups,
  getOldCustomersState,
} from '../../../store/selectors/customerSelectors';
import SaraDialogButtons from '../../common/SaraDialogButtons';
import CustomerAppGroupsContainer from './customerAppGroups/CustomerAppGroupsContainer';
import CustomerDetailsContainer from './customerDetails/CustomerDetailsContainer';
import CustomerDialog from './CustomerDialog';
import AppGroup from '../../../models/AppGroup';
import custAppGroupOverseeReqHandler from '../../../api/custAppGroupOverseeRemote';
import CustAppGroupOversee from '../../../models/CustAppGroupOversee';
import * as deepEqual from 'fast-deep-equal';
import { CustomersState } from '../../../store/reducers/customersReducer';
import { CustomerStep, customerSteps } from '../../../models/Step';
import NotificationDialog from '../../notification/NotificationDialog';
import UpdateCustomer from '../../../models/UpdateCustomer';
import CreateCustAppGroupOversee from '../../../models/CreateCustAppGroupOversee';
import CreateCustomer from '../../../models/CreateCustomer';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      padding: '24px',
      height: '80%',
    },
  });

interface CustomerDialogContainerProps extends WithStyles<typeof styles>, WithTranslation {
  isOpen: boolean;
  customer: Customer;
  customerAppGroups: Array<AppGroup>;
  dialogMode: DialogMode;
  onClose: () => void;
  onSaved: () => void;
  setSnackbarMessage: (message: string) => void;
  isFormDirty: boolean;
  isFormValid: boolean;
  oldState: Partial<CustomersState>;
  appGroupAccessId: number;
}

interface CustomerDialogContainerState {
  showCloseWarning: boolean;
  validated: boolean;
  activeStep: CustomerStep;
  viewSteps: boolean;
  isMove: boolean;
  isOpenNotification: boolean;
  notificationTitle: string;
  notificationContent: string;
}

type CustomerEditRequest = Promise<Customer | CustAppGroupOversee>;

const CUSTOMER_DIALOG_STEPS: Array<string> = customerSteps();

class CustomerDialogContainer extends Component<CustomerDialogContainerProps, CustomerDialogContainerState> {
  state: CustomerDialogContainerState = {
    showCloseWarning: false,
    validated: false,
    activeStep: CustomerStep.DETAILS,
    viewSteps: true,
    isMove: false,
    isOpenNotification: false,
    notificationTitle: '',
    notificationContent: '',
  };

  componentDidMount() {
    this.loadViewSteps();
  }

  componentDidUpdate(prevProps: CustomerDialogContainerProps) {
    if (prevProps !== this.props) {
      this.loadViewSteps();
    }
  }

  loadViewSteps = (): void => {
    let viewSteps: boolean = false;
    let isMove: boolean = false;
    switch (this.props.appGroupAccessId) {
      case 1:
        if (this.props.dialogMode !== DialogMode.CREATE) viewSteps = true;
        break;
      case 2:
        viewSteps = true;
        isMove = true;
        break;
    }
    this.setState({ viewSteps, isMove });
  };

  prepareCustomerToInsert(): UpdateCustomer {
    //we might have additional fields from the list of customers + fax cannot be null
    return {
      customerName: this.props.customer.customerName.trim(),
      billToText: this.props.customer.billToText ? this.props.customer.billToText.trim() : undefined,
      customerAddress: this.props.customer.customerAddress ? this.props.customer.customerAddress.trim() : undefined,
      customerEmail: this.props.customer.customerEmail ? this.props.customer.customerEmail.trim() : undefined,
      customerPhone: this.props.customer.customerPhone ? this.props.customer.customerPhone.trim() : undefined,
      notes: this.props.customer.notes ? this.props.customer.notes.trim() : undefined,
      taxCode: this.props.customer.taxCode ? this.props.customer.taxCode.trim() : undefined,
      amId: this.props.customer.amId,
    };
  }

  prepareAppGroupsToInsert(): Array<CreateCustAppGroupOversee> {
    return this.props.customerAppGroups.map((item: AppGroup) => {return {appGroupId: item.appGroupId};})
  }

  handleSave = () => {
    const customerData: CreateCustomer = {
      customer: this.prepareCustomerToInsert(),
      customerAppGroupOverSees: this.prepareAppGroupsToInsert()
    }
    this.setState({ showCloseWarning: false, validated: false });
    CustomersReqHandler.createCustomer(customerData)
    .then(() => {
      this.setState({ activeStep: CustomerStep.DETAILS, validated: false });
      this.props.onSaved();
    })
    .catch(error => this.handleError(error));
  };

  handleEdit = (shouldClose: boolean, nextStep: boolean) => {
    const stepToEdit = this.state.activeStep;
    this.setState({ showCloseWarning: false, validated: false });
    let editRequests: Array<CustomerEditRequest> = [];
    switch (stepToEdit) {
      case CustomerStep.DETAILS:
        if (this.props.oldState.customer && !deepEqual.default(this.props.customer, this.props.oldState.customer)) {
          editRequests.push(CustomersReqHandler.updateCustomer(this.props.customer.customerId, this.prepareCustomerToInsert()));
        }
        break;
      case CustomerStep.APP_GROUP:
        const { groupsToAdd, groupsToDel } = this.generateAppGroupsRequests();
        if (groupsToAdd.length) {
          editRequests = editRequests.concat(
            groupsToAdd.map((group: AppGroup) =>
              custAppGroupOverseeReqHandler.createCustAppGroupOversee({
                customerId: this.props.customer.customerId,
                appGroupId: group.appGroupId,
              })
            )
          );
        }
        if (groupsToDel.length) {
          editRequests = editRequests.concat(
            groupsToDel.map((group: AppGroup) =>
              custAppGroupOverseeReqHandler.deleteCustAppGroupOversee(this.props.customer.customerId, group.appGroupId)
            )
          );
        }
        break;
    }

    Promise.all(editRequests)
      .then(response => {
        if (response.length) {
          this.props.setSnackbarMessage(this.props.t('customerUpdatedSuccessfully'));
        }
        if (shouldClose) {
          this.setState({ showCloseWarning: false, validated: false, activeStep: CustomerStep.DETAILS });
          this.props.onClose();
        }
        if (nextStep) {
          this.setState(prevState => ({ activeStep: prevState.activeStep + 1, validated: false, showCloseWarning: false }));
        }
      })
      .catch(error => this.handleError(error));
  };

  generateAppGroupsRequests(): { groupsToAdd: Array<AppGroup>; groupsToDel: Array<AppGroup> } {
    let groupsToAdd: Array<AppGroup> = [];
    let groupsToDel: Array<AppGroup> = [];
    if (this.props.oldState.customerAppGroups) {
      const oldAppGroups = this.props.oldState.customerAppGroups;
      groupsToAdd = this.props.customerAppGroups.filter(group => !oldAppGroups.some(oldGroup => oldGroup.appGroupId === group.appGroupId));
      groupsToDel = oldAppGroups.filter(oldGroup => !this.props.customerAppGroups.some(group => group.appGroupId === oldGroup.appGroupId));
    }
    return { groupsToAdd, groupsToDel };
  }

  handleClose = () => {
    if (this.props.isFormDirty && !this.state.showCloseWarning) {
      this.setState({ showCloseWarning: true });
      return;
    }
    const { groupsToAdd, groupsToDel } = this.generateAppGroupsRequests();
    if ((groupsToAdd.length || groupsToDel.length) && !this.state.showCloseWarning) {
      this.setState({ showCloseWarning: true });
      return;
    }
    this.setState({ showCloseWarning: false, validated: false, activeStep: CustomerStep.DETAILS });
    this.props.onClose();
  };

  handleError = (error: { response: { data: ApiResult<any> } }) => {
    this.setState({
      isOpenNotification: true,
      notificationTitle: 'unexpectedErrorMessageTitle',
      notificationContent: 'unexpectedErrorMessage',
    });
  };

  getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <CustomerDetailsContainer validated={this.state.validated} dialogMode={this.props.dialogMode} onError={this.handleError} />;
      case 1:
        return <CustomerAppGroupsContainer dialogMode={this.props.dialogMode} onError={this.handleError} isMove={this.state.isMove} />;
      default:
        return null;
    }
  };

  onClickLabel = (step: string) => {
    if (!this.props.isFormValid) {
      this.setState({ validated: true });
      return;
    }
    if (this.props.dialogMode === DialogMode.UPDATE) {
      this.handleEdit(false, false);
    }

    const activeStep = CUSTOMER_DIALOG_STEPS.findIndex(item => item === step);
    this.setState({ activeStep, showCloseWarning: false });
  };

  handlePrimary = () => {
    if (this.props.dialogMode !== DialogMode.VIEW) {
      if (!this.props.isFormValid) {
        this.setState({ validated: true });
        return;
      }
    }

    if (this.state.activeStep === CUSTOMER_DIALOG_STEPS.length - 1) {
      if (this.props.dialogMode === DialogMode.CREATE) {
        this.handleSave();
      } else {
        this.handleEdit(true, false);
      }
    } else {
      switch (this.props.appGroupAccessId) {
        case 0:
          if (this.props.dialogMode === DialogMode.CREATE) {
            this.handleSave();
          } else {
            this.handleEdit(true, true);
          }
          break;
        case 1:
          if (this.props.dialogMode === DialogMode.VIEW) {
            this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
          }
          if (this.props.dialogMode === DialogMode.UPDATE) {
            this.handleEdit(false, true);
          }
          if (this.props.dialogMode === DialogMode.CREATE) {
            this.handleSave();
          }

          break;
        case 2:
          if (this.props.dialogMode === DialogMode.UPDATE) {
            this.handleEdit(false, true);
          } else {
            this.setState(prevState => ({ activeStep: prevState.activeStep + 1 }));
          }
          break;
      }
    }
  };

  handleSecondary = () => {
    if (this.state.activeStep === CustomerStep.DETAILS) {
      this.handleClose();
    } else {
      if (this.props.dialogMode === DialogMode.UPDATE) {
        this.handleEdit(false, false);
      }
      this.setState(prevState => ({ activeStep: prevState.activeStep - 1 }));
    }
  };

  getPrimaryButtonText = (): string => {
    if (!this.state.viewSteps) {
      return this.props.t('save');
    } else {
      if (this.state.activeStep === CUSTOMER_DIALOG_STEPS.length - 1) {
        if (this.props.dialogMode === DialogMode.CREATE) {
          return this.props.t('save');
        } else {
          const { groupsToAdd, groupsToDel } = this.generateAppGroupsRequests();
          if (groupsToAdd.length || groupsToDel.length) {
            return this.props.t('save');
          }
          return this.props.t('close');
        }
      } else {
        return this.props.t('next');
      }
    }
  };

  closeNotificationDialog = (): void => {
    this.setState({ isOpenNotification: false, notificationTitle: '', notificationContent: '' });
  };

  render() {
    const secondaryText =
      this.state.activeStep === CustomerStep.DETAILS || !this.state.viewSteps ? this.props.t('cancel') : this.props.t('back');
    const dialogActions: ReactNode =
      this.props.dialogMode === DialogMode.VIEW && this.props.appGroupAccessId === 0 ? null : (
        <DialogActions>
          <SaraDialogButtons
            secondaryText={secondaryText}
            onSecondary={this.handleSecondary}
            primaryText={this.getPrimaryButtonText()}
            onPrimary={this.handlePrimary}
            primaryDisabled={!this.props.isFormValid}
          />
        </DialogActions>
      );

    return (
      <Fragment>
        <CustomerDialog
          onClose={this.handleClose}
          onClickLabel={this.onClickLabel}
          dialogMode={this.props.dialogMode}
          dialogActions={dialogActions}
          isOpen={this.props.isOpen}
          customer={this.props.customer}
          activeStep={this.state.activeStep}
          showWarning={this.state.showCloseWarning}
          steps={CUSTOMER_DIALOG_STEPS}
          stepContent={this.getStepContent}
          viewSteps={this.state.viewSteps}
        />
        {this.state.isOpenNotification && (
          <NotificationDialog
            open={this.state.isOpenNotification}
            title={this.state.notificationTitle}
            content={this.state.notificationContent}
            onClose={this.closeNotificationDialog}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  customer: getCustomer(state),
  customerAppGroups: getCustomerAppGroups(state),
  isFormDirty: getIsDirty(state),
  isFormValid: getIsValid(state),
  oldState: getOldCustomersState(state),
});

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(CustomerDialogContainer)));
