import React from 'react';
import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import StatusIndicator from "../../../common/StatusIndicator";
import { FormViewControl } from '../../../../models/FormViewControl';
import AppGroup from '../../../../models/AppGroup';

const StatusIndicatorColumn = (translation: UseTranslationResponse, title: string): Column<AppGroup> => {
    return {
      title: title,
      cellStyle: {textAlign: 'center', width: 24},
      headerStyle: {textAlign: 'center'},
      render: (rowData: AppGroup) => {
        let accessId: number = 0;
        if (rowData.formViewControls) {
          const fvc: FormViewControl | undefined = rowData.formViewControls.find(formViewControl => formViewControl.formViewCd === title);
          if (fvc) {
            accessId = fvc.accessId;
          }
        }
        return <StatusIndicator
                value={accessId}
                activeLabel={translation.t('permissionReadWrite')}
                semiActiveLabel={translation.t('permissionReadOnly')}
                inactiveLabel={translation.t('permissionNone')}
        />
      },
      customSort: (first: AppGroup, second: AppGroup) => {
        if (first.formViewControls && second.formViewControls) {
          const firstFvc: FormViewControl | undefined = first.formViewControls.find(formViewControl => formViewControl.formViewCd === title);
          const secondFvc: FormViewControl | undefined = second.formViewControls.find(formViewControl => formViewControl.formViewCd === title);
          if (firstFvc && secondFvc) {
            return firstFvc.accessId - secondFvc.accessId;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      }
    };
};

export default StatusIndicatorColumn;
