import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import Project from "../../../../models/Project";
import { compare } from "../../../../utils/string";

const SOWCodeColumn = (translation: UseTranslationResponse): Column<Project> => {
    return {
      title: translation.t('sowCode'),
      field: 'sowCode',
      customSort: (first: Project, second: Project) => {
        return compare(first.sowCode, second.sowCode);
      }
    };
};

export default SOWCodeColumn;
