import Customer from '../../models/Customer';
import { CustomerActionType } from './customerActionType';
import AppGroup from '../../models/AppGroup';
import { CustomersState } from '../reducers/customersReducer';

export const addAppGroup = (appGroup: AppGroup) => ({
  type: CustomerActionType.ADD_APP_GROUP,
  payload: { appGroup }
});

export const addToOldCustomersState = (oldState: Partial<CustomersState>) => ({
  type: CustomerActionType.ADD_TO_OLD_CUSTOMERS_STATE,
  payload: { oldState }
});

export const clearCustomer = () => ({ type: CustomerActionType.CLEAR_CUSTOMER });

export const clearCustomersState = () => ({ type: CustomerActionType.CLEAR_CUSTOMERS_STATE });

export const clearOldCustomersState = () => ({ type: CustomerActionType.CLEAR_OLD_CUSTOMERS_STATE });

export const removeAppGroup = (appGroup: AppGroup) => ({
  type: CustomerActionType.REMOVE_APP_GROUP,
  payload: { appGroup }
});

export const storeCustomer = (customer: Customer) => ({
  type: CustomerActionType.STORE_CUSTOMER,
  payload: { customer },
});

export const switchDirty = (isDirty: boolean) => ({
  type: CustomerActionType.SWITCH_DIRTY,
  payload: { isDirty }
});

export const switchValid = (isValid: boolean) => ({
  type: CustomerActionType.SWITCH_VALID,
  payload: { isValid}
});
