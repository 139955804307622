import React from 'react';
import Moment from 'moment';
import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import Task from "../../../../../../models/Task";

const StartDateColumn = (translation: UseTranslationResponse): Column<Task> => {
    return {
      title: translation.t('startDate'), 
      field: 'taskStartDate', 
      type: 'date',
      render: (rowData: Task) => {
        return <span>{Moment(rowData.taskStartDate).format('DD/MM/YYYY')}</span>;
      },
      cellStyle: { minWidth: "75px" }
    };
};

export default StartDateColumn;