import genericApi from './genericApi';
import Customer from '../models/Customer';
import UpdateCustomer from '../models/UpdateCustomer';
import CreateCustomer from '../models/CreateCustomer';

const customersReqHandler = {
  loadCustomers: (orderBy?: string, orderByType?: string): Promise<Array<Customer>> => {
    let url = 'customers';
    if (orderBy) {
      url += '?sort=' + orderBy + ',' + (orderByType ? orderByType : 'ASC');
    } else {
      url += '?sort=customerName,ASC';
    }
    return genericApi.get(url);
  },
  createCustomer: (data: CreateCustomer): Promise<Customer> => {
    return genericApi.post('customers', data);
  },
  updateCustomer: (id: number, customer: UpdateCustomer): Promise<Customer> => {
    return genericApi.put(`customers/${id}`, customer);
  },
  deleteCustomer: (id: number): Promise<Customer> => {
    return genericApi.delete(`customers/${id}`);
  },
};

export default customersReqHandler;
