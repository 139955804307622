import React from 'react';
import { Tooltip } from '@material-ui/core';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@fullcalendar/core';
import { useTranslation } from 'react-i18next';
import { Check, Flag, Close, Autorenew } from '@material-ui/icons';
import { createClassName } from '../../../utils/array';

const styles = (theme: Theme) =>
  createStyles({
    statusIndicator: {
      width: 20,
      height: 20,
      borderRadius: '50%',
      color: 'white',
    },
    pendingStatus: {
      backgroundColor: '#FDC030',
    },
    approvedStatus: {
      backgroundColor: '#29BB9C',
    },
    rejectedStatus: {
      backgroundColor: 'red',
    },
    finalizedStatus: {
      backgroundColor: '#057c05',
    },
  });

interface RequestStatusIndicatorProps extends WithStyles<typeof styles> {
  status: number;
}

const RequestStatusIndicator: React.FC<RequestStatusIndicatorProps> = (props: RequestStatusIndicatorProps) => {
  const { t } = useTranslation();

  let tooltipText = t('pending');
  let tooltipIcon = <Autorenew className={createClassName([props.classes.statusIndicator, props.classes.pendingStatus])} />;

  switch (props.status) {
    case 0:
      tooltipText = t('pending');
      tooltipIcon = <Autorenew className={createClassName([props.classes.statusIndicator, props.classes.pendingStatus])} />;
      break;
    case 1:
      tooltipText = t('approved');
      tooltipIcon = <Check className={createClassName([props.classes.statusIndicator, props.classes.approvedStatus])} />;
      break;
    case 2:
      tooltipText = t('rejected');
      tooltipIcon = <Close className={createClassName([props.classes.statusIndicator, props.classes.rejectedStatus])} />;
      break;
    case 3:
      tooltipText = t('finalized');
      tooltipIcon = <Flag className={createClassName([props.classes.statusIndicator, props.classes.finalizedStatus])} />;
      break;
  }
  return <Tooltip title={tooltipText}>{tooltipIcon}</Tooltip>;
};

export default withStyles(styles)(RequestStatusIndicator);
