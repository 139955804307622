import React, { Component } from 'react';
import { ApiResult } from '../../../../api/apiResult';
import AppGroupsReqHandler from '../../../../api/appGroupsRemote';
import prjAppGroupOverseeReqHandler from '../../../../api/prjAppGroupOverseeRemote';
import { DialogMode } from '../../../../models/DialogMode';
import AppGroupAccess from './AppGroupAccess';
import PrjAppGroupOversee from '../../../../models/PrjAppGroupOversee';
import AppGroup from '../../../../models/AppGroup';
import { compare } from '../../../../utils/string';
import Project from '../../../../models/Project';

interface AppGroupAccessContainerProps {
  dialogMode: DialogMode;
  project: Project;
  appGroups: Array<AppGroup>;
  onError: (error: { response: { data: ApiResult<any> } }) => void;
  addAppGroup: (appGroup: AppGroup, initial?: boolean) => void;
  removeAppGroup: (appGroup: AppGroup) => void;
  appGroupAccessId: number;
}

interface AppGroupAccessContainerState {
  isLoading: boolean;
  inaccAppGroups: Array<AppGroup>;
}

class AppGroupAccessContainer extends Component<AppGroupAccessContainerProps, AppGroupAccessContainerState> {
  state: AppGroupAccessContainerState = {
    isLoading: false,
    inaccAppGroups: [],
  };

  componentDidMount() {
    const appGroupRequests: Array<Promise<any>> = [AppGroupsReqHandler.loadAppGroups()];
    if (this.props.dialogMode !== DialogMode.CREATE && !this.props.appGroups.length) {
      appGroupRequests.push(prjAppGroupOverseeReqHandler.loadPrjAppGroupOversees(this.props.project.prjId));
    }
    this.setState({ isLoading: true }, () =>
      Promise.all(appGroupRequests)
        .then(([appGroups, oversees]) => {
          let matchingAppGroupOversees: Array<PrjAppGroupOversee> = [];
          if (oversees) {
            matchingAppGroupOversees = oversees.filter((oversee: PrjAppGroupOversee) => oversee.projectId === this.props.project.prjId);
          }
          const filteredAppGroups = appGroups.filter((appGroup: AppGroup) => {
            if (matchingAppGroupOversees.length) {
              const isAppGroupInGroup = !!matchingAppGroupOversees.find(group => group.appGroupId === appGroup.appGroupId);
              if (isAppGroupInGroup) {
                this.props.addAppGroup(appGroup, true);
              }
            }
            return this.props.appGroups.findIndex(p => p.appGroupId === appGroup.appGroupId) < 0;
          });
          const inaccAppGroups = this.sortAppGroups(filteredAppGroups);
          this.setState({ inaccAppGroups, isLoading: false });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          this.props.onError(error);
        })
    );
  }

  handleMove = (id: number, toAuthorized: boolean) => {
    if (this.props.dialogMode === DialogMode.VIEW) {
      return;
    }
    const appGroupsList = [...this.state.inaccAppGroups];
    if (toAuthorized) {
      let appGroupIndex = 0;
      const selectedAppGroup = appGroupsList.find((appGroup, index) => {
        appGroupIndex = index;
        return appGroup.appGroupId === id;
      });
      appGroupsList.splice(appGroupIndex, 1);
      if (selectedAppGroup) {
        this.props.addAppGroup(selectedAppGroup);
      }
    } else {
      const selectedAppGroup = this.props.appGroups.find(appGroup => {
        return appGroup.appGroupId === id;
      });
      if (selectedAppGroup) {
        this.props.removeAppGroup(selectedAppGroup);
        appGroupsList.push(selectedAppGroup);
      }
    }
    this.setState({ inaccAppGroups: this.sortAppGroups(appGroupsList) });
  };

  private sortAppGroups(appGroups: Array<AppGroup>): Array<AppGroup> {
    return appGroups.sort((first, second) => compare(first.appGroupName, second.appGroupName));
  }

  render() {
    return (
      <AppGroupAccess
        dialogMode={this.props.dialogMode}
        isLoading={this.state.isLoading}
        unassGroups={this.state.inaccAppGroups}
        assGroups={this.sortAppGroups(this.props.appGroups)}
        moveAppGroup={this.props.appGroupAccessId === 2 ? this.handleMove : () => {}}
        readonly={this.props.appGroupAccessId !== 2}
      />
    );
  }
}

export default AppGroupAccessContainer;
