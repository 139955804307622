import { Checkbox, FormControl, FormHelperText, Input, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { ChangeEvent, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface SaraMultiSelectProps {
  id: string;
  value: any;
  onChange?: (event: ChangeEvent<{ name?: string; value: unknown }>, child: ReactNode) => void;
  validated: boolean;
  lookups: Array<Lookup>;
  label?: string;
  required?: boolean;
  multiple?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

export interface Lookup {
  value: number;
  text: string;
}

export const SaraMultiSelect: FC<SaraMultiSelectProps> = (props: SaraMultiSelectProps) => {
  const { t } = useTranslation();
  const selectHelperText = (value: number[]) => {
    if (props.required && props.validated && ((value && value.length === 0) || !value)) {
      return <FormHelperText error={true}>{t('fieldIsMandatory')}</FormHelperText>;
    }
    return null;
  };

  const renderValue = (selectedItems: Array<number>): string => {
    const displayLookups: Array<Lookup> = [];
    for (const item of selectedItems) {
      const result = props.lookups.find((lookup: Lookup) => lookup.value === item);
      if (result) {
        displayLookups.push(result);
      }
    }
    return displayLookups.map(item => item.text).join(', ');
  };

  const error: boolean | undefined = props.required ? props.validated && (!props.value || props.value.length === 0) : undefined;

  return (
    <FormControl fullWidth>
      <InputLabel required={props.required} shrink id={props.id + '-label'} error={error} htmlFor={props.id + '-label'}>
        {props.label}
      </InputLabel>
      <Select
        id={props.id}
        value={props.value ? props.value : ''}
        onChange={props.onChange}
        fullWidth={true}
        error={error}
        multiple={props.multiple}
        disabled={props.disabled}
        renderValue={(selected: any) => renderValue(selected)}
        input={<Input />}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {props.lookups && props.lookups.length > 0 ? (
          props.lookups.map((lookup, index) => (
            <MenuItem key={lookup.value} value={lookup.value}>
              <Checkbox checked={props.value.indexOf(lookup.value) > -1} color="primary" />
              {lookup.text}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>{t('noItemsAvailable')}</MenuItem>
        )}
      </Select>
      {selectHelperText(props.value)}
    </FormControl>
  );
};
