import genericApi from './genericApi';
import Task from '../models/Task';
import TaskModel from '../models/TaskModel';
import UpdateTask from '../models/UpdateTask';

const baseUrl = 'tasks';

const tasksReqHandler = {
  loadTasks: (empId?: number, startDate?: string, endDate?: string, statusCd?: string): Promise<Array<Task>> => {
    const filter = `${empId ? `&filterEmpId=${empId}`: ''}${startDate ? `&filterFromDate=${startDate}` : ''}
    ${endDate ? `&filterToDate=${endDate}` : ''}${statusCd ? `filterStatusCd=${statusCd}` : ''}`
    return genericApi.get(`${baseUrl}?sort=projectName${filter}`);
  },
  loadTasksPerProject: (prjId: number): Promise<Array<TaskModel>> => genericApi.get(`${baseUrl}?filterProjectId=${prjId}`),
  insertTask: (task: UpdateTask): Promise<TaskModel> => genericApi.post(baseUrl, task),
  deleteTask: (taskId: number): Promise<void> => genericApi.delete(`${baseUrl}/${taskId}`),
  updateTask: (id: number, task: UpdateTask): Promise<TaskModel> => genericApi.put(`${baseUrl}/${id}`, task),
};

export default tasksReqHandler;
