import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerDetails from './CustomerDetails';
import { getCustomer } from '../../../../store/selectors/customerSelectors';
import { DialogMode } from '../../../../models/DialogMode';
import { ApiResult } from '../../../../api/apiResult';
import Customer from '../../../../models/Customer';
import Employee from '../../../../models/Employee';
import { Lookup } from '../../../saraSelect/SaraMultiSelect';
import EmployeesReqHandler from '../../../../api/employeesRemote';
import { bindActionCreators } from 'redux';
import { storeCustomer, clearCustomer, switchDirty, switchValid, addToOldCustomersState } from '../../../../store/actions/customerActions';
import { CustomerActionType } from '../../../../store/actions/customerActionType';
import { CustomersState } from '../../../../store/reducers/customersReducer';
import {ensureArrayContains} from '../../../../utils/array';

interface CustomerDetailsContainerProps {
  customer: Customer;
  validated: boolean;
  dialogMode: DialogMode;
  onError: (error: { response: { data: ApiResult<any> } }) => void;
  storeCustomer: (customer: Customer) => { type: CustomerActionType; payload: { customer: Customer } };
  clearCustomer: () => { type: CustomerActionType };
  switchDirty: (isDirty: boolean) => { type: CustomerActionType; payload: { isDirty: boolean } };
  switchValid: (isValid: boolean) => { type: CustomerActionType; payload: { isValid: boolean } };
  addToOldCustomersState: (
    oldState: Partial<CustomersState>
  ) => { type: CustomerActionType; payload: { oldState: Partial<CustomersState> } };
}

interface CustomerDetailsContainerState {
  touchedFields: {
    customerEmail: boolean;
    customerPhone: boolean;
  };
  employees: Array<Employee>;
}

class CustomerDetailsContainer extends Component<CustomerDetailsContainerProps, CustomerDetailsContainerState> {
  state: CustomerDetailsContainerState = {
    touchedFields: {
      customerEmail: false,
      customerPhone: false,
    },
    employees: [],
  };

  componentDidMount() {
    this.props.switchDirty(false);
    EmployeesReqHandler.loadEmployees()
      .then((employees: Array<Employee>) => {
        this.setState({ employees });
      })
      .catch(error => this.props.onError(error));
    if (this.props.dialogMode === DialogMode.UPDATE) {
      this.props.switchValid(true);
      this.props.addToOldCustomersState({ customer: this.props.customer });
    }
  }

  componentWillUnmount() {
    this.props.switchDirty(false);
  }

  isValidPhone(inputPhone?: string): boolean {
    const phone = inputPhone || inputPhone === '' ? inputPhone : this.props.customer.customerPhone;
    return !phone || (!!phone && /^(\+|00?)?[1-9]\d+$/.test(phone));
  }

  isValidEmail(inputEmail?: string): boolean {
    const email = inputEmail || inputEmail === '' ? inputEmail : this.props.customer.customerEmail;
    return !email || (!!email && /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9]+)+$/.test(email));
  }

  handleChange = (event: any, field: string) => {
    const newCustomer: Customer = { ...this.props.customer };
    switch (field) {
      case 'amId':
        newCustomer.amId = event.target.value;
        break;
      case 'customerAddress':
      case 'customerEmail':
      case 'customerName':
      case 'customerPhone':
      case 'notes':
      case 'taxCode':
      case 'billToText':
        newCustomer[field] = event.target.value;
        break;
      default:
        break;
    }

    this.props.storeCustomer(newCustomer);
    this.props.switchDirty(true);
    this.props.switchValid(
      !!newCustomer.customerName &&
        !!newCustomer.amId &&
        this.isValidEmail(newCustomer.customerEmail) &&
        this.isValidPhone(newCustomer.customerPhone)
    );
  };

  markAsTouched = (fieldName: string) => {
    this.setState({
      touchedFields: {
        ...this.state.touchedFields,
        [fieldName]: true,
      },
    });
  };

  render() {
    const employeeLookups = this.state.employees.map(
      (employee: Employee): Lookup => {
        return {
          text: `${employee.empFirstName} ${employee.empLastName}`,
          value: employee.empId,
        };
      }
    );
    if(this.props.customer.accountManagerName){
      ensureArrayContains(employeeLookups, this.props.customer.amId, this.props.customer.accountManagerName);
    }
    return (
      <CustomerDetails
        customer={this.props.customer}
        dialogMode={this.props.dialogMode}
        handleChange={this.handleChange}
        markAsTouched={this.markAsTouched}
        isPhoneValid={!this.state.touchedFields.customerPhone || this.isValidPhone()}
        isEmailValid={!this.state.touchedFields.customerEmail || this.isValidEmail()}
        validated={this.props.validated}
        employees={employeeLookups}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({ customer: getCustomer(state) });

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({ storeCustomer, clearCustomer, switchDirty, switchValid, addToOldCustomersState }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsContainer);
