import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogMode } from '../../../../models/DialogMode';
import Employee from '../../../../models/Employee';
import SaraTransferLists from '../../../common/SaraTransferLists';
import appGroupsStyles from '../../AppGroups.module.css';

interface EmployeeAccessProps {
  isLoading: boolean;
  inaccEmployees: Array<Employee>;
  accEmployees: Array<Employee>;
  dialogMode: DialogMode;
  moveEmployee: (id: number, toAuthorized: boolean) => void;
}

const EmployeeAccess: React.FC<EmployeeAccessProps> = (props: EmployeeAccessProps) => {
  const { t } = useTranslation();
  const listHeightStyle = props.dialogMode === DialogMode.VIEW ? appGroupsStyles.readonlyListHeight : appGroupsStyles.listHeight;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>{t('assignStaffDataAccess')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <SaraTransferLists
          showSearchBar={props.dialogMode !== DialogMode.VIEW}
          leftHeader={t('inaccessible')}
          leftListStyle={`${appGroupsStyles.inaccList} ${listHeightStyle}`}
          leftItems={props.inaccEmployees.map((item: Employee) => ({
            key: item.empId,
            value: item,
            text: `${item.empFirstName} ${item.empLastName}`,
          }))}
          rightHeader={t('accessible')}
          rightListStyle={`${appGroupsStyles.accList} ${listHeightStyle}`}
          rightItems={props.accEmployees.map((item: Employee) => ({
            key: item.empId,
            value: item,
            text: `${item.empFirstName} ${item.empLastName}`,
          }))}
          handleMove={(id, toAuthorized) => props.moveEmployee(id, toAuthorized)}
        />
      </Grid>
    </Grid>
  );
};

export default EmployeeAccess;
