import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import CalendarEvent from '../../../../models/CalendarEvent';
import { StatusValues } from '../../../../models/StatusValues';

export default (translation: UseTranslationResponse): Column<CalendarEvent> => ({
  title: translation.t('comment'),
  field: 'notes',
  headerStyle: { paddingRight: '0px', paddingLeft: '0px', width: '200px' },
  cellStyle: (_, rowData) => ({
    paddingRight: '0px',
    paddingLeft: '0px',
    width: '200px',
    // `clear` CSS property is added in order to be targeted the sibling cell with Edit and Delete buttons
    clear: rowData.statusId === StatusValues.FINALIZED || rowData.statusId === StatusValues.REJECTED ? 'none' : 'unset',
  }),
});
