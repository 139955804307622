import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PeopleIcon from '@material-ui/icons/People';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import StarsIcon from '@material-ui/icons/Stars';
import { DateRange, CalendarViewDay } from '@material-ui/icons';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import React from 'react';
import { Route } from 'react-router-dom';
import AppGroupsContainer from '../components/appgroups/AppGroupsContainer';
import CustomersContainer from '../components/customers/CustomersContainer';
import ProjectsContainer from '../components/projects/ProjectsContainer';
import ReportsContainer from '../components/reports/ReportsContainer';
import ReportOvertimeContainer from '../components/reportOvertime/ReportOvertimeContainer';
import TeamContainer from '../components/team/TeamContainer';
import WorkTimeContainer from '../components/workTime/WorkTimeContainer';
import CalendarContainer from '../components/calendar/CalendarContainer';
import { FormViewCondition } from '../models/FormViewCondition';
import RequestsContainer from '../components/requests/RequestsContainer';
import OfficeContainer from '../components/offices/OfficeContainer';
import HistoryIcon from '@material-ui/icons/History';
import UpdateIcon from '@material-ui/icons/Update';

const menuItems = [
  {
    label: 'workTime',
    icon: <AccessTimeIcon />,
    link: '/',
    formViewCd: FormViewCondition.WORK_TIME,
    route: permissions => (
      <Route
        exact
        key="worktime"
        path="/"
        render={props => (
          <WorkTimeContainer
            {...props}
            canReadWorkTime={permissions.canRead}
            canWriteWorkTime={permissions.canWrite}
            extendedRead={permissions.extendedRead}
            extendedWrite={permissions.extendedWrite}
          />
        )}
      />
    ),
  },
  {
    label: 'schedule',
    icon: <PermContactCalendarIcon />,
    link: '',
    formViewCd: FormViewCondition.CALENDAR,
    children: [
      {
        label: 'calendar',
        icon: <DateRange />,
        link: '/calendar',
        formViewCd: FormViewCondition.CALENDAR,
        route: permissions => (
          <Route exact key="calendar" path="/calendar" render={props => <CalendarContainer {...props} permissions={permissions} />} />
        ),
      },
      {
        label: 'requests',
        icon: <CalendarViewDay />,
        link: '/requests',
        formViewCd: FormViewCondition.CALENDAR,
        route: permissions => (
          <Route exact key="requests" path="/requests" render={props => <RequestsContainer {...props} permissions={permissions} />} />
        ),
      },
      {
        label: 'offices',
        icon: <EventNoteIcon />,
        link: '/offices',
        formViewCd: FormViewCondition.CALENDAR_ADMIN,
        route: permissions => (
          <Route
            exact
            key="offices"
            path="/offices"
            render={props => <OfficeContainer {...props} permissions={permissions} />}
          />
        ),
      },
    ]
  },
  {
    label: 'projects',
    icon: <GroupWorkIcon />,
    link: '/projects',
    formViewCd: FormViewCondition.PROJECT,
    route: permissions => {
      return (
        <Route
          exact
          key="projects"
          path="/projects"
          render={props => (
            <ProjectsContainer
              {...props}
              canReadProject={permissions.canRead}
              canWriteProject={permissions.canWrite}
              appGroupAccessId={permissions.appGroupAccessId}
            />
          )}
        />
      );
    },
  },
  {
    label: 'customers',
    icon: <StarsIcon />,
    link: '/customers',
    formViewCd: FormViewCondition.CUSTOMER,
    route: permissions => (
      <Route
        exact
        key="customers"
        path="/customers"
        render={props => (
          <CustomersContainer
            {...props}
            canReadCustomer={permissions.canRead}
            canWriteCustomer={permissions.canWrite}
            appGroupAccessId={permissions.appGroupAccessId}
          />
        )}
      />
    ),
  },
  {
    label: 'team',
    icon: <PeopleIcon />,
    link: '/team',
    formViewCd: FormViewCondition.TEAM,
    route: permissions => (
      <Route
        exact
        key="team"
        path="/team"
        render={props => (
          <TeamContainer
            {...props}
            canReadTeam={permissions.canRead}
            canWriteTeam={permissions.canWrite}
            canWriteAppGroups={permissions.hasWriteAccess(FormViewCondition.APP_GROUP)}
            canReadAppGroups={permissions.hasReadAccess(FormViewCondition.APP_GROUP)}
          />
        )}
      />
    ),
  },
  {
    label: 'reports',
    icon: <EqualizerIcon />,
    link: '',
    formViewCd: FormViewCondition.REPORT_WORK_TIME,
    children: [
      {
        label: 'reportWorkTime',
        icon: <HistoryIcon />,
        link: '/report_worktime',
        formViewCd: FormViewCondition.REPORT_WORK_TIME,
        route: permissions => (
          <Route
            exact
            key="reportWorkTime"
            path="/report_worktime"
            render={props => <ReportsContainer {...props} canReadReports={permissions.canRead} canWriteReports={permissions.canWrite} />}
          />
        ),
      },
      {
        label: 'reportOvertime',
        icon: <UpdateIcon />,
        link: '/report_overtime',
        formViewCd: FormViewCondition.REPORT_OVERTIME,
        route: permissions => (
          <Route
            exact
            key="reportOvertime"
            path="/report_overtime"
            render={props => <ReportOvertimeContainer {...props} canReadReports={permissions.canRead} canWriteReports={permissions.canWrite} />}
          />
        ),
      },
    ],
  },
  {
    label: 'appGroups',
    icon: <PeopleOutlineIcon />,
    link: '/appgroups',
    formViewCd: FormViewCondition.APP_GROUP,
    route: permissions => (
      <Route
        exact
        key="appgroups"
        path="/appgroups"
        render={props => <AppGroupsContainer {...props} canReadAppGroups={permissions.canRead} canWriteAppGroups={permissions.canWrite} />}
      />
    ),
  },
];

export default menuItems;
