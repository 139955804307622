import React, { CSSProperties } from 'react';
import { Tooltip, Theme, createStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import MaterialIcon from "../../icons/IconsStyle";
import { withStyles, WithStyles } from '@material-ui/styles';
import Timer from '@material-ui/icons/Timer';
import Schedule from '@material-ui/icons/Schedule';

const styles = (theme: Theme) =>
  createStyles({
    materialIcon: MaterialIcon
  });

interface WorkTimeModeSwitcherProps extends WithStyles<typeof styles>{
  timerMode: boolean;
  className?: string;
  onSwitchToTimer: ()=>void;
  onSwitchToManual: ()=>void;
  style?: CSSProperties;
}

const WorkTimeModeSwitcher: React.FC<WorkTimeModeSwitcherProps> = (props: WorkTimeModeSwitcherProps) => {
    const { t } = useTranslation();
    if (!props.timerMode) {
        return (
          <Tooltip title={t("switchToTimer")}>
            <span className={props.className} style={props.style}>
              <Timer className={props.classes.materialIcon} onClick={props.onSwitchToTimer} />
            </span>
          </Tooltip>
        );
      }
      return (
        <Tooltip title={t("switchToManual")}>
          <span className={props.className} style={props.style}>
            <Schedule className={props.classes.materialIcon} onClick={props.onSwitchToManual} />
          </span>
        </Tooltip>
      );
}

export default withStyles(styles)(WorkTimeModeSwitcher);
