import { FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { compare } from '../../utils/string';

interface SaraSelectProps {
  id: string;
  value: any;
  onChange?: (event: React.ChangeEvent<{ name?: string; value: unknown }>, child: React.ReactNode) => void;
  validated: boolean;
  lookups: Array<Lookup>;
  label: string;
  required?: boolean;
  readonly?: boolean;
}

export interface Lookup {
  value: any;
  text: string;
}

export const SaraSelect: React.FC<SaraSelectProps> = (props: SaraSelectProps) => {
  const { t } = useTranslation();
  const selectHelperText = (value: number) => {
    if (props.required && props.validated && !value) {
      return <FormHelperText error={true}>{t('fieldIsMandatory')}</FormHelperText>;
    }
    return null;
  };

  let error: boolean | undefined = props.required ? props.validated && !props.value : undefined;

  let theLookups = props.lookups.sort((first, second) => compare(first.text, second.text));
  if (!props.required && props.value !== 0) {
    theLookups = [{ text: t('none'), value: 0 }, ...props.lookups];
  }

  return (
    <Fragment>
      <InputLabel required={props.required} shrink id={props.id + '-label'} error={error} htmlFor={props.id + '-label'}>
        {props.label}
      </InputLabel>
      <Select
        id={props.id}
        value={props.value === 0 ? '' : props.value}
        onChange={props.onChange}
        fullWidth={true}
        error={error}
        required={props.required}
        disabled={props.readonly ? true : false}
        inputProps={{
          readOnly: props.readonly ? true : false,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {theLookups && theLookups.length > 0 ? (
          theLookups.map((lookup, index) => {
            return (
              <MenuItem key={lookup.value} value={lookup.value}>
                {lookup.text}
              </MenuItem>
            );
          })
        ) : (
          <MenuItem disabled>{t('noItemsAvailable')}</MenuItem>
        )}
      </Select>
      {selectHelperText(props.value)}
    </Fragment>
  );
};
