import { SAVE_USER, REMOVE_USER, GO_OFFLINE, GO_ONLINE, SAVE_USER_NAMES, REMOVE_USER_NAMES, SET_TIMEZONE } from './actionsTypes';

import User from '../../models/User';

function removeUser() {
  return {
    type: REMOVE_USER,
  };
}

function saveUser(user: User) {
  return {
    type: SAVE_USER,
    data: user,
  };
}

function setTimezone(timezone: string){
  return {
    type: SET_TIMEZONE,
    data: timezone
  }
}

function goOffline() {
  return {
    type: GO_OFFLINE,
  };
}

function goOnline() {
  return {
    type: GO_ONLINE,
  };
}

function saveUserNames(firstName: string, lastName: string) {
  return {
    type: SAVE_USER_NAMES,
    payload: {
      firstName,
      lastName,
    },
  };
}

function removeUserNames() {
  return {
    type: REMOVE_USER_NAMES,
  };
}

export { saveUser, removeUser, goOffline, goOnline, saveUserNames, removeUserNames, setTimezone };
