import DateFnsUtils from '@date-io/date-fns';
import { Button, Grid, Paper, Theme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { InfoOutlined, VisibilityOutlined } from '@material-ui/icons'
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Lookup, SaraMultiSelect } from '../../saraSelect/SaraMultiSelect';
import { DatePicker } from '@material-ui/pickers'

const styles = (theme: Theme) =>
  createStyles({
    displayBtn: {
      width: 80
    },
    buttonWrapper: {
      textAlign: 'end',
      marginTop: '8px'
    },
    icon: {
      cursor: 'pointer',
      color: '#29BB9C'
    },
    text: {
      paddingLeft: 3,
      paddingTop: 3
    },
    iconInfo: {
      fontSize: 20,
      color: 'grey',
      paddingRight: 3
    }
  });

interface RequestFiltersProps extends WithStyles<typeof styles> {
  statusLookups: Array<Lookup>;
  selectedStatusIds: Array<number>;
  employeeLookups: Array<Lookup>;
  selectedEmployeeIds: Array<number>;
  selectedYear: string;
  selectedEventTypeIds: Array<number>;
  eventTypeLookups: Array<Lookup>;
  selectedOfficeIds: Array<Number>;
  officeLookups: Array<Lookup>;
  onChange: (event: any, field: string) => void;
  filter: () => void;
  extendedRead: boolean | undefined;
  isPendingToApproval: boolean;
  previewPendingApproval: () => void;
  isPeremptionToApproval: boolean;
}

const RequestFilters: React.FC<RequestFiltersProps> = (props: RequestFiltersProps) => {
  
  const { t } = useTranslation();
  const selectedYear = props.selectedYear ? props.selectedYear : new Date();
  const approvalInfo: JSX.Element | null = props.isPeremptionToApproval ?  
    props.isPendingToApproval ? (
      <Grid container item spacing={1}>
    
      <VisibilityOutlined onClick={props.previewPendingApproval} className={props.classes.icon}/>
      <span className={props.classes.text}>{t('previewPendingApproval')}</span>
      </Grid>
    ) : (
      <Grid container item spacing={1}>
        <InfoOutlined className={props.classes.iconInfo}/>
        <span >{t('noApprovalPending')}</span>
      </Grid>
    )
    : null

  return (
    <Paper style={{ padding: 15 }}>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
   
        <Grid container direction="column" spacing={3}>

            { approvalInfo }

          <Grid container item spacing={1}>

            <Grid item xs={12} md={4}>
              <SaraMultiSelect
                validated
                label={t('employee')}
                id="employee-id"
                value={props.selectedEmployeeIds}
                multiple
                onChange={event => props.onChange(event, 'selectedEmployeeIds')}
                lookups={props.employeeLookups}
                disabled={!props.extendedRead}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <SaraMultiSelect
                validated
                label={t('status')}
                id="status-id"
                value={props.selectedStatusIds}
                multiple
                onChange={event => props.onChange(event, 'selectedStatusIds')}
                lookups={props.statusLookups}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                label={t('year')}
                autoOk
                variant="inline"
                value={ selectedYear } 
                onChange={date => props.onChange(date, 'selectedYear')} 
                views={['year']}
                fullWidth={true}
              />
            </Grid>

          </Grid>

          <Grid container item spacing={1}>

            <Grid item xs={12} md={4}>
              <SaraMultiSelect
                validated
                label={t('eventType')}
                id="eventType-id"
                value={props.selectedEventTypeIds}
                multiple
                onChange={event => props.onChange(event, 'selectedEventTypeIds')}
                lookups={props.eventTypeLookups}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <SaraMultiSelect
                validated
                label={t('office')}
                id="office-id"
                value={props.selectedOfficeIds}
                multiple
                onChange={event => props.onChange(event, 'selectedOfficeIds')}
                lookups={props.officeLookups}
              />
            </Grid>

            <Grid item xs={12} md={4} className={ props.classes.buttonWrapper }>
              <Button
                  variant="contained"
                  color="primary"
                  onClick={props.filter}
                  className={ props.classes.displayBtn}
                >
                {t('filter')}
              </Button>
            </Grid>

          </Grid> 

        </Grid>

      </MuiPickersUtilsProvider>
      
    </Paper>
  );
};

export default withStyles(styles)(RequestFilters);
