import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en.json';
import bg from './bg.json';

const resources = {
  en: {
    translation: en
  },
  bg: {
    translation: bg
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;