import genericApi from './genericApi';
import EmpAppGroupOversee from '../models/EmpAppGroupOversee';
import CreateEmpAppGroupOversee from '../models/CreateEmpAppGroupOversee';

const baseUrl: string = 'emp_app_group_oversee';

const empAppGroupOverseeReqHandler = {
  loadEmpAppGroupOversees: (filterEmpId?: number, filterAppGroupId?: number): Promise<Array<EmpAppGroupOversee>> => {
    const filter: string = `${filterEmpId ? `&filterEmpId=${filterEmpId}` : ''}${filterAppGroupId ? `&filterAppGroupId=${filterAppGroupId}` : ''}`;
    return genericApi.get(`${baseUrl}?sort=appGroupId${filter}`);
  },
  loadEmpAppGroupOversee: (empId: number, appGroupId: number): Promise<EmpAppGroupOversee> => {
    return genericApi.get(`${baseUrl}/${empId}/${appGroupId}`);
  },
  createEmpAppGroupOversee: (payload: CreateEmpAppGroupOversee): Promise<EmpAppGroupOversee> => {
    return genericApi.post(baseUrl, payload);
  },
  deleteEmpAppGroupOversee: (empId: number, appGroupId: number): Promise<EmpAppGroupOversee> => {
    return genericApi.delete(`${baseUrl}/${empId}/${appGroupId}`);
  },
};

export default empAppGroupOverseeReqHandler;
