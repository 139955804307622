import React from 'react';
import Moment from 'moment';
import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import Task from "../../../../../../models/Task";

const EndDateColumn = (translation: UseTranslationResponse): Column<Task> => {
    return {
      title: translation.t('endDate'), 
      field: 'taskEndDate', 
      type: 'date',
      render: (rowData: Task) => {
        return <span>{Moment(rowData.taskEndDate).format('DD/MM/YYYY')}</span>;
      },
      cellStyle: { minWidth: "75px" }
    };
};

export default EndDateColumn;