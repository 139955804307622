import { LOGIN_FAIL, LOGIN_SUCCESS } from './actionsTypes';

function loginSuccess() {

  return {
    type: LOGIN_SUCCESS
  }
}

function loginFail() {

  return {
    type: LOGIN_FAIL
  }
}

function loginAction() {

  localStorage.setItem('login', 'true');

  return (dispatch: any) => {
    dispatch(loginSuccess());
  }
};

function logoutAction() {

  localStorage.removeItem('login');

  return (dispatch: any) => {
    dispatch(loginFail());
  }
}

export {
  loginAction,
  logoutAction,
  loginSuccess,
  loginFail
};
