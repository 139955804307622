import genericApi from './genericApi';
import AppUser from '../models/AppUser';
import CreateAppUser from '../models/CreateAppUser';
import ChangePassword from '../models/ChangePassword'; 


const appUsersReqHandler = {
  loadAppUsers: (): Promise<Array<AppUser>> => {
    return genericApi.get('app_users');
  },
  createAppUser: (appUser: CreateAppUser) : Promise<AppUser> => {
    return genericApi.post('app_users', appUser);
  },
  changeAppUserPassword: (userName: string, data: ChangePassword): Promise<number> => {
    return genericApi.put(`app_users/${userName}/password`, data);
  }
};

export default appUsersReqHandler;
