import { CircularProgress, createStyles, Grid, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppGroup from '../../../../models/AppGroup';
import { DialogMode } from '../../../../models/DialogMode';
import SaraTransferLists from '../../../common/SaraTransferLists';

const styles = (theme: Theme) =>
  createStyles({
    inaccList: {
      height: '22em',
      paddingLeft: 20,
    },
    accList: {
      height: '22em',
      marginLeft: -16,
    },
  });

interface CustomerAppGroupsProps extends WithStyles<typeof styles> {
  isLoading: boolean;
  unauthAppGroups: Array<AppGroup>;
  authAppGroups: Array<AppGroup>;
  dialogMode: DialogMode;
  moveAppGroup: (id: number, toAuthorized: boolean) => void;
  readOnly?: boolean;
}

const CustomerAppGroups: React.FC<CustomerAppGroupsProps> = (props: CustomerAppGroupsProps) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>{props.readOnly ? t('readOnlyAssignAppGroupCustomerAccess') : t('assignAppGroupCustomerAccess')}</Typography>
      </Grid>

      <Grid item container spacing={1}>
        {props.isLoading ? (
          <CircularProgress color="primary" className="loadingSpinner" />
        ) : (
          <SaraTransferLists
            showSearchBar={props.dialogMode !== DialogMode.VIEW && !props.readOnly}
            leftItems={props.unauthAppGroups.map((item: AppGroup) => ({ key: item.appGroupId, value: item, text: item.appGroupName }))}
            leftHeader={t('unauthorized')}
            leftListStyle={props.classes.inaccList}
            rightItems={props.authAppGroups.map((item: AppGroup) => ({ key: item.appGroupId, value: item, text: item.appGroupName }))}
            rightListStyle={props.classes.accList}
            rightHeader={t('authorized')}
            handleMove={(id, toAuthorized) => props.moveAppGroup(id, toAuthorized)}
            readonly={props.readOnly}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(CustomerAppGroups);
