import { Lookup } from '../components/saraSelect/SaraSelect';

export const ensureArrayContains = (lookups: Array<Lookup>, value: any, text: string) => {
  if (!lookups.find(x => x.value === value)) {
    lookups.push({ value: value, text: text });
  }
};

export const createClassName = (classes: string[]): string => {
  return classes.join(' ');
}
