import genericApi from './genericApi';
import AppUserGroup from '../models/AppUserGroup';
import UpdateAppUserGroup from '../models/UpdateAppUserGroup';

const baseUrl: string = 'app_user_groups';

const appUserGroupsReqHandler = {
  loadAppUserGroups: (): Promise<Array<AppUserGroup>> => {
    return genericApi.get(baseUrl);
  },
  createAppUserGroup: (appUserGroup: UpdateAppUserGroup) : Promise<AppUserGroup> => {
    return genericApi.post(baseUrl, appUserGroup);
  },
  deleteAppUserGroup: (appUserId: number, appGroupId: number): Promise<AppUserGroup> => {
    return genericApi.delete(`${baseUrl}/${appUserId}/${appGroupId}`);
  },
  getIsAppUserGroupAdmin: (appUserId: number, appGroupId: number): Promise<any> => {
    return genericApi.head(`${baseUrl}/${appUserId}/${appGroupId}`);
  }
};

export default appUserGroupsReqHandler;
