import React from 'react';
import { createStyles, withStyles, WithStyles, Theme, Button, Grid } from '@material-ui/core';
import { Moment } from 'moment';
import { SaraSelect } from '../../saraSelect/SaraSelect';
import { useTranslation } from 'react-i18next';
import Office from '../../../models/Office';
import SaraDatePicker from '../../common/SaraDatePicker';

const styles = (theme: Theme) =>
  createStyles({
    materialIcon: {
      cursor: 'pointer',
      fontSize: '26px'
    },
    filterButton: {
      marginTop: '9px'
    },
    filterButtonDisabled: {
      marginTop: '9px',
      opacity: 0.6
    }
  });

interface OfficeFilterProps extends WithStyles <typeof styles>  {
  selectedFilters: {timeStart: Moment; timeEnd: Moment; officeId: string;};
  offices: Array<Office>;
  changeFilter: Function;
  submitFilter: () => void;
}

const OfficeFilter: React.FC<OfficeFilterProps> = (props: OfficeFilterProps) => {
  const { t } = useTranslation();
  const isEndDateValid: boolean = props.selectedFilters.timeStart > props.selectedFilters.timeEnd? false : true;
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} md={3} xl={2}>
        <SaraDatePicker
            label={t('startDate')}
            required
            value={props.selectedFilters.timeStart}
            onChange={event => props.changeFilter(event, 'timeStart')}
            validated={!props.selectedFilters.timeStart}
            fullWidth={true}
          />
      </Grid>
      <Grid item xs={6} md={3} xl={2}>
        <SaraDatePicker
          label={t('endDate')}
          required
          minDate={props.selectedFilters.timeStart}
          value={props.selectedFilters.timeEnd}
          onChange={event => props.changeFilter(event, 'timeEnd')}
          validated={!props.selectedFilters.timeEnd}
          error={!isEndDateValid}
          helperText={isEndDateValid? '' : t('endDateShouldBeAfterStartDate')}
          fullWidth={true}
        />
      </Grid>
      <Grid item xs={12} md={3} xl={2}>
        <SaraSelect
            id="calendar-office"
            label={t('office')}
            validated={true}
            value={props.selectedFilters.officeId}
            lookups={props.offices.map((office: Office) => ({
              text: office.officeName,
              value: office.officeId,
            }))}
            onChange={(event: any) => props.changeFilter(event, 'officeId')}
            required
          />
      </Grid>
      <Grid item xs={12} md={3} xl={6}>
        <Button variant="contained" color="primary"
          className={!props.selectedFilters.timeStart || !props.selectedFilters.timeEnd || !isEndDateValid? props.classes.filterButtonDisabled : props.classes.filterButton}
          onClick={props.submitFilter}>{t('filter')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(OfficeFilter);
