import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import WorkItem from '../../../../models/WorkItem';
import DateColumn from './Date';
import DescriptionColumn from './Description';
import EndTimeColumn from './EndTime';
import HoursColumn from './Hours';
import ProjectColumn from './Project';
import StartTimeColumn from './StartTime';
import TaskColumn from './Task';

const columns = (translation: UseTranslationResponse): Column<WorkItem>[] => {
  return [
    TaskColumn(translation),
    ProjectColumn(translation),
    DescriptionColumn(translation),
    DateColumn(translation),
    StartTimeColumn(translation),
    EndTimeColumn(translation),
    HoursColumn(translation),
  ];
};

export default columns;
