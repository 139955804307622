import WorkItem from '../models/WorkItem';
import genericApi, { baseUrl } from './genericApi';
import OvertimeReport from '../models/OvertimeReport';

export type WorkItemWithEmplNames = WorkItem & { empFirstName: string; empLastName: string };

const reportsBaseUrl = 'reports_by_prj';
const reportOvertimeBaseUrl = 'reports_by_overtime';

const filters = (filterStartDate: string, filterEndDate: string, filterEmpIds: Array<number>, filterProjectIds: Array<number>, filterTaskIds: Array<number>) =>
  `?filterStartDate=${encodeURIComponent(filterStartDate)}&filterEndDate=${encodeURIComponent(filterEndDate)}${
    filterEmpIds.length ? `&filterEmployeeIds=${filterEmpIds.join(';')}` : ''
  }${filterProjectIds.length ? `&filterProjectIds=${filterProjectIds.join(';')}` : ''}${filterTaskIds.length ? `&filterTaskIds=${filterTaskIds.join(';')}` : '' }`;

const filterOvertime = (filterStartDate: string, filterEndDate: string, filterEmpIds: Array<number>, filterSplitByMonth: boolean) =>
  `?filterStartDate=${encodeURIComponent(filterStartDate)}&filterEndDate=${encodeURIComponent(filterEndDate)}${
    (filterEmpIds.length ? `&filterEmpIds=${filterEmpIds.join(';')}` : '')}&filterSplitByMonth=${filterSplitByMonth}`;

export const reportsCsvExportUrl = (
  filterStartDate: string,
  filterEndDate: string,
  filterEmpIds: Array<number>,
  filterProjectIds: Array<number>,
  filterTaskIds: Array<number>
): string => `${baseUrl}${reportsBaseUrl}/csv${filters(filterStartDate, filterEndDate, filterEmpIds, filterProjectIds,filterTaskIds)}`;

export const reportsPdfExportUrl = (
  filterStartDate: string,
  filterEndDate: string,
  filterEmpIds: Array<number>,
  filterProjectIds: Array<number>,
  filterTaskIds: Array<number>
): string => `${baseUrl}${reportsBaseUrl}/pdf${filters(filterStartDate, filterEndDate, filterEmpIds, filterProjectIds, filterTaskIds)}`;

export const reportsHandler = {
  loadReportsByProject: (
    filterStartDate: string,
    filterEndDate: string,
    filterEmpIds: Array<number>,
    filterProjectIds: Array<number>,
    filterTaskIds: Array<number>
  ): Promise<Array<WorkItemWithEmplNames>> =>
    genericApi.get(`${reportsBaseUrl}${filters(filterStartDate, filterEndDate, filterEmpIds, filterProjectIds, filterTaskIds)}`),
};

export const reportOvertimeCsvExportUrl = (
  filterStartDate: string,
  filterEndDate: string,
  filterEmpIds: Array<number>,
  filterSplitByMonth: boolean
): string => `${baseUrl}${reportOvertimeBaseUrl}/csv${filterOvertime(filterStartDate, filterEndDate, filterEmpIds, filterSplitByMonth)}`;

export const reportOvertimePdfExportUrl = (
  filterStartDate: string,
  filterEndDate: string,
  filterEmpIds: Array<number>,
  filterSplitByMonth: boolean
): string => `${baseUrl}${reportOvertimeBaseUrl}/pdf${filterOvertime(filterStartDate, filterEndDate, filterEmpIds, filterSplitByMonth)}`;

export const reportOvertimeHandler = {
  loadReportOvertime: (
    filterStartDate: string,
    filterEndDate: string,
    filterEmpIds: Array<number>,
    filterSplitByMonth: boolean
  ): Promise<Array<OvertimeReport>> =>
    genericApi.get(`${reportOvertimeBaseUrl}${filterOvertime(filterStartDate, filterEndDate, filterEmpIds, filterSplitByMonth)}`),
};

export default reportsHandler;
