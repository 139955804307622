import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Customer from '../../../../models/Customer';
import { DialogMode } from '../../../../models/DialogMode';
import SaraTransferLists from '../../../common/SaraTransferLists';
import appGroupsStyles from '../../AppGroups.module.css';

interface CustomerDataProps {
  isLoading: boolean;
  inaccCustomers: Array<Customer>;
  accCustomers: Array<Customer>;
  dialogMode: DialogMode;
  moveCustomer: (id: number, toAuthorized: boolean) => void;
}

const CustomerData: React.FC<CustomerDataProps> = (props: CustomerDataProps) => {
  const { t } = useTranslation();
  const listHeightStyle = props.dialogMode === DialogMode.VIEW ? appGroupsStyles.readonlyListHeight : appGroupsStyles.listHeight;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>{t('assignCustomerDataAccess')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <SaraTransferLists
          showSearchBar={props.dialogMode !== DialogMode.VIEW}
          leftHeader={t('inaccessible')}
          leftListStyle={`${appGroupsStyles.inaccList} ${listHeightStyle}`}
          leftItems={props.inaccCustomers.map((item: Customer) => ({ key: item.customerId, value: item, text: item.customerName }))}
          rightHeader={t('accessible')}
          rightListStyle={`${appGroupsStyles.accList} ${listHeightStyle}`}
          rightItems={props.accCustomers.map((item: Customer) => ({ key: item.customerId, value: item, text: item.customerName }))}
          handleMove={(id, toAuthorized) => props.moveCustomer(id, toAuthorized)}
        />
      </Grid>
    </Grid>
  );
};

export default CustomerData;
