import { History, Location } from 'history';
import Moment from 'moment';
import QueryString from 'query-string';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApiResult } from '../../api/apiResult';
import tasksReqHandler from '../../api/tasksRemote';
import workTimeReqHandler from '../../api/workTimeRemote';
import { ProjectStatusCondition } from '../../models/ProjectStatusCondition';
import Task from '../../models/Task';
import WorkItem from '../../models/WorkItem';
import { WorkTimeModel } from '../../models/WorkTimeModel';
import {
  getFirstDayLastMonth,
  getFirstDayNextMonth,
  convertBrowserTimeToApiFormat,
  convertApiFormatToBrowserTime,
  getLastDayLastMonth,
  getFirstDayThisMonth,
  getCurrentDay,
  formatTimeToISO,
} from '../../utils/dateTime';
import { InsertWorkTimeContainer, RenderMode } from './insertWorkItem/InsertWorkTimeContainer';
import workTimeStyles from './workTime.module.css';
import WorkTimeHistory from './worktimeHistory/WorkTimeHistory';
import WorkItemTitle from './WorkTimeTitle';
import UpdateWorkItem from '../../models/UpdateWorkItem';
import { getTimezone } from '../../store/selectors/userSelectors';
import { ApiErrorMessage, ApiWarningMessage } from '../../api/apiMessage';
import NotificationDialog from '../notification/NotificationDialog';
import calendarReqHandler from '../../api/calendarRemote';
import CalendarDay from '../../models/CalendarDay';
import { WorkingDay } from '../../models/WorkingDay';
import OverlapEntryWarning from './insertWorkItem/OverlapEntryWarning';

interface WorkTimeProps extends WithTranslation {
  history: History;
  location: Location;
  empId: number;
  canReadWorkTime: boolean;
  canWriteWorkTime: boolean;
  extendedRead: boolean;
  extendedWrite: boolean;
  loggedInUserTimeZoneId: string;
  officeId: number;
}

interface WorkTimeState {
  tasks: Array<Task>;
  workItemsThisMonth: Array<WorkItem>;
  workItemsPrevMonth: Array<WorkItem>;
  isInsertLoading: boolean;
  isHistoryLoading: boolean;
  lastTaskId?: number;
  lastProjectId?: number;
  workTimesExpanded: {
    thisMonth: boolean;
    previousMonth: boolean;
  };
  taskStartDate?: string;
  taskEndDate?: string;
  timeZoneId: string;
  thisMonthOvertime: number;
  lastMonthOvertime: number;
  isOpenNotification: boolean;
  notificationTitle: string;
  notificationContent: string;
  isFirstWorkingDay: boolean;
  editedWorkItem: WorkItem;
  warningConfirmationNeeded: boolean;
}

interface WorkTimeQueryString {
  empId?: string;
  empName?: string;
  timeZoneId?: string;
}

class WorkTimeContainer extends Component<WorkTimeProps, WorkTimeState> {
  constructor(props: WorkTimeProps) {
    super(props);

    this.state = {
      tasks: [],
      workItemsThisMonth: [],
      workItemsPrevMonth: [],
      isInsertLoading: true,
      isHistoryLoading: true,
      workTimesExpanded: {
        thisMonth: true,
        previousMonth: false,
      },
      timeZoneId: '',
      thisMonthOvertime: 0,
      lastMonthOvertime: 0,
      isOpenNotification: false,
      notificationTitle: '',
      notificationContent: '',
      isFirstWorkingDay: false,
      editedWorkItem: {} as WorkItem,
      warningConfirmationNeeded: false
    };
  }

  getQueryString(): WorkTimeQueryString {
    return QueryString.parse(this.props.location.search) as WorkTimeQueryString;
  }

  componentDidMount() {
    let doReloadWorkItems: boolean = false;

    if (this.props.canReadWorkTime) {
      doReloadWorkItems = true;
    }

    if (this.props.officeId !== undefined && this.props.officeId > 0) {
      this.loadFirstWorkingDay(this.props.officeId, doReloadWorkItems);
    }
  }

  componentDidUpdate(prevProps: WorkTimeProps) {
    if (prevProps.location.search !== this.props.location.search || prevProps.canReadWorkTime !== this.props.canReadWorkTime) {
      this.reloadWorkItems();
    }

    if (this.props.officeId !== undefined && this.props.officeId > 0 && this.props.officeId !== prevProps.officeId) {
      this.loadFirstWorkingDay(this.props.officeId, true);
    }
  }

  loadFirstWorkingDay = (officeId: number, reloadWorkItems: boolean): void => {
    const startDate: Moment.Moment = Moment(new Date()).startOf('month');
    const endDate: Moment.Moment = Moment(new Date()).endOf('month');
    calendarReqHandler
      .loadFirstWorkingDay(startDate, endDate, officeId)
      .then((resp: Array<CalendarDay>) => {
        const calendarDay: CalendarDay = resp[0];
        const todayDate: Moment.Moment = Moment(new Date());
        const firstWorkDate: Moment.Moment = Moment(calendarDay.date, 'YYYY-MM-DD');
        let isFirstWorkingDay: boolean = false;
        if (
          todayDate.year() === firstWorkDate.year() &&
          todayDate.month() === firstWorkDate.month() &&
          todayDate.date() === firstWorkDate.date()
        ) {
          isFirstWorkingDay = calendarDay.isWorking === WorkingDay.WORKING;
        }

        const taskStartDate: string =
          this.props.extendedRead || isFirstWorkingDay
            ? Moment(new Date())
                .subtract(1, 'months')
                .startOf('month')
                .toISOString()
            : Moment(new Date())
                .startOf('month')
                .toISOString();
        const taskEndDate: string = Moment(new Date())
          .endOf('month')
          .toISOString();
        this.setState({ taskStartDate, taskEndDate, isFirstWorkingDay });

        if (reloadWorkItems) {
          this.reloadWorkItems();
        }
      })
      .catch(error => this.handleError(error));
  };

  loadActiveTasks(): Promise<Task[]> {
    const empId = this.getCurrentEmployeeId();
    if (empId == null) {
      //there is no current emp ID!
      return Promise.reject();
    }
    return tasksReqHandler.loadTasks(empId, this.state.taskStartDate, this.state.taskEndDate, ProjectStatusCondition.ACTIVE).then(
      (resp: Array<Task>): Array<Task> => {
        return resp;
      }
    );
  }

  getCurrentTimeZoneId(): string {
    let timeZone: string | null | undefined = this.getQueryString().timeZoneId;
    if (!timeZone) {
      timeZone = this.props.loggedInUserTimeZoneId;
    }
    return timeZone;
  }

  getCurrentEmployeeId(): number | null {
    let empIdStr: string | null | undefined = this.getQueryString().empId;

    if (!empIdStr) {
      if (this.props.empId) {
        empIdStr = this.props.empId.toString();
      }
    }
    if (!empIdStr) {
      return null;
    }
    return Number.parseInt(empIdStr);
  }

  reloadWorkItems() {
    this.setState({ isHistoryLoading: true, isInsertLoading: true, timeZoneId: this.getCurrentTimeZoneId() }, () => {
      const empId = this.getCurrentEmployeeId();
      const firstDayLastMonth = getFirstDayLastMonth();
      const firstDayNextMonth = getFirstDayNextMonth();

      Promise.all([
        workTimeReqHandler.loadWorkItemsByEmpAndDate(
          empId || -1,
          formatTimeToISO(firstDayLastMonth, this.state.timeZoneId),
          formatTimeToISO(firstDayNextMonth, this.state.timeZoneId)
        ),
        tasksReqHandler.loadTasks(empId || -1, this.state.taskStartDate, this.state.taskEndDate, ProjectStatusCondition.ACTIVE),
        workTimeReqHandler.loadOvertime(
          empId || -1,
          formatTimeToISO(getFirstDayThisMonth(), this.state.timeZoneId),
          formatTimeToISO(getCurrentDay(), this.state.timeZoneId)
        ),
        workTimeReqHandler.loadOvertime(
          empId || -1,
          formatTimeToISO(firstDayLastMonth, this.state.timeZoneId),
          formatTimeToISO(getLastDayLastMonth(), this.state.timeZoneId)
        ),
      ])
        .then(([workItems, tasks, thisMonthOvertime, lastMonthOvertime]: [WorkItem[], Task[], number, number]) => {
          if (workItems) {
            let lastPrjId;
            let lastTaskId;
            const workTimesExpanded = { ...this.state.workTimesExpanded };
            if (workItems.length > 0) {
              const lastTask = tasks.find(t => t.taskId === workItems[0].taskId);
              if (
                lastTask &&
                lastTask.taskStartDate &&
                lastTask.taskEndDate &&
                Moment(lastTask.taskStartDate).isValid() &&
                Moment(lastTask.taskEndDate).isValid() &&
                Moment(lastTask.taskStartDate) <= Moment(new Date()).startOf('day') &&
                Moment(lastTask.taskEndDate) >= Moment(new Date()).startOf('day')
              ) {
                lastPrjId = lastTask.projectId;
                lastTaskId = lastTask.taskId;
              }
            }
            const { thisMonthItems, previousMonthItems } = this.splitWorkTimesByMonth(workItems);

            this.setState({
              workItemsThisMonth: thisMonthItems,
              workItemsPrevMonth: previousMonthItems,
              isHistoryLoading: false,
              isInsertLoading: false,
              lastProjectId: lastPrjId,
              lastTaskId: lastTaskId,
              workTimesExpanded,
              tasks,
              thisMonthOvertime,
              lastMonthOvertime,
            });
          }
        })
        .catch(error => this.handleError(error));
    });
  }

  splitWorkTimesByMonth(workItems: Array<WorkItem>): { thisMonthItems: Array<WorkItem>; previousMonthItems: Array<WorkItem> } {
    const today = Moment(new Date());
    const thisMonthResp = this.filterWorkItems(workItems, today.month(), today.year());
    const lastMonth = Moment(new Date()).subtract(1, 'month');
    const lastMonthResp = this.filterWorkItems(workItems, lastMonth.month(), lastMonth.year());
    return {
      thisMonthItems: thisMonthResp,
      previousMonthItems: lastMonthResp,
    };
  }

  insertWorkItem = (workItem: WorkTimeModel, suppressWarning: string | null): Promise<any> => {
    const empId = this.getCurrentEmployeeId();
    if (empId == null) {
      //there is no current emp ID!
      return Promise.reject();
    }
    this.setState({ isInsertLoading: true });

    const createModel: UpdateWorkItem = {
      empId: empId,
      taskId: workItem.taskId,
      wtComment: workItem.description,
      wtStartTime: convertBrowserTimeToApiFormat(workItem.startTime, this.state.timeZoneId),
      wtEndTime: convertBrowserTimeToApiFormat(workItem.endTime, this.state.timeZoneId),
    };

    const isThisMonth: boolean = Moment(workItem.startTime).month() === Moment(new Date()).month();
    const workTimesExpanded = { ...this.state.workTimesExpanded };
    if (isThisMonth) {
      workTimesExpanded.thisMonth = true;
    } else {
      workTimesExpanded.previousMonth = true;
    }

    return workTimeReqHandler
      .insertWorkItem(createModel, suppressWarning)
      .then(() => {
        this.setState(
          {
            workTimesExpanded,
          },
          () => this.reloadWorkItems()
        );
      })
      .catch((error: { response: { data: ApiResult<any> } }) => {
        this.handleError(error);
        this.setState({ isInsertLoading: false });
        return Promise.reject(error);
      });
  };

  handleDelete = (workItem: WorkItem): Promise<void> => {
    return workTimeReqHandler
      .deleteWorkItem(workItem.workTimeId)
      .then(() => this.reloadWorkItems())
      .catch(error => this.handleError(error));
  };

  handleUpdate = (workItem: WorkItem, suppressWarning: string | null): Promise<void> => {

    return workTimeReqHandler
      .updateWorkItem(
        workItem.workTimeId,
        {
          empId: workItem.empId,
          taskId: workItem.taskId,
          wtComment: workItem.wtComment,
          wtEndTime: convertBrowserTimeToApiFormat(workItem.wtEndTime, this.state.timeZoneId),
          wtStartTime: convertBrowserTimeToApiFormat(workItem.wtStartTime, this.state.timeZoneId),
        },
        suppressWarning
      )
      .then(() => {
        this.setState({ warningConfirmationNeeded: false });
        this.reloadWorkItems();
      })
      .catch((error: { response: { data: ApiResult<any> } }) => {

        if (!suppressWarning && error.response.data.messages.find(x => x.message === ApiWarningMessage.overlapWorkingTime)) {
          this.setState({ editedWorkItem: workItem, warningConfirmationNeeded: true });
        }

        this.handleError(error);
      });
  };

  filterWorkItems(workItems: Array<WorkItem>, month: number, year: number): Array<WorkItem> {
    const filteredWorkItems = workItems.filter(
      (wi: WorkItem) => Moment(wi.wtStartTime).month() === month && Moment(wi.wtStartTime).year() === year
    );

    if (filteredWorkItems.length > 0) {
      let lastWorkItem: WorkItem = filteredWorkItems[0];
      lastWorkItem.backgroundColor = 'white';

      filteredWorkItems.forEach(x => {
        if (Moment(lastWorkItem.wtStartTime).isSame(Moment(x.wtStartTime), 'day')) {
          x.backgroundColor = lastWorkItem.backgroundColor;
        } else {
          x.backgroundColor = lastWorkItem.backgroundColor === 'white' ? '#F3F3F4' : 'white';
        }
        lastWorkItem = x;
      });
    }

    return filteredWorkItems;
  }

  handleExpand = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState(prevState => ({
      workTimesExpanded: {
        ...prevState.workTimesExpanded,
        [panel]: isExpanded,
      },
    }));
  };

  reloadTasks = (): void => {
    this.loadActiveTasks()
      .then(resp => this.setState({ tasks: resp }))
      .catch(error => this.handleError(error));
  };

  handleError = (error: { response: { data: ApiResult<any> } }): void => {
    if (error.response.data.messages.find(x => x.message === ApiWarningMessage.overlapWorkingTime)) {
      return;
    } else
    if (error.response.data.messages.find(x => x.message === ApiErrorMessage.overlappingWorkTimeSameCustomer)) {
      this.setState({
        isOpenNotification: true,
        notificationTitle: 'entryOverlapWarningTitle',
        notificationContent: 'workTimeOverlapErrorSameCustomer',
      });
    } else if (
      error.response.data.messages.find(
        x => x.message === ApiErrorMessage.workTimeTaskPeriodConflict || x.message === ApiErrorMessage.workTimeForInactiveProject
      )
    ) {
      this.setState({
        isOpenNotification: true,
        notificationTitle: 'taskSelectionIsNoMoreValidTitle',
        notificationContent: 'taskSelectionIsNoMoreValid',
      });
    } else if (error.response.data.messages.find(x => x.message === ApiErrorMessage.accessDenied)) {
      this.setState({
        isOpenNotification: true,
        notificationTitle: 'accessDeniedTitle',
        notificationContent: 'accessDenied',
      });
    } else {
      this.setState({
        isOpenNotification: true,
        notificationTitle: 'unexpectedErrorMessageTitle',
        notificationContent: 'unexpectedErrorMessage',
      });
    }
  };

  closeNotificationDialog = (): void => {
    this.setState({ isOpenNotification: false, notificationTitle: '', notificationContent: '' });
  };

  secondaryHandleUpdateCalled = (): void => {
    this.handleUpdate(this.state.editedWorkItem, ApiWarningMessage.overlapWorkingTime).then();
  }

  render() {
    const employeeId = this.getCurrentEmployeeId();
    const viewOnly =
      (this.props.canWriteWorkTime && !this.getQueryString().empId) || (this.props.extendedWrite && !!this.getQueryString().empId);
    return (
      <div className="pageWrapper">
        <WorkItemTitle employeeName={this.getQueryString().empName} />
        {viewOnly && (
          <InsertWorkTimeContainer
            tasks={this.state.tasks}
            onSave={this.insertWorkItem}
            isLoading={this.state.isInsertLoading}
            renderMode={RenderMode.WithTimer}
            defaultProjectId={this.state.lastProjectId}
            defaultTaskId={this.state.lastTaskId}
            onTasksReloadNeeded={this.reloadTasks}
            hasExtendedPermission={this.props.extendedWrite || this.state.isFirstWorkingDay}
            rememberData={this.props.empId === employeeId}
            empId={employeeId ? employeeId : 0}
            collapsable
            handleError={this.handleError}
          />
        )}
        <div className={workTimeStyles.workItemHistory}>
          <WorkTimeHistory
            workItems={this.state.workItemsThisMonth}
            isLoading={this.state.isHistoryLoading}
            onDelete={this.handleDelete}
            title={this.props.t('thisMonth')}
            onUpdate={this.handleUpdate}
            isEditable={viewOnly}
            tasks={this.state.tasks}
            defaultExpanded={true}
            expanded={this.state.workTimesExpanded.thisMonth}
            onExpand={this.handleExpand('thisMonth')}
            readOnlyReason={this.props.t('readOnly')}
            hasExtendedPermission={this.props.extendedWrite}
            showTodaysTotal={true}
            timeZoneId={this.state.timeZoneId}
            overtime={this.state.thisMonthOvertime}
            handleError={this.handleError}
          />
        </div>
        <div className={workTimeStyles.workItemHistory}>
          <WorkTimeHistory
            workItems={this.state.workItemsPrevMonth}
            isLoading={this.state.isHistoryLoading}
            title={this.props.t('previousMonth')}
            onDelete={this.props.extendedWrite || this.state.isFirstWorkingDay ? this.handleDelete : undefined}
            onUpdate={this.handleUpdate}
            isEditable={this.props.extendedWrite || this.state.isFirstWorkingDay}
            tasks={this.state.tasks}
            expanded={this.state.workTimesExpanded.previousMonth}
            onExpand={this.handleExpand('previousMonth')}
            readOnlyReason={this.props.t('readOnlyMonthReason')}
            hasExtendedPermission={this.props.extendedWrite || this.state.isFirstWorkingDay}
            timeZoneId={this.state.timeZoneId}
            overtime={this.state.lastMonthOvertime}
            handleError={this.handleError}
          />
        </div>
        { true &&
          <OverlapEntryWarning
            open={this.state.warningConfirmationNeeded}
            onClose={() => this.setState({ warningConfirmationNeeded: false })}
            onSubmit={this.secondaryHandleUpdateCalled}
          />
        }
        {this.state.isOpenNotification && (
          <NotificationDialog
            open={this.state.isOpenNotification}
            title={this.state.notificationTitle}
            content={this.state.notificationContent}
            onClose={this.closeNotificationDialog}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  empId: state.user.user.empId,
  officeId: state.user.user.officeId,
  loggedInUserTimeZoneId: getTimezone(state),
});

export default connect(mapStateToProps)(withTranslation()(WorkTimeContainer));
