import { TaskDraftActionType } from './taskDraftActionType';
import Task from '../../models/Task';

export const setTaskDraft = (task: Task) => ({
  type: TaskDraftActionType.SET_TASK_DRAFT,
  payload: {
    task
  }
});

export const clearTaskDraft = () => ({
  type: TaskDraftActionType.CLEAR_TASK_DRAFT
});
