import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApiResult } from '../../../../../api/apiResult';
import EditRowProps from '../../../../../models/EditRowProps';
import Employee from '../../../../../models/Employee';
import Task from '../../../../../models/Task';
import TaskModel from '../../../../../models/TaskModel';
import SaraDeleteRow from '../../../../common/SaraDeleteRow';
import SaraTable from '../../../../common/SaraTable';
import Columns from './columns';
import EditTaskRow from './EditTaskRow';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { clearTaskDraft } from '../../../../../store/actions/taskDraftActions';

interface TasksListProps {
  projectId: number;
  minDate?: string;
  maxDate?: string;
  tasks: Array<Task>;
  isLoading: boolean;
  onAdd: (task: Task) => Promise<void>;
  onDelete?: (task: Task) => Promise<void>;
  onUpdate?: (task: Task) => Promise<void>;
  onChange: (oldTask: Task, newTask: Task) => void;
  onReload: () => void;
  onError: (error: { response: { data: ApiResult<any> } }) => void;
  clearTaskDraft: () => void;
  employees: Array<Employee>;
  canReadTask: boolean;
  canWriteTask: boolean;
}

const TasksList: React.FC<TasksListProps> = (props: TasksListProps) => (
  <div className="tableWithActionsContainer">
    <SaraTable<Task>
      isLoading={props.isLoading}
      items={props.tasks}
      columns={Columns(useTranslation(), props.employees)}
      sorting={true}
      isEditable={props.canWriteTask}
      onDelete={props.onDelete}
      maxHeight="auto"
      components={
        props.canWriteTask
          ? {
              EditRow: (erProps: EditRowProps<Task>) => {
                if (erProps.mode === 'delete') {
                  return (
                    <SaraDeleteRow
                      columnsBefore={4}
                      confirmationMessage="deleteTaskConfirmation"
                      onCancel={() => erProps.onEditingCanceled('delete', erProps.data)}
                      onSave={() => erProps.onEditingApproved('delete', erProps.data, erProps.data)}
                    />
                  );
                } else {
                  return (
                    <EditTaskRow
                      initialModel={{
                        taskId: erProps.data.taskId,
                        taskName: erProps.data.taskName,
                        projectId: erProps.data.projectId,
                        projectName: erProps.data.projectName,
                        taskStartDate: erProps.data.taskStartDate ? erProps.data.taskStartDate : '',
                        taskEndDate: erProps.data.taskEndDate ? erProps.data.taskEndDate : '',
                        taskAssignees: erProps.data.taskAssignees,
                        assignedBy: erProps.data.assignedBy,
                        assignedToName: erProps.data.assignedToName,
                      }}
                      projectId={props.projectId}
                      minDate={props.minDate}
                      maxDate={props.maxDate}
                      employees={props.employees}
                      onAdd={props.onAdd}
                      onSave={(newValue: TaskModel): Promise<void> => {
                        if (newValue.taskId === 0) {
                          props.onChange(erProps.data, { ...newValue, taskAssignees: [newValue.assignedTo] });
                        } else {
                          if (props.onUpdate) {
                            return props
                              .onUpdate(newValue)
                              .then(() => erProps.onEditingCanceled('update', erProps.data))
                              .catch((error: { response: { data: ApiResult<any> } }) => props.onError(error));
                          }
                        }
                        return Promise.resolve();
                      }}
                      onCancel={() => {
                        props.clearTaskDraft();
                        props.onReload();
                        erProps.onEditingCanceled('update', erProps.data);
                      }}
                      index={erProps.data.taskId}
                      canReadTask={props.canReadTask}
                      canWriteTask={props.canWriteTask}
                    />
                  );
                }
              },
            }
          : {}
      }
    />
  </div>
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearTaskDraft,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(TasksList);
