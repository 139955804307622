import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApiResult } from '../../../../api/apiResult';
import appUserGroupsReqHandler from '../../../../api/appUserGroupsRemote';
import appUsersReqHandler from '../../../../api/appUsersRemote';
import AppGroup from '../../../../models/AppGroup';
import AppUser from '../../../../models/AppUser';
import AppUserGroup from '../../../../models/AppUserGroup';
import { DialogMode } from '../../../../models/DialogMode';
import {
  addAppUser,
  addToOldState,
  enterAppGroupDescription,
  enterAppGroupName,
  removeAppUser,
} from '../../../../store/actions/appGroupActions';
import { AppGroupActionType } from '../../../../store/actions/appGroupActionType';
import { AppGroupState } from '../../../../store/reducers/appGroupsReducer';
import { getAppGroup, getAppGroupAppUsers, SelectAppGroupState } from '../../../../store/selectors/appGroupSelectors';
import { compare } from '../../../../utils/string';
import Users from './Users';

interface UsersContainerProps {
  dialogMode: DialogMode;
  validated: boolean;
  appGroup: AppGroup;
  appUsers: Array<AppUser>;
  onError: (error: { response: { data: ApiResult<any> } }) => void;
  addAppUser: (appUser: AppUser) => { type: AppGroupActionType; payload: { appUser: AppUser } };
  removeAppUser: (appUser: AppUser) => { type: AppGroupActionType; payload: { appUser: AppUser } };
  enterAppGroupName: (appGroupName: string) => { type: AppGroupActionType; payload: { appGroupName: string } };
  enterAppGroupDescription: (appGroupDescr: string) => { type: AppGroupActionType; payload: { appGroupDescr: string } };
  addToOldState: (oldState: Partial<AppGroupState>) => { type: AppGroupActionType; payload: { oldState: Partial<AppGroupState> } };
}

interface UsersContainerState {
  unauthAppUsers: Array<AppUser>;
  isLoading: boolean;
}

class UsersContainer extends Component<UsersContainerProps, UsersContainerState> {
  state: UsersContainerState = {
    unauthAppUsers: [],
    isLoading: false,
  };

  componentDidMount() {
    const userRequests: Array<Promise<any>> = [appUsersReqHandler.loadAppUsers()];
    this.props.addToOldState({ appGroup: this.props.appGroup });
    if (this.props.dialogMode !== DialogMode.CREATE && !this.props.appUsers.length) {
      userRequests.push(appUserGroupsReqHandler.loadAppUserGroups());
    }
    this.setState({ isLoading: true }, () =>
      Promise.all(userRequests)
        .then(([users, userGroups]) => {
          let matchingAppUserGroups: Array<AppUserGroup> = [];
          if (userGroups) {
            matchingAppUserGroups = userGroups.filter((group: AppUserGroup) => group.appGroupId === this.props.appGroup.appGroupId);
          }
          const filteredAppUsers = users.filter((user: AppUser) => {
            if (matchingAppUserGroups.length) {
              const isUserInGroup = !!matchingAppUserGroups.find(group => group.appUserId === user.appUserId);
              if (isUserInGroup) {
                this.props.addAppUser(user);
              }
            }
            return this.props.appUsers.findIndex(u => u.appUserId === user.appUserId) < 0;
          });
          this.props.addToOldState({ appUsers: this.props.appUsers });
          const unauthAppUsers = this.sortAppUsers(filteredAppUsers);
          this.setState({ unauthAppUsers, isLoading: false });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          this.props.onError(error);
        })
    );
  }

  handleChange = (event: any, field: string): void => {
    const value: string = event.target.value;
    switch (field) {
      case 'appGroupName':
        this.props.enterAppGroupName(value);
        break;
      case 'appGroupDescr':
        this.props.enterAppGroupDescription(value);
        break;
    }
  };

  handleMove = (id: number, toAuthorized: boolean) => {
    if (this.props.dialogMode === DialogMode.VIEW) {
      return;
    }
    const appUsersList = [...this.state.unauthAppUsers];
    if (toAuthorized) {
      let userIndex = 0;
      const selectedAppUser = appUsersList.find((user, index) => {
        userIndex = index;
        return user.appUserId === id;
      });
      appUsersList.splice(userIndex, 1);
      if (selectedAppUser) {
        this.props.addAppUser(selectedAppUser);
      }
    } else {
      const selectedAppUser = this.props.appUsers.find(user => {
        return user.appUserId === id;
      });
      if (selectedAppUser) {
        this.props.removeAppUser(selectedAppUser);
        appUsersList.push(selectedAppUser);
      }
    }
    this.setState({ unauthAppUsers: this.sortAppUsers(appUsersList) });
  };

  private sortAppUsers(appUsers: Array<AppUser>): Array<AppUser> {
    return appUsers.sort((first, second) => compare(first.username, second.username));
  }

  render() {
    return (
      <Users
        dialogMode={this.props.dialogMode}
        appGroup={this.props.appGroup}
        validated={this.props.validated}
        unauthAppUsers={this.state.unauthAppUsers}
        authAppUsers={this.sortAppUsers(this.props.appUsers)}
        isLoading={this.state.isLoading}
        moveAppUser={this.handleMove}
        handleChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = (state: SelectAppGroupState) => ({ appGroup: getAppGroup(state), appUsers: getAppGroupAppUsers(state) });

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      addAppUser,
      removeAppUser,
      enterAppGroupName,
      enterAppGroupDescription,
      addToOldState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
