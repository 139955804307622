import React, { ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Stepper, Step, StepButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@fullcalendar/core';
import SaraDialogTitle from '../../common/SaraDialogTitle';
import SaraDialogButtons from '../../common/SaraDialogButtons';
import { DialogMode } from '../../../models/DialogMode';
import { ProjectStep } from '../../../models/Step';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      height: '80%',
      padding: 24,
    },
    nextButton: {
      marginLeft: 24,
    },
    nextButtonDisabled: {
      marginLeft: 24,
      opacity: 0.6,
    },
    stepHalf: {
      width: '50%'
    }
  });

interface ProjectDialogProps extends WithStyles<typeof styles> {
  isOpen: boolean;
  onClose: (event: any) => void;
  showWarning: boolean;
  hasChanges: () => boolean;
  snackBar: ReactNode;
  activeStep: number;
  steps: Array<string>;
  dialogMode: DialogMode;
  title: string;
  getStepContent: Function;
  handleBack: (event: any) => void;
  handleNext: (event: any) => void;
  disableNext: Function;
  onClick: (event: any) => void;
  hasError: boolean;
}

const ProjectDialog: React.FC<ProjectDialogProps> = (props: ProjectDialogProps) => {
  const { t } = useTranslation();

  let subTitleText: string = '';
  if (props.showWarning) {
    subTitleText = t('closeAndDiscardWarning');
  }

  let okButtonText: string = t('next');
  if (props.activeStep === ProjectStep.TASKS) {
    if (props.dialogMode !== DialogMode.CREATE) {
      okButtonText = t('close');
    } else {
      okButtonText = t('save');
    }
  }

  const stepStyle: string = props.steps.length === 2 ? props.classes.stepHalf : '';
  const activeStep: number = props.activeStep > props.steps.length -1 ? props.steps.length -1 : props.activeStep;
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="-dialog-title"
      maxWidth="lg"
      fullWidth={true}
      classes={{ paper: props.classes.dialog }}
    >
      <SaraDialogTitle
        onClose={props.onClose}
        text={props.title}
        showWarning={props.showWarning || props.hasChanges()}
        warning={subTitleText}
      />
      <Stepper nonLinear activeStep={activeStep} className={ stepStyle }>
        {props.steps.map((label: string) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepButton onClick={() => props.onClick(label)}>{t(label)}</StepButton>
            </Step>
          );
        })}
      </Stepper>
      <DialogContent>
        <div>{props.getStepContent(props.activeStep)}</div>
        {props.snackBar}
      </DialogContent>
      <DialogActions>
        <SaraDialogButtons
          onSecondary={props.handleBack}
          secondaryText={props.activeStep === ProjectStep.PROJECT ? t('cancel') : t('back')}
          onPrimary={props.handleNext}
          primaryDisabled={props.disableNext()}
          primaryText={okButtonText}
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ProjectDialog);
