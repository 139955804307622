import genericApi from './genericApi';
import Project from '../models/Project';
import UpdateProject from '../models/UpdateProject';
import CreateProject from '../models/CreateProject';

const projectsReqHandler = {
  loadProjects: (orderBy?: string, orderByType?: string): Promise<Array<Project>> => {
    let url = 'projects';
    if (orderBy) {
      url += '?sort=' + orderBy + ',' + (orderByType ? orderByType : 'ASC');
    } else {
      url += '?sort=prjName,ASC'
    }
    return genericApi.get(url);
  },
  insertProject: (project: CreateProject): Promise<CreateProject> => {
    return genericApi.post('projects', project);
  },
  updateProject: (id: number, project: UpdateProject): Promise<Project> => {
    return genericApi.put(`projects/${id}`, project);
  },
  deleteProject: (id: number): Promise<Project> => {
    return genericApi.delete(`projects/${id}`);
  },
};

export const checkProjectSowCode = (sowCode: string): Promise<void> => {
  return genericApi.get(`projects/sow_codes/${sowCode}`)
}

export default projectsReqHandler;
