import axios from 'axios';
import { History, Location } from 'history';
import QueryString from 'query-string';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApiErrorMessage } from '../../api/apiMessage';
import { ApiResult } from '../../api/apiResult';
import loginReqHandler from '../../api/loginRemote';
import User from '../../models/User';
import { loginAction } from '../../store/actions/authActions';
import { saveUser, setTimezone } from '../../store/actions/userActions';
import Login from './Login';
import { validateEmail } from '../../utils/validation';

interface LoginProps extends WithTranslation {
  history: History;
  location: Location;
  storeUser: Function;
  login: Function;
  storeTimezone: Function;
}

interface LoginState {
  email: string;
  password: string;
  errors: {
    email: string;
    password: string;
  };
  isLoading: boolean;
  authenticationFailed: boolean;
  messageError: string;
  loginBoxHeight?: number;
}

interface LoginQueryString {
  sessionExpired?: string;
}

class LoginContainer extends Component<LoginProps, LoginState> {
  signal = axios.CancelToken.source();
  loginBoxRef: React.RefObject<any> = React.createRef<any>();

  state: LoginState = {
    email: '',
    password: '',
    errors: {
      email: '',
      password: '',
    },
    isLoading: false,
    authenticationFailed: true,
    messageError: '',
  };

  componentDidMount() {
    if (localStorage.getItem('login')) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    this.signal.cancel('Api is being canceled');
  }

  componentDidUpdate(prevProps: LoginProps, prevState: LoginState) {
    let childrenHeight = 0;
    for (let index = 0; index < this.loginBoxRef.current.children.length; index++) {
      childrenHeight += this.loginBoxRef.current.children[index].clientHeight;
    }
    const loginBoxHeight = childrenHeight + 32;
    if (loginBoxHeight !== this.state.loginBoxHeight) {
      this.setState({ loginBoxHeight: loginBoxHeight });
    }
  }

  handleChange = (event: any, field: string) => {
    if (this.state.authenticationFailed) this.setState({ authenticationFailed: false, messageError: '' });
    switch (field) {
      case 'email':
        this.setState({ email: event.target.value });
        break;
      case 'password':
        this.setState({ password: event.target.value });
        break;
      default:
        break;
    }
  };

  handleLogin = (event: any) => {
    event.preventDefault();
    const errors: { email: string; password: string } = this.handleValidation(null);
    if (!errors.email && !errors.password) {
      this.setState({ isLoading: true }, () => {
        loginReqHandler
          .login({ username: this.state.email, password: this.state.password })
          .then((response: any) => {
            if (response && response.username) {
              const { username, appUserId, empId, timeZoneId, officeId } = response;
              const userObject: User = {
                username,
                appUserId,
                empId,
                officeId
              };

              this.props.storeUser(userObject);
              this.props.storeTimezone(timeZoneId);
              this.props.login();
              this.setState({ isLoading: false });
              this.props.history.push('/');
            }
          })
          .catch((error: { response: { data: ApiResult<any> } }) => {
            const apiErrorMessage =
              error.response && error.response.data && error.response.data.messages
                ? error.response.data.messages.find(errMessage => !!ApiErrorMessage[errMessage.message])
                : null;
            this.setState({
              isLoading: false,
              authenticationFailed: true,
              messageError: apiErrorMessage ? apiErrorMessage.message : this.props.t('unexpectedErrorMessage'),
            });
          });
      });
    }
  };

  handleValidation = (field: string | null): { email: string; password: string } => {
    const errors = { ...this.state.errors };

    if (!field) {
      errors.email = this.setEmailErrorMessage();
      errors.password = this.setPasswordErrorMessage();
    }

    if (field && field === 'email') {
      errors.email = this.setEmailErrorMessage();
    }

    if (field && field === 'password') {
      errors.password = this.setPasswordErrorMessage();
    }

    this.setState({ errors });
    return errors;
  };

  setEmailErrorMessage = (): string => {
    let errorMessage = '';

    if (!this.state.email) {
      errorMessage = 'fieldIsMandatory';
    }

    if (this.state.email !== '' && !validateEmail(this.state.email)) {
      errorMessage = 'validEmailMessage';
    }

    return errorMessage;
  };

  setPasswordErrorMessage = (): string => {
    let errorMessage = '';

    if (!this.state.password) {
      errorMessage = 'fieldIsMandatory';
    }

    return errorMessage;
  };

  render() {
    const queryString = QueryString.parse(this.props.location.search) as LoginQueryString;
    return (
      <Login
        email={this.state.email}
        password={this.state.password}
        handleChange={this.handleChange}
        handleLogin={this.handleLogin}
        errors={this.state.errors}
        handleValidation={this.handleValidation}
        isLoading={this.state.isLoading}
        sessionExpired={queryString.sessionExpired === 'true'}
        authenticationFailed={this.state.authenticationFailed}
        messageError={this.state.messageError}
        loginBoxRef={this.loginBoxRef}
        loginBoxHeight={this.state.loginBoxHeight}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: () => dispatch(loginAction()),
    storeUser: (user: User) => dispatch(saveUser(user)),
    storeTimezone: (timezone: string) => dispatch(setTimezone(timezone))
  };
};

export default connect(null, mapDispatchToProps)(withTranslation()(LoginContainer));
