import Customer from '../../models/Customer';
import { CustomerActionType } from '../actions/customerActionType';
import AppGroup from '../../models/AppGroup';

export interface CustomersState {
  customer: Customer;
  isDirty: boolean;
  isValid: boolean;
  customerAppGroups: Array<AppGroup>;
  oldCustomersState: Partial<CustomersState>;
}

const initialState: CustomersState = {
  customer: {
    customerId: 0,
    customerName: '',
    amId: 0,
    customerAddress: '',
    customerEmail: '',
    customerPhone: '',
    notes: '',
    taxCode: '',
    billToText: '',
  },
  isDirty: false,
  isValid: false,
  customerAppGroups: [],
  oldCustomersState: {},
};

export default (state = initialState, action: any): CustomersState => {
  switch (action.type) {
    case CustomerActionType.ADD_APP_GROUP:
      return {
        ...state,
        customerAppGroups: [...state.customerAppGroups, action.payload.appGroup],
      };
    case CustomerActionType.ADD_TO_OLD_CUSTOMERS_STATE:
      return {
        ...state,
        oldCustomersState: {
          ...state.oldCustomersState,
          ...action.payload.oldState
        }
      };
    case CustomerActionType.CLEAR_CUSTOMER:
      return {
        ...state,
        customer: initialState.customer,
      };
    case CustomerActionType.CLEAR_CUSTOMERS_STATE:
      return initialState;
    case CustomerActionType.CLEAR_OLD_CUSTOMERS_STATE:
      return {
        ...state,
        oldCustomersState: initialState.oldCustomersState
      };
    case CustomerActionType.REMOVE_APP_GROUP:
      return {
        ...state,
        customerAppGroups: state.customerAppGroups.filter(group => group.appGroupId !== action.payload.appGroup.appGroupId),
      };
    case CustomerActionType.STORE_CUSTOMER:
      return {
        ...state,
        customer: action.payload.customer,
      };
    case CustomerActionType.SWITCH_DIRTY:
      return {
        ...state,
        isDirty: action.payload.isDirty,
      };
    case CustomerActionType.SWITCH_VALID:
      return {
        ...state,
        isValid: action.payload.isValid,
      };
    default:
      return state;
  }
};
