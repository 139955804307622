import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";

const returnedUser: ApiResult<any> = {
    messages: [],
    result: {
        "appUserId": 32,
        "username": "david.gregor@sciant-test.pri",
        "createdDate": "2019-11-27",
        "lastUpdateBy": 1,
        "lastUpdateDate": "2020-01-12",
        "appUserType": "E",
        "lastActivityTime": "2020-01-12T14:18:22.930Z",
        "custFirstName": null,
        "custLastName": null,
        "custPersonId": null,
        "customerName": null,
        "customerId": null,
        "empFirstName": "David",
        "empLastName": "Gregor",
        "empId": 25,
        "empOfficeName": "ITCIS",
        "empOfficeId": 1,
        "timeZoneId": "Europe/Sofia",
        "timeZoneName": "app_user.time_zone.bulgaria",
        "calendar": 1578841922220,
        "defaultContext": "user",
        "custFullName": null,
        "empFullName": "David Gregor",
        "roles": "user",
        "loginId": 32
    }
}

export const LoginPost: FakeRequest = {
    data: returnedUser,
    httpCode: 200,
    url: /login/,
    method: 'POST'
}

export const LogoutPost: FakeRequest = {
    data: {messages: [], result: null},
    httpCode: 200,
    url: /logout/,
    method: 'POST'
}