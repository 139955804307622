import { Column } from 'material-table';
import WorkItem from '../../../../models/WorkItem';
import DateColumn from './Date';
import Start from './Start';
import End from './End';
import Duration from './Duration';
import Project from './Project';
import Task from './Task';
import Description from './Description';
import { UseTranslationResponse } from 'react-i18next';

const columns = (translation: UseTranslationResponse, timeZone: string): Column<WorkItem>[] => {
  return [
    DateColumn(translation, timeZone),
    Start(translation, timeZone),
    End(translation, timeZone),
    Duration(translation),
    Project(translation),
    Task(translation),
    Description(translation),
  ];
};

export default columns;
