import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApiResult } from '../../../../api/apiResult';
import custAppGroupOverseeReqHandler from '../../../../api/custAppGroupOverseeRemote';
import CustomersReqHandler from '../../../../api/customersRemote';
import AppGroup from '../../../../models/AppGroup';
import CustAppGroupOversee from '../../../../models/CustAppGroupOversee';
import Customer from '../../../../models/Customer';
import { DialogMode } from '../../../../models/DialogMode';
import { addCustomer, addToOldState, removeCustomer } from '../../../../store/actions/appGroupActions';
import { AppGroupActionType } from '../../../../store/actions/appGroupActionType';
import { AppGroupState } from '../../../../store/reducers/appGroupsReducer';
import { getAppGroup, getAppGroupCustomers } from '../../../../store/selectors/appGroupSelectors';
import { compare } from '../../../../utils/string';
import CustomerData from './CustomerData';

interface CustomerDataContainerProps {
  dialogMode: DialogMode;
  appGroup: AppGroup;
  appGroupCustomers: Array<Customer>;
  onError: (error: { response: { data: ApiResult<any> } }) => void;
  addCustomer: (customer: Customer) => { type: AppGroupActionType; payload: { customer: Customer } };
  removeCustomer: (customer: Customer) => { type: AppGroupActionType; payload: { customer: Customer } };
  addToOldState: (oldState: Partial<AppGroupState>) => { type: AppGroupActionType; payload: { oldState: Partial<AppGroupState> } };
}

interface CustomerDataContainerState {
  isLoading: boolean;
  inaccCustomers: Array<Customer>;
}

class CustomerDataContainer extends Component<CustomerDataContainerProps, CustomerDataContainerState> {
  state: CustomerDataContainerState = {
    isLoading: false,
    inaccCustomers: [],
  };

  componentDidMount() {
    const customerRequests: Array<Promise<any>> = [CustomersReqHandler.loadCustomers('customerName')];
    if (this.props.dialogMode !== DialogMode.CREATE && !this.props.appGroupCustomers.length) {
      customerRequests.push(custAppGroupOverseeReqHandler.loadCustAppGroupOversees(0, this.props.appGroup.appGroupId));
    }
    this.setState({ isLoading: true }, () =>
      Promise.all(customerRequests)
        .then(([customers, oversees]) => {
          const filteredCustomers = customers.filter((cust: Customer) => {
            if (oversees && oversees.length) {
              const isCustomererInGroup = !!oversees.find((group: CustAppGroupOversee) => group.customerId === cust.customerId);
              if (isCustomererInGroup) {
                this.props.addCustomer(cust);
              }
            }
            return this.props.appGroupCustomers.findIndex(c => c.customerId === cust.customerId) < 0;
          });
          this.props.addToOldState({ customers: this.props.appGroupCustomers });
          const inaccCustomers = this.sortCustomers(filteredCustomers);
          this.setState({ inaccCustomers, isLoading: false });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          this.props.onError(error);
        })
    );
  }

  handleMove = (id: number, toAuthorized: boolean) => {
    if (this.props.dialogMode === DialogMode.VIEW) {
      return;
    }
    const customersList = [...this.state.inaccCustomers];
    if (toAuthorized) {
      let customerIndex = 0;
      const selectedCustomer = customersList.find((customer, index) => {
        customerIndex = index;
        return customer.customerId === id;
      });
      customersList.splice(customerIndex, 1);
      if (selectedCustomer) {
        this.props.addCustomer(selectedCustomer);
      }
    } else {
      const selectedCustomer = this.props.appGroupCustomers.find(customer => {
        return customer.customerId === id;
      });
      if (selectedCustomer) {
        this.props.removeCustomer(selectedCustomer);
        customersList.push(selectedCustomer);
      }
    }
    this.setState({ inaccCustomers: this.sortCustomers(customersList) });
  };

  private sortCustomers(customers: Array<Customer>): Array<Customer> {
    return customers.sort((first, second) => compare(first.customerName, second.customerName));
  }

  render() {
    return (
      <CustomerData
        dialogMode={this.props.dialogMode}
        isLoading={this.state.isLoading}
        inaccCustomers={this.state.inaccCustomers}
        accCustomers={this.sortCustomers(this.props.appGroupCustomers)}
        moveCustomer={this.handleMove}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({ appGroup: getAppGroup(state), appGroupCustomers: getAppGroupCustomers(state) });

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      addCustomer,
      removeCustomer,
      addToOldState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDataContainer);
