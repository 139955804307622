import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import Project from "../../../../models/Project";
import { compare } from "../../../../utils/string";

const CustomerNameColumn = (translation: UseTranslationResponse): Column<Project> => {
    return {
      title: translation.t('customerName'),
      field: 'customerName',
      customSort: (first: Project, second: Project) => {
        return compare(first.customerName, second.customerName);
      }
    };
};

export default CustomerNameColumn;
