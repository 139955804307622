import React from 'react';
import { useTranslation } from 'react-i18next';
import { SaraSelect, Lookup } from '../../saraSelect/SaraSelect';

interface ProjectsSelectorProps {
  value: number;
  onChange?: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode,
  ) => void;
  validated: boolean;
  lookups: Array<Lookup>;
}

const ProjectsSelector: React.FC<ProjectsSelectorProps> = (props: ProjectsSelectorProps) => {
  const { t } = useTranslation();

  return (
    <SaraSelect
        validated={props.validated}
        label={t("project")}
        id="project-id"
        value={props.value}
        onChange={props.onChange}
        lookups={props.lookups} 
        required/>
  );
}

export default ProjectsSelector;
