import React from 'react';
import { useTranslation } from 'react-i18next';
import unauthorizedGraphics from '../../assets/403.svg';
import ErrorPage from './ErrorPage';

interface UnauthorizedProps {}

const Unauthorized: React.FC<UnauthorizedProps> = (props: UnauthorizedProps) => {
  const { t } = useTranslation();

  return <ErrorPage imageUrl={unauthorizedGraphics} title={t('unauthorizedTitle')} description={t('unauthorizedDescription')} />;
};

export default Unauthorized;
