import { Column } from "material-table";
import NameColumn from "./Name";
import { UseTranslationResponse } from "react-i18next";
import { FormView } from "../../../../models/FormView";
import StatusIndicatorColumn from "./StatusIndicator";

const columns = (translation: UseTranslationResponse, formViews: Array<FormView>): Column<any>[] => {
    const columns: Array<Column<any>> = [
      NameColumn(translation)
    ];
    formViews.forEach(formView => {
      columns.push(StatusIndicatorColumn(translation, formView.formViewCd.toLocaleUpperCase()));
    });
    return columns;
}

export default columns;
