import { createStyles, Paper, Theme, withStyles, WithStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomersReqHandler from '../../api/customersRemote';
import Customer from '../../models/Customer';
import { DialogMode } from '../../models/DialogMode';
import { clearCustomersState, storeCustomer, switchValid } from '../../store/actions/customerActions';
import { CustomerActionType } from '../../store/actions/customerActionType';
import AddNewButton from '../common/AddNewButton';
import InfoSnackbar from '../infoSnackbar/InfoSnackbar';
import CustomerDialogContainer from './customerDialog/CustomerDialogContainer';
import CustomersList from './customersList/CustomersList';
import { ApiResult } from '../../api/apiResult';
import { ApiErrorMessage } from '../../api/apiMessage';
import NotificationDialog from '../notification/NotificationDialog';

const styles = (theme: Theme) =>
  createStyles({
    paperWithTable: {
      '& thead tr th': {
        borderRadius: 4,
      },
    },
  });

interface CustomersContainerProps extends WithStyles<typeof styles>, WithTranslation {
  canReadCustomer: boolean;
  canWriteCustomer: boolean;
  storeCustomer: (customer: Customer) => { type: CustomerActionType; payload: { customer: Customer } };
  clearCustomersState: () => { type: CustomerActionType };
  switchValid: (isValid: boolean) => { type: CustomerActionType; payload: { isValid: boolean } };
  appGroupAccessId: number;
}

interface CustomersContainerState {
  customers: Array<Customer>;
  isLoading: boolean;
  isDialogOpen: boolean;
  dialogMode: DialogMode;
  snackBarMessage: string;
  isOpenNotification: boolean;
  notificationTitle: string;
  notificationContent: string;
}

class CustomersContainer extends Component<CustomersContainerProps, CustomersContainerState> {
  state: CustomersContainerState = {
    customers: [],
    isLoading: true,
    isDialogOpen: false,
    dialogMode: DialogMode.CREATE,
    snackBarMessage: '',
    isOpenNotification: false,
    notificationTitle: '',
    notificationContent: '',
  };

  componentDidMount() {
    if (this.props.canReadCustomer) {
      this.refreshData();
    }
  }

  componentDidUpdate(prevProps: CustomersContainerProps) {
    if (prevProps.canReadCustomer !== this.props.canReadCustomer) {
      this.refreshData();
    }
  }

  refreshData() {
    this.setState({ isLoading: true, isDialogOpen: false }, () => {
      CustomersReqHandler.loadCustomers().then((customers: Array<Customer>) =>
        this.setState({
          customers,
          isLoading: false,
        })
      );
    });
  }

  handleClose = () => {
    this.props.clearCustomersState();
    this.refreshData();
  };

  handleSaved = () => {
    this.props.clearCustomersState();
    this.setState(
      {
        snackBarMessage:
          this.state.dialogMode === DialogMode.CREATE
            ? this.props.t('customerCreatedSuccessfully')
            : this.props.t('customerUpdatedSuccessfully'),
      },
      this.refreshData
    );
  };

  setSnackbarMessage = (snackBarMessage: string) => {
    this.setState({ snackBarMessage });
  };

  deleteCustomer = (data: Customer): void => {
    this.setState({ isLoading: true }, () => {
      CustomersReqHandler.deleteCustomer(data.customerId)
        .then(() => {
          this.refreshData();
        })
        .catch((err: any) => {
          this.setState({ isLoading: false }, () => {
            this.handleError(err);
          });
        });
    });
  };

  handleError = (error: { response: { data: ApiResult<any> } }) => {
    const apiErrorMessage =
      error.response && error.response.data && error.response.data.messages
        ? error.response.data.messages.find(errMessage => !!ApiErrorMessage[errMessage.message])
        : null;
    const errorMessage = apiErrorMessage ? apiErrorMessage.message : 'unexpectedErrorMessage';
    const errorMessageTitle = apiErrorMessage ? apiErrorMessage.message + 'Title' : 'unexpectedErrorMessageTitle';
    this.setState({ isOpenNotification: true, notificationTitle: errorMessageTitle, notificationContent: errorMessage });
  };

  closeNotificationDialog = (): void => {
    this.setState({ isOpenNotification: false, notificationTitle: '', notificationContent: '' });
  };

  render() {
    return (
      <div className="pageWrapper">
        {this.props.canWriteCustomer && (
          <AddNewButton
            onClick={() => {
              this.setState({ isDialogOpen: true, dialogMode: DialogMode.CREATE });
            }}
          />
        )}
        <Paper className={this.props.classes.paperWithTable}>
          <CustomersList
            canReadCustomer={this.props.canReadCustomer}
            canWriteCustomer={this.props.canWriteCustomer}
            isLoading={this.state.isLoading}
            customers={this.state.customers}
            onDetailsClick={
              this.props.canReadCustomer
                ? (event: any, data: Customer) => {
                    this.props.storeCustomer(data);
                    this.props.switchValid(true);
                    return this.setState({ dialogMode: DialogMode.VIEW, isDialogOpen: true });
                  }
                : () => {}
            }
            onEditClick={
              this.props.canWriteCustomer
                ? (event: any, data: Customer) => {
                    this.props.storeCustomer(data);
                    this.props.switchValid(true);
                    return this.setState({ dialogMode: DialogMode.UPDATE, isDialogOpen: true });
                  }
                : () => {}
            }
            onDelete={
              this.props.canWriteCustomer
                ? (data: Customer) => {
                    this.deleteCustomer(data);
                    return Promise.resolve();
                  }
                : () => {
                    return Promise.resolve();
                  }
            }
          />
        </Paper>
        <CustomerDialogContainer
          isOpen={this.state.isDialogOpen}
          onClose={this.handleClose}
          onSaved={this.handleSaved}
          dialogMode={this.state.dialogMode}
          setSnackbarMessage={this.setSnackbarMessage}
          appGroupAccessId={this.props.appGroupAccessId}
        />
        <InfoSnackbar
          open={!!this.state.snackBarMessage}
          onClose={() => this.setState({ snackBarMessage: '' })}
          text={this.state.snackBarMessage}
        />
        {this.state.isOpenNotification && (
          <NotificationDialog
            open={this.state.isOpenNotification}
            title={this.state.notificationTitle}
            content={this.state.notificationContent}
            onClose={this.closeNotificationDialog}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      storeCustomer,
      clearCustomersState,
      switchValid,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withTranslation()(withStyles(styles)(CustomersContainer)));
