import DateFnsUtils from '@date-io/date-fns';
import { Paper, Theme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';


const styles = (theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    value: {
      fontWeight: 'bold',
      paddingRight: '5px',
      paddingLeft: '5px'
    },
    textBorder: {
      borderLeft: '1px solid',
      paddingLeft: '5px'
    }
  });

interface AnnualPaidVacProps extends WithStyles<typeof styles> {
  year: string;
  remaining: number;
  initial: number;
}

const AnnualPaidVac: React.FC<AnnualPaidVacProps> = (props: AnnualPaidVacProps) => {
  
  const { t } = useTranslation();
  const year = moment(props.year).year().toString()

  return (
    <Paper style={{ padding: 15 }}>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>

        <div className={props.classes.wrapper}>
          <div>{t('vacationDaysSummeryText')}{year}</div>
          <div className={props.classes.wrapper}>
            <div>
              <span>{t('remaining')}</span>
              <span className={props.classes.value}>{props.remaining}</span>
            </div>
            <div>
              <span className={props.classes.textBorder}>{t('initial')}</span>
              <span className={props.classes.value}>{props.initial}</span>
            </div>
          </div>
        </div>

      </MuiPickersUtilsProvider>
      
    </Paper>
  );
};

export default withStyles(styles)(AnnualPaidVac);
