import { KeyboardDatePicker } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Moment from 'moment';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import enLocale from 'date-fns/locale/en-GB';
import DateFnsUtils from '@date-io/date-fns';

interface SaraDatePickerProps extends WithTranslation {
  label?: React.ReactNode;
  value: ParsableDate;
  onChange: (date: MaterialUiPickersDate | null, value?: string | null) => void;
  onHandleValidation?: () => void;
  fullWidth?: boolean;
  required?: boolean;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
  validated: boolean;
  error?: boolean;
  helperText?: string;
  selectOnFocus?: boolean;
  disabled?: boolean;
}

interface SaraDatePickerState {
  errorText?: string | null | undefined;
  hasError: boolean;
}

class SaraDatePicker extends Component<SaraDatePickerProps, SaraDatePickerState> {
  state: SaraDatePickerState = {
    hasError: false,
  };

  componentDidUpdate(prevProps: SaraDatePickerProps, prevState: SaraDatePickerState) {
    if (prevProps.validated !== this.props.validated) {
      //need to run validation after save
      this.handleValidation();
    }
  }

  handleValidation() {
    this.setState({ errorText: '', hasError: false });
    if (this.props.validated && !this.props.value && this.props.required) {
      this.setState({ errorText: this.props.t('fieldIsMandatory'), hasError: true });
    }

    if (this.props.value && this.props.value.toString().toUpperCase() === 'INVALID DATE') {
      this.setState({ errorText: this.props.t('wrongFormat'), hasError: true });
    }

    if (this.props.onHandleValidation) {
      this.props.onHandleValidation();
    }
  }

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          label={this.props.label}
          value={this.props.value === undefined ? null : this.props.value}
          onChange={this.props.onChange}
          fullWidth={this.props.fullWidth}
          InputLabelProps={{ shrink: this.props.disabled ? true : undefined }}
          format="dd/MM/yyyy"
          required={this.props.required}
          labelFunc={(date: Date | null, invalidLabel: string): string => {
            if (date == null || !Moment(date).isValid()) {
              return '';
            }
            return Moment(date).format('DD/MM/YYYY');
          }}
          minDate={this.props.minDate ? this.props.minDate : undefined}
          maxDate={this.props.maxDate ? this.props.maxDate : undefined}
          invalidDateMessage={this.props.t('wrongFormat')}
          onBlur={() => this.handleValidation()}
          onClose={() => this.handleValidation()}
          error={this.state.hasError || this.props.error}
          helperText={this.state.errorText ? this.state.errorText : this.props.helperText}
          ref={null}
          innerRef={null}
          className={''}
          style={undefined}
          onFocus={event => this.props.selectOnFocus && event.target.select()}
          rows={''}
          rowsMax={''}
          disabled={this.props.disabled}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withTranslation()(SaraDatePicker);
