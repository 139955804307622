import { createStyles, TableCell, TableRow, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SaraCheckCancelButtons from './SaraCheckCancelButtons';

const styles = () =>
  createStyles({
    actionsWrapper: {
      position: 'sticky',
      right: 0,
      zIndex: 995,
      width: 'max-content',
      display: 'flex',
      justifyContent: 'right',
      '& > .confirmMessage': {
        fontSize: 14,
        textAlign: 'right',
        padding: 12,
      },
      '& > .actionButtons': {
        display: 'flex',
        width: 84,
        padding: '0 5px',
      },
    },
  });

interface SaraDeleteRowProps extends WithStyles<typeof styles> {
  onSave: () => void;
  onCancel: () => void;
  confirmationMessage: string;
  columnsBefore: number;
}

const SaraDeleteRow: React.FC<SaraDeleteRowProps> = (props: SaraDeleteRowProps) => {
  const { t } = useTranslation();

  return (
    <TableRow className="MuiTableRow-root inlineEditRow">
      <TableCell colSpan={props.columnsBefore} />
      <TableCell padding="none" className={props.classes.actionsWrapper}>
        <Typography className="confirmMessage">{t(props.confirmationMessage)}</Typography>
        <div className="actionButtons">
          <SaraCheckCancelButtons onCheck={props.onSave} onCancel={props.onCancel} />
        </div>
      </TableCell>
    </TableRow>
  );
};

export default withStyles(styles)(SaraDeleteRow);
