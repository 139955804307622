import { Box } from '@material-ui/core';
import Moment from 'moment';
import moment from 'moment-timezone';
import React, { Component, Fragment } from 'react';
import { TimelineItem } from 'react-calendar-timeline';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApiErrorMessage } from '../../api/apiMessage';
import { ApiResult } from '../../api/apiResult';
import calendarReqHandler from '../../api/calendarRemote';
import OfficesReqHandler from '../../api/officesRemote';
import CalendarDay from '../../models/CalendarDay';
import Office from '../../models/Office';
import OfficeCalendarEvent from '../../models/OfficeCalendarEvent';
import CalendarDayType from '../../models/CalendarDayType';
import Permissions from '../../models/Permissions';
import User from '../../models/User';
import { getTimezone, getUser, SelectUserState } from '../../store/selectors/userSelectors';
import { convertBrowserTimeToApiFormat, resetHours } from '../../utils/dateTime';
import { OFFICE_CALENDAR_EVENT_ITEM } from '../../utils/officeCalendarEventItem';
import NotificationDialog from '../notification/NotificationDialog';
import OfficeCalendar from './OfficeCalendar';
import OfficeEventDialog from './officeEventDialog/OfficeEventDialog';
import OfficeFilter from './officeFilter/OfficeFilter';

interface OfficeContainerProps extends WithTranslation {
  permissions: Permissions;
  user: User;
  timeZoneId: string;
}

interface OfficeContainerState {
  timeStart: Moment.Moment;
  timeEnd: Moment.Moment;
  calendarEventItems: Array<TimelineItem<any, any>>;
  calendarTaskItems: Array<TimelineItem<any, any>>;
  isDialogOpen: boolean;
  newEvent: OfficeCalendarEvent;
  fakeEvent: TimelineItem<any, any>;
  holidays: Array<string>;
  selectedFilters: { timeStart: Moment.Moment; timeEnd: Moment.Moment; officeId: string };
  offices: Array<Office>;
  selectedOffices: Array<Office>;
  dayTypes: Array<CalendarDayType>;
  isOpenNotification: boolean;
  notificationTitle: string;
  notificationContent: string;
}

class OfficeContainer extends Component<OfficeContainerProps, OfficeContainerState> {
  state: OfficeContainerState = {
    timeStart: moment().startOf('month'),
    timeEnd: moment().endOf('month'),
    calendarEventItems: [],
    calendarTaskItems: [],
    isDialogOpen: false,
    newEvent: OFFICE_CALENDAR_EVENT_ITEM,
    fakeEvent: OFFICE_CALENDAR_EVENT_ITEM,
    holidays: [],
    selectedFilters: {
      timeStart: moment()
        .startOf('month')
        .hours(0)
        .minutes(0),
      timeEnd: moment()
        .endOf('month')
        .hours(0)
        .minutes(0),
      officeId: '',
    },
    offices: [],
    selectedOffices: [],
    dayTypes: [],
    isOpenNotification: false,
    notificationTitle: '',
    notificationContent: '',
  };

  componentDidMount() {
    this.loadDayTypes();
    this.setOfficeId();
    OfficesReqHandler.loadOffices()
      .then((resp: Array<Office>) => {
        const selectedFilters = { ...this.state.selectedFilters };
        if (resp.length) {
          selectedFilters.officeId = String(this.props.user.officeId);
        }
        this.setState({ offices: resp, selectedFilters }, this.submitFilter);
      })
      .catch(this.handleError);
  }

  loadHolidays = () => {
    const selectedFilters = this.state.selectedFilters;
    calendarReqHandler
      .loadHolidays(selectedFilters.timeStart, selectedFilters.timeEnd, selectedFilters.officeId)
      .then((resp: Array<CalendarDay>) => {
        const holidays: Array<string> = [];
        resp.forEach((item: CalendarDay) => {
          holidays.push(item.date);
        });
        this.setState({ holidays, calendarEventItems: resp });
      })
      .catch(this.handleError);
  };

  loadOffice = (officeId: number): void => {
    const offices = this.state.offices.filter(office => office.officeId === officeId);
    this.setState({ selectedOffices: offices || [] });
  };

  loadDayTypes = (): void => {
    calendarReqHandler
      .loadDayTypes()
      .then((resp: Array<CalendarDayType>) => {
        this.setState({ dayTypes: resp });
      })
      .catch(this.handleError);
  };

  submitFilter = (): void => {
    if (
      this.state.selectedFilters.timeStart &&
      this.state.selectedFilters.timeEnd &&
      this.state.selectedFilters.timeStart <= this.state.selectedFilters.timeEnd
    ) {
      this.setState(
        {
          timeStart: this.state.selectedFilters.timeStart
            .clone()
            .hours(0)
            .minutes(0),
          timeEnd: this.state.selectedFilters.timeEnd
            .clone()
            .hours(23)
            .minutes(59),
        },
        () => {
          this.loadOffice(+this.state.selectedFilters.officeId);
          this.loadHolidays();
        }
      );
    }
  };

  changeFilter = (event: any, field: string) => {
    const selectedFilters = { ...this.state.selectedFilters };
    switch (field) {
      case 'timeStart':
        selectedFilters.timeStart = moment(event).isValid() ? moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId)) : event;
        break;
      case 'timeEnd':
        selectedFilters.timeEnd = moment(event).isValid() ? moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId)) : event;
        break;
      case 'officeId':
        selectedFilters.officeId = event.target.value;
        break;
      default:
        break;
    }
    this.setState({ selectedFilters });
  };

  handleChange = (event: any, field: string) => {
    const newEvent: OfficeCalendarEvent = { ...this.state.newEvent };
    switch (field) {
      case 'isWorking':
        newEvent.isWorking = event.target.value;
        break;
      case 'comment':
        newEvent.dayDescription = event.target.value;
        break;
      default:
        break;
    }
    this.setState({ newEvent });
  };

  handleClose = () => {
    this.removeFakeEvent();
    this.setState({ newEvent: OFFICE_CALENDAR_EVENT_ITEM, fakeEvent: OFFICE_CALENDAR_EVENT_ITEM, isDialogOpen: false }, this.setOfficeId);
  };

  actionHandler = () => {
    this.handleClose();
    this.loadHolidays();
  };

  handleError = (error: { response: { data: ApiResult<any> } }) => {
    const apiErrorMessage =
      error.response && error.response.data && error.response.data.messages
        ? error.response.data.messages.find(errMessage => !!ApiErrorMessage[errMessage.message])
        : null;
    const errorMessage = apiErrorMessage ? apiErrorMessage.message : 'unexpectedErrorMessage';
    const errorMessageTitle = apiErrorMessage ? apiErrorMessage.message + 'Title' : 'unexpectedErrorMessageTitle';
    this.setState({ isOpenNotification: true, notificationTitle: errorMessageTitle, notificationContent: errorMessage });
  };

  handleSave = () => {
    const isWorking = this.state.newEvent.isWorking;
    const event = { ...this.state.newEvent, isWorking: isWorking === 2 ? 0 : 1 };
    const newEvent: OfficeCalendarEvent = { ...event };
    calendarReqHandler
      .editOfficeCalendar(newEvent)
      .then(this.actionHandler)
      .catch(this.handleError);
  };

  onCanvasClick = (officeId: number, time: number, event: any) => {
    const newDate: Moment.Moment = moment(convertBrowserTimeToApiFormat(resetHours(time), this.props.timeZoneId));

    const newEvent: OfficeCalendarEvent = (() => {
      // If an item with the same date is found - user clicked on a holiday
      const holiday = this.state.calendarEventItems.find((item: OfficeCalendarEvent) => moment(item.date).isSame(newDate, 'day'));
      if (holiday) {
        return { ...holiday, date: newDate, officeId, isWorking: 2 };
      }
      return { ...this.state.newEvent, date: newDate, officeId };
    })();

    this.setState(prevState => ({
      newEvent,
      calendarEventItems: [
        ...prevState.calendarEventItems,
        {
          id: 0.1,
          group: officeId,
          title: '',
          start_time: moment(newDate),
          end_time: moment(newDate).add(1, 'day'),
          style: {
            backgroundColor: '#FDC02F',
            borderColor: '#FDC02F',
          },
        },
      ],
      isDialogOpen: true,
    }));
  };

  removeFakeEvent = (): void => {
    const calendarEventItems: Array<TimelineItem<any, any>> = [...this.state.calendarEventItems];
    const fakeEventIndex: number = calendarEventItems.findIndex((item: TimelineItem<any, any>) => item.id === 0.1);

    if (fakeEventIndex > -1) {
      calendarEventItems.splice(fakeEventIndex, 1);
      this.setState({ calendarEventItems });
    }
  };

  closeNotificationDialog = (): void => {
    this.setState({ isOpenNotification: false, notificationTitle: '', notificationContent: '' });
  };

  setOfficeId = (): void => {
    const newEvent = { ...this.state.newEvent };
    if (this.props.user.officeId) {
      newEvent.officeId = this.props.user.officeId;
    }
    this.setState({ newEvent });
  };

  render() {
    const todayDate: Moment.Moment = Moment(convertBrowserTimeToApiFormat(resetHours(new Date()), this.props.timeZoneId));
    return (
      <Fragment>
        <div className="pageWrapper">
          <Box display="flex" alignItems="center" p={2} mt={1} mb={2} boxShadow={1} bgcolor="white" width="calc(100vw - 320px)">
            <OfficeFilter
              selectedFilters={this.state.selectedFilters}
              offices={this.state.offices}
              changeFilter={this.changeFilter}
              submitFilter={this.submitFilter}
            />
          </Box>
          <OfficeCalendar
            calendarEventItems={this.state.calendarEventItems}
            offices={this.state.selectedOffices}
            timeStart={this.state.timeStart.valueOf()}
            timeEnd={this.state.timeEnd.valueOf()}
            holidays={this.state.holidays}
            onCanvasClick={this.onCanvasClick}
          />
        </div>
        {this.state.selectedOffices && this.state.selectedOffices.length && (
          <OfficeEventDialog
            isOpen={this.state.isDialogOpen}
            onSave={this.handleSave}
            onClose={this.handleClose}
            newEvent={this.state.newEvent}
            handleChange={this.handleChange}
            dayTypes={this.state.dayTypes}
            office={this.state.selectedOffices[0]}
            user={this.props.user}
            todayDate={todayDate}
          />
        )}
        <NotificationDialog
          open={this.state.isOpenNotification}
          title={this.state.notificationTitle}
          content={this.state.notificationContent}
          onClose={this.closeNotificationDialog}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = (state: SelectUserState) => ({
  user: getUser(state),
  timeZoneId: getTimezone(state),
});
export default connect(mapStateToProps)(withTranslation()(OfficeContainer));
