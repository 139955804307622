import React from 'react';
import { Column } from 'material-table';
import Task from '../../../../../../models/Task';
import { UseTranslationResponse } from 'react-i18next';
import Employee from '../../../../../../models/Employee';
import { compare } from '../../../../../../utils/string';

const getEmployeeName = (task: Task, employees: Array<Employee>): string => {
  if (task.assignedToName) {
    return task.assignedToName;
  }
  const employee = employees.find(e => e.empId === (task.taskAssignees ? task.taskAssignees[0] : -1));
  return employee ? `${employee.empFirstName} ${employee.empLastName}` : 'unknown';
};

const AssigneeColumn = (translation: UseTranslationResponse, employees: Array<Employee>): Column<Task> => {
  return {
    title: translation.t('taskAssignee'),
    field: 'assignedTo',
    render: (rowData: Task) => {
      return <span>{getEmployeeName(rowData, employees)}</span>;
    },
    customSort: (first: Task, second: Task) => {
      if (!!first.taskAssignees && !!second.taskAssignees && first.taskAssignees[0] === second.taskAssignees[0]) {
        return 0;
      }
      const firstFullName = getEmployeeName(first, employees);
      const secondFullName = getEmployeeName(second, employees);
      return compare(firstFullName, secondFullName);
    },
  };
};

export default AssigneeColumn;
