import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import CalendarEvent from '../../../../models/CalendarEvent';
import StatusIndicatorColumn from './StatusIndicator';
import EmployColumn from './Employ';
import StartDateColumn from './StartDate';
import EndDateColumn from './EndDate';
import EventTypeColumn from './EventType';
import WorkingDaysColumn from './WorkingDays';
import Notes from './Notes';
import ApprovedByColumn from './ApprovedBy';

const columns = (translation: UseTranslationResponse): Column<CalendarEvent>[] => {
  return [
    StatusIndicatorColumn(),
    EmployColumn(translation),
    StartDateColumn(translation),
    EndDateColumn(translation),
    EventTypeColumn(translation),
    WorkingDaysColumn(translation),
    ApprovedByColumn(translation),
    Notes(translation),
  ];
};

export default columns;
