import React from 'react';
import { useTranslation } from 'react-i18next';
import loginStyles from './login.module.css';
import logoImg from '../../assets/logo.svg';
import { FormControl, TextField, Button, createStyles, Theme, CircularProgress } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: '#29BB9C',
      color: 'white',
      fontWeight: 500,
      '&:hover': {
        backgroundColor: '#23a68b',
      },
    },
    sciantLogo: {
      maxWidth: 200,
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    textField: {
      width: '300px',
      '& input': {
        paddingLeft: '6px',
      },
    },
  });

interface LoginProps extends WithStyles<typeof styles> {
  email: string;
  password: string;
  handleChange: (event: any, field: string) => void;
  handleLogin: (event: any) => void;
  errors: {
    email: string;
    password: string;
  };
  handleValidation: (field: string) => void;
  isLoading: boolean;
  sessionExpired: boolean;
  authenticationFailed: boolean;
  messageError: string;
  loginBoxRef: React.RefObject<any>;
  loginBoxHeight?: number;
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {
  const { t } = useTranslation();

  const handleKeyDown = (event: any): void => {
    if (event.key === 'Enter') {
      props.handleLogin(event);
    }
  };

  let loginStyle: React.CSSProperties | undefined;
  if (props.loginBoxHeight) {
    loginStyle = { height: props.loginBoxHeight };
  }

  return (
    <div className={loginStyles.loginWrapper}>
      <div className={loginStyles.loginBox} ref={props.loginBoxRef} style={loginStyle}>
        <img src={logoImg} alt="logo" className={props.classes.sciantLogo} />
        <div className={loginStyles.header}>{t('login')}</div>
        <div>{t(props.sessionExpired ? 'sessionExpiredMessage' : 'toContinue')}</div>
        <form onSubmit={ props.handleLogin }>
          <FormControl>
            <TextField
              label={t('email')}
              margin="normal"
              value={props.email}
              onChange={event => props.handleChange(event, 'email')}
              required
              error={props.errors.email !== ''}
              helperText={t(props.errors.email)}
              onBlur={() => props.handleValidation('email')}
              onKeyDown={handleKeyDown}
              className={props.classes.textField}
              autoComplete={'on'}
              name={'sara-email'}
            />
            <TextField
              label={t('password')}
              type="password"
              margin="normal"
              value={props.password}
              onChange={event => props.handleChange(event, 'password')}
              required
              error={props.errors.password !== ''}
              helperText={t(props.errors.password)}
              onBlur={() => props.handleValidation('password')}
              onKeyDown={handleKeyDown}
              className={props.classes.textField}
            />
            {props.authenticationFailed && <div className={loginStyles.errorAuthenticationText}>{t(`${props.messageError}`)}</div>}
            <div className={loginStyles.smallText}>
              <div>{t('troublesLogin')}</div>
              <div>{t('pleaceContact')}</div>
            </div>
            <div className={loginStyles.btnWrapper}>
              <Button type="submit" disabled={props.isLoading} fullWidth variant="contained" className={props.classes.button}>
                {t('login')}
              </Button>
              {props.isLoading && <CircularProgress color="primary" size={24} className={props.classes.buttonProgress} />}
            </div>
          </FormControl>
        </form>
      </div>
    </div>
  );
};

export default withStyles(styles)(Login);
