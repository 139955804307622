import { CircularProgress, Grid, TextField, Theme, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppGroup from '../../../../models/AppGroup';
import AppUser from '../../../../models/AppUser';
import { DialogMode } from '../../../../models/DialogMode';
import SaraTransferLists from '../../../common/SaraTransferLists';
import appGroupsStyles from '../../AppGroups.module.css';

const styles = (theme: Theme) =>
  createStyles({
    iconFieldContainer: {
      display: 'flex',
      '& > svg, & > .iconPlaceHolder': {
        height: '100%',
        paddingTop: 9,
        marginRight: 6,
      },
    },
    listHeight: {
      height: '14em',
    },
    readonlyListHeight: {
      height: '24em',
    },
  });

interface UsersProps extends WithStyles<typeof styles> {
  appGroup: AppGroup;
  validated: boolean;
  unauthAppUsers: Array<AppUser>;
  authAppUsers: Array<AppUser>;
  moveAppUser: (id: number, toAuthorized: boolean) => void;
  handleChange: (event: any, field: string) => void;
  dialogMode: DialogMode;
  isLoading: boolean;
}

const Users: React.FC<UsersProps> = (props: UsersProps) => {
  const { t } = useTranslation();
  const listHeightStyle = props.dialogMode === DialogMode.VIEW ? props.classes.readonlyListHeight : props.classes.listHeight;

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={12}>
          <Typography>{t('appGroupDescribe')}</Typography>
        </Grid>
        <Grid item xs={5} className={props.classes.iconFieldContainer}>
          <DescriptionIcon color="secondary" />
          <TextField
            label={t('appGroupName')}
            fullWidth={true}
            value={props.appGroup.appGroupName}
            required
            onChange={event => props.handleChange(event, 'appGroupName')}
            error={props.validated && !props.appGroup.appGroupName}
            helperText={props.validated && !props.appGroup.appGroupName ? t('fieldIsMandatory') : null}
            multiline={true}
            rowsMax={4}
            rows={1}
            InputProps={{ disabled: props.dialogMode === DialogMode.VIEW }}
            inputProps={{ maxLength: 200 }}
            InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={5} className={props.classes.iconFieldContainer}>
          <TextField
            label={t('appGroupDescr')}
            fullWidth={true}
            value={props.appGroup.appGroupDescr || ''}
            onChange={event => props.handleChange(event, 'appGroupDescr')}
            multiline={true}
            rowsMax={4}
            rows={1}
            InputProps={{ disabled: props.dialogMode === DialogMode.VIEW }}
            inputProps={{ maxLength: 1000 }}
            InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={12}>
          <Typography>{t('appGroupAddUsers')}</Typography>
        </Grid>
        <Grid item xs={12}>
          {props.isLoading ? (
            <CircularProgress color="primary" className="loadingSpinner" />
          ) : (
            <SaraTransferLists
              showSearchBar={props.dialogMode !== DialogMode.VIEW}
              leftItems={props.unauthAppUsers.map((item: AppUser) => ({ key: item.appUserId, value: item, text: item.username }))}
              rightItems={props.authAppUsers.map((item: AppUser) => ({ key: item.appUserId, value: item, text: item.username }))}
              leftListStyle={`${appGroupsStyles.inaccList} ${listHeightStyle}`}
              rightListStyle={`${appGroupsStyles.accList} ${listHeightStyle}`}
              handleMove={(id, toAuthorized) => props.moveAppUser(id, toAuthorized)}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Users);
