import { Column } from 'material-table';
import React from 'react';
import { UseTranslationResponse } from 'react-i18next';
import Employee from '../../../../models/Employee';
import StatusIndicator from '../../../common/StatusIndicator';

const EmployeeStatusColumn = (translation: UseTranslationResponse): Column<Employee> => ({
  title: '',
  field: '',
  cellStyle: { textAlign: 'center' },
  headerStyle: { textAlign: 'center' },
  width: 20,
  render: (rowData: Employee) => (
    <StatusIndicator activeLabel={translation.t('active')} inactiveLabel={translation.t('inactive')} status={rowData.isActive === 1} />
  ),
  customSort: (first: Employee, second: Employee) => second.isActive - first.isActive, //active needs to be before inactive
});

export default EmployeeStatusColumn;
