import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitle, DialogContent, Dialog, DialogActions, Theme, TextField, Tooltip, IconButton, InputAdornment, ListItem, List} from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import SaraDialogButtons from '../common/SaraDialogButtons';
import { Close, Visibility, VisibilityOff } from '@material-ui/icons';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      padding: '24px',
      height: '62%',
      minWidth: '420px'
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column', 
      height: '200px',
      justifyContent: 'space-between'
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    tooltip: {
      maxWidth: 'none',
    },
    paddingStyle: {
      paddingBottom: '2px', 
      paddingTop: '2px'
    },
    listWrapper : {
      width: '450px',
      fontSize: '14px'
    },
    iconStyle: {
      marginRight: '10px',
      paddingTop: 0
    },
    listItemWrapper: {
      display: 'flex', 
      alignItems: 'flex-start',
      lineHeight: '1.3'
    },
    hidden: {
      display: 'none'
    }
  });

interface ChangePasswordModalProps  extends WithStyles<typeof styles>  {
  classes: any;
  open: boolean;
  onClose: () => void;
  onSubmit: (event: any) => void;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  handleChange: (event: any, type: string) => void;
  showOldPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  handleClickShowPassword: (type: string) => void;
  oldPasswordValidationMessage: string;
  newPasswordValidationMessage: string;
  confirmPasswordValidationMessage: string;
  hiddenDialog: boolean;
};

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = (props: ChangePasswordModalProps) => {
  
  const { t } = useTranslation();
  const tooltipString: string  = t('helperTextPassword');
  const tooltipArray: Array<string> = tooltipString.split('|');
  const firstOl: Array<string>= tooltipArray.slice(0,3);
  const secondOl: Array<string> = tooltipArray.slice(3,8);
  secondOl[3] = secondOl[3] + '(~!@#$%^&*_-+=`|(){}[]:;"<>,.?/)';
  const firstList: JSX.Element =  (
    <Fragment>
      {firstOl.map( (item: string, index: number) => (
        <ListItem key={index} className={ props.classes.paddingStyle }>
          {item}
        </ListItem>
      ))}
    </Fragment>
  );
  const nestedList: JSX.Element = (
    <List className={ props.classes.paddingStyle }>
      {secondOl.map((item: string, index: number) => (
        <ListItem key={index} className={ [props.classes.paddingStyle, props.classes.listItemWrapper ].join(' ')}>
          <div className={ props.classes.iconStyle}> - </div>
          { item }
        </ListItem >
      ))}
    </List>
  )
  
  const tooltipTitle: any = (
    <List className={ props.classes.listWrapper }>
       {firstList}
      <ListItem className={ props.classes.paddingStyle }>{nestedList}</ListItem>
      <ListItem className={ props.classes.paddingStyle }>{tooltipArray[8]}</ListItem>
    </List>
  );

  return (
    <Dialog
      open={props.open}
      maxWidth='md'
      onClose={props.onClose }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: props.hiddenDialog ?  props.classes.hidden : props.classes.dialog }}
    >
      <DialogTitle id="alert-dialog-title">
        <div className={props.classes.titleWrapper}>
          <div>{t('changePassword')}</div>
          <Tooltip title={t('close')}>
            <IconButton aria-label="close" onClick={props.onClose}>
              <Close />
            </IconButton>
          </Tooltip>
        </div>
      </DialogTitle>
    
      <DialogContent>
        <div className={props.classes.wrapper}>
          <TextField
            label={t('oldPassword')}
            type={ props.showOldPassword ? 'text' : 'password'}
            fullWidth
            value={ props.oldPassword }
            required
            onChange={ event => props.handleChange(event, 'oldPassword')}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => props.handleClickShowPassword('oldPassword')}
                    edge="end"
                  >
                    { props.showOldPassword ? <Visibility /> : <VisibilityOff /> }
                  </IconButton>
                </InputAdornment> 
            }}
            error={ props.oldPasswordValidationMessage !== '' }
            helperText={ props.oldPasswordValidationMessage !== '' ? t(`${props.oldPasswordValidationMessage}`) : null }
          />
          <Tooltip title={tooltipTitle} classes={{ tooltip: props.classes.tooltip}} placement="bottom">
            <TextField
              label={t('newPassword')}
              type={ props.showNewPassword ? 'text' : 'password'}
              fullWidth
              value={ props.newPassword }
              required
              onChange={event => props.handleChange(event, 'newPassword')}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => props.handleClickShowPassword('newPassword')}
                      edge="end"
                    >
                      {props.showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
              }}
              error={props.newPasswordValidationMessage !== ''}
              helperText={props.newPasswordValidationMessage !== '' ? t(`${props.newPasswordValidationMessage}`) : null}
            />
          </Tooltip>
          <TextField
            label={t('confirmPassword')}
            type={ props.showConfirmPassword ? 'text' : 'password'}
            fullWidth
            value={ props.confirmPassword }
            required
            onChange={event => props.handleChange(event, 'confirmPassword')}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => props.handleClickShowPassword('confirmPassword')}
                    edge="end"
                  >
                    {props.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>,
            }}
            error={props.confirmPasswordValidationMessage !== ''}
            helperText={props.confirmPasswordValidationMessage !== '' ? t(`${props.confirmPasswordValidationMessage}`) : null}
          />
        </div>
      </DialogContent>

      <DialogActions>
        <SaraDialogButtons
          secondaryText={t('close')}
          onSecondary={props.onClose}
          primaryText={t('save')}
          onPrimary={props.onSubmit}
          primaryDisabled={false}
        />
      </DialogActions>

    </Dialog>
  );
};

export default withStyles(styles)(ChangePasswordModal);