import React from 'react';
import Timeline, { TimelineItem, TimelineMarkers, CustomMarker  } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import './timeline.css';
import calendarStyles from './calendar.module.css';
import moment, { Moment } from 'moment';
import Employee from '../../models/Employee';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import User from '../../models/User';

interface CalendarProps {
  timeStart: number;
  timeEnd: number;
  employees: Array<Employee>;
  calendarEventItems: Array<TimelineItem<any, any>>;
  calendarTaskItems: Array<TimelineItem<any, any>>;
  holidays: Array<string>;
  onCanvasClick: (groupId: number, time: number, event: any) => void;
  onItemSelect: (itemId: number, event: any, time: number) => void;
  user: User;
}

const Calendar: React.FC<CalendarProps> = (props: CalendarProps) => {
  const { t } = useTranslation();
  const zoom: number = props.timeEnd - props.timeStart;
  const itemRenderer = (itemObject: any) => {
    let { item, itemContext, getItemProps } = itemObject;
    const backgroundColor: string = itemContext.selected ? item.style.backgroundColor + 'CC' : item.style.backgroundColor + 'B3';
    const borderColor: string = itemContext.selected ? item.style.borderColor + 'CC' : item.style.borderColor + 'B3';
    const color: string = item.style.color;
    const itemProps = {...getItemProps({ style: {color, borderColor, backgroundColor}})};
    delete itemProps.title;
    return (
      <div {...itemProps} className={calendarStyles.eventItemWrapper}>
        <Tooltip title={itemContext.title}>
          <div className={calendarStyles.eventItem}>{itemContext.title}</div>
        </Tooltip>
      </div>
    );
  };

  const verticalLineClassNamesForTime = (timeStart: any, timeEnd: any) => {
    const currentTimeStart: Moment = moment(timeStart);
    const currentTimeEnd: Moment = moment(timeEnd);

    for(let i: number = 0; i < props.holidays.length; i++ ) {
      const holiday: Moment = moment(props.holidays[i]);
      if (holiday.isSame(currentTimeStart, 'day') && holiday.isSame(currentTimeEnd, 'day')) {
        return ['holiday'];
      }
    }

    return [];
  };

  const groups = props.employees.map((employee: Employee) => ({
    id: employee.empId,
    title: `${employee.empFirstName} ${employee.empLastName}`,
    tip: `${employee.remainingVac !== undefined && employee.annualVac!== undefined ? employee.remainingVac + '/' + employee.annualVac: '' }`
  }));

  const currentDate = new Date();
  currentDate.setHours(11);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  return (
    <div className={calendarStyles.timelineWrapper}>
      { props.employees.length !== 0 &&
        <Timeline
          groups={groups}
          items={props.calendarEventItems.concat(props.calendarTaskItems)}
          visibleTimeStart={props.timeStart}
          visibleTimeEnd={props.timeEnd}
          minZoom={zoom}
          maxZoom={zoom}
          stackItems={true}
          canMove={false}
          itemRenderer={itemRenderer}
          verticalLineClassNamesForTime={verticalLineClassNamesForTime}
          onCanvasClick={props.onCanvasClick}
          itemHeightRatio={0.5}
          onItemSelect={props.onItemSelect}
          onItemClick={props.onItemSelect}
          clickTolerance={100}
          groupRenderer={({group}) => {
            let classes: string = 'custom-group';
            if(group.id === props.user.empId) {
              classes = classes.concat(' highlighted');
            }
            return (
              <div className={classes}>
                <span>{ group.title }</span>
                <span className="tip"><Tooltip title={`${t('remaining')} / ${t('initial')}`}><span>{ group.tip }</span></Tooltip></span>
              </div>
            )
          }}
          sidebarWidth={200}
        >
          <TimelineMarkers>
            <CustomMarker date={currentDate.getTime()}>
              {({ styles, date }) => {
                  styles = {...styles, backgroundColor: '#3670B6B3', width: '0.25vw'}
                  return <div style={styles} />
                }
              }
            </CustomMarker>
          </TimelineMarkers>
        </Timeline>
      }
    </div>
  );
};

export default Calendar;
