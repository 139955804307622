import { createStyles, Paper, withStyles, WithStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApiErrorMessage } from '../../api/apiMessage';
import { ApiError } from '../../api/apiResult';
import EmployeesReqHandler from '../../api/employeesRemote';
import ldapUsersReqHandler from '../../api/ldapUsersRemote';
import { DialogMode } from '../../models/DialogMode';
import Employee from '../../models/Employee';
import LdapUser from '../../models/LdapUser';
import { compare } from '../../utils/string';
import AddNewButton from '../common/AddNewButton';
import InfoSnackbar from '../infoSnackbar/InfoSnackbar';
import NotificationDialog from '../notification/NotificationDialog';
import EmployeeDialogContainer from './employeeDialog/EmployeeDialogContainer';
import EmployeesList from './employeesList/EmployeesList';
import PendingUsersDialog from './pendingUsersDialog/PendingUsersDialog';

const styles = () =>
  createStyles({
    paperWithTable: {
      '& thead tr th': {
        borderRadius: 4,
      },
    },
  });

interface TeamContainerProps extends WithStyles<typeof styles>, WithTranslation {
  canReadTeam: boolean;
  canWriteTeam: boolean;
  canWriteAppGroups: boolean;
  canReadAppGroups: boolean;
}

interface TeamContainerState {
  employees: Array<Employee>;
  isLoading: boolean;
  isLoadingPendingUsers: boolean;
  editingEmployee: Employee | null;
  pendingUsers: Array<LdapUser>;
  selectedUser: LdapUser | null;
  isDialogOpen: boolean;
  dialogMode: DialogMode;
  isOpenNotification: boolean;
  notificationTitle: string;
  notificationContent: string;
  snackBarMessage: string | null;
}

class TeamContainer extends Component<TeamContainerProps, TeamContainerState> {
  state: TeamContainerState = {
    employees: [],
    isLoading: false,
    isLoadingPendingUsers: false,
    editingEmployee: null,
    pendingUsers: [],
    selectedUser: null,
    isDialogOpen: false,
    dialogMode: DialogMode.UPDATE,
    isOpenNotification: false,
    notificationTitle: '',
    notificationContent: '',
    snackBarMessage: null,
  };

  componentDidMount() {
    if (this.props.canReadTeam) {
      this.refreshData('');
    }
    if (this.props.canWriteTeam) {
      this.loadPendingUsers();
    }
  }

  componentDidUpdate(prevProps: TeamContainerProps) {
    if (prevProps.canReadTeam !== this.props.canReadTeam) {
      this.refreshData('');
      if (this.props.canWriteTeam) {
        this.loadPendingUsers();
      }
    }
  }

  refreshData = (message: string) => {
    this.setState({ isLoading: true, editingEmployee: null, dialogMode: DialogMode.UPDATE }, () => {
      EmployeesReqHandler.loadEmployees(true)
        .then((employees: Array<Employee>) => this.setState({ employees, isLoading: false }))
        .catch(() => this.setState({ isLoading: false }));
    });
  };

  loadPendingUsers() {
    this.setState({ pendingUsers: [], isLoadingPendingUsers: true }, () =>
      ldapUsersReqHandler
        .loadPendingUsers()
        .then((ldapUsers: Array<LdapUser>) => {
          const pendingUsers: Array<LdapUser> = ldapUsers.sort((first, second) =>
            compare(`${first.firstName} ${first.lastName}`, `${second.firstName} ${second.lastName}`)
          );
          this.setState({ pendingUsers, isLoadingPendingUsers: false });
        })
        .catch(() => this.setState({ isLoadingPendingUsers: false }))
    );
  }

  handleUserSelect = (selectedUid: string) => {
    const selectedUser = this.state.pendingUsers.find(user => user.uid === selectedUid);
    this.setState({ selectedUser: selectedUser ? selectedUser : null });
  };

  handleUserCreate = () => {
    if (this.state.selectedUser) {
      const editingEmployee: Employee & { uid: string; username: string } = {
        empId: 0,
        officeId: 1,
        empFirstName: this.state.selectedUser.firstName,
        empLastName: this.state.selectedUser.lastName,
        empEmail: this.state.selectedUser.email,
        isActive: 1,
        dayWorkHours: 8,
        officeName: '',
        appUserId: 0,
        uid: this.state.selectedUser.uid,
        username: this.state.selectedUser.username,
      };
      this.setState({
        isDialogOpen: false,
        editingEmployee,
        selectedUser: null,
        dialogMode: DialogMode.CREATE,
      });
    }
  };

  private addButtonText(): string {
    if (this.state.isLoadingPendingUsers) {
      return this.props.t('loadingPendingUsers');
    } else {
      return this.state.pendingUsers.length ? this.props.t('addPendingUsers') : this.props.t('noPendingUsers');
    }
  }

  openNotificationDialog = (notificationTitle: string, notificationContent: string): void => {
    this.setState({ isOpenNotification: true, notificationTitle, notificationContent });
  };

  closeNotificationDialog = (): void => {
    this.setState({ isOpenNotification: false, notificationTitle: '', notificationContent: '' });
  };

  render() {
    return (
      <div className="pageWrapper">
        {this.props.canWriteTeam && (
          <AddNewButton
            onClick={() => this.setState({ isDialogOpen: true })}
            text={this.addButtonText()}
            disabled={!this.state.pendingUsers.length || this.state.isLoadingPendingUsers}
          />
        )}
        <Paper className={this.props.classes.paperWithTable}>
          <EmployeesList
            employees={this.state.employees}
            isLoading={this.state.isLoading}
            canReadTeam={this.props.canReadTeam}
            canWriteTeam={this.props.canWriteTeam}
            onEditClick={(event: any, employee: Employee) =>
              this.setState(prevState => ({
                editingEmployee: employee,
                dialogMode: this.props.canReadTeam && !this.props.canWriteTeam ? DialogMode.VIEW : prevState.dialogMode,
              }))
            }
          />
          <EmployeeDialogContainer
            employee={this.state.editingEmployee}
            isOpen={!!this.state.editingEmployee}
            onClose={() => this.refreshData('')}
            onSaved={(successMessage: string) => {
              this.setState({ snackBarMessage: successMessage });
              this.refreshData(successMessage);
              this.loadPendingUsers();
            }}
            onError={(error: ApiError) => {
              this.setState({ editingEmployee: null, dialogMode: DialogMode.UPDATE });
              let notificationTitle = 'unexpectedErrorMessageTitle';
              let notificationContent = 'unexpectedErrorMessage';
              if (error.response.data.messages.some(m => m.message === ApiErrorMessage.employeeStartDateAfterEndDate)) {
                notificationTitle = 'employeeStartDateAfterEndDateTitle';
                notificationContent = 'employeeStartDateAfterEndDate';
              }
              this.openNotificationDialog(notificationTitle, notificationContent);
            }}
            dialogMode={this.state.dialogMode}
            canWriteAppGroups={this.props.canWriteAppGroups}
            canReadAppGroups={this.props.canReadAppGroups}
          />
        </Paper>
        <PendingUsersDialog
          isDialogOpen={this.state.isDialogOpen}
          pendingUsers={this.state.pendingUsers}
          selectedUser={this.state.selectedUser}
          onClose={() => this.setState({ isDialogOpen: false, dialogMode: DialogMode.UPDATE })}
          onUserSelect={this.handleUserSelect}
          onUserCreate={this.handleUserCreate}
        />
        {this.state.snackBarMessage && (
          <InfoSnackbar
            open={this.state.snackBarMessage !== null}
            onClose={() => this.setState({ snackBarMessage: null })}
            text={this.state.snackBarMessage ? this.state.snackBarMessage : ''}
          />
        )}
        {this.state.isOpenNotification && (
          <NotificationDialog
            open={this.state.isOpenNotification}
            title={this.state.notificationTitle}
            content={this.state.notificationContent}
            onClose={this.closeNotificationDialog}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(TeamContainer));
