import React from 'react';
import Moment from 'moment';
import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import Project from "../../../../models/Project";

const EndDateColumn = (translation: UseTranslationResponse): Column<Project> => {
    return {
      title: translation.t('endDate'), 
      field: 'prjEndDate', 
      type: 'date',
      render: (rowData: Project) => {
        return <span>{rowData.prjEndDate ? Moment(rowData.prjEndDate).format('DD/MM/YYYY') : translation.t('ongoing')}</span>;
      },
      cellStyle: { minWidth: "75px" }
    };
};

export default EndDateColumn;