import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitle, DialogContent, DialogContentText, Dialog, DialogActions } from '@material-ui/core';
import SaraDialogButtons from '../../common/SaraDialogButtons';
  
interface OverlapEntryWarningProps {
    open: boolean;
    onSubmit: () => void;
    onClose: () => void;
}

const OverlapEntryWarning: React.FC<OverlapEntryWarningProps> = (props: OverlapEntryWarningProps) => {
    const { t } = useTranslation();

    return (
        <Dialog
            fullWidth={false}
            maxWidth="xs"
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle id="max-width-dialog-title">
                {t('entryOverlapWarningTitle')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('entryOverlapWarningText')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <SaraDialogButtons
                    onPrimary={props.onSubmit}
                    onSecondary={props.onClose}
                    primaryDisabled={false}
                    primaryText={t('create')}
                    secondaryText={t('cancel')}
                />
            </DialogActions>
        </Dialog>
    );
}

export default OverlapEntryWarning;
