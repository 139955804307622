import genericApi from './genericApi';
import LdapUser from '../models/LdapUser';

const ldapUsersReqHandler = {
  loadPendingUsers: (): Promise<Array<LdapUser>> => {
    return genericApi.get('ldap_users');
  }
};

export default ldapUsersReqHandler;
