import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Theme, Tooltip } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import Today from '@material-ui/icons/Today';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import Moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CalendarDayType from '../../../models/CalendarDayType';
import Office from '../../../models/Office';
import OfficeCalendarEvent from '../../../models/OfficeCalendarEvent';
import User from '../../../models/User';
import SaraDatePicker from '../../common/SaraDatePicker';
import SaraDialogButtons from '../../common/SaraDialogButtons';
import { SaraSelect } from '../../saraSelect/SaraSelect';
import calendarEventDialogStyles from './officeEventDialog.module.css';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      padding: '24px',
      height: '40%',
    },
    iconFieldContainer: {
      display: 'flex',
      '& > svg': {
        height: '100%',
        paddingTop: 10,
        marginRight: 15,
      },
      '& > .formTextField': {
        width: '100%',
      },
    },
    statusContainer: {
      display: 'flex',
      fontSize: '15px',
    },
    marginRight: {
      marginRight: 20,
    },
  });

interface OfficeDialogProps extends WithStyles<typeof styles> {
  isOpen: boolean;
  onClose: (event: any) => void;
  onSave: (event: any) => void;
  newEvent: OfficeCalendarEvent;
  handleChange: (event: any, field: string) => void;
  dayTypes: Array<CalendarDayType>;
  office: Office;
  user: User;
  todayDate: Moment.Moment;
}

const OfficeDialog: React.FC<OfficeDialogProps> = (props: OfficeDialogProps) => {
  const { t } = useTranslation();
  const titleText: string = t('changeDayType');

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      fullWidth={true}
      aria-labelledby="dialog-title"
      classes={{ paper: props.classes.dialog }}
    >
      <DialogTitle id="dialog-title">
        <div className={calendarEventDialogStyles.titleWrapper}>
          <Tooltip title={titleText}>
            <div className={calendarEventDialogStyles.titleText}>{titleText}</div>
          </Tooltip>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={8} className={props.classes.iconFieldContainer}>
            <BusinessIcon color="secondary" />
            <TextField label={t('office')} fullWidth rows={1} rowsMax={1} multiline value={props.office.officeName} disabled />
          </Grid>
          <Grid item xs={4} className={props.classes.iconFieldContainer}>
            <SaraDatePicker
              label={t('date')}
              required
              value={props.newEvent.date ? props.newEvent.date : undefined}
              onChange={() => {}}
              validated={false}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} className={props.classes.iconFieldContainer}>
            <Today color="secondary" />
            <div className="formTextField">
              <SaraSelect
                validated={false}
                label={t('dayType')}
                id="event-type-id"
                value={props.newEvent.isWorking || 0}
                onChange={event => props.handleChange(event, 'isWorking')}
                lookups={props.dayTypes
                  .filter((dayType: CalendarDayType) => {
                    return dayType.dayTypeId === 0 || dayType.dayTypeId === 30;
                  })
                  .map((dayType: CalendarDayType) => ({
                    text: t(dayType.dayTypeName),
                    value: dayType.dayTypeId === 0 ? 1 : 2,
                  }))}
                required
              />
            </div>
          </Grid>
          <Grid item xs={12} className={props.classes.iconFieldContainer}>
            <InsertDriveFile color="secondary" />
            <TextField
              label={t('additionalComment')}
              fullWidth
              rows={1}
              rowsMax={5}
              multiline
              value={props.newEvent.dayDescription}
              onChange={event => props.handleChange(event, 'comment')}
              disabled={false}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <SaraDialogButtons
          secondaryText={t('cancel')}
          onSecondary={props.onClose}
          primaryText={t('save')}
          onPrimary={props.onSave}
          primaryDisabled={false}
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(OfficeDialog);
