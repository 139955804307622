import React, { Component } from 'react';
import { connect } from 'react-redux';
import User from '../../models/User';
import { getUser, SelectUserState } from '../../store/selectors/userSelectors';
import LoggedInMenu from '../loggedInMenu/LoggedInMenu';

interface LoadingPageContainerProps {
  user: User;
}

interface LoadingPageContainerState {  
}

class LoadingPageContainer extends Component<LoadingPageContainerProps, LoadingPageContainerState> {
  state: LoadingPageContainerState = {
  };

  render() {
    return (
      <LoggedInMenu
        activePermissions={[]}
        realoadPermissions={() => {}}
        loggedInUsername={this.props.user.username}
        isLoading={true}
      />
    );
  }
}

const mapStateToProps = (state: SelectUserState) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(LoadingPageContainer);
