import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import sciantLogo from '../../assets/logo.svg';
import ErrorPage from './ErrorPage';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    loadingContainer: {
      display: 'flex',
      marginLeft: 'auto',
      marginRight: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loadingText: {
      paddingRight: 25,
      paddingLeft: 25,
    },
    runningDots: {
      marginLeft: -20,
      width: 25,
      textAlign: 'left',
    },
  });

interface AppLoaderContainerState {
  numberOfDots: number;
}

interface AppLoaderContainerProps extends WithTranslation, WithStyles<typeof styles> {}

class AppLoaderContainer extends Component<AppLoaderContainerProps, AppLoaderContainerState> {
  state: AppLoaderContainerState = {
    numberOfDots: 0,
  };
  timerId: any;

  componentDidMount() {
    this.timerId = setInterval(() => {
      let newNumberOfDots = this.state.numberOfDots + 1;
      this.setState({ numberOfDots: newNumberOfDots % 3 });
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  render() {
    return (
      <ErrorPage
        imageUrl={sciantLogo}
        title={null}
        description={
          <div className={this.props.classes.loadingContainer}>
            <p className={this.props.classes.loadingText}>{this.props.t('loadingSara')}</p>
            <p className={this.props.classes.runningDots}>{'.'.repeat(this.state.numberOfDots + 1)}</p>
          </div>
        }
      />
    );
  }
}

export default withStyles(styles)(withTranslation()(AppLoaderContainer));
