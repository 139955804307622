import React from 'react';
import { Tooltip } from '@material-ui/core';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@fullcalendar/core';

const styles = (theme: Theme) =>
    createStyles({
        status: {
            width: 15,
            height: 15,
            borderRadius: '50%',
            display: 'inline-block'
        },
        yellowStatus: {
          backgroundColor: '#FDC02F'
        },
        greenStatus: {
            backgroundColor: '#29BB9C'
        },
        greyStatus: {
            backgroundColor: '#D3D3D4'
        },
        redStatus: {
            backgroundColor: '#FC5830'
        }
    });

interface StatusIndicatorProps extends WithStyles<typeof styles> {
    status?: boolean;
    value?: number;
    activeLabel: string;
    semiActiveLabel?: string;
    inactiveLabel: string;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = (props: StatusIndicatorProps) => {

    if (props.status !== undefined) {
        const className = props.status ? props.classes.greenStatus : props.classes.greyStatus;
        const tooltipText = props.status ? props.activeLabel : props.inactiveLabel;
        return (<Tooltip title={tooltipText}>
            <span className={`${props.classes.status} ${className}`}></span>
        </Tooltip>);
    } else {
        let className = '';
        let tooltipText = '';

        switch (props.value) {
          case 3: className = props.classes.redStatus; tooltipText= props.inactiveLabel; break;  
          case 2: className = props.classes.greenStatus; tooltipText = props.activeLabel; break;
          case 1: className = props.classes.yellowStatus; tooltipText = props.semiActiveLabel || ''; break;
          case 0: className = props.classes.greyStatus; tooltipText = props.inactiveLabel; break;
        }
        return (<Tooltip title={tooltipText}>
            <span className={`${props.classes.status} ${className}`}></span>
        </Tooltip>);

    }
}

export default withStyles(styles)(StatusIndicator);
