import genericApi from './genericApi';
import AppGroup from '../models/AppGroup';
import UpdateAppGroup from '../models/UpdateAppGroup';

const appGroupsReqHandler = {
  loadAppGroups: (): Promise<Array<AppGroup>> => {
    return genericApi.get('app_groups?sort=appGroupName');
  },
  createAppGroup: (appGroup: UpdateAppGroup) : Promise<AppGroup> => {
    return genericApi.post('app_groups', appGroup);
  },
  updateAppGroup: (id: number, appGroup: UpdateAppGroup) : Promise<AppGroup> => {
    return genericApi.put(`app_groups/${id}`, appGroup);
  }
};

export default appGroupsReqHandler;
