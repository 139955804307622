import { Column } from "material-table";
import Customer from "../../../../models/Customer";
import CustomerNameColumn from "./CustomerName";
import AccountManagerColumn from "./AccountManager";
import { UseTranslationResponse } from "react-i18next";

const columns = (translation: UseTranslationResponse): Column<Customer>[] => {
    return [
        CustomerNameColumn(translation),
        AccountManagerColumn(translation),
    ];
}

export default columns;