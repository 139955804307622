export enum FormViewCondition {
  MENU_PERMISSIONS = 'WORK_TIME|WORK_TIME_EXTENDED|PROJECT|CUSTOMER|APP_GROUP|TEAM|REPORT_OVERTIME|REPORT_WORK_TIME|CALENDAR|CALENDAR_EXTENDED|APPROVE_BT|APPROVE_HO|APPROVE_SICK|APPROVE_VAC|CALENDAR_ADMIN',
  WORK_TIME_PERMISSIONS = 'WORK_TIME|WORK_TIME_EXTENDED',
  WORK_TIME = 'WORK_TIME',
  WORK_TIME_EXTENDED = 'WORK_TIME_EXTENDED',
  PROJECT = 'PROJECT',
  CUSTOMER = 'CUSTOMER',
  APP_GROUP = 'APP_GROUP',
  TEAM = 'TEAM',
  REPORT_OVERTIME = 'REPORT_OVERTIME',
  REPORT_WORK_TIME = 'REPORT_WORK_TIME',
  CALENDAR_PERMISSIONS = 'CALENDAR|CALENDAR_EXTENDED|APPROVE_BT|APPROVE_HO|APPROVE_SICK|APPROVE_VAC|CALENDAR_ADMIN',
  CALENDAR = 'CALENDAR',
  CALENDAR_EXTENDED = 'CALENDAR_EXTENDED',
  APPROVE_BT = 'APPROVE_BT',
  APPROVE_HO = 'APPROVE_HO',
  APPROVE_SICK = 'APPROVE_SICK',
  APPROVE_VAC = 'APPROVE_VAC',
  CALENDAR_ADMIN = 'CALENDAR_ADMIN'
}
