import React from 'react';
import { Theme, InputLabel } from '@material-ui/core';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    notFoundContainer: {
      display: 'flex',
    },
    notFoundContent: {
      margin: 'auto',
      maxWidth: 480,
      width: '100%',
      '& img': {
        width: '100%',
        maxWidth: 320,
        margin: 'auto',
      },
      '& svg': {
        width: '100%',
        maxWidth: 320,
        margin: 'auto',
        maxHeight: 223,
      },
      textAlign: 'center',
    },
  });

interface ErrorPageProps extends WithStyles<typeof styles> {
  image?: React.ReactNode;
  imageUrl?: string;
  title: React.ReactNode;
  description: React.ReactNode;
}

const ErrorPage: React.FC<ErrorPageProps> = (props: ErrorPageProps) => {
  return (
    <div className={`pageWrapper ${props.classes.notFoundContainer}`}>
      <div className={props.classes.notFoundContent}>
        {props.image || <img src={props.imageUrl} alt="errorImage" />}
        <h2>{props.title}</h2>
        <InputLabel>{props.description}</InputLabel>
      </div>
    </div>
  );
};

export default withStyles(styles)(ErrorPage);
