import { TaskDraftActionType } from '../actions/taskDraftActionType';
import Task from '../../models/Task';

const initState: Task = {
  taskId: 0,
  taskName: '',
  projectId: 0,
  projectName: '',
  taskAssignees: [],
  taskStartDate: undefined,
  taskEndDate: undefined,
  assignedBy: 0
};

export default function tempTaskReducer(state: Task = initState, action: any) {
  switch (action.type) {
    case TaskDraftActionType.SET_TASK_DRAFT:
      return {
        ...state,
        taskId: action.payload.task.taskId,
        taskName: action.payload.task.taskName,
        projectId: action.payload.task.projectId,
        projectName: action.payload.task.projectName,
        taskAssignees: [ ...action.payload.task.taskAssignees ],
        taskStartDate: action.payload.task.taskStartDate,
        taskEndDate: action.payload.task.taskEndDate,
        assignedBy: action.payload.task.assignedBy
      };
    case TaskDraftActionType.CLEAR_TASK_DRAFT:
      return initState; 
    default:
      return state;
  }
}