import React, { Component, Fragment } from 'react';
import reportsHandler, { WorkItemWithEmplNames } from '../../api/reportsRemote';
import { totalMinutesBetween, formatToHoursDecimal } from '../../utils/dateTime';
import ReportsFiltersContainer from './reportsFilters/ReportsFiltersContainer';
import ReportsList from './reportsList/ReportsList';
import SummaryList from './reportsList/SummaryList';
import projectsHandler from '../../api/projectsRemote';
import Project from '../../models/Project';
import moment from 'moment';
import { WorkItemWithTaskId } from '../../models/WorkItemWithTaskIdType';
import TaskSummary from '../../models/TaskSummary';
import EmployeeSummary from '../../models/EmployeeSummary';
import SummaryProject from '../../models/SummaryProject';

interface ReportsContainerProps {}

interface ReportsContainerState {
  isLoading: boolean;
  employeesReports: Record<string, Array<WorkItemWithEmplNames>>;
  employeeSummaries: Array<EmployeeSummary>;
  showSummary: boolean;
  expandReportSections: boolean;
  projects: Array<Project>;
  taskReports: Record<string, Array<WorkItemWithTaskId>>;
  isTask: boolean;
  taskSummaries: Array<TaskSummary>;
}

class ReportsContainer extends Component<ReportsContainerProps, ReportsContainerState> {
  constructor(props: ReportsContainerProps) {
    super(props);
    this.state = {
      isLoading: true,
      employeesReports: {},
      employeeSummaries: [],
      showSummary: false,
      expandReportSections: false,
      projects: [],
      taskReports: {},
      isTask: false,
      taskSummaries: [],
    };
  }

  componentDidMount() {
    projectsHandler.loadProjects().then(projects => {
      this.setState({ projects, isLoading: false });
    });
  }

  handleSave = (
    startDate: string,
    endDate: string,
    empIds: Array<number>,
    projectIds: Array<number>,
    taskIds: Array<number>,
    showSummary: boolean
  ) => {
    this.setState(
      {
        showSummary,
        isLoading: true,
        employeeSummaries: [],
        employeesReports: {},
        expandReportSections: projectIds.length === 1,
        isTask: !!taskIds.length,
      },
      () => {
        reportsHandler
          .loadReportsByProject(startDate, endDate, empIds, projectIds, taskIds)
          //add SOW to project name if not unique (we need to look it up from all projects, not just by
          //projects returned by the reports endpoint)
          .then((reports: WorkItemWithEmplNames[] | Array<WorkItemWithTaskId>): WorkItemWithEmplNames[] | Array<WorkItemWithTaskId> =>
            reports.map(
              (report: WorkItemWithEmplNames): WorkItemWithEmplNames => {
                const lookedUpProject = this.state.projects.find(x => report.taskProjectId === x.prjId);
                return {
                  ...report,
                  taskProjectName:
                    lookedUpProject && lookedUpProject.isProjectDuplicatedInList
                      ? `${lookedUpProject.prjName} (${lookedUpProject.sowCode})`
                      : report.taskProjectName,
                };
              }
            )
          )
          .then(reports => {
            const employeesReports: Record<string, Array<WorkItemWithEmplNames>> = reports.reduce(
              (acc: Record<string, Array<WorkItemWithEmplNames>>, report: WorkItemWithEmplNames) => {
                const reportKey = `${report.empFirstName} ${report.empLastName}`;
                return {
                  ...acc,
                  [reportKey]: acc[reportKey] ? [...acc[reportKey], report] : [report],
                };
              },
              {}
            );
            const taskSummaries: Array<TaskSummary> = [];

            if (taskIds.length) {
              let taskReports: Record<string, Array<WorkItemWithTaskId>> = reports.reduce(
                (acc: Record<string, Array<WorkItemWithTaskId>>, report: WorkItemWithTaskId) => {
                  const reportKey: string = `${report.taskId}`;
                  return {
                    ...acc,
                    [reportKey]: acc[reportKey] ? [...acc[reportKey], report] : [report],
                  };
                },
                {}
              );
              for (const task in taskReports) {
                const taskObject: TaskSummary = {
                  totalHoursWorked: this.hoursSum(taskReports[task]),
                  title: `${taskReports[task][0].empFirstName} ${taskReports[task][0].empLastName} Project: ${taskReports[task][0].taskProjectName} | Task: ${taskReports[task][0].taskName}`,
                  taskName: taskReports[task][0].taskName,
                  projectId: taskReports[task][0].taskProjectId,
                  taskId: task,
                  empId: taskReports[task][0].empId,
                };

                taskSummaries.push(taskObject);
              }
              this.setState({ taskReports, taskSummaries });
            }
            const employeeSummaries: Array<EmployeeSummary> = [];
            for (const employee in employeesReports) {
              const totalHoursWorked = this.hoursSum(employeesReports[employee]);
              const projects: Array<SummaryProject> = [];

              const filteredItemsEmployee = this.filterEmployeesReportItems(employeesReports[employee]);
              employeesReports[employee] = filteredItemsEmployee;
              employeesReports[employee].forEach((report: WorkItemWithEmplNames) => {
                const currentProject = projects.find(project => project.id === report.taskProjectId);
                if (currentProject) {
                  currentProject.totalHoursWorked += formatToHoursDecimal(totalMinutesBetween(report.wtStartTime, report.wtEndTime));
                } else {
                  const task: Array<TaskSummary> = taskSummaries.filter(x => x.projectId === report.taskProjectId);
                  projects.push({
                    id: report.taskProjectId,
                    name: report.taskProjectName,
                    totalHoursWorked: formatToHoursDecimal(totalMinutesBetween(report.wtStartTime, report.wtEndTime)),
                    task,
                  });
                }
              });
              const empId: number = employeesReports[employee][0].empId;
              employeeSummaries.push({ employee, totalHoursWorked, projects, empId });
            }
            this.setState({ employeeSummaries, employeesReports, isLoading: false });
          });
      }
    );
  };

  hoursSum = (reports: Array<WorkItemWithEmplNames>) =>
    reports.reduce(
      (acc: number, report: WorkItemWithEmplNames) =>
        acc + (formatToHoursDecimal(totalMinutesBetween(report.wtStartTime, report.wtEndTime)) || 0),
      0
    );

  filterEmployeesReportItems = (items: Array<any>): Array<any> => {
    if (items.length > 0) {
      let lastItem = items[0];
      lastItem.backgroundColor = 'white';
      items.forEach(x => {
        if (moment(lastItem.wtStartTime).isSame(moment(x.wtStartTime), 'day')) {
          x.backgroundColor = lastItem.backgroundColor;
        } else {
          x.backgroundColor = lastItem.backgroundColor === 'white' ? '#F3F3F4' : 'white';
        }
        lastItem = x;
      });
    }
    return items;
  };

  render() {
    return (
      <div className="pageWrapper">
        <ReportsFiltersContainer handleSave={this.handleSave} projects={this.state.projects} isLoading={this.state.isLoading} />
        {this.state.employeeSummaries.length ? (
          <Fragment>
            {this.state.isTask
              ? this.state.taskSummaries.map((taskSummary: TaskSummary) => (
                  <ReportsList
                    key={taskSummary.taskId}
                    employee={taskSummary.title}
                    totalHoursWorked={this.hoursSum(this.state.taskReports[taskSummary.taskId]).toFixed(2)}
                    reports={this.state.taskReports[taskSummary.taskId]}
                    isLoading={this.state.isLoading}
                    defaultExpanded={this.state.expandReportSections}
                  />
                ))
              : this.state.employeeSummaries.map((summary: EmployeeSummary) => (
                  <ReportsList
                    key={summary.employee}
                    employee={summary.employee}
                    totalHoursWorked={this.hoursSum(this.state.employeesReports[summary.employee]).toFixed(2)}
                    reports={this.state.employeesReports[summary.employee]}
                    isLoading={this.state.isLoading}
                    defaultExpanded={this.state.expandReportSections}
                  />
                ))}
            <SummaryList
              employeeSummaries={this.state.employeeSummaries}
              showSummary={this.state.showSummary}
              showTask={this.state.isTask}
            />
          </Fragment>
        ) : (
          <ReportsList employee={''} reports={[]} isLoading={this.state.isLoading} defaultExpanded />
        )}
      </div>
    );
  }
}

export default ReportsContainer;
