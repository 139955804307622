import { Column } from "material-table";
import WorkItem from "../../../../models/WorkItem";
import { UseTranslationResponse } from 'react-i18next';
import { compare } from "../../../../utils/string";

const taskColumn = (translation: UseTranslationResponse): Column<WorkItem> => {
    return {
      title: translation.t('task'),
      field: 'taskId',
      render: (workItem: WorkItem) => workItem.taskName,
      customSort: (first: WorkItem, second: WorkItem)=>{
        return compare(first.taskName, second.taskName);
      }
    };
};

export default taskColumn;
