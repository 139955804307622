import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";

const returnedData: ApiResult<any> = {
    "messages": [],
    "result": [
        { "formViewCd": "CUSTOMER", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "MENU_TASKS", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "MY_PROJECT", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "MY_TASK", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "WORK_TIME", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "MENU_CALENDAR", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "CALENDAR_V", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "CALENDAR_H", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "EMP_ANNUAL_PAID_VAC", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "MENU_ADMINISTRATION", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "OFFICES", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "LOCATIONS", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "APP_CONFIG", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "APP_USER", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "APP_GROUP", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "APP_USER_GROUP", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "FORM_VIEW_CONTROL", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "CUST_COMPANIES_DATA_ACCESS", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "PROJECTS_DATA_ACCESS", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "MENU_REPORTS", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "REPORTS_BY_EMP", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "REPORTS_BY_PRJ", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "PROFILE_PREFERENCE", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "CUST_PERSON", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "CUST_OFFICE", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "CUST_COMPANIES", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "EMP_VAC_REQ", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "EMPLOYEES", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "PROJECT", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "TASK", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "WORK_TIME", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "REPORTS_BY_PRJ_SIMPLE", "accessId": 2, "appUserId": 32 },
        { "formViewCd": "WORK_TIME_EXTENDED", "accessId": 1, "appUserId": 32 }
    ]
}

export const FormViewUserControlsGet: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /form_view_user_controls/,
    method: 'GET'

}
