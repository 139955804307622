import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoggedInMenuContainer from '../loggedInMenu/LoggedInMenuContainer';
import LoginContainer from '../login/LoginContainer';
import OfflinePageContainer from '../errorPages/OfflinePageContainer';
import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/login" component={LoginContainer} />
      <Route path="/offline" component={OfflinePageContainer} />
      <PrivateRoute path="/" component={LoggedInMenuContainer} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
