import { Button, createStyles, Grid, TextField } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import Moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Employee from '../../../../../models/Employee';
import Task from '../../../../../models/Task';
import { ensureArrayContains } from '../../../../../utils/array';
import SaraDatePicker from '../../../../common/SaraDatePicker';
import { Lookup, SaraMultiSelect } from '../../../../saraSelect/SaraMultiSelect';
import { SaraSelect } from '../../../../saraSelect/SaraSelect';

const styles = () =>
  createStyles({
    addButtonEnabled: {
      marginLeft: 10,
      marginTop: 10,
    },
    addButtonDisabled: {
      marginLeft: 10,
      marginTop: 10,
      opacity: 0.6,
    },
    addButtonHidden: {
      marginLeft: 10,
      visibility: 'hidden',
    },
  });

interface CreateTaskProps extends WithStyles<typeof styles> {
  handleAdd: (task: Task) => void;
  handleEdit: (event: any, field: string) => void;
  validate?: () => void;
  task: Task;
  minDate?: string;
  maxDate?: string;
  teamMembers: Array<Employee>;
  isAddEnabled: boolean;
  index: number;
  validated: boolean;
}

const isButtonEnabled = (task: Task) =>
  task.taskName &&
  task.taskStartDate &&
  task.taskEndDate &&
  task.taskEndDate >= task.taskStartDate &&
  task.taskAssignees &&
  task.taskAssignees.length > 0;

const TaskForm: React.FC<CreateTaskProps> = (props: CreateTaskProps) => {
  const { t } = useTranslation();

  const assigneeLookups: Array<Lookup> = props.teamMembers.map((item: Employee) => {
    return { value: item.empId, text: item.empFirstName + ' ' + item.empLastName };
  });
  if (props.task && props.task.assignedToName && props.task.taskAssignees && props.task.taskAssignees.length === 1) {
    ensureArrayContains(assigneeLookups, props.task.taskAssignees[0], props.task.assignedToName);
  }

  const endDateValidationMessage = (): string | undefined => {
    if (props.task.taskEndDate) {
      if (props.task.taskStartDate && Moment(props.task.taskStartDate) > Moment(props.task.taskEndDate)) {
        return t('endTimeShouldBeAfterStartTime');
      }
      if (props.maxDate && Moment(props.task.taskEndDate) > Moment(props.maxDate)) {
        return `${t('endDateShouldBeTheSameOrBeforeEnd')} (${Moment(props.maxDate).format('DD/MM/YYYY')})`;
      }
      if (Moment(props.task.taskEndDate) < Moment(props.minDate)) {
        return `${t('endDateShouldBeTheSameOrAfterStart')} (${Moment(props.minDate).format('DD/MM/YYYY')})`;
      }
    }
    return undefined;
  };

  const startDateValidationMessage = (): string | undefined => {
    if (props.task.taskStartDate) {
      if (Moment(props.task.taskStartDate) < Moment(props.minDate)) {
        return `${t('startDateShouldBeTheSameOrAfterStart')} (${Moment(props.minDate).format('DD/MM/YYYY')})`;
      }
      if (Moment(props.task.taskStartDate) > Moment(props.maxDate)) {
        return `${t('startDateShouldBeTheSameOrBeforeEnd')} (${Moment(props.maxDate).format('DD/MM/YYYY')})`;
      }
    }
    return undefined;
  };

  const addButton = props.isAddEnabled ? (
    <Button
      variant="contained"
      color="primary"
      className={isButtonEnabled(props.task) ? props.classes.addButtonEnabled : props.classes.addButtonDisabled}
      onClick={() => props.handleAdd(props.task)}
    >
      Add
    </Button>
  ) : (
    <Button
      variant="contained"
      color="primary"
      className={props.classes.addButtonDisabled}
      onClick={() => (props.validate ? props.validate() : {})}
    >
      Add
    </Button>
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={5} sm={5} md={4}>
        <TextField
          label={t('taskName')}
          key={props.task.taskName}
          fullWidth={true}
          defaultValue={props.task.taskName}
          onBlur={event => props.handleEdit(event.target.value, 'taskName')}
          required
          rows={1}
          rowsMax={5}
          multiline={true}
          error={props.validated && !props.task.taskName}
          helperText={props.validated && !props.task.taskName ? t('fieldIsMandatory') : null}
        />
      </Grid>
      <Grid item xs={2} sm={2} md={2}>
        <SaraDatePicker
          label={t('startDate')}
          required
          value={props.task.taskStartDate}
          minDate={props.minDate}
          maxDate={props.maxDate}
          onChange={date => {
            date ? props.handleEdit(date, 'taskStartDate') : props.handleEdit(null, 'taskStartDate');
          }}
          validated={props.validated && !props.task.taskStartDate}
          error={startDateValidationMessage() !== undefined}
          helperText={startDateValidationMessage()}
        />
      </Grid>
      <Grid item xs={2} sm={2} md={2}>
        <SaraDatePicker
          label={t('endDate')}
          required
          value={props.task.taskEndDate}
          validated={props.validated && !props.task.taskEndDate}
          minDate={Moment(props.minDate) > Moment(props.task.taskStartDate) ? props.minDate : props.task.taskStartDate}
          maxDate={props.maxDate}
          onChange={date => {
            date ? props.handleEdit(date, 'taskEndDate') : props.handleEdit(null, 'taskEndDate');
          }}
          error={endDateValidationMessage() !== undefined}
          helperText={endDateValidationMessage()}
        />
      </Grid>
      {props.isAddEnabled ? (
        <Grid item xs={3} sm={3} md={3}>
          <SaraMultiSelect
            validated={props.validated || false}
            label={t('taskAssignee')}
            id="task-id"
            value={props.task.taskAssignees || []}
            onChange={event => props.handleEdit([...event.target.value as Array<number>], 'taskAssignees')}
            lookups={assigneeLookups}
            multiple
            required
            error={props.validated && !props.task.taskStartDate}
            helperText={props.validated && !props.task.taskStartDate ? t('fieldIsMandatory') : undefined}
          />
        </Grid>
      ) : (
        <Grid item xs={3} sm={3} md={4}>
          <SaraSelect
            id="task-id"
            value={props.task.taskAssignees || []}
            onChange={event => props.handleEdit([event.target.value], 'taskAssignees')}
            validated={props.validated || false}
            lookups={assigneeLookups}
            label={t('taskAssignee')}
            required
          />
        </Grid>
      )}
      {props.isAddEnabled && (
        <Grid item xs={3} sm={3} md={1}>
          {addButton}
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(TaskForm);
