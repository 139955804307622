import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";

const returnedData: ApiResult<any> = {
    "messages": [],
    "result": [
        {
            "customerId": 1,
            "customerName": "Cemex HQ",
            "customerAddress": "1234 Market Drive\n    Suite 45\n    Milan",
            "customerPhone": " 1 234 567-0000",
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 2,
            "customerName": "Cemex South",
            "customerAddress": "1000 Market Drive\n    Milan",
            "customerPhone": " 49 (0) 123-98765-0",
            "customerFax": null,
            "customerEmail": null,
            "notes": "Invoice at the end of every month, should be sent before 5-th of the next month.",
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 3,
            "customerName": "Cemex North",
            "customerAddress": "2000 Market Drive\n     Milan",
            "customerPhone": " 44 (0) 1122-333-444",
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 4,
            "customerName": "Equifax HQ",
            "customerAddress": "Stuttgart\n    ",
            "customerPhone": " 49 6341 96899 00",
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": "DE 246067402",
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 5,
            "customerName": "Tucows HQ",
            "customerAddress": "66 Rue de Rome\n    Paris 75008\n    ",
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 6,
            "customerName": "Sorcim Compass HQ",
            "customerAddress": "\n    бул. Скобелев\n    София 1000",
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 7,
            "customerName": "ITCIS HQ",
            "customerAddress": "Студентски Град",
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": "За вътрешни проекти",
            "taxCode": null,
            "billToText": "не се фактурира",
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 11,
            "customerName": "testOfficeName",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 12,
            "customerName": "testOfficeName",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 13,
            "customerName": "testOfficeName",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 14,
            "customerName": "111",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 15,
            "customerName": "111",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 16,
            "customerName": "asdasdasdasd",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 4,
            "accountManagerName": "Georgi Georgiev",
            "canDelete": false
        },
        {
            "customerId": 17,
            "customerName": "123123123123123",
            "customerAddress": "aaa",
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 25,
            "accountManagerName": "David Gregor",
            "canDelete": false
        },
        {
            "customerId": 18,
            "customerName": "123123123123123",
            "customerAddress": "aaa",
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 25,
            "accountManagerName": "David Gregor",
            "canDelete": false
        },
        {
            "customerId": 19,
            "customerName": "111111",
            "customerAddress": "asdasdasd",
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 23,
            "accountManagerName": "Eleonora Yaneva",
            "canDelete": false
        },
        {
            "customerId": 20,
            "customerName": "aaaaaaa",
            "customerAddress": "",
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": "",
            "taxCode": null,
            "billToText": null,
            "amId": 24,
            "accountManagerName": "Darja Gogunova",
            "canDelete": false
        },
        {
            "customerId": 21,
            "customerName": "Mitel",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": "info@mitel.com",
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        },
        {
            "customerId": 22,
            "customerName": "Full customer",
            "customerAddress": "U Okrouhlíku 2b",
            "customerPhone": "+420725801673",
            "customerFax": null,
            "customerEmail": "david.gregorr@gmail.com",
            "notes": "This is a customer with all the informations provided in the creation form.",
            "taxCode": "CZ9007033288",
            "billToText": "Please always send invoice via email with CC to his accountant",
            "amId": 183,
            "accountManagerName": "Todor Todorov",
            "canDelete": false
        },
        {
            "customerId": 23,
            "customerName": "David Gregor",
            "customerAddress": "U Okrouhlíku 2b",
            "customerPhone": "+420725801673",
            "customerFax": null,
            "customerEmail": "david.gregorr@gmail.com",
            "notes": "asdasdasdasd",
            "taxCode": "CZ 9007033288",
            "billToText": "ajajajaja",
            "amId": 25,
            "accountManagerName": "David Gregor",
            "canDelete": false
        },
        {
            "customerId": 24,
            "customerName": "The name",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 82,
            "accountManagerName": "Kiril Kirilov",
            "canDelete": false
        },
        {
            "customerId": 25,
            "customerName": "ssssss",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 2,
            "accountManagerName": "Boris Borisov",
            "canDelete": false
        },
        {
            "customerId": 26,
            "customerName": "TEST-0001",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 20,
            "accountManagerName": "Test Test",
            "canDelete": false
        },
        {
            "customerId": 27,
            "customerName": "TEST-0002",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 20,
            "accountManagerName": "Test Test",
            "canDelete": false
        },
        {
            "customerId": 28,
            "customerName": "_AAAAAAAAAAA",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": "1",
            "amId": 5,
            "accountManagerName": "Test Testy",
            "canDelete": false
        },
        {
            "customerId": 29,
            "customerName": "Full customer",
            "customerAddress": "U Okrouhlíku 2b",
            "customerPhone": "+420725801673",
            "customerFax": null,
            "customerEmail": "david.gregorr@gmail.com",
            "notes": "This is a customer with all the informations provided in the creation form.",
            "taxCode": "CZ9007033288",
            "billToText": "Please always send invoice via email with CC to his accountant",
            "amId": 183,
            "accountManagerName": "Todor Todorov",
            "canDelete": false
        },
        {
            "customerId": 30,
            "customerName": "001-DEMO_CUSTOMER",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 27,
            "accountManagerName": "Rosen Dochev",
            "canDelete": false
        },
        {
            "customerId": 31,
            "customerName": "newly created",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 5,
            "accountManagerName": "Test Testy",
            "canDelete": false
        },
        {
            "customerId": 32,
            "customerName": "Validation test",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 82,
            "accountManagerName": "Kiril Kirilov",
            "canDelete": false
        },
        {
            "customerId": 33,
            "customerName": "erfgrhhio",
            "customerAddress": null,
            "customerPhone": "0123",
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 42,
            "accountManagerName": "Pavel Pavlov",
            "canDelete": false
        },
        {
            "customerId": 34,
            "customerName": "Validation test",
            "customerAddress": null,
            "customerPhone": "0123",
            "customerFax": null,
            "customerEmail": "rosen@test.com",
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 42,
            "accountManagerName": "Pavel Pavlov",
            "canDelete": false
        },
        {
            "customerId": 35,
            "customerName": "Validation test",
            "customerAddress": null,
            "customerPhone": "+123456",
            "customerFax": null,
            "customerEmail": "test@validation.com",
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 82,
            "accountManagerName": "Kiril Kirilov",
            "canDelete": false
        },
        {
            "customerId": 36,
            "customerName": "Validation test ",
            "customerAddress": null,
            "customerPhone": "+123456789",
            "customerFax": null,
            "customerEmail": "test@test.com",
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 82,
            "accountManagerName": "Kiril Kirilov",
            "canDelete": false
        },
        {
            "customerId": 37,
            "customerName": "Validation test ",
            "customerAddress": null,
            "customerPhone": "+123456789",
            "customerFax": null,
            "customerEmail": "test@test.c.om",
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 82,
            "accountManagerName": "Kiril Kirilov",
            "canDelete": false
        },
        {
            "customerId": 38,
            "customerName": "Validation test",
            "customerAddress": null,
            "customerPhone": "01234567",
            "customerFax": null,
            "customerEmail": "test@test.co.uk",
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 82,
            "accountManagerName": "Kiril Kirilov",
            "canDelete": false
        },
        {
            "customerId": 39,
            "customerName": "Newly created",
            "customerAddress": null,
            "customerPhone": null,
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 83,
            "accountManagerName": "Metodi Metodiev",
            "canDelete": false
        },
        {
            "customerId": 41,
            "customerName": "Motor Car Company NY",
            "customerAddress": "100 Madison Ave, 1 floor\n    New York\n    USA",
            "customerPhone": "+1 222 777 1200",
            "customerFax": null,
            "customerEmail": null,
            "notes": null,
            "taxCode": null,
            "billToText": null,
            "amId": 1,
            "accountManagerName": "Angel_dev Angelov",
            "canDelete": false
        }
    ]
}

export const CustomersGet: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /customers/,
    method: 'GET'
}

export const CustomersPost: FakeRequest = {
    data: {messages: [], result: null},
    httpCode: 200,
    url: /customers/,
    method: 'POST'
}

export const CustomersPut: FakeRequest = {
    data: {messages: [], result: null},
    httpCode: 200,
    url: /customers/,
    method: 'PUT'
}