import React from 'react';
import { useTranslation } from 'react-i18next';
import notFoundGraphics from '../../assets/404.svg';
import ErrorPage from './ErrorPage';

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = (props: NotFoundProps) => {
  const { t } = useTranslation();

  return <ErrorPage imageUrl={notFoundGraphics} title={t('notFoundTitle')} description={t('notFoundDescription')} />;
};

export default NotFound;
