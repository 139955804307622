import React from 'react';
import { TableRow } from '@material-ui/core';
import Task from '../../../../../models/Task';
import TaskModel from '../../../../../models/TaskModel';
import Employee from '../../../../../models/Employee';
import InsertTaskContainer from '../../../projectDialog/projectTasks/tasksDialog/InsertTaskContainer';

interface EditTaskRowProps {
  projectId: number;
  minDate?: string;
  maxDate?: string;
  initialModel: Task;
  employees: Array<Employee>;
  onAdd: (newValue: Task) => Promise<void>;
  onSave: (newValue: TaskModel) => Promise<void>;
  onCancel: () => void;
  index: number;
  canReadTask: boolean;
  canWriteTask: boolean;
}

const EditTaskRow: React.FC<EditTaskRowProps> = (props: EditTaskRowProps) => (
  <TableRow className="MuiTableRow-root inlineEditRow">
    <InsertTaskContainer
      projectId={props.projectId}
      minDate={props.minDate}
      maxDate={props.maxDate}
      task={props.initialModel}
      employees={props.employees}
      isLoading={false}
      onAdd={props.onAdd}
      onSave={props.onSave}
      onCancel={props.onCancel}
      index={props.index}
      initialModel={props.initialModel}
      isAddEnabled={false}
      renderMode={'InlineEdit'}
      canReadTask={props.canReadTask}
      canWriteTask={props.canWriteTask}
    />
  </TableRow>
);

export default EditTaskRow;
