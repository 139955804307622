export enum UserType {
  CUSTOMER = 'C',
  EMPLOYEE = 'E',
}

export default interface AppUser {
  appUserId: number;
  username: string;
  appUserType: UserType;

  passw?: string;
  prefTimeZone?: string;
  ldap_uid?: string;
  isActive?: boolean;
  custPersonId?: number;
  custFirstName?: string;
  custLastName?: string;
  custFullName?:	string;
  empId?: number;
  empFirstName?: string;
  empLastName?: string;
  empFullName?:	string;
  customerId?: number;
  customerName?:	string;
  empOfficeId?: number;
  empOfficeName?: string;
  loginId?: number;
}
