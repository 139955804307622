import { createStyles, TableCell, withStyles, WithStyles } from '@material-ui/core';
import { default as moment, default as Moment } from 'moment';
import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CalendarDateRange from '../../../models/CalendarDateRange';
import CalendarEvent from '../../../models/CalendarEvent';
import User from '../../../models/User';
import { getTimezone, getUser, SelectUserState } from '../../../store/selectors/userSelectors';
import { CALENDAR_EVENT_ITEM } from '../../../utils/calendarEventItem';
import { convertBrowserTimeToApiFormat, resetHours } from '../../../utils/dateTime';
import SaraCheckCancelButtons from '../../common/SaraCheckCancelButtons';
import { Lookup } from '../../saraSelect/SaraMultiSelect';
import UpdateRequestForm from './UpdateRequestForm';

const styles = () =>
  createStyles({
    requestList: {
      marginTop: 24,
    },
  });

interface UpdateRequestContainerProps extends WithStyles<typeof styles>, WithTranslation {
  requestItem: CalendarEvent;
  onSave: (requestItem: CalendarEvent, surpressWarning: string | null) => Promise<void>;
  onCancel?: () => void;
  eventTypeLookups: Array<Lookup>;
  user: User;
  timeZoneId: string;
  isAdministratorGroup: boolean;
}

interface UpdateRequestContainerState {
  newRequestItem: CalendarEvent;
  validated: boolean;
  selectedEventTypeId: number;
  calendarDateRanges: Array<CalendarDateRange>;
}

class UpdateRequestContainer extends Component<UpdateRequestContainerProps, UpdateRequestContainerState> {
  state: UpdateRequestContainerState = {
    selectedEventTypeId: -1,
    newRequestItem: CALENDAR_EVENT_ITEM,
    validated: false,
    calendarDateRanges: [
      { minDate: null, maxDate: null },
      { minDate: null, maxDate: null },
    ],
  };

  componentDidMount() {
    const newRequestItem = { ...this.props.requestItem };
    this.setState({ newRequestItem });

    this.setCalendarDateRanges(newRequestItem);
  }

  componentDidUpdate(prevProps: UpdateRequestContainerProps, prevState: UpdateRequestContainerState): void {
    if (prevProps.isAdministratorGroup !== this.props.isAdministratorGroup) {
      const newRequestItem: CalendarEvent = { ...this.props.requestItem };
      this.setCalendarDateRanges(newRequestItem);
    }
  }

  setCalendarDateRanges = (calendarEvent: CalendarEvent): void => {
    let calendarDateRanges: Array<CalendarDateRange> = [{ ...this.state.calendarDateRanges[0] }, { ...this.state.calendarDateRanges[1] }];
    const todayDate: Moment.Moment = Moment(convertBrowserTimeToApiFormat(resetHours(new Date()), this.props.timeZoneId));
    const startDate: Moment.Moment = Moment(convertBrowserTimeToApiFormat(calendarEvent.rqStartDate, this.props.timeZoneId));
    const endDate: Moment.Moment = Moment(convertBrowserTimeToApiFormat(calendarEvent.rqEndDate, this.props.timeZoneId));
    if (!this.props.isAdministratorGroup && startDate.isBefore(todayDate) && endDate.isSameOrAfter(todayDate)) {
      calendarDateRanges = [
        { ...this.state.calendarDateRanges[0], minDate: todayDate },
        { ...this.state.calendarDateRanges[1], minDate: todayDate },
      ];
    }
    if (!this.props.isAdministratorGroup && startDate.isSameOrAfter(todayDate)) {
      calendarDateRanges = [
        { ...this.state.calendarDateRanges[0], minDate: todayDate },
        { ...this.state.calendarDateRanges[1], minDate: startDate },
      ];
    }
    if (this.props.isAdministratorGroup) {
      calendarDateRanges = [
        { ...this.state.calendarDateRanges[0], minDate: null, maxDate: null },
        { ...this.state.calendarDateRanges[1], minDate: null, maxDate: null },
      ];
    }
    this.setState({ calendarDateRanges });
  };

  isValid = (requestItem: CalendarEvent) =>
    requestItem.eventTypeId &&
    requestItem.rqStartDate &&
    requestItem.rqEndDate &&
    requestItem.rqEndDate >= requestItem.rqStartDate &&
    moment(requestItem.rqEndDate).isSame(requestItem.rqStartDate, 'year');

  handleSave = () => {
    if (!this.isValid(this.state.newRequestItem)) {
      this.setState({ validated: true });
      return;
    }
    const item: any = {
      rqStartDate: this.state.newRequestItem.rqStartDate,
      rqEndDate: this.state.newRequestItem.rqEndDate,
      statusId: this.props.requestItem.statusId,
      empId: this.props.requestItem.empId,
      eventTypeId: this.state.newRequestItem.eventTypeId,
      notes: this.state.newRequestItem.notes,
      empCalEventReqId: this.props.requestItem.empCalEventReqId,
    };

    this.props.onSave(item, '');
  };

  handleChange = (event: any, field: string): void => {
    const newRequestItem = { ...this.state.newRequestItem };
    switch (field) {
      case 'startDate':
        const rqStartDate: Moment.Moment = moment(event).isValid() ? moment(event).format('YYYY-MM-DD') : event;
        newRequestItem.rqStartDate = rqStartDate;
        this.setState({
          calendarDateRanges: [{ ...this.state.calendarDateRanges[0] }, { ...this.state.calendarDateRanges[1], minDate: rqStartDate }],
        });
        break;
      case 'endDate':
        newRequestItem.rqEndDate = moment(event).isValid() ? moment(event).format('YYYY-MM-DD') : event;
        break;
      case 'selectedEventTypeId':
        newRequestItem.eventTypeId = event.target.value;
        break;
      case 'notes':
        newRequestItem.notes = event.target.value;
        break;
      default:
        break;
    }
    this.setState({ newRequestItem });
  };

  render() {
    const todayDate: Moment.Moment = Moment(convertBrowserTimeToApiFormat(resetHours(new Date()), this.props.timeZoneId));
    return (
      <Fragment>
        <TableCell colSpan={8} className="MuiTableCell-root MuiTableCell-alignLeft MuiTableCell-body">
          <UpdateRequestForm
            requestItem={this.state.newRequestItem}
            handleChange={this.handleChange}
            eventTypeLookups={this.props.eventTypeLookups}
            validated={this.state.validated}
            isAdministratorGroup={this.props.isAdministratorGroup}
            calendarDateRanges={this.state.calendarDateRanges}
            todayDate={todayDate}
          />
        </TableCell>
        <TableCell padding="none" key="key-actions-column" style={{ width: 84, padding: '0px 5px' }}>
          <div style={{ display: 'flex' }}>
            <SaraCheckCancelButtons onCheck={this.handleSave} onCancel={this.props.onCancel} />
          </div>
        </TableCell>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: SelectUserState) => ({
  user: getUser(state),
  timeZoneId: getTimezone(state),
});

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(UpdateRequestContainer)));
