import {
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Step,
  StepButton,
  Stepper,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApiError } from '../../../api/apiResult';
import appGroupsReqHandler from '../../../api/appGroupsRemote';
import appUserGroupsReqHandler from '../../../api/appUserGroupsRemote';
import ConfigurationReqHandler from '../../../api/configurationRemote';
import empAppGroupOverseeReqHandler from '../../../api/empAppGroupOverseeRemote';
import EmployeesReqHandler from '../../../api/employeesRemote';
import OfficesReqHandler from '../../../api/officesRemote';
import AppGroup from '../../../models/AppGroup';
import { UserType } from '../../../models/AppUser';
import AppUserGroup from '../../../models/AppUserGroup';
import Configuration from '../../../models/Configuration';
import CreatedEmployee from '../../../models/CreatedEmployee';
import CreateEmployee, { AppGroupId } from '../../../models/CreateEmployee';
import { DialogMode } from '../../../models/DialogMode';
import EmpAppGroupOversee from '../../../models/EmpAppGroupOversee';
import Employee from '../../../models/Employee';
import Office from '../../../models/Office';
import { employeesSteps, EmployeeStep } from '../../../models/Step';
import UpdateEmployee from '../../../models/UpdateEmployee';
import { getTimezone, SelectUserState } from '../../../store/selectors/userSelectors';
import { convertBrowserTimeToApiFormat } from '../../../utils/dateTime';
import { validateEmail, validatePhone } from '../../../utils/validation';
import SaraDialogButtons from '../../common/SaraDialogButtons';
import SaraDialogTitle from '../../common/SaraDialogTitle';
import InfoSnackbar from '../../infoSnackbar/InfoSnackbar';
import EmployeeFormReadonlyFields from '.././employeeDialog/employeeForm/EmployeeFormReadonlyFields';
import EmployeeAppGroups from './employeeAppGroups/EmployeeAppGroups';
import EmployeeForm from './employeeForm/EmployeeForm';

const styles = () =>
  createStyles({
    dialog: {
      padding: '24px',
      height: '80%',
    },
    header: {
      marginLeft: '26px',
      marginBottom: '26px',
    },
  });

interface AppGroupsState {
  authorizedAppGroupIds: number[];
  appGroupIdsToAdd: number[];
  appGroupIdsToRemove: number[];
}

interface EmployeeDialogContainerProps extends WithStyles<typeof styles>, WithTranslation {
  isOpen: boolean;
  employee: (Employee & { uid?: string; username?: string }) | null;
  onClose: () => void;
  onSaved: (successMessage: string) => void;
  onError: (error: ApiError) => void;
  dialogMode: DialogMode;
  canWriteAppGroups: boolean;
  canReadAppGroups: boolean;
  timeZoneId: string;
}

interface EmployeeDialogContainerState {
  employee: Employee & { uid?: string; username?: string };
  validated: boolean;
  loaded: boolean;
  snackbarMessage: string | null;
  offices: Array<Office>;
  readonlyFields: EmployeeFormReadonlyFields;
  isDirty: boolean;
  showCloseWarning: boolean;
  activeStep: EmployeeStep;
  appGroups: AppGroup[];
  dataAccessAppGroups: AppGroupsState;
  userAppGroups: AppGroupsState;
}

class EmployeeDialogContainer extends Component<EmployeeDialogContainerProps, EmployeeDialogContainerState> {
  defaultEmployee: Employee = {
    dayWorkHours: 0,
    empEmail: '',
    empFirstName: '',
    empId: 0,
    empLastName: '',
    isActive: 1,
    officeId: 0,
    officeName: '',
    appUserId: 0,
  };
  state: EmployeeDialogContainerState = {
    employee: this.defaultEmployee,
    validated: false,
    loaded: false,
    offices: [],
    readonlyFields: {
      emailReadonly: false,
      firstNameReadonly: false,
      lastNameReadonly: false,
      isActiveReadonly: false,
      isMobileReadonly: false,
    },
    isDirty: false,
    showCloseWarning: false,
    activeStep: EmployeeStep.DETAILS,
    appGroups: [],
    snackbarMessage: null,
    dataAccessAppGroups: {
      appGroupIdsToAdd: [],
      appGroupIdsToRemove: [],
      authorizedAppGroupIds: [],
    },
    userAppGroups: {
      appGroupIdsToAdd: [],
      appGroupIdsToRemove: [],
      authorizedAppGroupIds: [],
    },
  };

  componentDidUpdate(prevProps: EmployeeDialogContainerProps, prevState: EmployeeDialogContainerState) {
    if (!prevProps.isOpen && this.props.isOpen && !this.state.loaded) {
      Promise.all([
        OfficesReqHandler.loadOffices(),
        ConfigurationReqHandler.loadConfiguration().then(this.processConfiguration),
        this.props.canReadAppGroups ? appGroupsReqHandler.loadAppGroups() : Promise.resolve<AppGroup[]>([]),
      ])
        .then(([offices, readonlyFields, appGroups]: [Office[], EmployeeFormReadonlyFields, AppGroup[]]) =>
          this.setState({ loaded: true, offices, readonlyFields, appGroups })
        )
        .catch(error => this.props.onError(error));
    }
    if (this.props.employee && prevProps.employee !== this.props.employee) {
      this.setState({ employee: { ...this.props.employee }, isDirty: false, activeStep: EmployeeStep.DETAILS });
      const empId = this.props.employee.empId;
      const appUserId = this.props.employee.appUserId;
      let appGroupsRequests: [Promise<EmpAppGroupOversee[]>, Promise<AppUserGroup[]>];
      if (this.props.canReadAppGroups && empId) {
        appGroupsRequests = [empAppGroupOverseeReqHandler.loadEmpAppGroupOversees(empId), appUserGroupsReqHandler.loadAppUserGroups()];
      } else {
        appGroupsRequests = [Promise.resolve<EmpAppGroupOversee[]>([]), Promise.resolve<AppUserGroup[]>([])];
      }
      Promise.all(appGroupsRequests)
        .then(([empAppGroupOversees, appUserGroups]: [EmpAppGroupOversee[], AppUserGroup[]]) => {
          let dataAccessAppGroups = empAppGroupOversees.map(x => x.appGroupId);
          let employeeAppGroups = appUserGroups.filter(x => x.appUserId === appUserId).map(x => x.appGroupId);
          this.setState({
            dataAccessAppGroups: {
              authorizedAppGroupIds: dataAccessAppGroups,
              appGroupIdsToAdd: [],
              appGroupIdsToRemove: [],
            },
            userAppGroups: {
              authorizedAppGroupIds: employeeAppGroups,
              appGroupIdsToAdd: [],
              appGroupIdsToRemove: [],
            },
          });
        })
        .catch((error: any) => this.props.onError(error));
    }
  }

  processConfiguration(configuration: Configuration): EmployeeFormReadonlyFields {
    const fieldsCollection = configuration.ldapUserMappedFields.split(',');
    return {
      emailReadonly: !!fieldsCollection.find(field => field === 'email' || field === 'username'),
      firstNameReadonly: !!fieldsCollection.find(field => field === 'firstName'),
      lastNameReadonly: !!fieldsCollection.find(field => field === 'lastName'),
      isActiveReadonly: !!fieldsCollection.find(field => field === 'isActive'),
      isMobileReadonly: !!fieldsCollection.find(field => field === 'mobile'),
    };
  }

  handleChange = (event: any, field: string): void => {
    if (this.props.dialogMode === DialogMode.VIEW) {
      return;
    }
    const newEmployee: Employee = { ...this.state.employee };
    switch (field) {
      case 'startDate':
      case 'endDate':
      case 'endProbDate':
        const dateValue: Moment = event as Moment;
        if (dateValue === null) {
          newEmployee[field] = null;
        } else {
          newEmployee[field] =
            dateValue && moment(dateValue).isValid()
              ? moment(convertBrowserTimeToApiFormat(dateValue, this.props.timeZoneId)).format('YYYY-MM-DD')
              : dateValue.toString();
        }
        break;
      case 'officeId':
        newEmployee.officeId = event.target.value;
        break;
      case 'empFirstName':
      case 'empLastName':
      case 'empEmail':
      case 'empPhone':
      case 'empMobile':
      case 'empHomeAddress':
        newEmployee[field] = event.target.value;
        break;
      case 'dayWorkHours':
        const parsedNumber = Number.parseFloat(event.target.value);
        newEmployee[field] = Number.isNaN(parsedNumber) ? 0 : parsedNumber;
        break;
      case 'isActive':
        newEmployee[field] = event.target.checked ? 1 : 0;
        break;
      default:
        break;
    }
    this.setState({ employee: newEmployee, isDirty: true });
  };

  areAppGroupsDirty = (appGroups: AppGroupsState): boolean => {
    return appGroups.appGroupIdsToAdd.length > 0 || appGroups.appGroupIdsToRemove.length > 0;
  };

  handleClose = () => {
    const isDirty =
      this.state.isDirty || this.areAppGroupsDirty(this.state.dataAccessAppGroups) || this.areAppGroupsDirty(this.state.userAppGroups);
    if (isDirty && !this.state.showCloseWarning) {
      this.setState({ showCloseWarning: true });
      return;
    }
    this.setState({ showCloseWarning: false });
    this.props.onClose();
  };

  isStartDateValid = (): boolean =>
    !this.state.employee.startDate ||
    (moment(this.state.employee.startDate).isValid() &&
      (!this.state.employee.endDate ||
        (moment(this.state.employee.endDate).isValid() &&
          moment(this.state.employee.startDate).isSameOrBefore(this.state.employee.endDate, 'day'))) &&
      (!this.state.employee.endProbDate ||
        (moment(this.state.employee.endProbDate).isValid() &&
          moment(this.state.employee.startDate).isSameOrBefore(this.state.employee.endProbDate, 'day'))));

  isValidSameOrAfterStartDate = (date?: string | null): boolean =>
    !date ||
    (moment(date).isValid() &&
      (!this.state.employee.startDate ||
        (moment(this.state.employee.startDate).isValid() && moment(date).isSameOrAfter(this.state.employee.startDate, 'day'))));

  isValid = (): boolean => {
    const phone = this.state.employee.empPhone;
    const mobile = this.state.employee.empMobile;
    const email = this.state.employee.empEmail;
    const isValidEmail = email ? validateEmail(email) : false;
    const isValidPhone = phone ? validatePhone(phone) : true;
    const isValidMobile = mobile ? validatePhone(mobile) : true;
    const dayWorkHoursInvalid = this.state.employee.dayWorkHours < 0 || this.state.employee.dayWorkHours > 24;
    const isEndProbDateValid = !this.state.employee.endProbDate || moment(this.state.employee.endProbDate).isValid();
    return (
      this.isStartDateValid() &&
      this.isValidSameOrAfterStartDate(this.state.employee.endDate) &&
      this.isValidSameOrAfterStartDate(this.state.employee.endProbDate) &&
      isEndProbDateValid &&
      !dayWorkHoursInvalid &&
      isValidPhone &&
      isValidMobile &&
      isValidEmail
    );
  };

  handleSave = () => {
    if (this.props.dialogMode === DialogMode.VIEW) {
      return;
    }
    if (!this.isValid()) {
      this.setState({ validated: true });
      return;
    }
  };

  createEmployee = (): void => {
    const dataAccessAppGroups: Array<number> = this.state.dataAccessAppGroups.appGroupIdsToAdd;
    const empAppGroupOversees: Array<AppGroupId> = [];
    const userAccessAppGroups: Array<number> = this.state.userAppGroups.appGroupIdsToAdd;
    const appUserGroups: Array<AppGroupId> = [];
    for (const appGroupId of dataAccessAppGroups) {
      empAppGroupOversees.push({ appGroupId });
    }
    for (const appGroupId of userAccessAppGroups) {
      appUserGroups.push({ appGroupId });
    }
    const createEmployee: CreateEmployee = {
      employee: this.getUpdateEmployeeFromState(),
      appUser: {
        ldapUid: this.state.employee.uid || '',
        username: this.state.employee.username || '',
        appUserType: UserType.EMPLOYEE,
      },
      appUserGroups: appUserGroups,
      empAppGroupOversees: empAppGroupOversees,
    };
    this.setState({ loaded: false }, () => {
      EmployeesReqHandler.createEmployee(createEmployee)
        .then((resp: CreatedEmployee) => {
          this.setState({ loaded: true, showCloseWarning: false });
          this.props.onSaved(this.props.t('employeeCreatedSuccessfully'));
        })
        .catch(error => this.props.onError(error));
    });
  };

  getUpdateEmployeeFromState = (): UpdateEmployee => {
    return {
      officeId: this.state.employee.officeId,
      empFirstName: this.state.employee.empFirstName,
      empLastName: this.state.employee.empLastName,
      empEmail: this.state.employee.empEmail,
      dayWorkHours: this.state.employee.dayWorkHours,
      appUserId: this.state.employee.appUserId,
      startDate: this.state.employee.startDate,
      endDate: this.state.employee.endDate,
      endProbDate: this.state.employee.endProbDate,
      empPhone: this.state.employee.empPhone,
      empMobile: this.state.employee.empMobile,
      empHomeAddress: this.state.employee.empHomeAddress,
    };
  };

  saveEmployee = (showSnackbarMessage: boolean, successActionCallback: () => void) => {
    this.setState({ loaded: false }, () => {
      const updateEmployee: UpdateEmployee = this.getUpdateEmployeeFromState();
      EmployeesReqHandler.updateEmployee(this.state.employee.empId, updateEmployee)
        .then(() => {
          this.setState(
            {
              isDirty: false,
              loaded: true,
              snackbarMessage: showSnackbarMessage ? this.props.t('employeeUpdatedSuccessfully') : null,
              showCloseWarning: false,
            },
            () => {
              successActionCallback();
            }
          );
        })
        .catch(error => {
          this.setState({ loaded: true });
          this.props.onError(error);
        });
    });
  };

  saveUserAppGroups = (showSuccessMessage: boolean, successActionCallback: () => void) => {
    if (!this.areAppGroupsDirty(this.state.userAppGroups)) {
      successActionCallback();
      return;
    }
    this.setState({ loaded: false }, () => {
      const appUserId = this.state.employee.appUserId;
      const allRequests: Array<Promise<AppUserGroup>> = this.state.userAppGroups.appGroupIdsToAdd.map(x =>
        appUserGroupsReqHandler.createAppUserGroup({
          appUserId: appUserId,
          appGroupId: x,
        })
      );
      const removeRequests: Array<Promise<AppUserGroup>> = this.state.userAppGroups.appGroupIdsToRemove.map(x =>
        appUserGroupsReqHandler.deleteAppUserGroup(appUserId, x)
      );
      allRequests.push(...removeRequests);
      Promise.all(allRequests)
        .then((appGroups: AppUserGroup[]) => {
          this.setState(
            {
              userAppGroups: {
                ...this.state.userAppGroups,
                appGroupIdsToAdd: [],
                appGroupIdsToRemove: [],
              },
              loaded: true,
              snackbarMessage: showSuccessMessage ? this.props.t('employeesAppGroupsUpdatedSuccessfully') : null,
              showCloseWarning: false,
            },
            () => {
              successActionCallback();
            }
          );
        })
        .catch(error => {
          this.setState({ loaded: true });
          this.props.onError(error);
        });
    });
  };

  saveDataAccessAppGroups = (successActionCallback: () => void) => {
    if (!this.areAppGroupsDirty(this.state.dataAccessAppGroups)) {
      successActionCallback();
      return;
    }

    this.setState({ loaded: false }, () => {
      const empId = this.state.employee.empId;
      const allRequests: Array<Promise<EmpAppGroupOversee>> = this.state.dataAccessAppGroups.appGroupIdsToAdd.map(x =>
        empAppGroupOverseeReqHandler.createEmpAppGroupOversee({
          empId: empId,
          appGroupId: x,
        })
      );
      const removeRequests: Array<Promise<EmpAppGroupOversee>> = this.state.dataAccessAppGroups.appGroupIdsToRemove.map(x =>
        empAppGroupOverseeReqHandler.deleteEmpAppGroupOversee(empId, x)
      );
      allRequests.push(...removeRequests);
      Promise.all(allRequests)
        .then((appGroups: EmpAppGroupOversee[]) => {
          this.setState(
            {
              dataAccessAppGroups: {
                ...this.state.dataAccessAppGroups,
                appGroupIdsToAdd: [],
                appGroupIdsToRemove: [],
              },
              loaded: true,
              snackbarMessage: this.props.t('employeesAppGroupsUpdatedSuccessfully'),
              showCloseWarning: false,
            },
            () => {
              successActionCallback();
            }
          );
        })
        .catch(error => {
          this.setState({ loaded: true });
          this.props.onError(error);
        });
    });
  };

  onClickLabel(label: string | null) {
    if (!this.isValid()) {
      this.setState({ validated: true });
      return;
    }
    let targetStep = employeesSteps().findIndex(x => x === label);
    switch (this.state.activeStep) {
      case EmployeeStep.DETAILS:
        if (this.props.dialogMode === DialogMode.UPDATE && this.state.isDirty) {
          this.saveEmployee(true, () => this.setState({ activeStep: targetStep }));
        } else {
          this.setState({ activeStep: targetStep });
        }
        break;
      case EmployeeStep.DATA_ACCESS:
        if (this.props.dialogMode === DialogMode.UPDATE && this.areAppGroupsDirty(this.state.dataAccessAppGroups)) {
          this.saveDataAccessAppGroups(() => this.setState({ activeStep: targetStep }));
        } else {
          this.setState({ activeStep: targetStep });
        }
        break;
      case EmployeeStep.APP_GROUPS:
        if (this.props.dialogMode === DialogMode.UPDATE && this.areAppGroupsDirty(this.state.userAppGroups)) {
          this.saveUserAppGroups(true, () => this.setState({ activeStep: targetStep }));
        } else {
          this.setState({ activeStep: targetStep });
        }
        break;
      default:
        break;
    }
  }

  handleAppGroupMove = (appGroupsSelector: () => AppGroupsState, successCallBack: (appGroups: AppGroupsState) => void) => (
    id: number,
    toAuthorized: boolean
  ) => {
    if (this.props.dialogMode === DialogMode.VIEW || !this.props.canWriteAppGroups) {
      return;
    }
    const appGroups = appGroupsSelector();
    const authorizedIds = appGroups.authorizedAppGroupIds.slice(0, appGroups.authorizedAppGroupIds.length);
    const idsToAdd = appGroups.appGroupIdsToAdd.slice(0, appGroups.appGroupIdsToAdd.length);
    const idsToRemove = appGroups.appGroupIdsToRemove.slice(0, appGroups.appGroupIdsToRemove.length);
    if (toAuthorized) {
      authorizedIds.push(id);
      if (idsToRemove.find(x => x === id)) {
        idsToRemove.splice(idsToRemove.indexOf(id), 1);
      } else {
        idsToAdd.push(id);
      }
    } else {
      authorizedIds.splice(authorizedIds.indexOf(id), 1);
      if (idsToAdd.find(x => x === id)) {
        idsToAdd.splice(idsToAdd.indexOf(id), 1);
      } else {
        idsToRemove.push(id);
      }
    }
    successCallBack({
      authorizedAppGroupIds: authorizedIds,
      appGroupIdsToAdd: idsToAdd,
      appGroupIdsToRemove: idsToRemove,
    });
  };

  getDialogContent(): React.ReactNode {
    if (!this.state.loaded) {
      return (
        <div style={{ width: '100%', position: 'relative' }}>
          <CircularProgress color="primary" className="centeredProgress" />
        </div>
      );
    }
    switch (this.state.activeStep) {
      case EmployeeStep.DETAILS:
        return (
          <EmployeeForm
            employee={this.state.employee}
            validated={this.state.validated}
            handleChange={this.handleChange}
            offices={this.state.offices.map((office: Office) => ({
              text: office.officeName,
              value: office.officeId,
            }))}
            readonlyFields={this.state.readonlyFields}
            dialogMode={this.props.dialogMode}
            isEndDateInvalid={!this.isValidSameOrAfterStartDate(this.state.employee.endDate)}
            isProbDateInvalid={!this.isValidSameOrAfterStartDate(this.state.employee.endProbDate)}
            isStartDateInvalid={!this.isStartDateValid()}
          />
        );
      case EmployeeStep.DATA_ACCESS:
        return (
          <EmployeeAppGroups
            allAppGroups={this.state.appGroups}
            dialogMode={this.props.dialogMode}
            moveAppGroup={this.handleAppGroupMove(
              () => this.state.dataAccessAppGroups,
              appGroups => this.setState({ dataAccessAppGroups: appGroups })
            )}
            authAppGroupIds={this.state.dataAccessAppGroups.authorizedAppGroupIds}
            label={
              this.props.canWriteAppGroups ? this.props.t('assignEmployeeDataAccess') : this.props.t('readOnlyAssignAppGroupEmployeeAccess')
            }
            readonly={!this.props.canWriteAppGroups}
          />
        );
      case EmployeeStep.APP_GROUPS:
        return (
          <EmployeeAppGroups
            allAppGroups={this.state.appGroups}
            dialogMode={this.props.dialogMode}
            moveAppGroup={this.handleAppGroupMove(
              () => this.state.userAppGroups,
              appGroups => this.setState({ userAppGroups: appGroups })
            )}
            authAppGroupIds={this.state.userAppGroups.authorizedAppGroupIds}
            label={
              this.props.canWriteAppGroups ? this.props.t('assignEmployeeToAppGroup') : this.props.t('readOnlyAssignEmployeeToAppGroup')
            }
            readonly={!this.props.canWriteAppGroups}
          />
        );
      default:
        return null;
    }
  }

  getPrimaryButtonText = (): string => {
    if (this.state.activeStep === employeesSteps().length - 1 || !this.props.canReadAppGroups) {
      if (
        this.props.dialogMode !== DialogMode.VIEW &&
        this.props.canWriteAppGroups &&
        (this.areAppGroupsDirty(this.state.dataAccessAppGroups) || this.areAppGroupsDirty(this.state.userAppGroups))
      ) {
        return this.props.t('save');
      } else {
        return this.props.t('close');
      }
    } else {
      return this.props.t('next');
    }
  };

  getHeader(): React.ReactNode {
    if (this.props.canReadAppGroups) {
      return (
        <Stepper nonLinear activeStep={this.state.activeStep}>
          {employeesSteps().map((label: string) => {
            const stepProps: { completed?: boolean } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepButton onClick={() => this.onClickLabel(label)}>{this.props.t(label)}</StepButton>
              </Step>
            );
          })}
        </Stepper>
      );
    }
    return <h3 className={this.props.classes.header}>{this.props.t(employeesSteps()[0])}</h3>;
  }

  handleFinish = () => {
    if (this.props.dialogMode === DialogMode.CREATE) {
      this.createEmployee();
      return;
    }
    if (this.props.dialogMode === DialogMode.UPDATE) {
      if (!this.props.canReadAppGroups) {
        //It is finish from the first screen
        if (this.state.isDirty) {
          this.saveEmployee(false, () => this.props.onSaved(this.props.t('employeeUpdatedSuccessfully')));
        } else {
          this.handleClose();
        }
        return;
      }
      //It is finish from the second screen
      const isDirty = this.areAppGroupsDirty(this.state.userAppGroups);
      this.saveUserAppGroups(false, () => this.props.onSaved(isDirty ? this.props.t('employeesAppGroupsUpdatedSuccessfully') : ''));
    } else {
      this.handleClose();
    }
  };

  getDialogButtons(): React.ReactNode {
    if (this.props.dialogMode === DialogMode.VIEW && !this.props.canReadAppGroups) {
      return null;
    }
    return (
      <SaraDialogButtons
        secondaryText={this.state.activeStep === EmployeeStep.DETAILS ? this.props.t('cancel') : this.props.t('back')}
        onSecondary={() => {
          switch (this.state.activeStep) {
            case EmployeeStep.APP_GROUPS:
              this.onClickLabel(employeesSteps()[1]);
              break;
            case EmployeeStep.DATA_ACCESS:
              this.onClickLabel(employeesSteps()[0]);
              break;
            case EmployeeStep.DETAILS:
              this.handleClose();
              break;
            default:
              break;
          }
        }}
        primaryText={this.getPrimaryButtonText()}
        onPrimary={() => {
          if (!this.props.canReadAppGroups) {
            this.handleFinish();
            return;
          }
          switch (this.state.activeStep) {
            case EmployeeStep.DETAILS:
              this.onClickLabel(employeesSteps()[1]);
              break;
            case EmployeeStep.DATA_ACCESS:
              this.onClickLabel(employeesSteps()[2]);
              break;
            case EmployeeStep.APP_GROUPS:
              this.handleFinish();
              break;
            default:
              break;
          }
        }}
        primaryDisabled={!this.isValid}
      />
    );
  }

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.handleClose}
        aria-labelledby="dialog-title"
        maxWidth="md"
        fullWidth
        classes={{ paper: this.props.classes.dialog }}
      >
        <SaraDialogTitle
          onClose={this.handleClose}
          text={`${this.state.employee.empFirstName} ${this.state.employee.empLastName}`}
          showWarning={this.state.showCloseWarning}
          warning={this.props.t('closeAndDiscardWarning')}
        />
        {this.getHeader()}
        <DialogContent>
          {this.getDialogContent()}
          <InfoSnackbar
            open={this.state.snackbarMessage !== null}
            onClose={() => this.setState({ snackbarMessage: null })}
            text={this.state.snackbarMessage ? this.state.snackbarMessage : ''}
          />
        </DialogContent>
        <DialogActions>{this.getDialogButtons()}</DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: SelectUserState) => {
  return {
    timeZoneId: getTimezone(state),
  };
};

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(EmployeeDialogContainer)));
