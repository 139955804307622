import { Grid, InputAdornment, List, ListItem, ListItemText, TextField, Theme, Tooltip, Typography } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import SearchIcon from '@material-ui/icons/Search';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import commonStyles from './common.module.css';

const styles = (theme: Theme) =>
  createStyles({
    searchFieldContainer: {
      margin: '3px 0',
      '& fieldset': {
        borderRadius: 8,
      },
    },
    adornedStart: {
      paddingLeft: 7,
    },
    leftSubheader: {
      paddingLeft: 35,
    },
    groupHeader: {
      fontSize: 15,
      fontWeight: 'bold',
    },
    listItem: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    readonlyContainer: {
      width: '100%',
    },
  });

export interface Lookup<Item> {
  key: number;
  value: Item;
  text: string;
}

interface SaraTransferListsProps<Item> extends WithStyles<typeof styles>, WithTranslation {
  leftHeader?: string;
  showSearchBar: boolean;
  leftItems: Array<Lookup<Item>>;
  leftListStyle: string;
  rightHeader?: string;
  rightItems: Array<Lookup<Item>>;
  rightListStyle: string;
  handleMove: (id: number, toAuthorized: boolean) => void;
  readonly?: boolean;
}

interface SaraTransferListsState {
  searchTerm: string;
}

class SaraTransferLists<Item> extends React.Component<SaraTransferListsProps<Item>, SaraTransferListsState> {
  constructor(props: SaraTransferListsProps<Item>) {
    super(props);
    this.state = { searchTerm: '' };
  }

  customList = (items: Lookup<Item>[], isLeft: boolean) => (
    <div className={isLeft ? this.props.leftListStyle : this.props.rightListStyle}>
      <List dense component="div" role="list" className={commonStyles.scrollList}>
        {items.map((item: Lookup<Item>) => {
          const labelId = `transfer-list-item-${item.value}-label`;
          return (
            <ListItem
              key={item.key}
              role="listitem"
              button
              onClick={event => !this.props.readonly && this.props.handleMove(item.key, isLeft)}
            >
              <Tooltip title={this.props.readonly ? '' : item.text}>
                <ListItemText className={this.props.classes.listItem} id={labelId} primary={item.text} />
              </Tooltip>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </div>
  );

  handleSearch(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const searchTerm = event.target.value.trim().toLowerCase();
    this.setState({ searchTerm });
  }

  filter(items: Array<Lookup<Item>>, searchTerm: string): Array<Lookup<Item>> {
    return items.filter(item => item.text.toLowerCase().includes(searchTerm));
  }

  render() {
    if (this.props.readonly) {
      return <div className={this.props.classes.readonlyContainer}>{this.customList(this.filter(this.props.rightItems, ''), true)}</div>;
    }
    return (
      <Grid container spacing={2} justify="center" alignItems="center">
        {this.props.showSearchBar && (
          <Grid item xs={12}>
            <div className={this.props.classes.searchFieldContainer}>
              <TextField
                placeholder={`${this.props.t('search')}...`}
                fullWidth={true}
                variant="outlined"
                margin="dense"
                onChange={event => this.handleSearch(event)}
                value={this.state.searchTerm}
                InputProps={{
                  classes: { adornedStart: this.props.classes.adornedStart },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Grid>
        )}
        <Grid item container spacing={1}>
          <Grid item xs={5}>
            <div className={this.props.classes.leftSubheader}>
              <Typography className={this.props.classes.groupHeader} color="textSecondary">
                {this.props.leftHeader ? this.props.leftHeader : this.props.t('unauthorized')}
              </Typography>
            </div>
            {this.customList(this.filter(this.props.leftItems, this.state.searchTerm), true)}
          </Grid>
          <Grid item className={commonStyles.arrowsContainer} container xs={2}>
            <ArrowForward fontSize="large" color="disabled" />
            <ArrowBack fontSize="large" color="disabled" />
          </Grid>
          <Grid item xs={5}>
            <Typography className={this.props.classes.groupHeader} color="textSecondary">
              {this.props.rightHeader ? this.props.rightHeader : this.props.t('authorized')}
            </Typography>
            {this.customList(this.filter(this.props.rightItems, this.state.searchTerm), false)}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(withStyles(styles)(SaraTransferLists));
