import { CircularProgress, createStyles, Paper, WithStyles, withStyles } from '@material-ui/core';
import Moment from 'moment';
import React, { Component } from 'react';
import { TreeNode } from 'react-dropdown-tree-select';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import employeesReqHandler from '../../../api/employeesRemote';
import { reportsCsvExportUrl, reportsPdfExportUrl } from '../../../api/reportsRemote';
import tasksReqHandler from '../../../api/tasksRemote';
import AllTaskModel from '../../../models/AllTaskModel';
import ChildEmployeeOfProjectsTree from '../../../models/ChildEmployeeOfProjectsTree';
import ChildTaskOfProjectsTree from '../../../models/ChildTaskOfProjectsTree';
import Employee from '../../../models/Employee';
import Project from '../../../models/Project';
import ProjectObjectValue from '../../../models/ProjectObjectValue';
import ProjectObjectValueChild from '../../../models/ProjectObjectValueChild';
import ProjectsTree from '../../../models/ProjectsTree';
import Task from '../../../models/Task';
import { getTimezone, getUser, SelectUserState } from '../../../store/selectors/userSelectors';
import { convertBrowserTimeToApiFormat } from '../../../utils/dateTime';
import { Lookup } from '../../saraSelect/SaraMultiSelect';
import ReportsFilters from './ReportsFilters';

const styles = () =>
  createStyles({
    loadingPaper: {
      padding: 15,
    },
    loaderContainer: {
      width: '100%',
      position: 'relative',
    },
  });

interface ReportsFiltersContainerProps extends WithTranslation, WithStyles<typeof styles> {
  empId: number | null;
  projects: Array<Project>;
  isLoading: boolean;
  timeZoneId: string;
  handleSave: (
    startDate: string,
    endDate: string,
    empIds: Array<number>,
    projectIds: Array<number>,
    taskIds: Array<number>,
    showSummary: boolean
  ) => void;
}

interface ReportsFiltersContainerState {
  selectedProjectIds: Array<number>;
  employeeLookups: Array<Lookup>;
  selectedEmployeeIds: Array<number>;
  startDate: string;
  endDate: string;
  showSummary: boolean;
  validated: boolean;
  dateErrorMessage: string;
  projectTrees: Array<ProjectsTree>;
  taskValues: Array<string>;
  taskIds: Array<number>;
  filteredProjectTrees: Array<ProjectsTree>;
  viewTaskValues: Array<string>;
}

const dateFormat = 'YYYY-MM-DD';

class ReportsFiltersContainer extends Component<ReportsFiltersContainerProps, ReportsFiltersContainerState> {
  constructor(props: ReportsFiltersContainerProps) {
    super(props);
    this.state = {
      selectedProjectIds: [],
      employeeLookups: [],
      selectedEmployeeIds: [],
      startDate: Moment()
        .startOf('month')
        .format(dateFormat),
      endDate: Moment()
        .endOf('month')
        .format(dateFormat),
      showSummary: false,
      validated: false,
      dateErrorMessage: '',
      projectTrees: [],
      taskValues: [],
      taskIds: [],
      filteredProjectTrees: [],
      viewTaskValues: [],
    };
  }

  componentDidMount() {
    employeesReqHandler.loadEmployees(true, 'empFirstName').then((employees: Employee[]) => {
      const employeeLookups: Array<Lookup> = employees.map((item: Employee) => ({
        value: item.empId,
        text: `${item.empFirstName} ${item.empLastName}`,
      }));
      this.setState({ employeeLookups });
    });

    tasksReqHandler.loadTasks().then((res: Array<Task>) => {
      const allTasks: Array<AllTaskModel> = res.map((item: Task) => ({
        taskId: item.taskId,
        taskName: item.taskName,
        projectId: item.projectId,
        projectName: item.projectName,
        assignedTo: item.assignedTo ? item.assignedTo : 0,
        assignedToName: item.assignedToName ? item.assignedToName : '',
      }));
      this.createProjectsTreeArray(allTasks);
    });
  }

  createProjectsTreeArray = (allTasks: Array<AllTaskModel>): void => {
    const projectObject: { [key: number]: ProjectObjectValue } = {};

    for (const item of allTasks) {
      const key: number = item.projectId;
      const value: ProjectObjectValue = projectObject[key];
      const child: ProjectObjectValueChild = {
        assignedTo: item.assignedTo,
        assignedToName: item.assignedToName,
        taskId: item.taskId,
        taskName: item.taskName,
      };

      if (!value) {
        projectObject[key] = {
          value: 'p-' + key,
          label: item.projectName,
          children: [child],
        };
      } else {
        projectObject[key].children.push(child);
      }
    }

    const projectsArray: Array<any> = Object.values(projectObject).sort((first: ProjectObjectValue, second: ProjectObjectValue) =>
      first.label.localeCompare(second.label)
    );

    for (const item of projectsArray) {
      const employeeObject: { [key: number]: ChildEmployeeOfProjectsTree } = {};
      const childrenProject: Array<any> = item.children;

      for (const childProject of childrenProject) {
        const key: number = childProject.assignedTo;
        const value: ChildEmployeeOfProjectsTree = employeeObject[key];
        const child: ChildTaskOfProjectsTree = {
          value: 't-' + childProject.taskId,
          label: childProject.taskName,
        };

        if (!value) {
          employeeObject[key] = {
            value: 'e-' + childProject.assignedTo,
            label: childProject.assignedToName,
            children: [child],
          };
        } else {
          employeeObject[key].children.push(child);
        }
      }

      const employeeArray: Array<ChildEmployeeOfProjectsTree> = Object.values(
        employeeObject
      ).sort((first: ChildEmployeeOfProjectsTree, second: ChildEmployeeOfProjectsTree) => first.label.localeCompare(second.label));

      item.children = employeeArray;
    }
    this.setState({ projectTrees: projectsArray });
  };

  handleSave = (): void => {
    const filterEmployee: Array<number> = this.state.taskIds.length ? [] : this.state.selectedEmployeeIds;
    const filterProject: Array<number> = this.state.taskIds.length ? [] : this.state.selectedProjectIds;

    this.props.handleSave(
      Moment(this.state.startDate).toISOString(),
      Moment(this.state.endDate)
        .endOf('day')
        .toISOString(),
      filterEmployee,
      filterProject,
      this.state.taskIds,
      this.state.showSummary
    );
    this.setState({ validated: true });
  };

  handleChange = (event: any, field: string): void => {
    switch (field) {
      case 'startDate':
        this.setState(
          {
            startDate: Moment(event).isValid()
              ? Moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId)).format(dateFormat)
              : event,
          },
          this.handleDateValidation
        );
        break;
      case 'endDate':
        this.setState(
          {
            endDate: Moment(event).isValid()
              ? Moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId)).format(dateFormat)
              : event,
          },
          this.handleDateValidation
        );
        break;
      case 'selectedEmployeeIds':
        const oldSelectEmployeeIds: Array<number> = [...this.state.selectedEmployeeIds];
        this.setState(
          {
            selectedEmployeeIds: event.target.value as Array<number>,
          },
          () => {
            this.filteredTree(null, oldSelectEmployeeIds);
          }
        );
        break;
      case 'selectedProjectIds':
        const oldSelectProjectIds: Array<number> = [...this.state.selectedProjectIds];
        this.setState(
          {
            selectedProjectIds: event.target.value as Array<number>,
          },
          () => {
            this.filteredTree(oldSelectProjectIds, null);
          }
        );
        break;
      case 'showSummary':
        this.setState(prevState => ({
          showSummary: !prevState.showSummary,
        }));
        break;
      default:
        break;
    }
  };

  handleDateValidation = (): void => {
    this.setState({
      dateErrorMessage: Moment(this.state.startDate).isAfter(Moment(this.state.endDate))
        ? this.props.t('endTimeShouldBeAfterStartTime')
        : '',
    });
  };

  getProjectLookups = (): Array<Lookup> =>
    this.props.projects.map((project: Project) => ({
      value: project.prjId,
      text: project.isProjectDuplicatedInList ? `${project.prjName} (${project.sowCode})` : project.prjName,
    }));

  onChangeTask = (selectedNodes: Array<TreeNode>, data: Array<ProjectsTree>, viewValue: Array<string>): void => {
    const tasksNumber: Array<number> = [];

    for (const item of selectedNodes) {
      const id: Array<string> = item.value.split('-');

      if (id[0] === 't') {
        tasksNumber.push(+id[1]);
      } else if (id[0] === 'e') {
        const parentIndex: string = item._parent.split('-')[1];
        const children: Array<ChildEmployeeOfProjectsTree> = data[+parentIndex].children;
        const child: ChildEmployeeOfProjectsTree | undefined = children.find((x: ChildEmployeeOfProjectsTree) => x.value === item.value);

        if (child) {
          for (const task of child.children) {
            const taskNumb: Array<string> = task.value.split('-');
            tasksNumber.push(+taskNumb[1]);
          }
        }
      } else if (id[0] === 'p') {
        const project: ProjectsTree | undefined = data.find((x: any) => x.value === item.value);

        if (project) {
          for (const employee of project.children) {
            const childrenEmployee: Array<ChildTaskOfProjectsTree> = employee.children;

            for (const task of childrenEmployee) {
              const taskNumber: Array<string> = task.value.split('-');

              tasksNumber.push(+taskNumber[1]);
            }
          }
        }
      }
    }
    this.setState({ taskIds: tasksNumber, viewTaskValues: viewValue });
  };

  filteredTree = (oldProjects: Array<number> | null, oldEmploys: Array<number> | null): void => {
    const selectedProjectIds: Array<number> = [...this.state.selectedProjectIds];
    const selectedEmployeeIds: Array<number> = [...this.state.selectedEmployeeIds];
    const projectsTree: Array<ProjectsTree> = [...this.state.projectTrees];

    if (oldProjects && oldProjects.length > selectedProjectIds.length) {
      this.unSelectProject(oldProjects, projectsTree);
    }

    if (oldEmploys && oldEmploys.length > selectedEmployeeIds.length) {
      this.unSelectEmployee(oldEmploys, projectsTree);
    }

    if (selectedProjectIds.length > 0 && selectedEmployeeIds.length === 0) {
      const projectsTreeFiltered: Array<ProjectsTree> = [];

      for (const item of selectedProjectIds) {
        const value: string = 'p-' + item;
        const project: Array<ProjectsTree> = projectsTree.filter(x => x.value === value);

        if (project.length) projectsTreeFiltered.push(project[0]);
      }

      this.setState({ filteredProjectTrees: projectsTreeFiltered });
    } else if (selectedProjectIds.length === 0 && selectedEmployeeIds.length > 0) {
      this.filteredSelectedEmployee(selectedEmployeeIds, projectsTree);
    } else if (selectedProjectIds.length > 0 && selectedEmployeeIds.length > 0) {
      const projects: Array<ProjectsTree> = [];

      for (const item of selectedProjectIds) {
        const valueProject: string = 'p-' + item;
        const project: ProjectsTree | undefined = projectsTree.find(x => x.value === valueProject);

        if (project) {
          projects.push(project);
        }

        this.filteredSelectedEmployee(selectedEmployeeIds, projects);
      }
    }
  };

  unSelectProject = (oldProjects: Array<number>, projectsTree: Array<ProjectsTree>): void => {
    const difference: Array<number> = oldProjects.filter((x: any) => !this.state.selectedProjectIds.includes(x));
    const prjCheck: ProjectsTree | undefined = projectsTree.find((x: ProjectsTree) => x.value === `p-${difference[0]}`);
    const viewTaskValue: Array<string> = [...this.state.viewTaskValues];

    if (prjCheck) {
      if (prjCheck.checked) {
        prjCheck.checked = false;
        const indexOff: number = viewTaskValue.indexOf(prjCheck.label);
        if (indexOff >= 0) {
          viewTaskValue.splice(indexOff, 1);
        }
      }

      prjCheck.expanded = false;

      const childPr: Array<ChildEmployeeOfProjectsTree> = prjCheck.children;

      childPr.forEach((employee: ChildEmployeeOfProjectsTree) => {
        if (employee.checked) {
          employee.checked = false;
          const indexOff: number = viewTaskValue.indexOf(employee.label);
          if (indexOff >= 0) {
            viewTaskValue.splice(indexOff, 1);
          }
        }

        employee.expanded = false;

        const children: Array<ChildTaskOfProjectsTree> = employee.children;
        children.forEach((task: ChildTaskOfProjectsTree) => {
          if (task.checked) {
            task.checked = false;
            const indexOff: number = viewTaskValue.indexOf(task.label);
            if (indexOff >= 0) {
              viewTaskValue.splice(indexOff, 1);
            }
          }
        });
      });

      this.setState({ viewTaskValues: viewTaskValue });
      prjCheck.expanded = false;
    }
  };

  unSelectEmployee = (oldEmploys: Array<number>, projectsTree: Array<ProjectsTree>): void => {
    const difference: Array<number> = oldEmploys.filter((x: number) => !this.state.selectedEmployeeIds.includes(x));
    const viewTaskValue: Array<string> = [...this.state.viewTaskValues];

    projectsTree.forEach((project: ProjectsTree) => {
      const employs: Array<ChildEmployeeOfProjectsTree> = project.children;
      const empCheck: ChildEmployeeOfProjectsTree | undefined = employs.find(
        (x: ChildEmployeeOfProjectsTree) => x.value === `e-${difference[0]}`
      );

      if (empCheck) {
        if (empCheck.checked) {
          empCheck.checked = false;
          const indexOff: number = viewTaskValue.indexOf(empCheck.label);
          if (indexOff >= 0) {
            viewTaskValue.splice(indexOff, 1);
          }
        }

        const child: Array<ChildTaskOfProjectsTree> = empCheck.children;

        child.forEach((task: ChildTaskOfProjectsTree) => {
          task.checked = false;
          const indexOff: number = viewTaskValue.indexOf(task.label);
          if (indexOff >= 0) {
            viewTaskValue.splice(indexOff, 1);
          }
        });

        empCheck.expanded = false;

        this.setViewValue(viewTaskValue);
      }
      project.expanded = this.handleExpanded(project);
      project.checked = this.handleChecked(project);
    });
  };

  filteredSelectedEmployee = (selectedEmployeeIds: Array<number>, projectsArray: Array<ProjectsTree>): void => {
    const projectsTreeFiltered: Array<ProjectsTree> = [];
    const filterSet: Set<ProjectsTree> = new Set();

    for (const item of selectedEmployeeIds) {
      const value: string = 'e-' + item;

      projectsArray.forEach((item: ProjectsTree) => {
        const children: ChildEmployeeOfProjectsTree | undefined = item.children.find(
          (employee: ChildEmployeeOfProjectsTree) => employee.value === value
        );

        if (children) {
          filterSet.add(item);
        }
      });
    }

    filterSet.forEach((item: ProjectsTree) => {
      const project: ProjectsTree = { ...item };
      const employees: Array<ChildEmployeeOfProjectsTree> = [];
      const child: Array<ChildEmployeeOfProjectsTree> = [...project.children];

      for (const employee of selectedEmployeeIds) {
        const filteredEmployee: ChildEmployeeOfProjectsTree | undefined = child.find(x => x.value === 'e-' + employee);
        if (filteredEmployee) {
          employees.push(filteredEmployee);
        }
      }

      project.children = employees;
      projectsTreeFiltered.push(project);
    });

    projectsTreeFiltered.forEach((project: ProjectsTree) => {
      const employees: Array<ChildEmployeeOfProjectsTree> = project.children;

      for (const employee of employees) {
        employee.checked = this.handleChecked(employee);
        employee.expanded = this.handleExpanded(employee);
      }

      project.checked = this.handleChecked(project);
      project.expanded = this.handleExpanded(project);
    });

    this.setState({ filteredProjectTrees: projectsTreeFiltered });
  };

  setViewValue = (viewTaskValue: Array<string>): void => {
    this.setState({ viewTaskValues: viewTaskValue });
  };

  handleExpanded = (parent: ProjectsTree | ChildEmployeeOfProjectsTree): boolean => {
    if (parent.checked) {
      return false;
    }

    const childrenChecked: Array<ChildEmployeeOfProjectsTree | ChildTaskOfProjectsTree> = parent.children.filter(child => child.checked);
    const childrenExpanded: Array<ChildEmployeeOfProjectsTree | ChildTaskOfProjectsTree> = parent.children.filter(
      child => (child as ChildEmployeeOfProjectsTree).expanded
    );
    return !!childrenChecked.length || !!childrenExpanded.length;
  };

  handleChecked = (parent: ProjectsTree | ChildEmployeeOfProjectsTree): boolean =>
    parent.children.filter(child => child.checked).length === parent.children.length;

  render() {
    if (this.props.isLoading) {
      return (
        <Paper className={this.props.classes.loadingPaper}>
          <div className={this.props.classes.loaderContainer}>
            <CircularProgress color="primary" className="centeredProgress" />
          </div>
        </Paper>
      );
    }
    return (
      <ReportsFilters
        projectLookups={this.getProjectLookups()}
        selectedProjectIds={this.state.selectedProjectIds}
        employeeLookups={this.state.employeeLookups}
        selectedEmployeeIds={this.state.selectedEmployeeIds}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        showSummary={this.state.showSummary}
        validated={this.state.validated}
        dateErrorMessage={this.state.dateErrorMessage}
        onChange={this.handleChange}
        onSave={this.handleSave}
        pdfExportUrl={reportsPdfExportUrl(
          Moment(this.state.startDate).toISOString(),
          Moment(this.state.endDate)
            .endOf('day')
            .toISOString(),
          this.state.taskIds.length ? [] : this.state.selectedEmployeeIds,
          this.state.taskIds.length ? [] : this.state.selectedProjectIds,
          this.state.taskIds
        )}
        csvExportUrl={reportsCsvExportUrl(
          Moment(this.state.startDate).toISOString(),
          Moment(this.state.endDate)
            .endOf('day')
            .toISOString(),
          this.state.taskIds.length ? [] : this.state.selectedEmployeeIds,
          this.state.taskIds.length ? [] : this.state.selectedProjectIds,
          this.state.taskIds
        )}
        onDateValidation={this.handleDateValidation}
        tasksDropdown={this.state.filteredProjectTrees}
        onChangeTask={this.onChangeTask}
        setViewValue={this.setViewValue}
        viewTaskValue={this.state.viewTaskValues}
        disabled={!this.state.selectedProjectIds.length && !this.state.selectedEmployeeIds.length}
        handleExpanded={this.handleExpanded}
      />
    );
  }
}

const mapStateToProps = (state: SelectUserState) => ({ empId: getUser(state).empId, timeZoneId: getTimezone(state) });

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(ReportsFiltersContainer)));
