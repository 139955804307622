import React, { Component } from 'react';
import { reportOvertimeHandler } from '../../api/reportsRemote';
import ReportOvertimeFiltersContainer from './reportOvertimeFilters/ReportOvertimeFiltersContainer';
import ReportOvertimeList from './reportOvertimeList/ReportOvertimeList';
import projectsHandler from '../../api/projectsRemote';
import Project from '../../models/Project';
import moment from 'moment';
import OvertimeReport from '../../models/OvertimeReport';
import { WithTranslation, withTranslation } from 'react-i18next';
import SummaryOvertimeList from './reportOvertimeList/SummaryOvertimeList';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { IconButton, Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'end',
    },
    buttonWrapper: {
      margin: '15px 0px',
      '& > .iconButton': {
        padding: 0,
        '& .icon': {
          color: '#8E8C92',
          borderRadius: '50%',
          width: '25px',
          height: '25px',
        },
      },
      '& > .textStyle': {
        textTransform: 'initial',
        color: '#8E8C92',
        fontSize: '14px',
        marginRight: '10px',
      },
    },
  });

interface ReportOvertimeContainerProps extends WithStyles<typeof styles>, WithTranslation {}

interface ReportOvertimeContainerState {
  isLoading: boolean;
  reports: Array<OvertimeReport>;
  employeesReports: Array<OvertimeReport>;
  showSummary: boolean;
  expandReportSections: boolean;
  projects: Array<Project>;
  hideEmptyValues: boolean;
}

class ReportOvertimeContainer extends Component<ReportOvertimeContainerProps, ReportOvertimeContainerState> {
  constructor(props: ReportOvertimeContainerProps) {
    super(props);
    this.state = {
      isLoading: true,
      reports: [],
      employeesReports: [],
      showSummary: false,
      expandReportSections: false,
      projects: [],
      hideEmptyValues: false,
    };
  }

  componentDidMount() {
    projectsHandler.loadProjects().then(projects => {
      this.setState({ projects, isLoading: false });
    });
  }

  handleSave = (startDate: string, endDate: string, empIds: Array<number>, splitByMonth: boolean) => {
    this.setState({ isLoading: true, employeesReports: [] }, () => {
      reportOvertimeHandler.loadReportOvertime(startDate, endDate, empIds, splitByMonth).then(reports => {
        this.setState({
          reports,
          employeesReports: reports.filter(report => (this.state.hideEmptyValues ? report.overtime !== 0 : true)),
          isLoading: false,
        });
      });
    });
  };

  toggleHideEmptyValues = () => {
    this.setState({ isLoading: true, employeesReports: [], hideEmptyValues: !this.state.hideEmptyValues }, () => {
      this.setState({
        employeesReports: this.state.reports.filter(report => (this.state.hideEmptyValues ? report.overtime !== 0 : true)),
        isLoading: false,
      });
    });
  };

  filterEmployeesReportItems = (items: Array<any>): Array<any> => {
    if (items.length > 0) {
      let lastItem = items[0];
      lastItem.backgroundColor = 'white';
      items.forEach(x => {
        if (moment(lastItem.wtStartTime).isSame(moment(x.wtStartTime), 'day')) {
          x.backgroundColor = lastItem.backgroundColor;
        } else {
          x.backgroundColor = lastItem.backgroundColor === 'white' ? '#F3F3F4' : 'white';
        }
        lastItem = x;
      });
    }
    return items;
  };

  render() {
    let reports = null;
    if (this.state.employeesReports && this.state.employeesReports.length) {
      reports = (
        <div>
          {this.state.employeesReports.map(report => (
            <ReportOvertimeList
              key={`${report.empFullName}_${report.startTime}`}
              report={report}
              isLoading={this.state.isLoading}
              defaultExpanded={this.state.expandReportSections}
            />
          ))}
        </div>
      );
    }

    return (
      <div className="pageWrapper">
        <ReportOvertimeFiltersContainer handleSave={this.handleSave} projects={this.state.projects} isLoading={this.state.isLoading} />
        {reports ? (
          <div className={this.props.classes.buttonsWrapper}>
            <div className={this.props.classes.buttonWrapper}>
              <span className="textStyle">
                {this.state.hideEmptyValues ? this.props.t('showZeroOvertime') : this.props.t('hideZeroOvertime')}
              </span>
              <IconButton className="iconButton" onClick={() => this.toggleHideEmptyValues()}>
                {this.state.hideEmptyValues ? <VisibilityIcon className="icon" /> : <VisibilityOffIcon className="icon" />}
              </IconButton>
            </div>
          </div>
        ) : null}
        {reports}
        <SummaryOvertimeList reports={this.state.employeesReports} showSummary={this.state.showSummary} />
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(ReportOvertimeContainer));
