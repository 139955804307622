import Moment from 'moment';
import moment from 'moment-timezone';

export const parseDate = (value: Date | number | null | string): Date | null => {
  if (value == null) {
    return null;
  }
  if (value instanceof Date) {
    return value as Date;
  }
  return new Date(value);
};

export const getDuration = (startDate: Date | number | null | string, endDate: Date | number | null | string): string | null => {
  let duration: string | null = null;
  let parsedStartDate: Date | null = parseDate(startDate);
  let parsedEndDate: Date | null = parseDate(endDate);
  if (parsedStartDate && parsedEndDate) {
    let minutes = totalMinutesBetween(parsedStartDate, parsedEndDate);
    if (minutes >= 0) {
      duration = formatToHoursAndMinutes(minutes);
    }
  }
  return duration;
};

export const getDurationIncludingSeconds = (
  startDate: Date | number | null | string,
  endDate: Date | number | null | string
): string | null => {
  let duration: string | null = null;
  let parsedStartDate: Date | null = parseDate(startDate);
  let parsedEndDate: Date | null = parseDate(endDate);
  if (parsedStartDate && parsedEndDate) {
    let seconds = totalSecondsBetween(parsedStartDate, parsedEndDate);
    if (seconds >= 0) {
      duration = formatToHoursMinutesAndSeconds(seconds);
    }
  }
  return duration;
};

export const totalSencondsSinceMidnight = (value: Date | string): number => {
  let midnight = Moment(value)
    .hour(0)
    .minute(0)
    .second(0);
  return Moment(value).diff(midnight, 'seconds');
};

export const totalSecondsBetween = (startDate: Date | string, endDate: Date | string): number => {
  return Moment(endDate).diff(startDate, 'seconds');
};

export const totalMinutesBetween = (startDate: Date | string, endDate: Date | string): number => {
  return Moment(endDate).diff(startDate, 'minutes');
};

export const formatToHoursAndMinutes = (minutes: number): string => {
  return (
    Math.floor(minutes / 60)
      .toString()
      .padStart(2, '0') +
    ':' +
    (minutes % 60).toString().padStart(2, '0')
  );
};

export const formatToHoursMinutesAndSeconds = (seconds: number): string => {
  return (
    Math.floor(seconds / 3600)
      .toString()
      .padStart(2, '0') +
    ':' +
    Math.floor((seconds / 60) % 60)
      .toString()
      .padStart(2, '0') +
    '.' +
    (seconds % 60).toString().padStart(2, '0')
  );
};

export const formatDate = (value: number | string, format: string) => {
  return value ? Moment(value).format(format) : '';
};

export const formatToHoursDecimal = (minutes: number): number => {
  let hours = minutes / 60;
  let result = Math.round(hours * 100) / 100;
  if (!result) {
    return 0;
  }
  return result;
};

export const formatNumber = (minutes: number, digits: number): string => {
  return formatToHoursDecimal(minutes).toFixed(digits);
};

export const getFirstDayLastMonth = (): string => {
  return (
    Moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD') + 'T00:00:00.000Z'
  );
};
export const getLastDayLastMonth = (): string => {
  return (
    Moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD') + 'T23:59:59.000Z'
  );
};
export const getFirstDayThisMonth = (): string => {
  return (
    Moment()
      .startOf('month')
      .format('YYYY-MM-DD') + 'T00:00:00.000Z'
  );
};
export const getCurrentDay = (): string => {
  return Moment().format('YYYY-MM-DD') + 'T23:59:59.000Z';
};
export const getFirstDayNextMonth = (): string => {
  return (
    Moment()
      .add(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD') + 'T00:00:00.000Z'
  );
};

export const convertBrowserTimeToApiFormat = (time: Moment.MomentInput, timeZoneId: string): string => {
  const clientTimezoneOffset = moment(time)
    .tz(moment.tz.guess(true))
    .utcOffset();
  const loggedInUserTimezoneOffset = moment(time)
    .tz(timeZoneId)
    .utcOffset();
  return moment(time)
    .add(clientTimezoneOffset - loggedInUserTimezoneOffset, 'minutes')
    .toISOString();
};

export const convertApiFormatToBrowserTime = (time: Moment.MomentInput, timeZoneId: string): string => {
  const clientTimezoneOffset = moment(time)
    .tz(moment.tz.guess(true))
    .utcOffset();
  const loggedInUserTimezoneOffset = moment(time)
    .tz(timeZoneId)
    .utcOffset();
  return moment(time)
    .add(loggedInUserTimezoneOffset - clientTimezoneOffset, 'minutes')
    .toISOString();
};

export const formatTimeToISO = (time: Moment.MomentInput, timeZone: string): string => {
  const timeMoment = moment(time);
  return moment(timeMoment.subtract(timeMoment.tz(timeZone).utcOffset(), 'minutes')).toISOString();
};

export const resetHours = (time: number | Date): Date => {
  let result: Date = new Date(time);
  result.setHours(0);
  result.setMinutes(0);
  result.setSeconds(0);
  result.setMilliseconds(0);
  return result;
};
