import { createStyles, Paper, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import appGroupsReqHandler from '../../api/appGroupsRemote';
import formViewControlsReqHandler from '../../api/formViewControlsRemote';
import formViewsReqHandler from '../../api/formViewsRemote';
import AppGroup from '../../models/AppGroup';
import { DialogMode } from '../../models/DialogMode';
import { FormView } from '../../models/FormView';
import { FormViewControl } from '../../models/FormViewControl';
import { clearAppGroupsState, storeAppGroup } from '../../store/actions/appGroupActions';
import { AppGroupActionType } from '../../store/actions/appGroupActionType';
import AddNewButton from '../common/AddNewButton';
import InfoSnackbar from '../infoSnackbar/InfoSnackbar';
import AppGroupDialogContainer from './appGroupsDialog/AppGroupDialogContainer';
import AppGroupsList from './appGroupsList/AppGroupsList';

const styles = (theme: Theme) =>
  createStyles({
    paperWithTable: {
      '& thead tr th': {
        borderRadius: 4,
      },
    },
    headerTitle: {
      margin: '15px 35px',
      color: '#333333',
      fontWeight: 600,
    },
  });

interface AppGroupsContainerProps extends WithStyles<typeof styles>, WithTranslation {
  canReadAppGroups: false;
  canWriteAppGroups: false;
  storeAppGroup: (appGroup: AppGroup) => { type: AppGroupActionType; payload: { appGroup: AppGroup } };
  clearAppGroupsState: () => { type: AppGroupActionType };
}

interface AppGroupsContainerState {
  appGroups: Array<AppGroup>;
  formViews: Array<FormView>;
  formViewControls: Array<FormViewControl>;
  isLoading: boolean;
  isDialogOpen: boolean;
  dialogMode: DialogMode;
  snackbarText: string;
}

class AppGroupsContainer extends Component<AppGroupsContainerProps, AppGroupsContainerState> {
  state: AppGroupsContainerState = {
    appGroups: [],
    formViews: [],
    formViewControls: [],
    isLoading: true,
    isDialogOpen: false,
    dialogMode: DialogMode.CREATE,
    snackbarText: '',
  };

  componentDidMount() {
    if (this.props.canReadAppGroups) {
      this.refreshData();
    }
  }

  componentDidUpdate(prevProps: AppGroupsContainerProps) {
    if (prevProps.canReadAppGroups !== this.props.canReadAppGroups) {
      this.refreshData();
    }
  }

  refreshData() {
    this.setState({ isLoading: true, isDialogOpen: false }, () => {
      Promise.all([
        appGroupsReqHandler.loadAppGroups(),
        formViewControlsReqHandler.loadFormViewControls(),
        formViewsReqHandler.loadFormViews(),
      ]).then(([appGroups, formViewControls, formViews]: [AppGroup[], FormViewControl[], FormView[]]) => {
        const sortFormViews : FormView[] = formViews.sort((a, b) => (a.formViewCd < b.formViewCd) ? -1: 1);
        this.setState({
          appGroups,
          formViewControls,
          formViews: sortFormViews,
          isLoading: false,
        });
      });
    });
  }

  setSnackbarText = (snackbarText: string) => {
    this.setState({ snackbarText });
  };

  render() {
    return (
      <div className="pageWrapper">
        {this.props.canWriteAppGroups && (
          <AddNewButton
            onClick={() => {
              this.setState({ isDialogOpen: true, dialogMode: DialogMode.CREATE });
            }}
            text={this.props.t('newAppGroupTitle')}
          />
        )}
        <Typography className={this.props.classes.headerTitle} variant="h6">
          {this.props.t('appGroupFormViewPermissions')}
        </Typography>
        <Paper className={this.props.classes.paperWithTable}>
          <AppGroupsList
            canReadAppGroups={this.props.canReadAppGroups}
            canWriteAppGroups={this.props.canWriteAppGroups}
            isLoading={this.state.isLoading}
            appGroups={this.state.appGroups}
            formViews={this.state.formViews}
            formViewControls={this.state.formViewControls}
            onDetailsClick={
              this.props.canReadAppGroups
                ? (event: any, data: AppGroup) => {
                    this.props.storeAppGroup(data);
                    return this.setState({ dialogMode: DialogMode.VIEW, isDialogOpen: true });
                  }
                : () => {}
            }
            onEditClick={
              this.props.canWriteAppGroups
                ? (event: any, data: AppGroup) => {
                    this.props.storeAppGroup(data);
                    return this.setState({ dialogMode: DialogMode.UPDATE, isDialogOpen: true });
                  }
                : () => {}
            }
          />
          <InfoSnackbar
            open={!!this.state.snackbarText}
            onClose={() => this.setState({ snackbarText: '' })}
            text={this.state.snackbarText ? this.state.snackbarText : ''}
          />
        </Paper>
        <AppGroupDialogContainer
          isOpen={this.state.isDialogOpen}
          onClose={() => {
            this.props.clearAppGroupsState();
            this.setState({ isDialogOpen: false });
            this.refreshData();
          }}
          onSaved={() => {
            this.props.clearAppGroupsState();
            this.refreshData();
          }}
          dialogMode={this.state.dialogMode}
          setSnackbarText={this.setSnackbarText}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      storeAppGroup,
      clearAppGroupsState,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withTranslation()(withStyles(styles)(AppGroupsContainer)));
