import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import WorkItem from '../../../../models/WorkItem';
import { compare } from '../../../../utils/string';

const ProjectColumn = (translation: UseTranslationResponse): Column<WorkItem> => ({
  title: translation.t('project'),
  field: 'taskProjectName',
  customSort: (first: WorkItem, second: WorkItem) => compare(first.taskProjectName, second.taskProjectName),
  cellStyle: { minWidth: 100, width: '20%' },
});

export default ProjectColumn;
