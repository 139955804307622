class Step {
  static readonly PROJECT = new Step(0, 'project');
  static readonly APP_GROUPS = new Step(1, 'dataAccess');
  static readonly TASKS = new Step(2, 'tasks');

  static readonly DETAILS = new Step(0, 'customerDetails');
  static readonly APP_GROUP = new Step(1, 'dataAccess');

  static readonly EMPLOYEE_DETAIL = new Step(0, 'employeeDetails');
  static readonly EMPLOYEE_DATA_ACCESS = new Step(1, 'dataAccess');
  static readonly EMPLOYEE_APP_GROUPS = new Step(2, 'appGroups');

  private constructor(public readonly id: number, public readonly name: string) {}
}

export enum ProjectStep {
  PROJECT = Step.PROJECT.id,
  APP_GROUPS = Step.APP_GROUPS.id,
  TASKS = Step.TASKS.id,
}

export const projectSteps = (): Array<string> => {
  return [Step.PROJECT.name, Step.APP_GROUPS.name, Step.TASKS.name];
};

export const projectStepsWithoutAppGroups = (): Array<string> => {
  return [Step.PROJECT.name, Step.TASKS.name];
};

export enum CustomerStep {
  DETAILS = Step.DETAILS.id,
  APP_GROUP = Step.APP_GROUP.id,
}

export const customerSteps = (): Array<string> => {
  return [Step.DETAILS.name, Step.APP_GROUP.name];
};

export enum EmployeeStep {
  DETAILS = Step.EMPLOYEE_DETAIL.id,
  DATA_ACCESS = Step.EMPLOYEE_DATA_ACCESS.id,
  APP_GROUPS = Step.EMPLOYEE_APP_GROUPS.id,
}

export const employeesSteps = (): Array<string> => {
  return [Step.EMPLOYEE_DETAIL.name, Step.EMPLOYEE_DATA_ACCESS.name, Step.EMPLOYEE_APP_GROUPS.name];
};
