import CalendarEvent from '../models/CalendarEvent';
import { StatusValues } from '../models/StatusValues';

const CALENDAR_EVENT_ITEM: CalendarEvent = {
  empCalEventReqId: -1,
  rqStartDate: undefined,
  rqEndDate: undefined,
  statusId: StatusValues.PENDING,
  empId:0,
  eventTypeId: 0,
  eventTypeName : '',
  daysCount: -1,
  notes: '',
  approvedBy: -1,
  empName: '',
  vacationLeft: null,
  hasFiles: null,
  vacTypeName: '',
  statusName: ''
}

export {
  CALENDAR_EVENT_ITEM
}
