import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import saraColorPickerStyles from './saraColorPicker.module.css';

interface SaraColorPickerProps extends WithTranslation {
  color: string | undefined;
  handleChange: (event: any, field: string) => void;
  readOnly: boolean;
}

interface SaraColorPickerState {
  displayColorPicker: boolean;
}

class SaraColorPicker extends Component<SaraColorPickerProps, SaraColorPickerState> {
  state: SaraColorPickerState = {
    displayColorPicker: false,
  };

  openColorPicker = (): void => {
    this.setState({ displayColorPicker: true });
  };

  closeColorPicker = (): void => {
    this.setState({ displayColorPicker: false });
  };

  render() {
    const color = this.props.color ? this.props.color : undefined;
    if (this.props.readOnly) {
      return (
        <div className={saraColorPickerStyles.wrapper}>
          <div className={saraColorPickerStyles.colorLabel}>{this.props.t('color')}</div>
          <div className={saraColorPickerStyles.colorBoxWrapper}>
            <div
              className={saraColorPickerStyles.colorBox}
              style={{ backgroundColor: this.props.color ? '#' + this.props.color : 'white' }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className={saraColorPickerStyles.wrapper}>
          <div className={saraColorPickerStyles.colorLabel}>{this.props.t('pickColor')}</div>
          <div className={saraColorPickerStyles.colorBoxWrapper} onClick={this.openColorPicker}>
            <div
              className={saraColorPickerStyles.colorBox}
              style={{ backgroundColor: this.props.color ? '#' + this.props.color : 'white' }}
            />
          </div>
          {this.state.displayColorPicker && (
            <div className={saraColorPickerStyles.popover}>
              <div className={saraColorPickerStyles.cover} onClick={this.closeColorPicker} />
              <SketchPicker color={color} onChange={event => this.props.handleChange(event, 'prjColor')} />
            </div>
          )}
        </div>
      );
    }
  }
}

export default withTranslation()(SaraColorPicker);
