import Moment from 'moment';
import React, { Component, Fragment, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApiErrorMessage } from '../../../api/apiMessage';
import { ApiResult } from '../../../api/apiResult';
import customersReqHandler from '../../../api/customersRemote';
import employeesReqHandler from '../../../api/employeesRemote';
import prjAppGroupOverseeReqHandler from '../../../api/prjAppGroupOverseeRemote';
import projectsReqHandler, { checkProjectSowCode } from '../../../api/projectsRemote';
import projectStatusesReqHandler from '../../../api/projectStatusesRemote';
import AppGroup from '../../../models/AppGroup';
import CreateProject from '../../../models/CreateProject';
import Customer from '../../../models/Customer';
import { DialogMode } from '../../../models/DialogMode';
import Employee from '../../../models/Employee';
import PrjAppGroupOversee from '../../../models/PrjAppGroupOversee';
import Project from '../../../models/Project';
import ProjectStatus from '../../../models/ProjectStatus';
import { ProjectStatusCondition } from '../../../models/ProjectStatusCondition';
import { ProjectStep, projectSteps, projectStepsWithoutAppGroups } from '../../../models/Step';
import Task from '../../../models/Task';
import TaskModel from '../../../models/TaskModel';
import UpdateProject from '../../../models/UpdateProject';
import User from '../../../models/User';
import { clearTaskDraft } from '../../../store/actions/taskDraftActions';
import { getTimezone, getUser } from '../../../store/selectors/userSelectors';
import { convertBrowserTimeToApiFormat } from '../../../utils/dateTime';
import InfoSnackbar from '../../infoSnackbar/InfoSnackbar';
import NotificationDialog from '../../notification/NotificationDialog';
import { Lookup } from '../../saraSelect/SaraSelect';
import AppGroupAccessContainer from './projectAppGroups/AppGroupAccessContainer';
import ProjectDescription from './projectDescription/ProjectDescription';
import ProjectDialog from './ProjectDialog';
import TasksContainer, { TaskAction } from './projectTasks/TasksContainer';
import { RootState } from '../../../store/reducers/rootReducer';

const steps3: Array<string> = projectSteps();
const steps2: Array<string> = projectStepsWithoutAppGroups();

type ProjectRequests = TaskModel | PrjAppGroupOversee | null;

const emptyProjectData: Project = {
  prjId: 0,
  sowCode: '',
  prjName: '',
  prjDescr: '',
  prjLead: 0,
  customerId: 0,
  statusCd: ProjectStatusCondition.ACTIVE,
};

interface ProjectDialogProps extends WithTranslation {
  isOpen: boolean;
  project: Project;
  onClose: () => void;
  showSnackbar: () => void;
  dialogMode: DialogMode;
  onUpdate: (project: Project) => void;
  canReadProject: boolean;
  canWriteProject: boolean;
  user: User;
  appGroupAccessId: number;
  timeZoneId: string;
  clearTaskDraft: () => void;
  taskDraft: Task;
}

interface ProjectDialogState {
  activeStep: number;
  project: Project;
  customers: Array<Customer>;
  statuses: Array<ProjectStatus>;
  employees: Array<Employee>;
  groups: Array<AppGroup>;
  groupsHaveChanges: boolean;
  initialGroups: Array<AppGroup>;
  tasks: Array<Task>;
  tasksHaveChanges: boolean;
  showWarning: boolean;
  snackbarText?: string;
  validated: boolean;
  steps: Array<string>;
  isValidSowCode: boolean;
  initialSowCode: string;
  isOpenNotification: boolean;
  notificationTitle: string;
  notificationContent: string;
}

class ProjectDialogContainer extends Component<ProjectDialogProps, ProjectDialogState> {
  state: ProjectDialogState = {
    activeStep: 0,
    project: emptyProjectData,
    customers: [],
    statuses: [],
    employees: [],
    groups: [],
    groupsHaveChanges: false,
    initialGroups: [],
    tasks: [],
    tasksHaveChanges: false,
    showWarning: false,
    validated: false,
    steps: [],
    isValidSowCode: false,
    initialSowCode: '',
    isOpenNotification: false,
    notificationTitle: '',
    notificationContent: '',
  };

  componentDidMount() {
    this.loadCustomers();
    this.loadProjectStatuses();
    this.loadEmployees();
    this.loadSteps();
  }

  componentDidUpdate(prevProps: ProjectDialogProps) {
    if (prevProps !== this.props) {
      this.loadSteps();
      this.setState({ initialSowCode: this.props.project.sowCode });
    }
  }

  static getDerivedStateFromProps(props: ProjectDialogProps, state: ProjectDialogState) {
    if (props.project && (!state.project || !state.project.prjId) && props.project.prjId > 0) {
      return {
        ...state,
        activeStep: props.dialogMode === DialogMode.UPDATE ? ProjectStep.TASKS : state.activeStep,
        project: {
          ...props.project,
        },
      };
    } else {
      return state;
    }
  }

  loadCustomers = (): void => {
    customersReqHandler.loadCustomers().then((resp: Array<Customer>) => {
      this.setState({ customers: resp });
    });
  };

  loadProjectStatuses = (): void => {
    projectStatusesReqHandler.loadProjectStatuses().then((resp: Array<ProjectStatus>) => {
      this.setState({ statuses: resp });
    });
  };

  loadEmployees = (): void => {
    employeesReqHandler.loadEmployees(false, 'empFirstName').then((resp: Array<Employee>) => {
      this.setState({ employees: resp });
    });
  };

  loadSteps = (): void => {
    let steps: Array<string> = steps2;
    switch (this.props.appGroupAccessId) {
      case 1:
        if (this.props.dialogMode !== DialogMode.CREATE) steps = steps3;
        break;
      case 2:
        steps = steps3;
        break;
    }
    this.setState({ steps });
  };

  addAppGroup = (appGroup: AppGroup, inital?: boolean): void => {
    const groups = [...this.state.groups];
    groups.push(appGroup);
    if (inital) {
      this.setState({ groups, initialGroups: groups });
    } else {
      this.setState({ groups, groupsHaveChanges: true });
    }
  };

  removeAppGroup = (appGroup: AppGroup): void => {
    let groups = [...this.state.groups];
    groups = groups.filter(group => group.appGroupId !== appGroup.appGroupId);
    this.setState({ groups, groupsHaveChanges: true });
  };

  getStepContent = (step: number) => {
    switch (step) {
      case ProjectStep.PROJECT:
        let defaultPrjLead: Lookup | undefined;
        let defaultCustomer: Lookup | undefined;
        if (this.props.project.prjLead && this.props.project.prjLeadFirstName && this.props.project.prjLeadLastName) {
          defaultPrjLead = {
            value: this.props.project.prjLead,
            text: `${this.props.project.prjLeadFirstName} ${this.props.project.prjLeadLastName}`,
          };
        }
        if (this.props.project.customerId && this.props.project.customerName) {
          defaultCustomer = {
            value: this.props.project.customerId,
            text: this.props.project.customerName,
          };
        }
        return (
          <ProjectDescription
            project={this.state.project}
            handleChange={this.handleChange}
            customers={this.state.customers}
            statuses={this.state.statuses}
            employees={this.state.employees}
            validated={this.state.validated}
            readonly={this.props.canReadProject && !this.props.canWriteProject}
            onBlurSow={this.onBlurSow}
            isValidSowCode={this.state.isValidSowCode}
            defaultPrjLead={defaultPrjLead}
            defaultCustomer={defaultCustomer}
          />
        );
      case ProjectStep.APP_GROUPS:
        return (
          <AppGroupAccessContainer
            dialogMode={this.props.dialogMode}
            project={this.state.project}
            appGroups={this.state.groups}
            onError={this.handleError}
            addAppGroup={this.addAppGroup}
            removeAppGroup={this.removeAppGroup}
            appGroupAccessId={this.props.appGroupAccessId}
          />
        );
      case ProjectStep.TASKS:
        return (
          <TasksContainer
            project={this.state.project}
            employees={this.state.employees}
            setTasks={this.setTasks}
            tasks={this.state.tasks}
            onError={this.handleError}
            canReadTask={this.props.canReadProject}
            canWriteTask={this.props.canWriteProject}
            dialogMode={this.props.dialogMode}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  setTasks = (tasks: Array<Task>, showSnackbar: boolean, tasksHaveChanges: boolean, taskAction?: TaskAction): void => {
    if (showSnackbar) {
      this.setState({
        tasks,
        tasksHaveChanges,
        snackbarText:
          taskAction === TaskAction.created
            ? this.props.t('taskCreatedSuccessfully')
            : taskAction === TaskAction.updated
            ? this.props.t('taskUpdatedSuccessfully')
            : this.props.t('taskDeletedSuccessfully'),
      });
    } else {
      this.setState({ tasks, tasksHaveChanges });
    }
  };

  handleChange = (event: any, field: string) => {
    const project: Project = { ...this.state.project };
    switch (field) {
      case 'prjStartDate':
        project.prjStartDate = Moment(event).isValid()
          ? Moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId)).format('YYYY-MM-DD')
          : event;
        break;
      case 'prjEndDate':
        project.prjEndDate = Moment(event).isValid()
          ? Moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId)).format('YYYY-MM-DD')
          : event;
        break;
      case 'sowCode':
        this.setState({ isValidSowCode: false });
        project.sowCode = event.target.value;
        break;
      case 'prjName':
        project.prjName = event.target.value;
        break;
      case 'prjDescr':
        project.prjDescr = event.target.value;
        break;
      case 'customerId':
        project.customerId = event.target.value;
        const customer: Customer | undefined = this.state.customers.find((c: Customer) => c.customerId === event.target.value);
        if (customer) {
          project.customerName = customer.customerName;
        }
        break;
      case 'statusCd':
        project.statusCd = event.target.value;
        break;
      case 'prjLead':
        project.prjLead = Number(event.target.value);
        break;
      case 'prjColor':
        project.prjColor = event.hex ? event.hex.replace('#', '') : '';
        break;
      default:
        break;
    }
    this.setState({ project, showWarning: false });
  };

  disableNext = (): boolean => {
    if (this.state.activeStep === ProjectStep.PROJECT) {
      return !this.isValid();
    }
    return false;
  };

  getUpdateProjectModel = (project: Project): UpdateProject => {
    return {
      customerId: project.customerId,
      customerName: project.customerName,
      prjLead: project.prjLead,
      prjName: project.prjName,
      sowCode: project.sowCode,
      prjDescr: project.prjDescr,
      prjEndDate: project.prjEndDate,
      prjStartDate: project.prjStartDate,
      statusCd: project.statusCd,
      prjColor: project.prjColor,
    };
  };

  handleNext = (event?: any, targetStep?: number) => {
    if (this.state.activeStep === 0 && this.state.project.sowCode && this.state.project.sowCode !== this.state.initialSowCode) {
      checkProjectSowCode(this.state.project.sowCode).then((res: any) => {
        if (res) {
          this.setState({ isValidSowCode: true });
        }
      });
    }
    let activeStep: number = this.state.activeStep;
    const isValid = this.isValid();
    const hasChanges = this.projectHasChanges();

    if (this.props.dialogMode === DialogMode.UPDATE) {
      switch (activeStep) {
        case ProjectStep.PROJECT:
          isValid &&
            hasChanges &&
            projectsReqHandler
              .updateProject(this.state.project.prjId, this.getUpdateProjectModel(this.state.project))
              .then(resp => {
                const updatedProject: Project = {
                  prjId: resp.prjId,
                  prjStartDate: resp.prjStartDate,
                  prjEndDate: resp.prjEndDate,
                  prjName: resp.prjName,
                  prjDescr: resp.prjDescr,
                  customerId: resp.customerId,
                  customerName: resp.customerName,
                  sowCode: resp.sowCode,
                  prjLead: resp.prjLead,
                  statusCd: resp.statusCd,
                  prjColor: resp.prjColor,
                };
                this.setState(
                  {
                    activeStep: targetStep ? targetStep : this.state.steps.length === 3 ? ProjectStep.APP_GROUPS : ProjectStep.TASKS,
                    validated: false,
                    showWarning: false,
                    project: updatedProject,
                  },
                  () => {
                    this.props.onUpdate(updatedProject);
                    this.props.showSnackbar();
                  }
                );
              })
              .catch((error: { response: { data: ApiResult<any>; status: number } }) => {
                this.handleError(error);
              });
          !isValid && this.setState({ validated: true });
          !hasChanges &&
            this.setState({
              activeStep: targetStep ? targetStep : this.state.steps.length === 3 ? ProjectStep.APP_GROUPS : ProjectStep.TASKS,
              validated: false,
              showWarning: false,
            });
          break;
        case ProjectStep.APP_GROUPS:
          this.updateAppGroups(this.state.project.prjId).then((res: ProjectRequests[]) => {
            this.setState({
              snackbarText: res.length ? this.props.t('appGroupsUpdatedSuccessfully') : undefined,
              activeStep: ProjectStep.TASKS,
              initialGroups: this.state.groups,
              groupsHaveChanges: false,
              showWarning: false,
            });
          });
          break;
        case ProjectStep.TASKS:
          this.onClose();
          break;
      }
    } else {
      switch (activeStep) {
        case ProjectStep.PROJECT:
          if (!isValid) {
            this.setState({ validated: true });
          }
          if (this.datesCoverTaskDates()) {
            isValid &&
              this.setState({
                activeStep: targetStep ? targetStep : this.state.steps.length === 3 ? ProjectStep.APP_GROUPS : ProjectStep.TASKS,
                validated: false,
                showWarning: false,
              });
          } else {
            this.setState({
              isOpenNotification: true,
              notificationTitle: 'projectTaskPeriodConflictTitle',
              notificationContent: 'projectTaskPeriodConflict',
            });
          }
          break;
        case ProjectStep.APP_GROUPS:
          this.setState({ activeStep: targetStep ? targetStep : ProjectStep.TASKS, validated: false, showWarning: false });
          break;
        case ProjectStep.TASKS:
          this.handleSave();
          break;
      }
    }
  };

  isValid(): boolean {
    const tempProject: Project = { ...this.state.project };
    if (
      tempProject.sowCode &&
      tempProject.prjName &&
      tempProject.customerId &&
      tempProject.prjLead &&
      tempProject.prjStartDate &&
      (!tempProject.prjEndDate || Moment(tempProject.prjStartDate).isSameOrBefore(tempProject.prjEndDate))
    ) {
      return true;
    }
    return false;
  }

  handleSave = () => {
    if (this.props.dialogMode === DialogMode.CREATE) {
      const project: UpdateProject = this.getUpdateProjectModel(this.state.project);
      const prjOversees: Array<PrjAppGroupOversee> = this.getAppGroups(this.state.project.prjId);
      const taskModels: Array<TaskModel> = this.getTasks(this.state.project.prjId);
      const createProject: CreateProject = { project, projectAppGroupOversees: prjOversees, tasks: taskModels };
      // TODO: Think about calling props.onClose() here and making this call in ProjectsContainer
      // in order to show loading spinner while waiting the request
      projectsReqHandler
        .insertProject(createProject)
        .then((resp: CreateProject) => {
          this.props.clearTaskDraft();
          this.onFinish();
          this.props.showSnackbar();
        })
        .catch((error: { response: { data: ApiResult<any>; status: number } }) => {
          this.handleError(error);
        });
    } else {
      this.props.clearTaskDraft();
      this.setState({ project: emptyProjectData, groups: [], tasks: [], activeStep: 0 });
      this.onFinish();
    }
  };

  insertAppGroups(projectId: number): Array<Promise<PrjAppGroupOversee>> {
    let groups: Array<AppGroup> = [...this.state.groups];
    return groups.map(group => {
      const prjOversee: PrjAppGroupOversee = {
        appGroupId: group.appGroupId,
        projectId,
        assignDate: new Date(),
      };
      return prjAppGroupOverseeReqHandler.createPrjAppGroupOversee(prjOversee);
    });
  }

  getAppGroups(projectId: number): Array<PrjAppGroupOversee> {
    const groups: Array<AppGroup> = [...this.state.groups];
    const prjOversees: Array<PrjAppGroupOversee> = [];
    for (const appGroup of groups) {
      const prjOversee: PrjAppGroupOversee = {
        appGroupId: appGroup.appGroupId,
        projectId,
      };
      prjOversees.push(prjOversee);
    }
    return prjOversees;
  }

  updateAppGroups(projectId: number, callback?: () => void): Promise<Array<ProjectRequests>> {
    let requests: Array<Promise<ProjectRequests> | null> = [];
    requests.push(...this.getTasksForAdd(projectId));
    requests.push(...this.getTasksForRemove(projectId));
    requests = requests.filter(item => item !== null);
    return Promise.all(requests);
  }

  getTasksForAdd(projectId: number): Array<Promise<PrjAppGroupOversee> | null> {
    let groups: Array<AppGroup> = [...this.state.groups];
    return groups.map(group => {
      if (!this.state.initialGroups.some(g => g.appGroupId === group.appGroupId)) {
        const prjOversee: PrjAppGroupOversee = {
          appGroupId: group.appGroupId,
          projectId,
          assignDate: new Date(),
        };
        return prjAppGroupOverseeReqHandler.createPrjAppGroupOversee(prjOversee);
      } else {
        return null;
      }
    });
  }

  getTasksForRemove(projectId: number): Array<Promise<PrjAppGroupOversee> | null> {
    let initialGroups: Array<AppGroup> = [...this.state.initialGroups];
    return initialGroups.map(group => {
      if (!this.state.groups.some(g => g.appGroupId === group.appGroupId)) {
        return prjAppGroupOverseeReqHandler.deletePrjAppGroupOversee(projectId, group.appGroupId);
      } else {
        return null;
      }
    });
  }

  getTasks(projectId: number): Array<TaskModel> {
    const tasks: Array<Task> = this.state.tasks;
    const taskModels: Array<TaskModel> = [];
    const assignedBy = this.props.user.empId;
    for (const task of tasks) {
      const taskModel: TaskModel = {
        taskId: task.taskId || 0,
        taskName: task.taskName,
        taskStartDate: task.taskStartDate ? task.taskStartDate : '',
        taskEndDate: task.taskEndDate ? task.taskEndDate : '',
        projectId: projectId,
        projectName: task.projectName,
        assignedTo: task.taskAssignees && task.taskAssignees.length > 0 ? task.taskAssignees[0] : 0,
        assignedBy: assignedBy || 0,
        statusCd: 'ACTIVE',
      };
      taskModels.push(taskModel);
    }
    return taskModels;
  }

  handleBack = () => {
    const activeStep: number = this.state.activeStep > this.state.steps.length - 1 ? this.state.steps.length - 1 : this.state.activeStep;
    switch (activeStep) {
      case ProjectStep.PROJECT:
        this.onClose();
        break;
      case ProjectStep.APP_GROUPS:
        if (this.props.dialogMode === DialogMode.UPDATE) {
          this.updateAppGroups(this.state.project.prjId).then((res: ProjectRequests[]) => {
            this.setState({
              snackbarText: res.length ? this.props.t('appGroupsUpdatedSuccessfully') : undefined,
              activeStep: ProjectStep.PROJECT,
              initialGroups: this.state.groups,
              groupsHaveChanges: false,
              showWarning: false,
            });
          });
        } else {
          this.setState({ activeStep: ProjectStep.PROJECT });
        }
        break;
      case ProjectStep.TASKS:
        this.setState({ activeStep: ProjectStep.APP_GROUPS });
        break;
    }
  };

  onClose = () => {
    if (
      (this.props.dialogMode === DialogMode.CREATE && this.state.project === emptyProjectData) ||
      (this.props.dialogMode !== DialogMode.CREATE && !this.hasChanges()) ||
      this.state.showWarning
    ) {
      this.onFinish();
      this.props.clearTaskDraft();
    } else {
      this.setState({ showWarning: true });
    }
  };

  onFinish = () => {
    this.setState({
      project: emptyProjectData,
      groups: [],
      tasks: [],
      activeStep: 0,
      showWarning: false,
      validated: false,
      groupsHaveChanges: false,
    });
    this.props.onClose();
  };

  onClick = (stepLabel: any) => {
    let newStep = -1;
    switch (stepLabel) {
      case 'project':
        newStep = ProjectStep.PROJECT;
        break;
      case 'dataAccess':
        newStep = ProjectStep.APP_GROUPS;
        break;
      case 'tasks':
        newStep = ProjectStep.TASKS;
        break;
      default:
        break;
    }
    const isValid = this.isValid();
    if (!isValid) {
      this.setState({ validated: true });
      return;
    }
    if (isValid && newStep > this.state.activeStep) {
      this.handleNext(null, newStep);
    } else if (newStep < this.state.activeStep) {
      this.handleBack();
    }
    this.setState({ activeStep: newStep });
  };

  hasChanges = (): boolean =>
    this.projectHasChanges() ||
    this.state.tasksHaveChanges ||
    this.state.groupsHaveChanges ||
    !!this.props.taskDraft.taskName ||
    !!this.props.taskDraft.taskStartDate ||
    !!this.props.taskDraft.taskEndDate ||
    !!this.props.taskDraft.taskAssignees?.length;

  projectHasChanges = (): boolean =>
    this.props.project.customerId !== this.state.project.customerId ||
    this.props.project.customerName !== this.state.project.customerName ||
    this.props.project.prjDescr !== this.state.project.prjDescr ||
    this.props.project.prjEndDate !== this.state.project.prjEndDate ||
    this.props.project.prjId !== this.state.project.prjId ||
    this.props.project.prjLead !== this.state.project.prjLead ||
    this.props.project.prjName !== this.state.project.prjName ||
    this.props.project.prjStartDate !== this.state.project.prjStartDate ||
    this.props.project.sowCode !== this.state.project.sowCode ||
    this.props.project.statusCd !== this.state.project.statusCd ||
    this.props.project.prjColor !== this.state.project.prjColor;

  datesCoverTaskDates = (): boolean => {
    let startDateCovers = true;
    let endDateCovers = true;
    if (this.state.tasks.length) {
      if (this.state.project.prjStartDate && Moment(this.state.project.prjStartDate).isValid()) {
        startDateCovers = !this.state.tasks.some(
          task =>
            (task.taskStartDate &&
              Moment(task.taskStartDate).isValid() &&
              Moment(task.taskStartDate).isBefore(this.state.project.prjStartDate, 'day')) ||
            (task.taskEndDate &&
              Moment(task.taskEndDate).isValid() &&
              Moment(task.taskEndDate).isBefore(this.state.project.prjStartDate, 'day'))
        );
      }
      if (this.state.project.prjEndDate && Moment(this.state.project.prjEndDate).isValid()) {
        endDateCovers = !this.state.tasks.some(
          task =>
            (task.taskStartDate &&
              Moment(task.taskStartDate).isValid() &&
              Moment(task.taskStartDate).isAfter(this.state.project.prjEndDate, 'day')) ||
            (task.taskEndDate &&
              Moment(task.taskEndDate).isValid() &&
              Moment(task.taskEndDate).isAfter(this.state.project.prjEndDate, 'day'))
        );
      }
    }
    return startDateCovers && endDateCovers;
  };

  handleError = (error: { response: { data: ApiResult<any> } }) => {
    const apiErrorMessage =
      error.response && error.response.data && error.response.data.messages
        ? error.response.data.messages.find(errMessage => !!ApiErrorMessage[errMessage.message])
        : null;
    const errorMessage = apiErrorMessage ? apiErrorMessage.message : 'unexpectedErrorMessage';
    const errorMessageTitle = apiErrorMessage ? apiErrorMessage.message + 'Title' : 'unexpectedErrorMessageTitle';
    this.setState({ isOpenNotification: true, notificationTitle: errorMessageTitle, notificationContent: errorMessage });
  };

  onBlurSow = (): void => {
    if (this.state.project.sowCode && this.state.project.sowCode !== this.state.initialSowCode) {
      checkProjectSowCode(this.state.project.sowCode).then((res: any) => {
        if (res) {
          this.setState({ isValidSowCode: true });
        }
      });
    }
  };

  closeNotificationDialog = (): void => {
    this.setState({ isOpenNotification: false, notificationTitle: '', notificationContent: '' });
  };

  render() {
    const snackBar: ReactNode = (
      <InfoSnackbar
        open={this.state.snackbarText !== undefined}
        onClose={() => this.setState({ snackbarText: undefined })}
        text={this.state.snackbarText ? this.state.snackbarText : ''}
      />
    );

    const projectCustomerPart: string = this.state.project
      ? ' ' + this.state.project.prjName + ' ' + this.props.t('forCustomer') + ' ' + this.state.project.customerName
      : '';
    const title: string =
      this.props.dialogMode === DialogMode.CREATE
        ? this.props.t('addNewProject')
        : this.props.canWriteProject
        ? this.props.t('editProject') + projectCustomerPart
        : this.props.t('viewProject') + projectCustomerPart;

    return (
      <Fragment>
        <ProjectDialog
          isOpen={this.props.isOpen}
          onClose={this.onClose}
          showWarning={this.state.showWarning}
          hasChanges={this.hasChanges}
          activeStep={this.state.activeStep}
          dialogMode={this.props.dialogMode}
          hasError={this.disableNext()}
          title={title}
          steps={this.state.steps}
          getStepContent={this.getStepContent}
          handleBack={this.handleBack}
          handleNext={this.handleNext}
          disableNext={this.disableNext}
          onClick={this.onClick}
          snackBar={snackBar}
        />
        {this.state.isOpenNotification && (
          <NotificationDialog
            open={this.state.isOpenNotification}
            title={this.state.notificationTitle}
            content={this.state.notificationContent}
            onClose={this.closeNotificationDialog}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: getUser(state),
  timeZoneId: getTimezone(state),
  taskDraft: state.taskDraft,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearTaskDraft,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ProjectDialogContainer));
