import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import CalendarEvent from "../../../../models/CalendarEvent";
import { compare } from "../../../../utils/string";

const employColumn = (translation: UseTranslationResponse): Column<CalendarEvent> => {
    return {
      title: translation.t('employee'),
      field: 'empName',
      customSort: (first: CalendarEvent, second: CalendarEvent) => {
        return compare(first.empName, second.empName);
      }
    };
};

export default employColumn;