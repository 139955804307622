import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import { ProjectWorkedTime } from '../../../../models/OvertimeReport';

const WorkedTimeColumn = (translation: UseTranslationResponse): Column<ProjectWorkedTime> => ({
  title: translation.t('workedTime'),
  type: 'numeric',
  render: (rowData: ProjectWorkedTime) => {
    return rowData.workedTime.toFixed(2);
  },
  cellStyle: { minWidth: 120, textAlign: 'right', paddingRight: '60px' },
  headerStyle: {textAlign: 'right', paddingRight: '60px'}
});

export default WorkedTimeColumn;
