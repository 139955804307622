import { createStyles, Typography, withStyles, WithStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApiResult } from '../../../../api/apiResult';
import tasksReqHandler from '../../../../api/tasksRemote';
import { DialogMode } from '../../../../models/DialogMode';
import Employee from '../../../../models/Employee';
import Project from '../../../../models/Project';
import Task from '../../../../models/Task';
import TaskModel from '../../../../models/TaskModel';
import UpdateTask from '../../../../models/UpdateTask';
import { clearTaskDraft, setTaskDraft } from '../../../../store/actions/taskDraftActions';
import InsertTaskContainer from './tasksDialog/InsertTaskContainer';
import TasksList from './tasksList/TasksList';
import deepEqual from 'fast-deep-equal';

const styles = () =>
  createStyles({
    paperWithTable: {
      '& thead tr th': {
        borderRadius: 4,
      },
    },
    tasksList: {
      marginTop: 24,
    },
  });

export enum TaskAction {
  created = 'CREATED',
  updated = 'UPDATED',
  deleted = 'DELETED',
}

interface TasksContainerProps extends WithStyles<typeof styles>, WithTranslation {
  project: Project;
  employees: Array<Employee>;
  tasks: Array<Task>;
  setTasks: (tasks: Array<Task>, showSnackbar: boolean, tasksHaveChanges: boolean, taskAction?: TaskAction) => void;
  onError: (error: { response: { data: ApiResult<any> } }) => void;
  canReadTask: boolean;
  canWriteTask: boolean;
  dialogMode: DialogMode;
  setTaskDraft: (task: Task) => void;
  clearTaskDraft: () => void;
  taskDraft: Task;
}

interface TasksContainerState {
  newTask: Task;
  isLoading: boolean;
  isDialogOpen: boolean;
}

const emptyTask: Task = {
  taskId: 0,
  taskName: '',
  projectId: 0,
  projectName: '',
  taskAssignees: [],
  taskStartDate: undefined,
  taskEndDate: undefined,
  assignedBy: 0,
};

class TasksContainer extends Component<TasksContainerProps, TasksContainerState> {
  constructor(props: TasksContainerProps) {
    super(props);
    this.state = {
      newTask: { ...props.taskDraft, projectId: props.project.prjId },
      isLoading: false,
      isDialogOpen: false,
    };
  }

  componentDidMount() {
    this.refreshData();
  }

  shouldComponentUpdate(prevProps: TasksContainerProps, prevState: TasksContainerState) {
    const previous = { ...prevProps, taskDraft: '' };
    const currentProps = { ...this.props, taskDraft: '' };
    return !deepEqual(previous, currentProps) || !deepEqual(prevState, this.state);
  }

  refreshData = () => {
    if ((this.props.canReadTask || this.props.canWriteTask) && this.props.project.prjId > 0) {
      this.setState({ isLoading: true, isDialogOpen: false }, () => {
        tasksReqHandler.loadTasksPerProject(this.props.project.prjId).then((tasksResponse: Array<TaskModel>) => {
          const tasks = tasksResponse.map(task => ({ ...task, taskAssignees: [task.assignedTo] }));
          this.props.setTasks(tasks, false, false);
          this.setState({ isLoading: false });
        });
      });
    }
  };

  handleAdd = (task: Task): Promise<void> => {
    if (this.props.canWriteTask && task.taskAssignees) {
      if (this.props.project.prjId > 0) {
        Promise.all(
          task.taskAssignees.map(assignee =>
            tasksReqHandler.insertTask({
              taskName: task.taskName,
              taskStartDate: task.taskStartDate ? task.taskStartDate : '',
              taskEndDate: task.taskEndDate ? task.taskEndDate : '',
              projectId: task.projectId,
              assignedTo: assignee,
              statusCd: 'ACTIVE',
            })
          )
        )
          .then((resp: Array<TaskModel>) => {
            this.addTasksToList(
              task,
              resp.map(t => t.taskId)
            );
          })
          .catch((error: { response: { data: ApiResult<any> } }) => this.props.onError(error));
      } else {
        this.addTasksToList(task);
      }
    }
    return Promise.resolve();
  };

  addTasksToList = (task: Task, taskIds?: Array<number>) => {
    this.setState(
      (_, props) => ({ newTask: { ...emptyTask, projectId: props.project.prjId } }),
      () => {
        const tasks = [...this.props.tasks];
        if (task.taskAssignees) {
          task.taskAssignees.forEach((assignee, index) => {
            tasks.unshift({ ...task, taskAssignees: [assignee], taskId: taskIds && index < taskIds.length ? taskIds[index] : task.taskId });
          });
          this.props.setTasks(tasks, true, false, TaskAction.created);
          this.props.clearTaskDraft();
        }
      }
    );
  };

  handleUpdate = (task: Task): Promise<void> => {
    if (task.taskId > 0) {
      const updateTask: UpdateTask = {
        assignedTo: (task as TaskModel).assignedTo,
        projectId: task.projectId,
        statusCd: (task as TaskModel).statusCd,
        taskEndDate: (task as TaskModel).taskEndDate,
        taskStartDate: (task as TaskModel).taskStartDate,
        taskName: task.taskName,
      };
      return tasksReqHandler.updateTask(task.taskId, updateTask).then((newTask: TaskModel) => {
        const tasks: Array<Task> = [...this.props.tasks];
        const index = tasks.findIndex((task: Task) => task.taskId === newTask.taskId);
        tasks[index] = { ...newTask, taskAssignees: [newTask.assignedTo] };
        this.props.setTasks(tasks, true, false, TaskAction.updated);
      });
    } else {
      return Promise.resolve();
    }
  };

  handleDelete = (task: Task): Promise<void> => {
    if (task.taskId > 0) {
      return tasksReqHandler
        .deleteTask(task.taskId)
        .then(() => this.deleteTaskFromList(task))
        .catch((error: { response: { data: ApiResult<any> } }) => this.props.onError(error));
    } else {
      this.deleteTaskFromList(task);
      return Promise.resolve();
    }
  };

  deleteTaskFromList(task: Task) {
    let tasks: Array<Task> = [...this.props.tasks];
    const index = this.findIndexByData(tasks, task);
    tasks = tasks.slice(0, index).concat(tasks.slice(index + 1, tasks.length));
    this.props.setTasks(tasks, true, false, TaskAction.deleted);
  }

  handleChange = (oldValue: Task, newValue: Task) => {
    const tasks: Array<Task> = [...this.props.tasks];
    const index = this.findIndexByData(tasks, oldValue);
    tasks[index] = newValue;
    this.props.setTasks(tasks, false, true);
  };

  findIndexByData(tasks: Array<Task>, task: Task) {
    return tasks.findIndex(
      (item: Task) =>
        item.taskName === task.taskName &&
        item.taskStartDate === task.taskStartDate &&
        item.taskEndDate === task.taskEndDate &&
        item.taskAssignees === task.taskAssignees
    );
  }

  render() {
    return (
      <div>
        {this.props.dialogMode !== DialogMode.VIEW && (
          <div>
            <div className={this.props.classes.tasksList}>
              <Fragment>
                <Typography variant="h6">{this.props.t('newTask')}</Typography>
              </Fragment>
            </div>
            <InsertTaskContainer
              task={this.state.newTask}
              projectId={this.props.project.prjId}
              minDate={this.props.project.prjStartDate}
              maxDate={this.props.project.prjEndDate}
              onAdd={this.handleAdd}
              onSave={this.handleUpdate}
              onError={this.props.onError}
              index={-1}
              employees={this.props.employees}
              isLoading={this.state.isLoading}
              isAddEnabled={true}
              renderMode={'Normal'}
              canReadTask={this.props.canReadTask}
              canWriteTask={this.props.canWriteTask}
            />
          </div>
        )}
        <div className={this.props.classes.tasksList}>
          <Fragment>
            <Typography variant="h6">{this.props.t('tasksList')}</Typography>
          </Fragment>
        </div>
        <div className={this.props.classes.tasksList}>
          <TasksList
            projectId={this.props.project.prjId}
            minDate={this.props.project.prjStartDate}
            maxDate={this.props.project.prjEndDate}
            employees={this.props.employees}
            tasks={this.props.tasks}
            isLoading={this.state.isLoading}
            onAdd={this.handleAdd}
            onDelete={this.handleDelete}
            onUpdate={this.handleUpdate}
            onChange={this.handleChange}
            onReload={this.refreshData}
            onError={this.props.onError}
            canReadTask={this.props.canReadTask}
            canWriteTask={this.props.canWriteTask}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  taskDraft: state.taskDraft,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setTaskDraft,
      clearTaskDraft,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(TasksContainer)));
