import { Grid, TextField, Theme } from '@material-ui/core';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import NoteIcon from '@material-ui/icons/Note';
import PersonIcon from '@material-ui/icons/Person';
import StarsIcon from '@material-ui/icons/Stars';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Customer from '../../../../models/Customer';
import { DialogMode } from '../../../../models/DialogMode';
import { Lookup, SaraSelect } from '../../../saraSelect/SaraSelect';

const styles = (theme: Theme) =>
  createStyles({
    iconFieldContainer: {
      display: 'flex',
      '& > svg, & > .iconPlaceHolder': {
        width: 24,
        height: '100%',
        paddingTop: 9,
        marginRight: 6,
      },
      '& > .amDropdown': {
        display: 'block',
        width: '100%',
      },
    },
  });

interface CustomerDetailsProps extends WithStyles<typeof styles> {
  customer: Customer;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { name?: string | undefined; value: unknown }>,
    field: string
  ) => void;
  markAsTouched: (fieldName: string) => void;
  validated: boolean;
  isEmailValid: boolean;
  isPhoneValid: boolean;
  employees: Array<Lookup>;
  dialogMode: DialogMode;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = (props: CustomerDetailsProps) => {
  const { t } = useTranslation();

  const selectAccountManager = (): Lookup => {
    return props.employees.find(e => e.value === props.customer.amId) || { text: '', value: '' };
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={8} className={props.classes.iconFieldContainer}>
        <StarsIcon color="secondary" />
        <TextField
          label={t('customerName')}
          fullWidth
          value={props.customer.customerName || ''}
          required
          onChange={event => props.handleChange(event, 'customerName')}
          error={props.validated && !props.customer.customerName}
          helperText={props.validated && !props.customer.customerName ? t('fieldIsMandatory') : null}
          multiline
          rowsMax={4}
          rows={1}
          InputProps={{
            disabled: props.dialogMode === DialogMode.VIEW,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={props.classes.iconFieldContainer}>
        <PersonIcon color="secondary" />
        <div className="amDropdown">
          <SaraSelect
            id="account-manager"
            label={t('accountManager')}
            validated={props.validated}
            value={selectAccountManager().value}
            lookups={props.employees}
            onChange={event => props.handleChange(event, 'amId')}
            required
            readonly={props.dialogMode === DialogMode.VIEW}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={8} className={props.classes.iconFieldContainer}>
        <LocationCityIcon color="secondary" />
        <TextField
          label={t('address')}
          fullWidth
          value={props.customer.customerAddress || ''}
          onChange={event => props.handleChange(event, 'customerAddress')}
          multiline
          rowsMax={4}
          rows={1}
          InputProps={{ disabled: props.dialogMode === DialogMode.VIEW }}
          InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
        />
      </Grid>
      <Grid item xs={12} sm={4}></Grid>
      <Grid item xs={12} sm={4} className={props.classes.iconFieldContainer}>
        <ContactMailIcon color="secondary" />
        <TextField
          label={t('email')}
          fullWidth
          value={props.customer.customerEmail || ''}
          onChange={event => props.handleChange(event, 'customerEmail')}
          onBlur={() => props.markAsTouched('customerEmail')}
          error={!props.isEmailValid}
          helperText={!props.isEmailValid ? t('wrongFormat') : null}
          InputProps={{ disabled: props.dialogMode === DialogMode.VIEW }}
          InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={props.classes.iconFieldContainer}>
        <div className="iconPlaceHolder" />
        <TextField
          label={t('phone')}
          fullWidth
          value={props.customer.customerPhone || ''}
          onChange={event => props.handleChange(event, 'customerPhone')}
          onBlur={() => props.markAsTouched('customerPhone')}
          error={!props.isPhoneValid}
          helperText={!props.isPhoneValid ? t('wrongFormat') : null}
          InputProps={{ disabled: props.dialogMode === DialogMode.VIEW }}
          InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
        />
      </Grid>
      <Grid item xs={12} sm={4}></Grid>
      <Grid item xs={12} sm={8} className={props.classes.iconFieldContainer}>
        <NoteIcon color="secondary" />
        <TextField
          label={t('customerNotes')}
          fullWidth
          multiline
          rowsMax={4}
          rows={1}
          value={props.customer.notes || ''}
          onChange={event => props.handleChange(event, 'notes')}
          InputProps={{ disabled: props.dialogMode === DialogMode.VIEW }}
          InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
        />
      </Grid>
      <Grid item xs={12} sm={4}></Grid>
      <Grid item xs={12} sm={8} className={props.classes.iconFieldContainer}>
        <BusinessCenterIcon color="secondary" />
        <TextField
          label={t('taxCode')}
          fullWidth
          value={props.customer.taxCode || ''}
          onChange={event => props.handleChange(event, 'taxCode')}
          InputProps={{ disabled: props.dialogMode === DialogMode.VIEW }}
          InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
        />
      </Grid>
      <Grid item xs={12} sm={4}></Grid>
      <Grid item xs={12} sm={8} className={props.classes.iconFieldContainer}>
        <div className="iconPlaceHolder" />
        <TextField
          label={t('invoiceNotes')}
          fullWidth
          multiline
          rowsMax={4}
          rows={1}
          value={props.customer.billToText || ''}
          onChange={event => props.handleChange(event, 'billToText')}
          InputProps={{ disabled: props.dialogMode === DialogMode.VIEW }}
          InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
        />
      </Grid>
      <Grid item xs={12} sm={4}></Grid>
    </Grid>
  );
};

export default withStyles(styles)(CustomerDetails);
