import DateFnsUtils from '@date-io/date-fns';
import { createStyles, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Tooltip, Typography, Theme } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LockIcon from '@material-ui/icons/Lock';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles, WithStyles } from '@material-ui/styles';
import Moment from 'moment';
import React, { Fragment } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { ApiResult } from '../../../api/apiResult';
import EditRowProps from '../../../models/EditRowProps';
import { ProjectStatusCondition } from '../../../models/ProjectStatusCondition';
import Task from '../../../models/Task';
import WorkItem from '../../../models/WorkItem';
import { WorkTimeModel } from '../../../models/WorkTimeModel';
import { convertApiFormatToBrowserTime, formatToHoursDecimal, totalMinutesBetween } from '../../../utils/dateTime';
import SaraDeleteRow from '../../common/SaraDeleteRow';
import SaraTable from '../../common/SaraTable';
import MaterialIcon from '../../icons/IconsStyle';
import Columns from './columns';
import EditWorkTimeRow from './EditWorkTimeRow';

const styles = (theme: Theme) =>
  createStyles({
    materialIcon: MaterialIcon,
    boldText: {
      fontWeight: 700,
      paddingLeft: 8,
    },
    separtaor: {
      paddingLeft: 8,
      paddingRight: 8,
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    greenText: {
      margin: '0px 10px',
      fontWeight: 700,
      color: theme.palette.primary.main,
    },
    redText: {
      margin: '0px 10px',
      fontWeight: 700,
      color: theme.palette.error.main,
    },
  });

interface WorkTimeHistoryProps extends WithStyles<typeof styles>, WithTranslation {
  workItems: Array<WorkItem>;
  isLoading: boolean;
  onDelete?: (workItem: WorkItem) => Promise<void>;
  onUpdate?: (workItem: WorkItem, suppressWarning: string | null) => Promise<void>;
  title: string;
  isEditable: boolean;
  tasks: Array<Task>;
  defaultExpanded?: boolean;
  expanded: boolean;
  onExpand: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  readOnlyReason?: string;
  hasExtendedPermission: boolean;
  showTodaysTotal?: boolean;
  timeZoneId: string;
  overtime: number;
  handleError: (error: { response: { data: ApiResult<any> } }) => void;
}

const WorkTimeHistory: React.FC<WorkTimeHistoryProps> = (props: WorkTimeHistoryProps) => {
  const { t } = useTranslation();

  const mapModelToEntity = (model: WorkTimeModel): WorkItem => ({
    workTimeId: model.workTimeId ? model.workTimeId : 0,
    empId: model.empId !== undefined ? model.empId : 0,
    taskId: model.taskId,
    taskName: '',
    taskProjectId: 0,
    taskProjectName: '',
    wtComment: model.description,
    wtStartTime: new Date(model.startTime).toISOString(),
    wtEndTime: new Date(model.endTime).toISOString(),
    wtMonth: 0,
    wtYear: 0,
    backgroundColor: 'white',
    taskProjectStatusCd: ProjectStatusCondition.ACTIVE,
  });

  let minutesSum = props.workItems.reduce(
    (first: number, second: WorkItem) => first + (formatToHoursDecimal(totalMinutesBetween(second.wtStartTime, second.wtEndTime)) || 0),
    0
  );

  let readOnlyIcon: React.ReactNode = null;
  if (!props.isEditable) {
    readOnlyIcon = (
      <Tooltip title={props.readOnlyReason}>
        <span className={'iconWrapper iconWrapper-noHover'} style={{ padding: '0px 0px 0px 12px' }}>
          <LockIcon className={props.classes.materialIcon} style={{ cursor: 'normal' }} />
        </span>
      </Tooltip>
    );
  }

  let today: React.ReactNode = null;
  if (props.showTodaysTotal) {
    const todaysMinutesSum = props.workItems
      .filter(x => Moment(x.wtStartTime).isSame(Moment(), 'date'))
      .reduce(
        (first: number, second: WorkItem) => first + (formatToHoursDecimal(totalMinutesBetween(second.wtStartTime, second.wtEndTime)) || 0),
        0
      );
    today = (
      <Fragment>
        <Typography>{t('today')}</Typography>
        <Typography className={props.classes.boldText}>{todaysMinutesSum.toFixed(2)}</Typography>
        <Typography className={props.classes.separtaor}>|</Typography>
      </Fragment>
    );
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ExpansionPanel defaultExpanded={props.defaultExpanded} expanded={props.expanded} onChange={props.onExpand}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ display: 'flex' }}>
          <Typography style={{ width: '100%' }}>{props.title}</Typography>
          {today}
          <Typography>{t('total')}</Typography>
          <Typography className={props.classes.boldText}>{minutesSum.toFixed(2)}</Typography>
          <Typography className={props.classes.separtaor}>|</Typography>
          <Typography className={props.classes.nowrap}>{t('overtimeStatus')}</Typography>
          <Typography className={props.overtime >= 0 ? props.classes.greenText : props.classes.redText}>
            {props.overtime > 0 ? '+' : ''}
            {props.overtime.toFixed(2)}
          </Typography>
          {readOnlyIcon}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: '0px' }} className={props.isEditable ? 'tableWithActionsContainer' : ''}>
          <SaraTable<WorkItem>
            isLoading={props.isLoading}
            items={props.workItems.sort((first, second) =>
              Moment(first.wtStartTime).isSame(Moment(second.wtStartTime), 'day')
                ? Moment(first.wtStartTime).valueOf() - Moment(second.wtStartTime).valueOf()
                : Moment(second.wtStartTime).valueOf() - Moment(first.wtStartTime).valueOf()
            )}
            columns={Columns(useTranslation(), props.timeZoneId)}
            sorting={true}
            isEditable={props.isEditable ? (row: WorkItem) => row.taskProjectStatusCd === ProjectStatusCondition.ACTIVE : false}
            onDelete={props.onDelete}
            editTooltip={(row: WorkItem) =>
              row.taskProjectStatusCd.valueOf() === ProjectStatusCondition.ACTIVE.valueOf()
                ? props.t('Edit')
                : props.t('projectIsClosedForEditing')
            }
            deleteTooltip={(row: WorkItem) =>
              row.taskProjectStatusCd.valueOf() === ProjectStatusCondition.ACTIVE.valueOf()
                ? props.t('Delete')
                : props.t('projectIsClosedForEditing')
            }
            components={{
              EditRow: (erProps: EditRowProps<WorkItem>) => {
                if (erProps.mode === 'delete') {
                  return (
                    <SaraDeleteRow
                      columnsBefore={7}
                      confirmationMessage="deleteWorkEntryConfirmation"
                      onCancel={() => erProps.onEditingCanceled('delete', erProps.data)}
                      onSave={() => erProps.onEditingApproved('delete', erProps.data, erProps.data)}
                    />
                  );
                } else {
                  return (
                    <EditWorkTimeRow
                      initialModel={{
                        description: erProps.data.wtComment,
                        endTime: new Date(convertApiFormatToBrowserTime(erProps.data.wtEndTime, props.timeZoneId)).valueOf(),
                        startTime: new Date(convertApiFormatToBrowserTime(erProps.data.wtStartTime, props.timeZoneId)).valueOf(),
                        taskId: erProps.data.taskId,
                        workTimeId: erProps.data.workTimeId,
                        empId: erProps.data.empId,
                        projectId: erProps.data.taskProjectId,
                        taskName: erProps.data.taskName,
                        projectName: erProps.data.taskProjectName,
                      }}
                      hasExtendedPermission={props.hasExtendedPermission}
                      tasks={props.tasks}
                      onSave={(newValue: WorkTimeModel, suppressWarning: string | null): Promise<void> => {
                        if (props.onUpdate) {
                          return props
                          .onUpdate(mapModelToEntity(newValue), suppressWarning)
                          .then(() => erProps.onEditingCanceled('update', erProps.data))
                        }
                        return Promise.resolve();
                      }}
                      onCancel={() => erProps.onEditingCanceled('update', erProps.data)}
                    />
                  );
                }
              },
            }}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </MuiPickersUtilsProvider>
  );
};

export default withTranslation()(withStyles(styles)(WorkTimeHistory));
