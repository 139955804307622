import React, { Fragment } from 'react';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Paper, Theme, Typography, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import Moment from 'moment';
import OvertimeReport, { ProjectWorkedTime } from '../../../models/OvertimeReport';
import SaraTable from '../../common/SaraTable';
import Columns from './columns';

const styles = (theme: Theme) =>
  createStyles({
    ReportOvertimeList: {
      marginBottom: 23,
    },
    header: {
      width: '100%',
      opacity: 0.87,
    },
    overtimeNumber: {
      margin: '0px 10px',
      fontWeight: 700,
      textAlign: 'right',
      minWidth: 65,
      '&.greenText': {
        color: theme.palette.primary.main,
      },
      '&.redText': {
        color: theme.palette.error.main,
      },
    },
  });

interface ReportOvertimeListProps extends WithStyles<typeof styles>, WithTranslation {
  report: OvertimeReport;
  isLoading: boolean;
  defaultExpanded?: boolean;
}

const ReportOvertimeList: React.FC<ReportOvertimeListProps> = (props: ReportOvertimeListProps) => (
  <Paper className={props.classes.ReportOvertimeList}>
    <ExpansionPanel defaultExpanded={props.defaultExpanded}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ display: 'flex' }}>
        <Typography className={props.classes.header}>{`${props.report.empFullName} ${Moment(props.report.startTime).format(
          'DD/MM/YYYY'
        )} - ${Moment(props.report.endTime).format('DD/MM/YYYY')}`}</Typography>
        <Typography>{props.t('overtime')}</Typography>
        <Typography className={`${props.classes.overtimeNumber} ${props.report.overtime >= 0 ? 'greenText' : 'redText'}`}>
          {props.report.overtime > 0 ? '+' : ''}
          {props.report.overtime.toFixed(2)}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ padding: 0 }}>
        <Grid container item spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <SaraTable<ProjectWorkedTime>
              isLoading={props.isLoading}
              items={props.report.projectWorktimes}
              columns={Columns(useTranslation())}
              sorting
              isEditable={false}
              actionsCellStyle={{ position: 'inherit' }}
              maxHeight="auto"
            />
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </Paper>
);

export default withTranslation()(withStyles(styles)(ReportOvertimeList));
