import {
  CircularProgress,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormView } from '../../../../models/FormView';
import { FormViewAccess } from '../../../../models/FormViewAccess';

const styles = (theme: Theme) =>
  createStyles({
    formViewsList: {
      height: '100%',
      overflowY: 'auto',
    },
    listContainer: {
      marginLeft: -16,
    },
    formViewText: {
      flexGrow: 0,
      width: 200,
    },
    radioGrey: {
      color: '#D3D3D4',
      '&$checked': {
        color: '#D3D3D4',
      },
    },
    radioYellow: {
      color: '#FDC02F',
      '&$checked': {
        color: '#FDC02F',
      },
    },
    radioGreen: {
      color: '#29BB9C',
      '&$checked': {
        color: '#29BB9C',
      },
    },
    checked: {},
    subheader: {
      fontSize: 16,
    },
    descriptionText: {
      opacity: '0.3',
      fontWeight: 'bold',
    },
  });

interface FormViewsProps extends WithStyles<typeof styles> {
  formViews: Array<FormView>;
  permissionsMap: Record<string, { formViewControlId?: number; accessId: FormViewAccess }>;
  isLoading: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, formViewName: string) => void;
}

const FormViews: React.FC<FormViewsProps> = (props: FormViewsProps) => {
  const { t } = useTranslation();

  const permissionDescription = (permission: FormViewAccess) => {
    switch (permission) {
      case FormViewAccess.Read:
        return t('readPermission');
      case FormViewAccess.ReadWrite:
        return t('readWritePermission');
      default:
        return t('noPermission');
    }
  };

  const formViewsList = (formViews: Array<FormView>) => (
    <List className={props.classes.formViewsList}>
      {formViews.map((formView: FormView) => (
        <ListItem button disableRipple={true} key={formView.formViewCd}>
          <ListItemText className={props.classes.formViewText} id={formView.formViewCd} primary={formView.formViewCd} />

          <RadioGroup
            aria-label="permissions"
            value={props.permissionsMap[formView.formViewCd] ? props.permissionsMap[formView.formViewCd].accessId : FormViewAccess.None}
            onChange={event => props.handleChange(event, formView.formViewCd)}
            name="permissions"
            row
          >
            <Tooltip title={t('noPermission')}>
              <FormControlLabel
                value={0}
                control={
                  <Radio
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    classes={{ root: props.classes.radioGrey, checked: props.classes.checked }}
                    size="small"
                  />
                }
                label={''}
              />
            </Tooltip>
            <Tooltip title={t('readPermission')}>
              <FormControlLabel
                value={1}
                control={
                  <Radio
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    classes={{ root: props.classes.radioYellow, checked: props.classes.checked }}
                    size="small"
                  />
                }
                label={''}
              />
            </Tooltip>
            <Tooltip title={t('readWritePermission')}>
              <FormControlLabel
                value={2}
                control={
                  <Radio
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    classes={{ root: props.classes.radioGreen, checked: props.classes.checked }}
                    size="small"
                  />
                }
                label={''}
              />
            </Tooltip>
          </RadioGroup>

          <ListItemText
            classes={{ primary: props.classes.descriptionText }}
            id={formView.formViewCd}
            primary={
              props.permissionsMap[formView.formViewCd]
                ? permissionDescription(props.permissionsMap[formView.formViewCd].accessId)
                : t('noPermission')
            }
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6" classes={{ h6: props.classes.subheader }}>
          {t('assignFormViewPermissions')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div className={props.classes.listContainer}>
          {props.isLoading ? <CircularProgress color="primary" className="loadingSpinner" /> : formViewsList(props.formViews)}
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(FormViews);
