export const validatePhone = (phone: string ) => {
  if (phone.search(/^\+?(.*\d.*){10,}$/) > -1) {
    return true;
  };
  return false;
}

export const validateEmail = (email: string ): boolean => {
  const emailRegex: RegExp = new RegExp( /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);
  return emailRegex.test(email);
}