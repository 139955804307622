import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import { useTranslation } from 'react-i18next';
import EditRowProps from '../../../models/EditRowProps';
import Project from '../../../models/Project';
import { compare } from '../../../utils/string';
import SaraDeleteRow from '../../common/SaraDeleteRow';
import SaraTable from '../../common/SaraTable';
import Columns from './columns';

interface ProjectsListProps {
  canReadProject: boolean;
  canWriteProject: boolean;
  projects: Array<Project>;
  isLoading: boolean;
  onEditClick: (event: any, data: Project | Project[]) => void;
  onDetailsClick: (event: any, data: Project | Project[]) => void;
  onDelete: (data: Project) => Promise<void>;
}

const ProjectsList: React.FC<ProjectsListProps> = (props: ProjectsListProps) => {
  const { t } = useTranslation();

  let actions = [];

  if (props.canReadProject && !props.canWriteProject) {
    actions.push({
      icon: () => <VisibilityIcon />,
      onClick: (event: any, data: Project | Project[]) => props.onDetailsClick(event, data as Project),
      tooltip: t('view'),
    });
  }

  if (props.canWriteProject) {
    actions.push({
      icon: () => <CreateIcon />,
      onClick: (event: any, data: Project | Project[]) => props.onEditClick(event, data as Project),
      tooltip: t('edit'),
    });
  }

  return (
    <div className="tableWithActionsContainer">
      <SaraTable<Project>
        isLoading={props.isLoading}
        items={props.projects.sort((first, second) => compare(first.prjName, second.prjName))}
        columns={Columns(useTranslation())}
        sorting={true}
        isEditable={false}
        actions={actions}
        isDeletable={true}
        onDelete={props.onDelete}
        components={{
          EditRow: (erProps: EditRowProps<Project>) => (
            <SaraDeleteRow
              columnsBefore={6}
              confirmationMessage="deleteProjectConfirmation"
              onCancel={() => erProps.onEditingCanceled('delete', erProps.data)}
              onSave={() => erProps.onEditingApproved('delete', erProps.data, erProps.data)}
            />
          ),
        }}
      />
    </div>
  );
};

export default ProjectsList;
