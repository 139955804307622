import Moment from 'moment';
import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import employeesReqHandler from '../../../api/employeesRemote';
import { reportOvertimePdfExportUrl, reportOvertimeCsvExportUrl } from '../../../api/reportsRemote';
import Employee from '../../../models/Employee';
import { getUser, SelectUserState, getTimezone } from '../../../store/selectors/userSelectors';
import { Lookup } from '../../saraSelect/SaraMultiSelect';
import ReportOvertimeFilters from './ReportOvertimeFilters';
import Project from '../../../models/Project';
import { Paper, CircularProgress, Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { convertBrowserTimeToApiFormat } from '../../../utils/dateTime';

const styles = (theme: Theme) =>
  createStyles({
    loadingPaper: {
      padding: 15,
    },
    loaderContainer: {
      width: '100%',
      position: 'relative',
    },
  });

interface ReportOvertimeFiltersContainerProps extends WithTranslation, WithStyles<typeof styles> {
  empId: number | null;
  projects: Array<Project>;
  isLoading: boolean;
  timeZoneId: string;
  handleSave: (
    startDate: string,
    endDate: string,
    empIds: Array<number>,
    splitByMonth: boolean
  ) => void;
}

interface ReportOvertimeFiltersContainerState {
  employeeLookups: Array<Lookup>;
  selectedEmployeeIds: Array<number>;
  startDate: string;
  endDate: string;
  validated: boolean;
  dateErrorMessage: string;
  splitByMonth: boolean;
}

const dateFormat = 'YYYY-MM-DD';

class ReportOvertimeFiltersContainer extends Component<ReportOvertimeFiltersContainerProps, ReportOvertimeFiltersContainerState> {
  constructor(props: ReportOvertimeFiltersContainerProps) {
    super(props);
    this.state = {
      employeeLookups: [],
      selectedEmployeeIds: [],
      startDate: Moment()
        .startOf('month')
        .format(dateFormat),
      endDate: Moment()
        .endOf('month')
        .format(dateFormat),
      validated: false,
      dateErrorMessage: '',
      splitByMonth: false
    };
  }

  componentDidMount() {
    employeesReqHandler.loadEmployees(true, 'empFirstName').then((employees: Employee[]) => {
      const employeeLookups: Array<Lookup> = employees.map((item: Employee) => ({
        value: item.empId,
        text: `${item.empFirstName} ${item.empLastName}`,
      }));
      this.setState({ employeeLookups });
    });
  };

  handleSave = (): void => {

    this.props.handleSave(
      Moment(this.state.startDate).toISOString(),
      Moment(this.state.endDate)
        .endOf('day')
        .toISOString(),
      this.state.selectedEmployeeIds,
      this.state.splitByMonth
    );
    this.setState({ validated: true });
  };

  handleChange = (event: any, field: string): void => {
    switch (field) {
      case 'startDate':
        this.setState(
          {
            startDate: Moment(event).isValid()
              ? Moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId)).format(dateFormat)
              : event,
          },
          () => this.handleDateValidation()
        );
        break;
      case 'endDate':
        this.setState(
          {
            endDate: Moment(event).isValid()
              ? Moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId)).format(dateFormat)
              : event,
          },
          () => this.handleDateValidation()
        );
        break;
      case 'selectedEmployeeIds':
        this.setState({
          selectedEmployeeIds: event.target.value as Array<number>,
        });
        break;
      case 'splitByMonth':
        this.setState(prevState => ({
          splitByMonth: !prevState.splitByMonth,
        }));
        break;
      default:
        break;
    }
  };

  handleDateValidation = (): void => {
    this.setState({
      dateErrorMessage: Moment(this.state.startDate).isAfter(Moment(this.state.endDate))
        ? this.props.t('endTimeShouldBeAfterStartTime')
        : '',
    });
  };

  render() {
    if (this.props.isLoading) {
      return (
        <Paper className={this.props.classes.loadingPaper}>
          <div className={this.props.classes.loaderContainer}>
            <CircularProgress color="primary" className="centeredProgress" />
          </div>
        </Paper>
      );
    }
    return (
      <ReportOvertimeFilters
        employeeLookups={this.state.employeeLookups}
        selectedEmployeeIds={this.state.selectedEmployeeIds}
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        validated={this.state.validated}
        dateErrorMessage={this.state.dateErrorMessage}
        onChange={this.handleChange}
        onSave={this.handleSave}
        pdfExportUrl={reportOvertimePdfExportUrl(
          Moment(this.state.startDate).toISOString(),
          Moment(this.state.endDate)
            .endOf('day')
            .toISOString(),
            this.state.selectedEmployeeIds,
            this.state.splitByMonth
        )}
        csvExportUrl={reportOvertimeCsvExportUrl(
          Moment(this.state.startDate).toISOString(),
          Moment(this.state.endDate)
            .endOf('day')
            .toISOString(),
            this.state.selectedEmployeeIds,
            this.state.splitByMonth
        )}
        onDateValidation={this.handleDateValidation}
        disabled={!this.state.selectedEmployeeIds.length}
        splitByMonth={this.state.splitByMonth}
      />
    );
  }
}

const mapStateToProps = (state: SelectUserState) => ({ empId: getUser(state).empId, timeZoneId: getTimezone(state) });

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(ReportOvertimeFiltersContainer)));
