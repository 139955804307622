import axios from 'axios';
import environments from '../configs/environments';
import MockAdapter from 'axios-mock-adapter';
import fakedRequests from './axiosMocks';
import store from '../store';
import { loginFail } from '../store/actions/authActions';
import { removeUser, goOffline } from '../store/actions/userActions';

interface ApiGateway {
  protocol: string;
  url: string;
  port: string;
}

interface GenericApi {
  post: Function;
  get: Function;
  put: Function;
  delete: Function;
  head: Function;
}

const apiGateway: ApiGateway = environments.apiGateway;

export const baseUrl: string = environments.apiGateway.fake ? '' : `${apiGateway.protocol}://${apiGateway.url}:${apiGateway.port}/`;

if (environments.apiGateway.fake) {
  var mock = new MockAdapter(axios, { delayResponse: 300 });
  fakedRequests.forEach(fakeRequest => {
    switch (fakeRequest.method) {
      case 'GET':
        mock.onGet(fakeRequest.url).reply(fakeRequest.httpCode, fakeRequest.data);
        break;
      case 'POST':
        mock.onPost(fakeRequest.url).reply(fakeRequest.httpCode, fakeRequest.data);
        break;
      case 'PUT':
        mock.onPut(fakeRequest.url).reply(fakeRequest.httpCode, fakeRequest.data);
        break;
      case 'DELETE':
        mock.onDelete(fakeRequest.url).reply(fakeRequest.httpCode, fakeRequest.data);
        break;
      default:
        break;
    }
  });
}

const genericApi: GenericApi = {
  get: (path: string) =>
    axios({
      method: 'get',
      url: baseUrl + path,
      withCredentials: true,
    })
      .then(resp => handleResponse(resp))
      .catch(error => handleError(error)),

  post: (path: string, data?: any) =>
    axios({
      method: 'post',
      url: baseUrl + path,
      withCredentials: true,
      data: data,
    })
      .then(resp => handleResponse(resp))
      .catch(error => handleError(error)),

  put: (path: string, data?: any) =>
    axios({
      method: 'put',
      url: baseUrl + path,
      withCredentials: true,
      data: data,
    })
      .then(resp => handleResponse(resp))
      .catch(error => handleError(error)),

  delete: (path: string, data?: any) =>
    axios({
      method: 'delete',
      url: baseUrl + path,
      withCredentials: true,
      data: data,
    })
      .then(resp => handleResponse(resp))
      .catch(error => handleError(error)),
  head: (path: string) =>
    axios({
      method: 'head',
      url: baseUrl + path,
      withCredentials: true,
    })
      .catch(error => { return error.response })
};

function handleResponse(resp: any) {
  if (resp.status === 200) {
    if (resp.data) return resp.data.result;
    return resp.status;
  } else {
    console.log(resp);
  }
}

function handleError(error: any) {
  if (!error.response) {
    store.dispatch(goOffline());
    throw error;
  }
  const errorCode: number = error.response ? error.response.status : null;
  switch (errorCode) {
    case 440:
      localStorage.removeItem('login');
      store.dispatch(removeUser());
      store.dispatch(loginFail());
      window.location = '/login?sessionExpired=true' as any;
      break;
    case 403:
      throw error;
    default:
      console.error(error.response);
      throw error;
  }
}

export default genericApi;
