import React from 'react';
import { InsertWorkTimeContainer } from '../insertWorkItem/InsertWorkTimeContainer';
import { WorkTimeModel } from '../../../models/WorkTimeModel';
import Task from '../../../models/Task';
import { TableRow } from '@material-ui/core';
import { RenderMode } from '../insertWorkItem/InsertWorkTimeContainer';

interface EditWorkTimeRowProps {
  initialModel: WorkTimeModel;
  tasks: Array<Task>;
  onSave: (newValue: WorkTimeModel, surpressWarning: string | null) => Promise<void>;
  onCancel: () => void;
  hasExtendedPermission: boolean;
}

const EditWorkTimeRow: React.FC<EditWorkTimeRowProps> = (props: EditWorkTimeRowProps) => {
  return (
    <TableRow className="MuiTableRow-root inlineEditRow">
      <InsertWorkTimeContainer
        tasks={props.tasks}
        isLoading={false}
        renderMode={RenderMode.InlineEdit}
        onSave={props.onSave}
        onCancel={props.onCancel}
        initialModel={props.initialModel}
        hasExtendedPermission={props.hasExtendedPermission}
        rememberData={false}
      />
    </TableRow>
  );
};

export default EditWorkTimeRow;
