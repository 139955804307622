import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import Employee from "../../../../models/Employee";
import { compare } from "../../../../utils/string";

const FirstNameColumn = (translation: UseTranslationResponse): Column<Employee> => {
    return {
      title: translation.t('firstName'),
      field: 'empFirstName',
      customSort: (first: Employee, second: Employee) => {
        return compare(first.empFirstName, second.empFirstName);
      }
    };
};

export default FirstNameColumn;
