import { Column } from 'material-table';
import React from 'react';
import { UseTranslationResponse } from 'react-i18next';
import WorkItem from '../../../../models/WorkItem';
import moment from 'moment-timezone';

const dateColumn = (translation: UseTranslationResponse, timezone: string): Column<WorkItem> => {
  return {
    title: translation.t('date'),
    field: 'wtStartTime',
    type: 'date',
    render: (rowData: WorkItem) => {
      return (
        <span>
          {moment(rowData.wtStartTime)
            .tz(timezone)
            .format('DD/MM')}
        </span>
      );
    },
    cellStyle: { minWidth: 75 },
  };
};

export default dateColumn;
