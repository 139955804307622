import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import employeesReqHandler from '../../api/employeesRemote';
import Employee from '../../models/Employee';
import { TimelineItem } from 'react-calendar-timeline';
import Moment from 'moment';
import moment from 'moment-timezone';
import Calendar from './Calendar';
import CalendarEvent from '../../models/CalendarEvent';
import CalendarFilter from './calendarFilter/CalendarFilter';
import AddNewButton from '../common/AddNewButton';
import { Box } from '@material-ui/core';
import CalendarEventDialog from './calendarEventDialog/CalendarEventDialog';
import calendarReqHandler from '../../api/calendarRemote';
import OfficesReqHandler from '../../api/officesRemote';
import CalendarDay from '../../models/CalendarDay';
import Office from '../../models/Office';
import { resetHours, convertBrowserTimeToApiFormat } from '../../utils/dateTime';
import CalendarDayType from '../../models/CalendarDayType';
import { getTitle, getCalendarEventStyle, getCalendarTaskStyle } from '../../utils/calendar';
import { getUser, SelectUserState, getTimezone } from '../../store/selectors/userSelectors';
import User from '../../models/User';
import Permissions from '../../models/Permissions';
import NotificationDialog from '../notification/NotificationDialog';
import { CALENDAR_EVENT_ITEM } from '../../utils/calendarEventItem';
import { ApiErrorMessage } from '../../api/apiMessage';
import { ApiResult } from '../../api/apiResult';
import RemainingVacsModel from '../../models/RemainingVacs';
import AnnualVacsModel from '../../models/AnnualVacs';
import CalendarProject from '../../models/CalendarProject';
import calendarStyles from './calendar.module.css';
import { StatusValues } from '../../models/StatusValues';
import CalendarDateRange from '../../models/CalendarDateRange';
import appUserGroupsRemote from '../../api/appUserGroupsRemote';

interface CalendarContainerProps extends WithTranslation {
  permissions: Permissions;
  user: User;
  timeZoneId: string;
}

interface CalendarContainerState {
  timeStart: Moment.Moment;
  timeEnd: Moment.Moment;
  employees: Array<Employee>;
  calendarEventItems: Array<TimelineItem<any, any>>;
  calendarTaskItems: Array<TimelineItem<any, any>>;
  isDialogOpen: boolean;
  newEvent: CalendarEvent;
  holidays: Array<string>;
  selectedFilters: { timeStart: Moment.Moment; timeEnd: Moment.Moment; officeId: string };
  offices: Array<Office>;
  dayTypes: Array<CalendarDayType>;
  isEditing: boolean;
  isOpenNotification: boolean;
  notificationTitle: string;
  notificationContent: string;
  employeesGroup: Array<Employee>;
  dialogReadOnly: boolean;
  isAdministratorGroup: boolean;
  calendarDateRanges: Array<CalendarDateRange>;
}

class CalendarContainer extends Component<CalendarContainerProps, CalendarContainerState> {
  state: CalendarContainerState = {
    timeStart: moment().startOf('month'),
    timeEnd: moment().endOf('month'),
    employees: [],
    calendarEventItems: [],
    calendarTaskItems: [],
    isDialogOpen: false,
    newEvent: CALENDAR_EVENT_ITEM,
    holidays: [],
    selectedFilters: {
      timeStart: moment()
        .startOf('month')
        .hours(0)
        .minutes(0),
      timeEnd: moment()
        .endOf('month')
        .hours(0)
        .minutes(0),
      officeId: '',
    },
    offices: [],
    dayTypes: [],
    isEditing: false,
    isOpenNotification: false,
    notificationTitle: '',
    notificationContent: '',
    employeesGroup: [],
    dialogReadOnly: true,
    isAdministratorGroup: false,
    calendarDateRanges: [
      { minDate: null, maxDate: null },
      { minDate: null, maxDate: null },
    ],
  };

  componentDidMount() {
    this.loadDayTypes();

    this.setEmpId();
    OfficesReqHandler.loadOffices()
      .then((resp: Array<Office>) => {
        let selectedFilters = { ...this.state.selectedFilters };
        if (resp.length) {
          selectedFilters.officeId = String(this.props.user.officeId);
        }
        this.setState({ offices: resp, selectedFilters }, this.submitFilter);
      })
      .catch(this.handleError);

    this.loadIsAppUserGroupAdmin();
  }

  loadIsAppUserGroupAdmin = (): void => {
    const appGroupId: number = 1; // TODO: At a time this was created, no easy way from BE to retrieve `appGroupId`.
    appUserGroupsRemote
      .getIsAppUserGroupAdmin(this.props.user.appUserId, appGroupId)
      .then(resp =>
        resp.status && resp.status === 200 ? this.setState({ isAdministratorGroup: true }) : this.setState({ isAdministratorGroup: false })
      );
  };

  loadHolidays = () => {
    const selectedFilters = this.state.selectedFilters;
    calendarReqHandler
      .loadHolidays(selectedFilters.timeStart, selectedFilters.timeEnd, selectedFilters.officeId)
      .then((resp: Array<CalendarDay>) => {
        let holidays: Array<string> = [];
        resp.forEach((item: CalendarDay) => {
          holidays.push(item.date);
        });
        this.setState({ holidays });
      })
      .catch(this.handleError);
  };

  loadEmployees = (date: Moment.Moment): void => {
    employeesReqHandler
      .loadEmployees(false, 'empFirstName', 'ASC', this.state.selectedFilters.officeId)
      .then((resp: Array<Employee>) => {
        const currentEmpIndex: number = resp.findIndex((item: Employee) => item.empId === this.props.user.empId);
        if (currentEmpIndex !== -1) {
          const currentEmpItem: Array<Employee> = resp.splice(currentEmpIndex, 1);
          resp = currentEmpItem.concat(resp);
        }
        this.setState({ employees: resp }, () => {
          this.mappingEmployeesGroup(date);
        });
      })
      .catch(this.handleError);
  };

  loadCalendarEvents = (): void => {
    const selectedFilter = this.state.selectedFilters;
    const statusFilter: Array<number> = [StatusValues.PENDING, StatusValues.APPROVED, StatusValues.FINALIZED];
    const officeId: number = Number(selectedFilter.officeId);
    calendarReqHandler
      .loadEventsMultipleFilter(selectedFilter.timeStart, selectedFilter.timeEnd, [officeId], [], [], statusFilter)
      .then((vacations: Array<CalendarEvent>): void => {
        let calendarEventItems: Array<TimelineItem<any>> = [];
        vacations.forEach((item: CalendarEvent, index: number) => {
          calendarEventItems.push({
            id: item.empCalEventReqId,
            group: item.empId,
            title: this.props.t(getTitle(this.state.dayTypes, item.eventTypeId)),
            start_time: moment(item.rqStartDate),
            end_time: item.rqEndDate ? moment(item.rqEndDate).add(1, 'day') : this.state.timeEnd,
            style: getCalendarEventStyle(this.state.dayTypes, item.eventTypeId, item.statusId),
            data: item,
          });
        });
        this.setState({ calendarEventItems });
      });
  };

  loadCalendarTasks = (): void => {
    const selectedFilter = this.state.selectedFilters;
    calendarReqHandler
      .loadCalendarTasks(selectedFilter.timeStart, selectedFilter.timeEnd, selectedFilter.officeId)
      .then((tasks: Array<CalendarProject>): void => {
        let calendarTaskItems: Array<TimelineItem<any>> = [];
        tasks.forEach((item: CalendarProject, index: number) => {
          if (item.taskPeriodSummaries.length) {
            item.taskPeriodSummaries.forEach((task: { startDate: string; endDate: string }, ind: number) => {
              calendarTaskItems.push({
                id: 'proj' + item.projectId + ind + item.empId,
                group: item.empId,
                title: item.projectName,
                start_time: moment(task.startDate),
                end_time: task.endDate ? moment(task.endDate).add(1, 'day') : this.state.timeEnd,
                style: getCalendarTaskStyle(item),
                data: null,
              });
            });
          }
        });
        this.setState({ calendarTaskItems });
      });
  };

  loadDayTypes = (): void => {
    calendarReqHandler
      .loadDayTypes()
      .then((resp: Array<CalendarDayType>) => {
        this.setState({ dayTypes: resp });
      })
      .catch(this.handleError);
  };

  submitFilter = (): void => {
    if (
      this.state.selectedFilters.timeStart &&
      this.state.selectedFilters.timeEnd &&
      this.state.selectedFilters.timeStart <= this.state.selectedFilters.timeEnd
    ) {
      this.setState({
        timeStart: this.state.selectedFilters.timeStart
          .clone()
          .hours(0)
          .minutes(0),
        timeEnd: this.state.selectedFilters.timeEnd
          .clone()
          .hours(23)
          .minutes(59),
      });
      this.loadHolidays();
      this.loadEmployees(this.state.selectedFilters.timeStart);
      this.loadCalendarEvents();
      this.loadCalendarTasks();
    }
  };

  mappingEmployeesGroup = (date: Moment.Moment): void => {
    const employeesGroup: Array<Employee> = [...this.state.employees];
    const year: string = moment(date)
      .year()
      .toString();
    const vacsRequest: Array<Promise<any>> = [
      employeesReqHandler.getEmpRemainingVacs(undefined, year),
      employeesReqHandler.getEmpAnnualPaidVacs(undefined, year),
    ];
    Promise.all(vacsRequest).then(([remainingVacs, annualVacs]) => {
      for (let item of employeesGroup) {
        const remainingVacsObj = remainingVacs.find((r: RemainingVacsModel) => r.empId === item.empId);
        const annualVacsObj = annualVacs.find((a: AnnualVacsModel) => a.empId === item.empId);
        item.remainingVac = remainingVacsObj ? remainingVacsObj.remainingDays : 0;
        item.annualVac = annualVacsObj ? annualVacsObj.vacDaysNumber : 0;
      }
      this.setState({ employeesGroup });
    });
  };

  changeFilter = (event: any, field: string) => {
    let selectedFilters = { ...this.state.selectedFilters };
    switch (field) {
      case 'timeStart':
        selectedFilters.timeStart = moment(event).isValid() ? moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId)) : event;
        break;
      case 'timeEnd':
        selectedFilters.timeEnd = moment(event).isValid() ? moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId)) : event;
        break;
      case 'officeId':
        selectedFilters.officeId = event.target.value;
        break;
      default:
        break;
    }
    this.setState({ selectedFilters });
  };

  handleChange = (event: any, field: string) => {
    const newEvent: CalendarEvent = { ...this.state.newEvent };
    switch (field) {
      case 'startDate':
        const rqStartDate: Moment.Moment = moment(event).isValid()
          ? moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId))
          : event;
        newEvent.rqStartDate = rqStartDate;
        this.setState({
          calendarDateRanges: [{ ...this.state.calendarDateRanges[0] }, { ...this.state.calendarDateRanges[1], minDate: rqStartDate }],
        });
        break;
      case 'endDate':
        newEvent.rqEndDate = moment(event).isValid() ? moment(convertBrowserTimeToApiFormat(event, this.props.timeZoneId)) : event;
        break;
      case 'comment':
        newEvent.notes = event.target.value;
        break;
      case 'typeId':
        newEvent.eventTypeId = event.target.value;
        break;
      case 'empId':
        newEvent.empId = event.target.value;
        break;
      default:
        break;
    }
    this.setState({ newEvent });
  };

  handleClose = () => {
    this.setState({ newEvent: CALENDAR_EVENT_ITEM, isDialogOpen: false, isEditing: false, dialogReadOnly: true }, this.setEmpId);
    this.removeFakeEvent();
  };

  actionHandler = () => {
    this.loadCalendarEvents();
    this.handleClose();
    this.mappingEmployeesGroup(this.state.selectedFilters.timeStart);
  };

  handleError = (error: { response: { data: ApiResult<any> } }) => {
    const apiErrorMessage =
      error.response && error.response.data && error.response.data.messages
        ? error.response.data.messages.find(errMessage => !!ApiErrorMessage[errMessage.message])
        : null;
    const errorMessage = apiErrorMessage ? apiErrorMessage.message : 'unexpectedErrorMessage';
    const errorMessageTitle = apiErrorMessage ? apiErrorMessage.message + 'Title' : 'unexpectedErrorMessageTitle';
    this.setState({ isOpenNotification: true, notificationTitle: errorMessageTitle, notificationContent: errorMessage });
  };

  handleSave = (editAction?: 'approve' | 'reject' | 'finalize') => {
    if (this.primaryDisabled()) {
      return;
    }
    if (!this.state.isEditing) {
      calendarReqHandler
        .createCalendarEvent(this.state.newEvent)
        .then(this.actionHandler)
        .catch(this.handleError);
    } else {
      let newEvent: CalendarEvent = { ...this.state.newEvent };
      if (editAction === 'approve') {
        newEvent.statusId = StatusValues.APPROVED;
      }
      if (editAction === 'reject') {
        newEvent.statusId = StatusValues.REJECTED;
      }
      if (editAction === 'finalize') {
        newEvent.statusId = StatusValues.FINALIZED;
      }
      calendarReqHandler
        .editCalendarEvent(newEvent)
        .then(this.actionHandler)
        .catch(this.handleError);
    }
  };

  handleDelete = () => {
    if (this.state.newEvent.empCalEventReqId) {
      calendarReqHandler
        .deleteCalendarEvent(this.state.newEvent.empCalEventReqId)
        .then(this.actionHandler)
        .catch(this.handleError);
    }
  };

  onCanvasClick = (groupId: number, time: number, event: any) => {
    let newEvent: CalendarEvent = { ...this.state.newEvent };
    let newDate: Moment.Moment = moment(convertBrowserTimeToApiFormat(resetHours(time), this.props.timeZoneId));
    const todayDate: Moment.Moment = Moment(convertBrowserTimeToApiFormat(resetHours(new Date()), this.props.timeZoneId));

    if (!this.props.permissions.extendedWrite && this.props.user.empId !== groupId) {
      return;
    }

    if (!this.state.isAdministratorGroup && newDate.isBefore(todayDate)) {
      return;
    }

    if (newEvent.rqStartDate && newEvent.rqStartDate <= newDate && newEvent.empId === groupId) {
      newEvent.rqEndDate = newDate;
      this.setState({
        newEvent,
        isDialogOpen: true,
        dialogReadOnly: false,
        calendarDateRanges: [
          { ...this.state.calendarDateRanges[0], minDate: todayDate },
          { ...this.state.calendarDateRanges[1], minDate: newEvent.rqStartDate },
        ],
      });
      this.changeFakeEventEndDate(newDate);
    } else {
      newEvent.rqStartDate = newDate;
      newEvent.empId = groupId;
      this.setState({ newEvent });
      this.addFakeEvent(groupId, newDate);
    }
  };

  addFakeEvent = (groupId: number, newDate: Moment.Moment): void => {
    let calendarEventItems: Array<TimelineItem<any, any>> = [...this.state.calendarEventItems];

    let fakeEvent: TimelineItem<any, any> | undefined = calendarEventItems.find((item: TimelineItem<any, any>) => {
      return item.id === 0.1;
    });

    if (fakeEvent) {
      fakeEvent.group = groupId;
      fakeEvent.start_time = moment(newDate);
      fakeEvent.end_time = moment(newDate).add(1, 'day');
    } else {
      calendarEventItems.push({
        id: 0.1,
        group: groupId,
        title: '',
        start_time: moment(newDate),
        end_time: moment(newDate).add(1, 'day'),
        style: {
          backgroundColor: '#FDC02F',
          borderColor: '#FDC02F',
        },
      });
    }
    this.setState({ calendarEventItems });
  };

  changeFakeEventEndDate = (newDate: Moment.Moment): void => {
    let calendarEventItems: Array<TimelineItem<any, any>> = [...this.state.calendarEventItems];
    let fakeEvent: TimelineItem<any, any> | undefined = calendarEventItems.find((item: TimelineItem<any, any>) => {
      return item.id === 0.1;
    });

    if (fakeEvent) {
      fakeEvent.end_time = moment(newDate).add(1, 'day');
      this.setState({ calendarEventItems });
    }
  };

  removeFakeEvent = (): void => {
    let calendarEventItems: Array<TimelineItem<any, any>> = [...this.state.calendarEventItems];
    let fakeEventIndex: number = calendarEventItems.findIndex((item: TimelineItem<any, any>) => {
      return item.id === 0.1;
    });

    if (fakeEventIndex > -1) {
      calendarEventItems.splice(fakeEventIndex, 1);
      this.setState({ calendarEventItems });
    }
  };
  primaryDisabled = (): boolean => {
    return (
      !this.state.newEvent.rqStartDate ||
      !this.state.newEvent.rqEndDate ||
      this.state.newEvent.rqStartDate > this.state.newEvent.rqEndDate ||
      !this.state.newEvent.eventTypeId ||
      this.differentYear()
    );
  };
  differentYear = (): boolean => {
    const newEvent: CalendarEvent = this.state.newEvent;
    if (newEvent.rqStartDate && newEvent.rqEndDate && moment(newEvent.rqStartDate).year() !== moment(newEvent.rqEndDate).year()) {
      return true;
    }
    return false;
  };
  onItemSelect = (itemId: number, event: any, time: number): void => {
    const selectedTimelineItem = this.state.calendarEventItems.find(item => {
      return item.id === itemId;
    });
    if (selectedTimelineItem && selectedTimelineItem.data) {
      this.setState({ isDialogOpen: true, newEvent: selectedTimelineItem.data, isEditing: true });
      this.setCalendarDateRanges(selectedTimelineItem.start_time, selectedTimelineItem.end_time);
    }
  };
  closeNotificationDialog = (): void => {
    this.setState({ isOpenNotification: false, notificationTitle: '', notificationContent: '' });
  };
  setEmpId = (): void => {
    let newEvent = { ...this.state.newEvent };
    if (this.props.user.empId) {
      newEvent.empId = this.props.user.empId;
    }
    this.setState({ newEvent });
  };
  setCalendarDateRanges = (startTime: string, endTime: string): void => {
    let calendarDateRanges: Array<CalendarDateRange> = [{ ...this.state.calendarDateRanges[0] }, { ...this.state.calendarDateRanges[1] }];
    const todayDate: Moment.Moment = Moment(convertBrowserTimeToApiFormat(resetHours(new Date()), this.props.timeZoneId));
    const startDate: Moment.Moment = Moment(convertBrowserTimeToApiFormat(startTime, this.props.timeZoneId));
    const endDate: Moment.Moment = Moment(convertBrowserTimeToApiFormat(endTime, this.props.timeZoneId));
    if (!this.state.isAdministratorGroup && startDate.isBefore(todayDate) && endDate.isSameOrAfter(todayDate)) {
      calendarDateRanges = [
        { ...this.state.calendarDateRanges[0], minDate: todayDate },
        { ...this.state.calendarDateRanges[1], minDate: todayDate },
      ];
    }
    if (!this.state.isAdministratorGroup && startDate.isSameOrAfter(todayDate)) {
      calendarDateRanges = [
        { ...this.state.calendarDateRanges[0], minDate: todayDate },
        { ...this.state.calendarDateRanges[1], minDate: startDate },
      ];
    }
    if (this.state.isAdministratorGroup) {
      calendarDateRanges = [
        { ...this.state.calendarDateRanges[0], minDate: null, maxDate: null },
        { ...this.state.calendarDateRanges[1], minDate: null, maxDate: null },
      ];
    }
    this.setState({ calendarDateRanges });
  };

  render() {
    const todayDate: Moment.Moment = Moment(convertBrowserTimeToApiFormat(resetHours(new Date()), this.props.timeZoneId));
    return (
      <Fragment>
        <div className="pageWrapper">
          <Box display="flex" alignItems="center" p={2} mt={1} mb={2} boxShadow={1} bgcolor="white" width="calc(100vw - 320px)">
            <div className={calendarStyles.addButtonWrapper}>
              <AddNewButton
                text={this.props.t('addNewRequest')}
                onClick={() => {
                  this.setState({
                    dialogReadOnly: false,
                    isDialogOpen: true,
                    calendarDateRanges: [
                      { ...this.state.calendarDateRanges[0], minDate: !this.state.isAdministratorGroup ? todayDate : null },
                      { ...this.state.calendarDateRanges[1], minDate: !this.state.isAdministratorGroup ? todayDate : null },
                    ],
                  });
                }}
              />
            </div>
            <CalendarFilter
              selectedFilters={this.state.selectedFilters}
              offices={this.state.offices}
              changeFilter={this.changeFilter}
              submitFilter={this.submitFilter}
            />
          </Box>
          <Calendar
            employees={this.state.employees}
            calendarEventItems={this.state.calendarEventItems}
            calendarTaskItems={this.state.calendarTaskItems}
            timeStart={this.state.timeStart.valueOf()}
            timeEnd={this.state.timeEnd.valueOf()}
            holidays={this.state.holidays}
            onCanvasClick={this.onCanvasClick}
            onItemSelect={this.onItemSelect}
            user={this.props.user}
          />
        </div>
        <CalendarEventDialog
          isOpen={this.state.isDialogOpen}
          onClose={this.handleClose}
          onSave={this.handleSave}
          newEvent={this.state.newEvent}
          handleChange={this.handleChange}
          dayTypes={this.state.dayTypes}
          employees={this.state.employees}
          primaryDisabled={this.primaryDisabled}
          isEditing={this.state.isEditing}
          permissions={this.props.permissions}
          user={this.props.user}
          handleDelete={this.handleDelete}
          differentYear={this.differentYear}
          readOnly={this.state.dialogReadOnly}
          openEditMode={() => {
            this.setState({ dialogReadOnly: false });
          }}
          isAdministratorGroup={this.state.isAdministratorGroup}
          calendarDateRanges={this.state.calendarDateRanges}
          todayDate={todayDate}
        />
        <NotificationDialog
          open={this.state.isOpenNotification}
          title={this.state.notificationTitle}
          content={this.state.notificationContent}
          onClose={this.closeNotificationDialog}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = (state: SelectUserState) => ({
  user: getUser(state),
  timeZoneId: getTimezone(state),
});
export default connect(mapStateToProps)(withTranslation()(CalendarContainer));
