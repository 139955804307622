export interface ApiMessage {
  message: string;
  type: ApiMessageType;
}

export enum ApiMessageType {
  warning = 'WARNING',
  error = 'ERROR',
}

export const ApiWarningMessage = {
  overlapWorkingTime: 'overlappingWorkTime',
};

export const ApiErrorMessage: Record<string, string> = {
  overlappingWorkTimeSameCustomer: 'overlappingWorkTimeSameCustomer',
  workTimeTaskPeriodConflict: 'workTimeTaskPeriodConflict',
  workTimeForInactiveProject: 'workTimeForInactiveProject',
  taskProjectPeriodConflict: 'taskProjectPeriodConflict',
  taskWorkTimePeriodConflict: 'taskWorkTimePeriodConflict',
  existingWorkTimesForTask: 'existingWorkTimesForTask',
  projectTaskPeriodConflict: 'projectTaskPeriodConflict',
  taskAlreadyInProgress: 'taskAlreadyInProgress',
  authenticationFailed: 'authenticationFailed',
  overlappingEmpCalEventReqSameEventType: 'overlappingEmpCalEventReqSameEventType',
  insufficientPaidVacation: 'insufficientPaidVacation',
  accessDenied: 'accessDenied',
  changePasswordValidationFailed: 'validationFailed',
  changePasswordAuthenticationFailed: 'authenticationFailed',
  existingTasksForProject: 'existingTasksForProject',
  existingProjectsForCustomer: 'existingProjectsForCustomer',
  employeeStartDateAfterEndDate: 'employeeStartDateAfterEndDate',
};
