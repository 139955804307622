import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppGroup from '../../../models/AppGroup';
import SaraTable from '../../common/SaraTable';
import { FormView } from '../../../models/FormView';
import { FormViewControl } from '../../../models/FormViewControl';
import Columns from './columns';
import { compare } from '../../../utils/string';

interface AppGroupsListProps {
  appGroups: Array<AppGroup>;
  formViews: Array<FormView>;
  formViewControls: Array<FormViewControl>;
  isLoading: boolean;
  onDetailsClick: (event: any, data: AppGroup) => void;
  onEditClick: (event: any, data: AppGroup) => void;
  canReadAppGroups: boolean;
  canWriteAppGroups: boolean;
}

const AppGroupsList: React.FC<AppGroupsListProps> = (props: AppGroupsListProps) => {
  const { t } = useTranslation();
  props.appGroups.forEach(group => {
    group.formViewControls = props.formViews.map(
      (x: FormView): FormViewControl => {
        const formViewControl = props.formViewControls
          .find(fvc => fvc.appGroupId === group.appGroupId && fvc.formViewCd === x.formViewCd);
        return {
          formViewCd: x.formViewCd,
          appGroupId: group.appGroupId,
          accessId: formViewControl ? formViewControl.accessId : 0
        };
      });
  });


  let actions = [];

  if (props.canReadAppGroups && !props.canWriteAppGroups) {
    actions.push({
      icon: () => <VisibilityIcon />,
      onClick: (event: any, data: AppGroup | AppGroup[]) => props.onDetailsClick(event, data as AppGroup),
      tooltip: t('view'),
    });
  }

  if (props.canWriteAppGroups) {
    actions.push({
      icon: () => <CreateIcon />,
      onClick: (event: any, data: AppGroup | AppGroup[]) => props.onEditClick(event, data as AppGroup),
      tooltip: t('edit'),
    });
  }

  return (
    <div className="tableWithFixedFirstColumn tableWithActionsContainer">
      <SaraTable<AppGroup>
        isLoading={props.isLoading}
        items={props.appGroups.sort((first, second) => compare(first.appGroupName, second.appGroupName))}
        columns={Columns(useTranslation(), props.formViews)}
        sorting={true}
        isEditable={false}
        actions={actions}
        actionsColumnIndex={props.formViews.length + 1}
        actionsCellStyle={{ color: "#8E8C92", backgroundColor: 'white' }}
      />
    </div>
  );
};

export default AppGroupsList;
