import React from 'react';
import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import CalendarEvent from "../../../../models/CalendarEvent";

const EventTypeColumn = (translation: UseTranslationResponse): Column<CalendarEvent> => {
    return {
      title: translation.t('eventType'),
      field: 'eventTypeName',
      render: (rowData: CalendarEvent) => {
       const eventTypeName = rowData.eventTypeName ? rowData.eventTypeName : '';
        return <span>{ translation.t(eventTypeName) }</span>;
      },
      customSort: (first: CalendarEvent, second: CalendarEvent) => {
        return first.eventTypeId-second.eventTypeId
      }
    };
};

export default EventTypeColumn;