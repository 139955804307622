import React from 'react';
import { Button } from '@material-ui/core';
import { WithStyles, createStyles, withStyles } from '@material-ui/styles';
import { Theme } from '@fullcalendar/core';

const styles = (theme: Theme) =>
  createStyles({
    cancelButton: {
      color: '#29BB9C',
    },
    nextButton: {
      marginLeft: '24px',
    },
    nextButtonDisabled: {
      marginLeft: '24px',
      opacity: 0.6,
    },
    buttonsContainer: {
      paddingBottom: '16px',
      paddingRight: '16px',
    },
  });

interface SaraDialogButtonsProps extends WithStyles<typeof styles> {
  onPrimary: (event: any) => void;
  onSecondary: (event: any) => void;
  primaryText: string;
  secondaryText: string;
  primaryDisabled: boolean;
}

const SaraDialogButtons: React.FC<SaraDialogButtonsProps> = (props: SaraDialogButtonsProps) => {
  return (
    <div className={props.classes.buttonsContainer}>
      <Button  onClick={props.onSecondary} className={props.classes.cancelButton}>
        {props.secondaryText}
      </Button>
      <Button
        variant="contained"
        color="primary"
        className={props.primaryDisabled ? props.classes.nextButtonDisabled : props.classes.nextButton}
        onClick={props.onPrimary}
      >
        {props.primaryText}
      </Button>
    </div>
  );
};

export default withStyles(styles)(SaraDialogButtons);
