import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import { ProjectWorkedTime } from '../../../../models/OvertimeReport';

const ProjectColumn = (translation: UseTranslationResponse): Column<ProjectWorkedTime> => ({
  title: translation.t('project'),
  field: 'projectName',
  cellStyle: { minWidth: 100, width: '50%' },
});

export default ProjectColumn;
