import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApiResult } from '../../../../api/apiResult';
import prjAppGroupOverseeReqHandler from '../../../../api/prjAppGroupOverseeRemote';
import ProjectsReqHandler from '../../../../api/projectsRemote';
import AppGroup from '../../../../models/AppGroup';
import { DialogMode } from '../../../../models/DialogMode';
import PrjAppGroupOversee from '../../../../models/PrjAppGroupOversee';
import Project from '../../../../models/Project';
import { addProject, addToOldState, removeProject } from '../../../../store/actions/appGroupActions';
import { AppGroupActionType } from '../../../../store/actions/appGroupActionType';
import { AppGroupState } from '../../../../store/reducers/appGroupsReducer';
import { getAppGroup, getAppGroupProjects } from '../../../../store/selectors/appGroupSelectors';
import { compare } from '../../../../utils/string';
import ProjectAccess from './ProjectAccess';

interface ProjectAccessContainerProps {
  dialogMode: DialogMode;
  appGroup: AppGroup;
  appGroupProjects: Array<Project>;
  onError: (error: { response: { data: ApiResult<any> } }) => void;
  addProject: (project: Project) => { type: AppGroupActionType; payload: { project: Project } };
  removeProject: (project: Project) => { type: AppGroupActionType; payload: { project: Project } };
  addToOldState: (oldState: Partial<AppGroupState>) => { type: AppGroupActionType; payload: { oldState: Partial<AppGroupState> } };
}

interface ProjectAccessContainerState {
  isLoading: boolean;
  inaccProjects: Array<Project>;
}

class ProjectAccessContainer extends Component<ProjectAccessContainerProps, ProjectAccessContainerState> {
  state: ProjectAccessContainerState = {
    isLoading: false,
    inaccProjects: [],
  };

  componentDidMount() {
    const projectRequests: Array<Promise<any>> = [ProjectsReqHandler.loadProjects('prjName')];
    if (this.props.dialogMode !== DialogMode.CREATE && !this.props.appGroupProjects.length) {
      projectRequests.push(prjAppGroupOverseeReqHandler.loadPrjAppGroupOversees(0, this.props.appGroup.appGroupId));
    }
    this.setState({ isLoading: true }, () =>
      Promise.all(projectRequests)
        .then(([projects, oversees]) => {
          const filteredProjects = projects.filter((proj: Project) => {
            if (oversees && oversees.length) {
              const isProjectInGroup = !!oversees.find((group: PrjAppGroupOversee) => group.projectId === proj.prjId);
              if (isProjectInGroup) {
                this.props.addProject(proj);
              }
            }
            return this.props.appGroupProjects.findIndex(p => p.prjId === proj.prjId) < 0;
          });
          this.props.addToOldState({ projects: this.props.appGroupProjects });
          const inaccProjects = this.sortProjects(filteredProjects);
          this.setState({ inaccProjects, isLoading: false });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          this.props.onError(error);
        })
    );
  }

  handleMove = (id: number, toAuthorized: boolean) => {
    if (this.props.dialogMode === DialogMode.VIEW) {
      return;
    }
    const projectsList = [...this.state.inaccProjects];
    if (toAuthorized) {
      let projectIndex = 0;
      const selectedProject = projectsList.find((project, index) => {
        projectIndex = index;
        return project.prjId === id;
      });
      projectsList.splice(projectIndex, 1);
      if (selectedProject) {
        this.props.addProject(selectedProject);
      }
    } else {
      const selectedProject = this.props.appGroupProjects.find(project => {
        return project.prjId === id;
      });
      if (selectedProject) {
        this.props.removeProject(selectedProject);
        projectsList.push(selectedProject);
      }
    }
    this.setState({ inaccProjects: this.sortProjects(projectsList) });
  };

  private sortProjects(projects: Array<Project>): Array<Project> {
    return projects.sort((first, second) => compare(first.prjName, second.prjName));
  }

  render() {
    return (
      <ProjectAccess
        dialogMode={this.props.dialogMode}
        isLoading={this.state.isLoading}
        inaccProjects={this.state.inaccProjects}
        accProjects={this.sortProjects(this.props.appGroupProjects)}
        moveProject={this.handleMove}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({ appGroup: getAppGroup(state), appGroupProjects: getAppGroupProjects(state) });

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      addProject,
      removeProject,
      addToOldState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAccessContainer);
