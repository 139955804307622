import { Column } from "material-table";
import EmployeeStatusColumn from "./EmployeeStatus";
import FirstNameColumn from "./FirstName";
import LastNameColumn from "./LastName";
import { UseTranslationResponse } from "react-i18next";
import Employee from "../../../../models/Employee";

const columns = (translation: UseTranslationResponse): Column<Employee>[] => {
    return [
        EmployeeStatusColumn(translation),
        FirstNameColumn(translation),
        LastNameColumn(translation),
    ];
}

export default columns;