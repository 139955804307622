import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import Project from "../../../../models/Project";
import { compare } from "../../../../utils/string";

const ProjectNameColumn = (translation: UseTranslationResponse): Column<Project> => {
    return {
      title: translation.t('projectName'),
      field: 'prjName',
      customSort: (first: Project, second: Project) => {
        return compare(first.prjName, second.prjName);
      }
    };
};

export default ProjectNameColumn;
