import { AppGroupState } from '../reducers/appGroupsReducer';

export type SelectAppGroupState = { appGroups: AppGroupState };

export const getAppGroup = ({ appGroups }: SelectAppGroupState) => appGroups.appGroup;

export const getAppGroupName = (state: SelectAppGroupState) => getAppGroup(state).appGroupName;

export const getAppGroupDescription = (state: SelectAppGroupState) => getAppGroup(state).appGroupDescr;

export const getAppGroupAppUsers = ({ appGroups }: SelectAppGroupState) => appGroups.appUsers;

export const getAppGroupPermissions = ({ appGroups }: SelectAppGroupState) => appGroups.permissions;

export const getAppGroupCustomers = ({ appGroups }: SelectAppGroupState) => appGroups.customers;

export const getAppGroupProjects = ({ appGroups }: SelectAppGroupState) => appGroups.projects;

export const getAppGroupStaff = ({ appGroups }: SelectAppGroupState) => appGroups.staff;

export const getOldState = ({ appGroups }: SelectAppGroupState) => appGroups.oldState;

export const getOldStatePermissions = (state: SelectAppGroupState) => getOldState(state).permissions;
