import React from 'react';
import MaterialTable, { Components, Column, Action } from 'material-table';

interface SaraTableProps<RowData extends object> {
  items: Array<RowData>;
  isLoading: boolean;
  isEditable: boolean | ((rowData: RowData) => boolean);
  isDeletable?: boolean | ((rowData: RowData) => boolean);
  editTooltip?: string | ((rowData: RowData) => string);
  deleteTooltip?: string | ((rowData: RowData) => string);
  columns: Column<RowData>[];
  hideHeader?: boolean;
  components?: Components;
  sorting?: boolean;
  actions?: (Action<RowData> | ((rowData: RowData) => Action<RowData>))[];
  actionsColumnIndex?: number;
  actionsCellStyle?: React.CSSProperties;
  onDelete?: (workItem: RowData) => Promise<void>;
  maxHeight?: string;
}

class SaraTable<RowData extends object> extends React.Component<SaraTableProps<RowData>> {
  isEditable = (rowData: RowData): boolean => {
    if (this.props.isEditable instanceof Function) {
      return this.props.isEditable(rowData);
    }
    return this.props.isEditable as boolean;
  };

  isDeletable = (rowData: RowData): boolean => {
    if (this.props.isDeletable instanceof Function) {
      return this.props.isDeletable(rowData);
    }
    return this.props.isDeletable as boolean;
  };

  editTooltip = (rowData: RowData): string => {
    if (typeof this.props.editTooltip === 'function') {
      return this.props.editTooltip(rowData);
    }
    return this.props.editTooltip as string;
  };

  deleteTooltip = (rowData: RowData): string => {
    if (typeof this.props.deleteTooltip === 'function') {
      return this.props.deleteTooltip(rowData);
    }
    return this.props.deleteTooltip as string;
  };

  getEditOptions():
    | {
        isEditable?: (rowData: RowData) => boolean;
        isDeletable?: (rowData: RowData) => boolean;
        onRowAdd?: (newData: RowData) => Promise<void>;
        onRowUpdate?: (newData: RowData, oldData?: RowData) => Promise<void>;
        onRowDelete?: (oldData: RowData) => Promise<void>;
        editTooltip?: (rowData: RowData) => string;
        deleteTooltip?: (rowData: RowData) => string;
      }
    | undefined {
    if (this.props.isEditable) {
      return {
        isDeletable: this.isEditable,
        onRowDelete: this.props.onDelete,
        isEditable: this.isEditable,
        onRowUpdate: (newData: RowData, oldData?: RowData): Promise<void> => Promise.resolve(),
        editTooltip: this.props.editTooltip ? this.editTooltip : undefined,
        deleteTooltip: this.props.deleteTooltip ? this.deleteTooltip : undefined,
      };
    }
    return undefined;
  }

  getDeleteOptions():
    | {
        isDeletable?: (rowData: RowData) => boolean;
        onRowDelete?: (oldData: RowData) => Promise<void>;
        deleteTooltip?: (rowData: RowData) => string;
      }
    | undefined {
    if (this.props.isDeletable) {
      return {
        isDeletable: this.isDeletable,
        onRowDelete: this.props.onDelete,
        deleteTooltip: this.props.deleteTooltip ? this.deleteTooltip : undefined,
      };
    }
    return undefined;
  }

  render() {
    return (
      <MaterialTable
        style={{ width: '100%' }}
        isLoading={this.props.isLoading}
        data={this.props.items}
        columns={this.props.columns}
        options={{
          selection: false,
          paging: false,
          showTitle: false,
          toolbar: false,
          sorting: this.props.sorting,
          header: !this.props.hideHeader,
          headerStyle: {
            fontWeight: 'bold',
            color: '#8E8C92',
            textAlign: 'left',
            position: 'sticky',
            top: 0,
          },
          maxBodyHeight: this.props.maxHeight ? this.props.maxHeight : '80vh',
          actionsColumnIndex: this.props.actionsColumnIndex ? this.props.actionsColumnIndex : 7,
          actionsCellStyle: this.props.actionsCellStyle ? this.props.actionsCellStyle : { color: '#8E8C92' },
          rowStyle: (rowData, index, param) => {
            if (rowData.backgroundColor) {
              return { backgroundColor: rowData.backgroundColor };
            }

            return {};
          },
        }}
        editable={{ ...this.getEditOptions(), ...this.getDeleteOptions() }}
        components={this.props.components}
        actions={this.props.actions}
        localization={{
          header: {
            actions: '',
          },
        }}
      />
    );
  }
}

export default SaraTable;
