import genericApi, { baseUrl } from './genericApi';
import WorkItem from '../models/WorkItem';
import UpdateWorkItem from '../models/UpdateWorkItem';
import { getFirstDayLastMonth, getFirstDayNextMonth } from '../utils/dateTime';

const workTimeReqHandler = {
  loadWorkItems: (): Promise<Array<WorkItem>> => {
    return genericApi.get('worktime');
  },
  loadWorkItemsByEmpAndDate: (empId: number, date: string, toDate: string): Promise<Array<WorkItem>> => {
    return genericApi.get('worktime?filterByEmpId=' + empId
      + '&filterFromDate=' + encodeURIComponent(date)
      + '&filterToDate=' + encodeURIComponent(toDate)
      + '&sort=wtStartTime, DESC');
  },
  insertWorkItem: (workItem: UpdateWorkItem, supressWarning: string | null): Promise<WorkItem> => {
    let queryArgs = supressWarning ? '?suppressWarning=' + supressWarning : '';
    return genericApi.post('worktime' + queryArgs, { ...workItem, workTimeId: 0 });
  },
  deleteWorkItem: (workItemId: number): Promise<void> => {
    return genericApi.delete('worktime/' + workItemId);
  },
  updateWorkItem: (id: number, workItem: UpdateWorkItem, supressWarning: string | null): Promise<WorkItem> => {
    let queryArgs = supressWarning ? `?suppressWarning=${supressWarning}` : '';
    return genericApi.put(`worktime/${id}${queryArgs}`, workItem);
  },
  loadOvertime: (empId: number, startDate: string, endDate: string):Promise<number>  => {
    const url: string = `overtime?filterByEmpId=${empId}&filterStartDate=${startDate}&filterEndDate=${endDate}`;
    return genericApi.get(url);
  }
};

export const exportUrl = (employeeId: number): string => {
  return baseUrl + 'worktime/csv?filterByEmpId=' + employeeId + '&filterFromDate=' +
    getFirstDayLastMonth() + '&filterToDate=' + getFirstDayNextMonth();
}

export default workTimeReqHandler;