import React, { Component } from 'react';
import { InputLabel, Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardTimePicker } from '@material-ui/pickers';

const styles = (theme: Theme) =>
  createStyles({
    timePickerWithoutIcon: {
      '& .MuiInputAdornment-positionEnd button': {
        display: 'none',
      },
    },
  });

interface TimePickerProps extends WithStyles<typeof styles>, WithTranslation {
  timerMode: boolean;
  label: string;
  value: ParsableDate;
  onChange: (date: MaterialUiPickersDate | null, value?: string | null) => void;
  errorText?: string | null | undefined;
  validated: boolean;
  select?: boolean;
  onSelected?: () => void;
}

interface TimePickerState {
  errorText?: string | null | undefined;
  hasError: boolean;
}

class TimePickerContainer extends Component<TimePickerProps, TimePickerState> {
  timePickerRef: React.RefObject<any>;

  constructor(props: TimePickerProps) {
    super(props);
    this.state = { errorText: '', hasError: false };
    this.timePickerRef = React.createRef<any>();
  }

  componentDidUpdate(prevProps: TimePickerProps, prevState: TimePickerState) {
    if (this.props.select && this.timePickerRef.current) {
      this.timePickerRef.current.children[1].firstChild.select();
      this.props.onSelected && this.props.onSelected();
    }
    if (!prevProps.validated && this.props.validated) {
      //need to run validation after save
      this.handleValidation();
    }
  }

  handleValidation() {
    this.setState({ errorText: '', hasError: false }, () => {
      if (!this.props.errorText) {
        if (this.props.validated && !this.props.value) {
          this.setState({ errorText: this.props.t('fieldIsMandatory'), hasError: true });
        }
        if (this.props.value && this.props.value.toString() === 'Invalid Date') {
          this.setState({ errorText: this.props.t('wrongFormat'), hasError: true });
        }
      } else {
        this.setState({ errorText: this.props.errorText, hasError: !!this.props.errorText });
      }
    });
  }

  render() {
    if (!this.props.timerMode) {
      return (
        <KeyboardTimePicker
          ampm={false}
          variant="inline"
          label={this.props.label}
          value={this.props.value}
          onChange={this.props.onChange}
          fullWidth={true}
          onBlur={() => this.handleValidation()}
          error={this.state.hasError}
          helperText={this.state.errorText ? this.state.errorText : ''}
          required
          keyboardIcon={null}
          className={this.props.classes.timePickerWithoutIcon}
          innerRef={this.timePickerRef}
          ref={null}
          style={undefined}
          onFocus={event => this.props.select && event.target.select()}
          rows={''}
          rowsMax={''}
        />
      );
    }
    return <InputLabel style={{ marginTop: '19px' }}>{this.props.label}</InputLabel>;
  }
}

export default withTranslation()(withStyles(styles)(TimePickerContainer));
