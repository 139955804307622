import { Dialog, DialogContent, Step, StepButton, Stepper, Theme } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogMode } from '../../../models/DialogMode';
import SaraDialogTitle from '../../common/SaraDialogTitle';
import Customer from '../../../models/Customer';
import { CustomerStep } from '../../../models/Step';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      padding: '24px',
      height: '80%',
    },
    // In the future remove this class if the stepper has more than two steps
    twoStepsStepper: {
      width: '50%',
    },
    header: {
      marginLeft: '26px',
      marginBottom: '26px'
    }
  });

interface CustomerDialogProps extends WithStyles<typeof styles> {
  customer: Customer;
  dialogMode: DialogMode;
  isOpen: boolean;
  dialogActions: ReactNode;
  activeStep: CustomerStep;
  showWarning: boolean;
  onClose: (event: any) => void;
  onClickLabel: (label: string) => void;
  steps: Array<string>;
  stepContent: Function;
  viewSteps: boolean;
}

const CustomerDialog: React.FC<CustomerDialogProps> = (props: CustomerDialogProps) => {
  const { t } = useTranslation();
  const header: JSX.Element = props.viewSteps ? 
    <Stepper className={props.classes.twoStepsStepper} nonLinear activeStep={props.activeStep}>
      {props.steps.map((label: string) => {
        const stepProps: { completed?: boolean } = {};
        return (
          <Step key={label} {...stepProps}>
            <StepButton onClick={() => props.onClickLabel(label)}>{t(label)}</StepButton>
          </Step>
        );
      })}
    </Stepper> :
    <h3 className={ props.classes.header }>{t(props.steps[0])}</h3>

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="dialog-title"
      maxWidth="md"
      fullWidth={true}
      classes={{ paper: props.classes.dialog }}
    >
      <SaraDialogTitle
        onClose={props.onClose}
        text={props.dialogMode === DialogMode.CREATE ? t('newCustomerTitle') : props.customer.customerName}
        showWarning={props.showWarning}
        warning={t('closeAndDiscardWarning')}
      />

      { header }
     
      <DialogContent>
        <div>{props.stepContent(props.activeStep)}</div>
      </DialogContent>
      {props.dialogActions}
    </Dialog>
  );
};

export default withStyles(styles)(CustomerDialog);
