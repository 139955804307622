import { FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PersonIcon from '@material-ui/icons/Person';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogMode } from '../../../../models/DialogMode';
import Employee from '../../../../models/Employee';
import { validateEmail, validatePhone } from '../../../../utils/validation';
import SaraDatePicker from '../../../common/SaraDatePicker';
import { Lookup, SaraSelect } from '../../../saraSelect/SaraSelect';
import EmployeeFormReadonlyFields from './EmployeeFormReadonlyFields';

const styles = () =>
  createStyles({
    iconFieldContainer: {
      display: 'flex',
      '& > svg, & > .iconPlaceHolder': {
        height: '100%',
        paddingTop: 9,
        marginRight: 6,
        width: 24,
      },
      '& > .formField': {
        display: 'block',
        width: '100%',
      },
    },
  });

interface EmployeeFormProps extends WithStyles<typeof styles> {
  employee: Employee;
  handleChange: (event: any, field: string) => void;
  validated: boolean;
  offices: Array<Lookup>;
  readonlyFields: EmployeeFormReadonlyFields | null;
  dialogMode: DialogMode;
  isStartDateInvalid: boolean;
  isEndDateInvalid: boolean;
  isProbDateInvalid: boolean;
}

const EmployeeForm: React.FC<EmployeeFormProps> = (props: EmployeeFormProps) => {
  const { t } = useTranslation();

  const dayWorkHoursInvalid = props.employee.dayWorkHours < 0 || props.employee.dayWorkHours > 24;
  const phone = props.employee.empPhone;
  const mobile = props.employee.empMobile;
  const isValidPhone = phone ? validatePhone(phone) : true;
  const isValidMobile = mobile ? validatePhone(mobile) : true;
  const getEmailErrorMessage = (): string | null => {
    if (props.validated) {
      if (!props.employee.empEmail) {
        return t('fieldIsMandatory');
      }
      if (!validateEmail(props.employee.empEmail)) {
        return t('validEmailMessage');
      }
      return null;
    }
    return null;
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} className={props.classes.iconFieldContainer}>
        <PersonIcon color="secondary" />
        <TextField
          label={t('firstName')}
          fullWidth
          value={props.employee.empFirstName || ''}
          required
          onChange={event => props.handleChange(event, 'empFirstName')}
          error={props.validated && !props.employee.empFirstName}
          helperText={props.validated && !props.employee.empFirstName ? t('fieldIsMandatory') : null}
          InputProps={{ disabled: !!props.readonlyFields && props.readonlyFields.firstNameReadonly }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={props.classes.iconFieldContainer}>
        <div className="iconPlaceHolder" />
        <TextField
          label={t('lastName')}
          fullWidth
          value={props.employee.empLastName || ''}
          required
          onChange={event => props.handleChange(event, 'empLastName')}
          error={props.validated && !props.employee.empLastName}
          helperText={props.validated && !props.employee.empLastName ? t('fieldIsMandatory') : null}
          InputProps={{ disabled: !!props.readonlyFields && props.readonlyFields.lastNameReadonly }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={props.classes.iconFieldContainer}>
        <ContactMailIcon color="secondary" />
        <TextField
          label={t('email')}
          fullWidth
          value={props.employee.empEmail || ''}
          required
          onChange={event => props.handleChange(event, 'empEmail')}
          error={props.validated && (!props.employee.empEmail || !validateEmail(props.employee.empEmail))}
          helperText={getEmailErrorMessage()}
          InputProps={{ disabled: !!props.readonlyFields && props.readonlyFields.emailReadonly }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={props.classes.iconFieldContainer}>
        <div className="iconPlaceHolder" />
        <div className="formField">
          {props.dialogMode === DialogMode.VIEW ? (
            <TextField label={t('office')} fullWidth value={props.employee.officeName} InputProps={{ disabled: true }} />
          ) : (
            <SaraSelect
              id="employee-office"
              label={t('office')}
              validated={props.validated}
              value={props.employee.officeId}
              lookups={props.offices}
              onChange={(event: any) => props.handleChange(event, 'officeId')}
              required
            />
          )}
        </div>
      </Grid>
      <Grid item xs={12} sm={6} className={props.classes.iconFieldContainer}>
        <AccessTimeIcon color="secondary" />
        <TextField
          label={t('hoursPerDay')}
          fullWidth
          type="number"
          value={props.employee.dayWorkHours}
          required
          onChange={event => props.handleChange(event, 'dayWorkHours')}
          error={props.validated && dayWorkHoursInvalid}
          helperText={props.validated && dayWorkHoursInvalid ? t('hoursPerDayValidationMessage') : null}
          InputProps={{ disabled: props.dialogMode === DialogMode.VIEW }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={props.classes.iconFieldContainer}>
        <div className="iconPlaceHolder" />
        <FormControlLabel
          control={
            <Switch
              checked={!!props.employee.isActive}
              onChange={event => props.handleChange(event, 'isActive')}
              value="isActive"
              color="primary"
              disabled={!!props.readonlyFields && props.readonlyFields.isActiveReadonly}
            />
          }
          label={t('active')}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={props.classes.iconFieldContainer}>
        <DateRangeIcon color="secondary" />
        {props.dialogMode === DialogMode.VIEW ? (
          <TextField
            label={t('startDate')}
            fullWidth
            value={props.employee.startDate || ''}
            InputProps={{ disabled: true }}
            InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
          />
        ) : (
          <SaraDatePicker
            label={t('startDate')}
            value={props.employee.startDate || ''}
            onChange={event => props.handleChange(event, 'startDate')}
            validated={props.validated}
            fullWidth
            error={props.isStartDateInvalid}
            helperText={props.isStartDateInvalid ? t('employeeStartDateAfterEndDate') : ''}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={4} className={props.classes.iconFieldContainer}>
        <div className="iconPlaceHolder" />
        {props.dialogMode === DialogMode.VIEW ? (
          <TextField
            label={t('endDate')}
            fullWidth
            value={props.employee.endDate || ''}
            InputProps={{ disabled: true }}
            InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
          />
        ) : (
          <SaraDatePicker
            label={t('endDate')}
            value={props.employee.endDate || ''}
            onChange={event => props.handleChange(event, 'endDate')}
            validated={props.validated}
            fullWidth
            error={props.isEndDateInvalid}
            helperText={props.isEndDateInvalid ? t('employeeEndDateBeforeStartDate') : ''}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={4} className={props.classes.iconFieldContainer}>
        <div className="iconPlaceHolder" />
        {props.dialogMode === DialogMode.VIEW ? (
          <TextField
            label={t('probationEnd')}
            fullWidth
            value={props.employee.endProbDate || ''}
            InputProps={{ disabled: true }}
            InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
          />
        ) : (
          <SaraDatePicker
            label={t('probationEnd')}
            value={props.employee.endProbDate || ''}
            onChange={event => props.handleChange(event, 'endProbDate')}
            validated={props.validated}
            fullWidth
            error={props.isProbDateInvalid}
            helperText={props.isProbDateInvalid ? t('employeeProbDateBetweenStartAndEnd') : ''}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6} className={props.classes.iconFieldContainer}>
        <PersonIcon color="secondary" />
        <TextField
          label={t('phone')}
          fullWidth
          value={props.employee.empPhone || ''}
          onChange={event => props.handleChange(event, 'empPhone')}
          error={props.validated && !isValidPhone}
          helperText={props.validated && !isValidPhone ? t('wrongFormat') : null}
          InputProps={{ disabled: props.dialogMode === DialogMode.VIEW }}
          InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={props.classes.iconFieldContainer}>
        <div className="iconPlaceHolder" />
        <TextField
          label={t('mobile')}
          fullWidth
          value={props.employee.empMobile || ''}
          error={props.validated && !isValidMobile}
          helperText={props.validated && !isValidMobile ? t('wrongFormat') : null}
          onChange={event => props.handleChange(event, 'empMobile')}
          InputProps={{ disabled: !!props.readonlyFields && props.readonlyFields.isMobileReadonly }}
        />
      </Grid>
      <Grid item xs={12} sm={12} className={props.classes.iconFieldContainer}>
        <BusinessCenterIcon color="secondary" />
        <TextField
          label={t('address')}
          fullWidth
          value={props.employee.empHomeAddress || ''}
          onChange={event => props.handleChange(event, 'empHomeAddress')}
          multiline
          rows={1}
          rowsMax={4}
          InputProps={{ disabled: props.dialogMode === DialogMode.VIEW }}
          InputLabelProps={{ shrink: props.dialogMode === DialogMode.VIEW ? true : undefined }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(EmployeeForm);
