import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";
import Project from "../../models/Project";

const returnedData: ApiResult<any> = {
    "messages": [],
    "result": [
        {
            "prjId": 11,
            "prjName": "Education",
            "prjDescr": "Education/training for new technologies.",
            "prjStartDate": "2011-12-01",
            "prjEndDate": "2012-12-31",
            "prjLead": 1,
            "prjLeadFirstName": "Angel_dev",
            "prjLeadLastName": "Angelov",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 7,
            "customerName": "ITCIS HQ",
            "prjColor": null,
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "11",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Angel_dev Angelov"
        },
        {
            "prjId": 7,
            "prjName": "Sorcim Analyzer",
            "prjDescr": null,
            "prjStartDate": "2011-12-01",
            "prjEndDate": "2012-12-31",
            "prjLead": 4,
            "prjLeadFirstName": "Georgi",
            "prjLeadLastName": "Georgiev",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 6,
            "customerName": "Sorcim Compass HQ",
            "prjColor": "17A617",
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "7",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Georgi Georgiev"
        },
        {
            "prjId": 41,
            "prjName": "Mitel - minor assignments",
            "prjDescr": "Coldimex - minor assignments",
            "prjStartDate": "2012-01-01",
            "prjEndDate": "2012-06-30",
            "prjLead": 4,
            "prjLeadFirstName": "Georgi",
            "prjLeadLastName": "Georgiev",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 21,
            "customerName": "Mitel",
            "prjColor": "FF1FF8",
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "41",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Georgi Georgiev"
        },
        {
            "prjId": 10,
            "prjName": "Internal Billing System",
            "prjDescr": "Вътрешен проект - IBS",
            "prjStartDate": "2011-12-01",
            "prjEndDate": "2012-12-31",
            "prjLead": 1,
            "prjLeadFirstName": "Angel_dev",
            "prjLeadLastName": "Angelov",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 7,
            "customerName": "ITCIS HQ",
            "prjColor": "60ACE6",
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "10",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Angel_dev Angelov"
        },
        {
            "prjId": 101,
            "prjName": "Store Management System",
            "prjDescr": "",
            "prjStartDate": "2012-02-01",
            "prjEndDate": "2012-12-31",
            "prjLead": 1,
            "prjLeadFirstName": "Angel_dev",
            "prjLeadLastName": "Angelov",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 7,
            "customerName": "ITCIS HQ",
            "prjColor": null,
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "101",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Angel_dev Angelov"
        },
        {
            "prjId": 6,
            "prjName": "Sorcim websites",
            "prjDescr": "Supporting, minor fixes and enhancements.",
            "prjStartDate": "2011-12-01",
            "prjEndDate": "2012-12-31",
            "prjLead": 4,
            "prjLeadFirstName": "Georgi",
            "prjLeadLastName": "Georgiev",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 6,
            "customerName": "Sorcim Compass HQ",
            "prjColor": "269AFF",
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "6",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Georgi Georgiev"
        },
        {
            "prjId": 21,
            "prjName": "CA - not billed",
            "prjDescr": "",
            "prjStartDate": "2012-01-01",
            "prjEndDate": "2012-12-31",
            "prjLead": 1,
            "prjLeadFirstName": "Angel_dev",
            "prjLeadLastName": "Angelov",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 7,
            "customerName": "ITCIS HQ",
            "prjColor": null,
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "21",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Angel_dev Angelov"
        },
        {
            "prjId": 164,
            "prjName": "R002",
            "prjDescr": "",
            "prjStartDate": "2020-01-01",
            "prjEndDate": "2020-01-31",
            "prjLead": 27,
            "prjLeadFirstName": "Rosen",
            "prjLeadLastName": "Dochev",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 7,
            "customerName": "ITCIS HQ",
            "prjColor": null,
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "R002",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Rosen Dochev"
        },
        {
            "prjId": 5,
            "prjName": "Equifax Development",
            "prjDescr": null,
            "prjStartDate": "2011-12-01",
            "prjEndDate": "2012-12-31",
            "prjLead": 1,
            "prjLeadFirstName": "Angel_dev",
            "prjLeadLastName": "Angelov",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 4,
            "customerName": "Equifax HQ",
            "prjColor": "1111D9",
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "5",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Angel_dev Angelov"
        },
        {
            "prjId": 166,
            "prjName": "Created by David",
            "prjDescr": "",
            "prjStartDate": "2020-01-02",
            "prjEndDate": null,
            "prjLead": 4,
            "prjLeadFirstName": "Georgi",
            "prjLeadLastName": "Georgiev",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 7,
            "customerName": "ITCIS HQ",
            "prjColor": null,
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "1111",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Georgi Georgiev"
        },
        {
            "prjId": 3,
            "prjName": "Tucows General Project",
            "prjDescr": "",
            "prjStartDate": "2011-12-01",
            "prjEndDate": "2012-05-31",
            "prjLead": 2,
            "prjLeadFirstName": "Boris",
            "prjLeadLastName": "Borisov",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 5,
            "customerName": "Tucows HQ",
            "prjColor": null,
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "3",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Boris Borisov"
        },
        {
            "prjId": 4,
            "prjName": "CA",
            "prjDescr": "",
            "prjStartDate": "2011-12-01",
            "prjEndDate": "2012-12-31",
            "prjLead": 1,
            "prjLeadFirstName": "Angel_dev",
            "prjLeadLastName": "Angelov",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 2,
            "customerName": "Cemex South",
            "prjColor": null,
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "4",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Angel_dev Angelov"
        },
        {
            "prjId": 13,
            "prjName": "testProj",
            "prjDescr": null,
            "prjStartDate": "2019-02-21",
            "prjEndDate": "2019-12-12",
            "prjLead": 1,
            "prjLeadFirstName": "Angel_dev",
            "prjLeadLastName": "Angelov",
            "prjTimeBudget": null,
            "statusCd": "PLANNED",
            "statusName": null,
            "customerId": 1,
            "customerName": "Cemex HQ",
            "prjColor": null,
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "13",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Angel_dev Angelov"
        },
        {
            "prjId": 167,
            "prjName": "01-TEST",
            "prjDescr": "",
            "prjStartDate": "2019-10-08",
            "prjEndDate": null,
            "prjLead": 27,
            "prjLeadFirstName": "Rosen",
            "prjLeadLastName": "Dochev",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 7,
            "customerName": "ITCIS HQ",
            "prjColor": null,
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "01",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Rosen Dochev"
        },
        {
            "prjId": 1,
            "prjName": "Tucows Middleware Project",
            "prjDescr": null,
            "prjStartDate": "2011-12-01",
            "prjEndDate": "2012-05-31",
            "prjLead": 2,
            "prjLeadFirstName": "Boris",
            "prjLeadLastName": "Borisov",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 5,
            "customerName": "Tucows HQ",
            "prjColor": "6890ED",
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "1",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Boris Borisov"
        },
        {
            "prjId": 2,
            "prjName": "Tucows Web Project",
            "prjDescr": null,
            "prjStartDate": "2011-12-01",
            "prjEndDate": "2012-05-31",
            "prjLead": 2,
            "prjLeadFirstName": "Boris",
            "prjLeadLastName": "Borisov",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 5,
            "customerName": "Tucows HQ",
            "prjColor": "0F47FF",
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "2",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Boris Borisov"
        },
        {
            "prjId": 81,
            "prjName": "MCC",
            "prjDescr": null,
            "prjStartDate": "2012-02-20",
            "prjEndDate": "2012-05-31",
            "prjLead": 1,
            "prjLeadFirstName": "Angel_dev",
            "prjLeadLastName": "Angelov",
            "prjTimeBudget": null,
            "statusCd": "ACTIVE",
            "statusName": null,
            "customerId": 41,
            "customerName": "Motor Car Company NY",
            "prjColor": "0F6127",
            "hasFiles": null,
            "groupIds": null,
            "groupNames": null,
            "canDelete": null,
            "isProjectDuplicatedInList": false,
            "prjCurrencyCd": null,
            "sowCode": "81",
            "taskId": null,
            "taskName": null,
            "prjLeadFullName": "Angel_dev Angelov"
        }
    ]
}

const returnedProject: ApiResult<Project>={
    messages: [],
    result: {
        customerId: 1,
        prjId: 1,
        prjLead: 1,
        prjName: 'aaa',
        sowCode: '222'
    }
}

export const ProjectsGet: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /projects/,
    method: 'GET'
}

export const ProjectsPost: FakeRequest = {
    data: returnedProject,
    httpCode: 200,
    url: /projects/,
    method: 'POST'
}

export const ProjectsPut: FakeRequest = {
    data: returnedProject,
    httpCode: 200,
    url: /projects/,
    method: 'PUT'
}
