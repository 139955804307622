import genericApi from './genericApi';
import Office from '../models/Office';

const officesReqHandler = {
  loadOffices: (): Promise<Array<Office>> => {
    return genericApi.get('offices');
  },
  loadOffice: (officeId: number): Promise<Office> => {
    return genericApi.get(`offices/${officeId}`);
  },
};

export default officesReqHandler;
