import { SAVE_USER, REMOVE_USER, GO_OFFLINE, GO_ONLINE, SAVE_USER_NAMES, REMOVE_USER_NAMES, SET_TIMEZONE } from '../actions/actionsTypes';

import User from '../../models/User';

export interface UserState {
  user: User;
  firstName: string;
  lastName: string;
  isOffline: boolean;
  timezone: string;
}

const initialState: UserState = {
  user: { username: '', appUserId: -1, empId: null, officeId: -1 },
  firstName: '',
  lastName: '',
  isOffline: false,
  timezone: '',
};

export default (state: UserState = initialState, action: any): UserState => {
  switch (action.type) {
    case SAVE_USER:
      return { ...state, user: { ...action.data } };
    case REMOVE_USER:
      return { ...state, user: { username: '', appUserId: -1, empId: null, officeId: -1 } };
    case GO_OFFLINE:
      return { ...state, isOffline: true };
    case GO_ONLINE:
      return { ...state, isOffline: false };
    case SAVE_USER_NAMES:
      return { ...state, firstName: action.payload.firstName, lastName: action.payload.lastName };
    case REMOVE_USER_NAMES:
      return { ...state, firstName: '', lastName: '' };
    case SET_TIMEZONE:
      return { ...state, timezone: action.data };
    default:
      return state;
  }
};
