import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";
import Task from "../../models/Task";

const returnedData: ApiResult<any> = {
    "messages": [],
    "result": [
        {
            "taskId": 698,
            "taskName": "TEST123",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-23",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 696,
            "taskName": "TASK-008",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 681,
            "taskName": "004-PROJECT-5",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 694,
            "taskName": "TASK-006-123",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-27",
            "taskEndDate": "2020-01-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 247,
            "taskName": "Minor WebUI enhancements",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-07-28",
            "taskEndDate": "2019-12-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 693,
            "taskName": "TASK-005",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 128,
            "taskName": "Project Management",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 2,
            "assignedToName": "Boris Borisov",
            "assignedBy": 2,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-08-21",
            "taskEndDate": "2020-12-19",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 101,
            "taskName": "Add wallpaper banner",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-08-19",
            "taskEndDate": "2019-08-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 21,
            "taskName": "Support/Investigations",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-09-27",
            "taskEndDate": "2019-12-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 678,
            "taskName": "005-PROJECT-1",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 683,
            "taskName": "TASK-001",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-27",
            "taskEndDate": "2020-01-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 691,
            "taskName": "TASK-003",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 11,
            "taskName": "testTask",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 5,
            "assignedToName": "Test Testy",
            "assignedBy": 26,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-08",
            "taskEndDate": "2019-12-08",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 381,
            "taskName": "Python and Django",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-03-27",
            "taskEndDate": "2019-04-25",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 444,
            "taskName": "Content Management System",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-03-29",
            "taskEndDate": "2019-08-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 248,
            "taskName": "321213: Virtual links",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-08-11",
            "taskEndDate": "2019-04-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 7,
            "taskName": "testTask",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 5,
            "assignedToName": "Test Testy",
            "assignedBy": 5,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-08",
            "taskEndDate": "2019-12-08",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 703,
            "taskName": "003-TASK",
            "taskDescr": null,
            "projectId": 167,
            "projectName": "01-TEST",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 20,
            "assignedToName": "Test Test",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-12",
            "taskEndDate": "2020-01-14",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 679,
            "taskName": "004-PROJECT-3",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-27",
            "taskEndDate": "2019-12-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 3,
            "taskName": "DB Optimization",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-09-27",
            "taskEndDate": "2019-10-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 242,
            "taskName": "JQuery",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-07-27",
            "taskEndDate": "2019-12-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 241,
            "taskName": "PHP",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-08-27",
            "taskEndDate": "2019-12-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 697,
            "taskName": "TEST123",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-02",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 221,
            "taskName": "MySQL Database",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-08-27",
            "taskEndDate": "2022-12-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 251,
            "taskName": "Project management",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-08-28",
            "taskEndDate": "2019-12-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 521,
            "taskName": "Newsletters and Polls",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-05-11",
            "taskEndDate": "2019-05-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 362,
            "taskName": "Stand-alone webservice example",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-02-25",
            "taskEndDate": "2019-12-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 361,
            "taskName": "Specs. write/review",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-02-25",
            "taskEndDate": "2019-12-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 123,
            "taskName": "Quality Assurance",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 3,
            "assignedToName": "Victor Victorov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-07-28",
            "taskEndDate": "2019-07-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 62,
            "taskName": "Move GALLERY module to production sites",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-10-06",
            "taskEndDate": "2019-10-09",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 246,
            "taskName": "CMS for the site of big food factory",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-09-23",
            "taskEndDate": "2019-04-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 688,
            "taskName": "TEST3",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 15,
            "taskName": "testTask",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 26,
            "assignedToName": "Gergana Grudeva",
            "assignedBy": 26,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-08",
            "taskEndDate": "2019-12-08",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 302,
            "taskName": "DB improvement",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-02-25",
            "taskEndDate": "2019-03-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 42,
            "taskName": "Remove online creation of thumbnails",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-09-30",
            "taskEndDate": "2019-10-02",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 6,
            "taskName": "Maintenance, minor enhancements",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-09-27",
            "taskEndDate": "2019-12-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 4,
            "taskName": "DB Data Reduction",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-09-27",
            "taskEndDate": "2019-10-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 245,
            "taskName": "42348: Fix file names in Japanese",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-08-28",
            "taskEndDate": "2019-03-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 690,
            "taskName": "TASK-002",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 244,
            "taskName": "Project Management",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-07-21",
            "taskEndDate": "2020-12-19",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 12,
            "taskName": "testTask",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 5,
            "assignedToName": "Test Testy",
            "assignedBy": 26,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-08",
            "taskEndDate": "2019-12-08",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 126,
            "taskName": "Billing Systame v3.0",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 5,
            "assignedToName": "Test Testy",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-07-28",
            "taskEndDate": "2019-07-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 686,
            "taskName": "TEST1",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-27",
            "taskEndDate": "2020-01-12",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 726,
            "taskName": "004-TASK",
            "taskDescr": null,
            "projectId": 167,
            "projectName": "01-TEST",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 19,
            "assignedToName": "Ivan Ivanov",
            "assignedBy": 25,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-25",
            "taskEndDate": "2020-01-04",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 5,
            "taskName": "Maintenance, minor enhancements",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-09-27",
            "taskEndDate": "2019-12-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 243,
            "taskName": "Project management",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-09-16",
            "taskEndDate": "2019-05-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 680,
            "taskName": "004-PROJECT-4",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-27",
            "taskEndDate": "2019-12-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 676,
            "taskName": "004-PROJECT-2",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-27",
            "taskEndDate": "2020-01-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 83,
            "taskName": "Custom DB",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-09-27",
            "taskEndDate": "2019-09-25",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 321,
            "taskName": "JSP and Java",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-02-24",
            "taskEndDate": "2019-04-25",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 700,
            "taskName": "T001",
            "taskDescr": null,
            "projectId": 164,
            "projectName": "R002",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-11-30",
            "taskEndDate": "2020-01-07",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 668,
            "taskName": "The task right there",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 25,
            "assignedToName": "David Gregor",
            "assignedBy": 25,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-08",
            "taskEndDate": "2019-12-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 122,
            "taskName": "Setting new website ",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-07-28",
            "taskEndDate": "2019-08-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 3,
            "assignedToName": "Victor Victorov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-04-27",
            "taskEndDate": "2019-07-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 687,
            "taskName": "TEST2",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 226,
            "taskName": "Quality Assurance",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 3,
            "assignedToName": "Victor Victorov",
            "assignedBy": 2,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-08-28",
            "taskEndDate": "2019-07-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 124,
            "taskName": "R1 Development",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 5,
            "assignedToName": "Test Testy",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-07-28",
            "taskEndDate": "2019-07-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 249,
            "taskName": "SVN merging",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-08-28",
            "taskEndDate": "2019-12-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 699,
            "taskName": "asdasd",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 83,
            "assignedToName": "Metodi Metodiev",
            "assignedBy": 25,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2020-01-02",
            "taskEndDate": "2020-01-18",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 125,
            "taskName": "Python",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 5,
            "assignedToName": "Test Testy",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-07-27",
            "taskEndDate": "2022-12-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 702,
            "taskName": "002-TASK",
            "taskDescr": null,
            "projectId": 167,
            "projectName": "01-TEST",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-10",
            "taskEndDate": "2020-01-09",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 201,
            "taskName": "Remove HTML and Javascript Errors on sites",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-08-28",
            "taskEndDate": "2019-09-25",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 261,
            "taskName": "New mailing functionality",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-08-28",
            "taskEndDate": "2019-03-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 81,
            "taskName": "Site To Site Data Exchange",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-09-27",
            "taskEndDate": "2019-04-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-04-27",
            "taskEndDate": "2019-05-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 1,
            "taskName": "1234: Fix \"too many open cursors\"",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 1,
            "assignedToName": "Angel_dev Angelov",
            "assignedBy": 1,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-10-10",
            "taskEndDate": "2019-10-19",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 10,
            "taskName": "testTask",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 5,
            "assignedToName": "Test Testy",
            "assignedBy": 26,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-08",
            "taskEndDate": "2019-12-08",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 701,
            "taskName": "001-TEST",
            "taskDescr": null,
            "projectId": 167,
            "projectName": "01-TEST",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-10-14",
            "taskEndDate": "2020-01-10",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 695,
            "taskName": "TASK-007",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 692,
            "taskName": "TASK-004",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-27",
            "taskEndDate": "2020-01-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 9,
            "taskName": "testTask",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 5,
            "assignedToName": "Test Testy",
            "assignedBy": 26,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-08",
            "taskEndDate": "2019-12-08",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 121,
            "taskName": "CMS for all_websites.com site",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-07-28",
            "taskEndDate": "2019-08-27",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 689,
            "taskName": "TEST",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 181,
            "assignedToName": "Simeon Simeonov",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-28",
            "taskEndDate": "2020-01-05",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 670,
            "taskName": "004-PROJECT-1",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-27",
            "taskEndDate": "2020-01-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 682,
            "taskName": "004-PROJECT-6",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 27,
            "assignedToName": "Rosen Dochev",
            "assignedBy": 27,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-27",
            "taskEndDate": "2020-01-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 422,
            "taskName": "New exhibition for 2012",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 4,
            "assignedToName": "Georgi Georgiev",
            "assignedBy": 4,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-03-28",
            "taskEndDate": "2019-07-26",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        },
        {
            "taskId": 14,
            "taskName": "testTask",
            "taskDescr": null,
            "projectId": 3,
            "projectName": "Tucows General Project",
            "prjCurrencyCd": null,
            "statusCd": "ACTIVE",
            "statusName": "task_status.ACTIVE",
            "assignedTo": 26,
            "assignedToName": "Gergana Grudeva",
            "assignedBy": 26,
            "assignedByName": null,
            "taskHourlyRate": null,
            "taskStartDate": "2019-12-08",
            "taskEndDate": "2019-12-08",
            "onGoing": null,
            "canDelete": false,
            "prjColor": null,
            "prjPosition": 0,
            "prjStatusCd": "ACTIVE"
        }
    ]
}

const returnedTask: ApiResult<Task>={
    messages: [],
    result: {
        projectId: 1,
        projectName: "The name",
        taskId: 5,
        taskName: 'the task name',
        assignedBy: 1
    }
}

export const TasksGet: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /tasks/,
    method: 'GET'
}

export const TasksPost: FakeRequest = {
    data: returnedTask,
    httpCode: 200,
    url: /tasks/,
    method: 'POST'
}

export const TaskPut: FakeRequest = {
    data: returnedTask,
    httpCode: 200,
    url: /tasks/,
    method: 'PUT'
}

export const TaskDelete: FakeRequest = {
    data: returnedTask,
    httpCode: 200,
    url: /tasks/,
    method: 'DELETE'
}
