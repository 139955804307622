import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";

const returnedData: ApiResult<any> = {
    "messages": [],
    "result": [
        {
            "workTimeId": 6,
            "wtDate": "2019-09-18",
            "wtStartTime": "2019-09-18T00:00:00.000Z",
            "wtEndTime": "2019-09-18T00:00:00.000Z",
            "wtComment": "test12345",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 6,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T09:26:56.083Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 11,
            "wtDate": "2019-09-18",
            "wtStartTime": "2019-09-18T00:00:00.000Z",
            "wtEndTime": "2019-09-18T10:10:00.000Z",
            "wtComment": "My entry",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 123,
            "taskName": "Quality Assurance",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T13:38:02.564Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 14,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T16:03:27.075Z",
            "wtEndTime": "2019-11-25T17:10:27.075Z",
            "wtComment": "Some new time",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 261,
            "taskName": "New mailing functionality",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:03:43.188Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572624207075,
            "wtMonthLastDay": 1575129807075,
            "wtMonth": 10
        },
        {
            "workTimeId": 15,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T16:04:57.304Z",
            "wtEndTime": "2019-11-25T16:17:57.304Z",
            "wtComment": "AA",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 251,
            "taskName": "Project management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:05:18.708Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572624297304,
            "wtMonthLastDay": 1575129897304,
            "wtMonth": 10
        },
        {
            "workTimeId": 20,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T16:11:42.684Z",
            "wtEndTime": "2019-11-25T17:00:42.684Z",
            "wtComment": "Reloading 2",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 244,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:12:25.669Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572624702684,
            "wtMonthLastDay": 1575130302684,
            "wtMonth": 10
        },
        {
            "workTimeId": 120,
            "wtDate": "2019-12-10",
            "wtStartTime": "2019-12-10T13:44:00.000Z",
            "wtEndTime": "2019-12-10T13:45:00.000Z",
            "wtComment": "test test 2",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 381,
            "taskName": "Python and Django",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-09T12:12:46.120Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575207840000,
            "wtMonthLastDay": 1577799840000,
            "wtMonth": 11
        },
        {
            "workTimeId": 1511,
            "wtDate": "2019-09-18",
            "wtStartTime": "2019-09-18T00:00:00.000Z",
            "wtEndTime": "2019-09-18T00:00:00.000Z",
            "wtComment": " Issue with Loader",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 21,
            "taskName": "Support/Investigations",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-18T03:40:57.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 160,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 2,
            "wtDate": "2019-02-23",
            "wtStartTime": "2019-02-23T02:00:00.000Z",
            "wtEndTime": "2019-02-24T02:00:00.000Z",
            "wtComment": "test comment",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 6,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-08T11:11:33.480Z",
            "lastUpdateDate": "2019-11-08T11:11:46.915Z",
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1548986400000,
            "wtMonthLastDay": 1551319200000,
            "wtMonth": 1
        },
        {
            "workTimeId": 7,
            "wtDate": "2019-09-18",
            "wtStartTime": "2019-09-18T00:00:00.000Z",
            "wtEndTime": "2019-09-18T10:10:00.000Z",
            "wtComment": "My entry",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 123,
            "taskName": "Quality Assurance",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T09:31:24.941Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 12,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T15:58:33.553Z",
            "wtEndTime": "2019-11-25T16:11:33.553Z",
            "wtComment": "And now",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 101,
            "taskName": "Add wallpaper banner",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:00:09.473Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572623913553,
            "wtMonthLastDay": 1575129513553,
            "wtMonth": 10
        },
        {
            "workTimeId": 16,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T16:07:32.269Z",
            "wtEndTime": "2019-11-25T16:17:32.269Z",
            "wtComment": "Reload pls",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 422,
            "taskName": "New exhibition for 2012",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:07:45.557Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572624452269,
            "wtMonthLastDay": 1575130052269,
            "wtMonth": 10
        },
        {
            "workTimeId": 17,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T16:09:02.580Z",
            "wtEndTime": "2019-11-25T16:17:02.580Z",
            "wtComment": "New one",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 101,
            "taskName": "Add wallpaper banner",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:09:56.142Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572624542580,
            "wtMonthLastDay": 1575130142580,
            "wtMonth": 10
        },
        {
            "workTimeId": 18,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T16:10:56.394Z",
            "wtEndTime": "2019-11-25T16:17:56.394Z",
            "wtComment": "New bind",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 3,
            "taskName": "DB Optimization",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:11:07.353Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572624656394,
            "wtMonthLastDay": 1575130256394,
            "wtMonth": 10
        },
        {
            "workTimeId": 24,
            "wtDate": "2019-11-21",
            "wtStartTime": "2019-11-21T16:45:00.000Z",
            "wtEndTime": "2019-11-21T17:18:00.000Z",
            "wtComment": "Next task to fill",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 226,
            "taskName": "Quality Assurance",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:45:26.269Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572626700000,
            "wtMonthLastDay": 1575132300000,
            "wtMonth": 10
        },
        {
            "workTimeId": 118,
            "wtDate": "2019-12-10",
            "wtStartTime": "2019-12-10T11:18:00.000Z",
            "wtEndTime": "2019-12-10T13:44:00.000Z",
            "wtComment": "test test 2",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 381,
            "taskName": "Python and Django",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-08T11:18:52.103Z",
            "lastUpdateDate": "2019-12-09T12:11:36.046Z",
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575199080000,
            "wtMonthLastDay": 1577791080000,
            "wtMonth": 11
        },
        {
            "workTimeId": 123,
            "wtDate": "2019-12-11",
            "wtStartTime": "2019-12-11T18:27:00.000Z",
            "wtEndTime": "2019-12-11T18:30:00.000Z",
            "wtComment": "asd",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 381,
            "taskName": "Python and Django",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-10T18:27:58.985Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575224820000,
            "wtMonthLastDay": 1577816820000,
            "wtMonth": 11
        },
        {
            "workTimeId": 2125,
            "wtDate": "2019-10-08",
            "wtStartTime": "2019-10-08T00:00:00.000Z",
            "wtEndTime": "2019-10-08T00:00:00.000Z",
            "wtComment": "Bugfix: New connector",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T04:54:45.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 260,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 3,
            "wtDate": "2019-02-23",
            "wtStartTime": "2019-02-23T00:00:00.000Z",
            "wtEndTime": "2019-02-24T00:00:00.000Z",
            "wtComment": "test",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 6,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T09:00:58.077Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1548979200000,
            "wtMonthLastDay": 1551312000000,
            "wtMonth": 1
        },
        {
            "workTimeId": 4,
            "wtDate": "2019-09-18",
            "wtStartTime": "2019-09-18T00:00:00.000Z",
            "wtEndTime": "2019-02-24T00:00:00.000Z",
            "wtComment": "test",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 6,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T09:13:35.608Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 8,
            "wtDate": "2019-09-18",
            "wtStartTime": "2019-09-18T00:00:00.000Z",
            "wtEndTime": "2019-11-08T00:00:00.000Z",
            "wtComment": "test12345",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 6,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T12:13:51.761Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 124,
            "wtDate": "1970-01-01",
            "wtStartTime": "1970-01-01T09:21:00.000Z",
            "wtEndTime": "1970-01-01T11:22:00.000Z",
            "wtComment": "123123",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 381,
            "taskName": "Python and Django",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-11T09:22:18.811Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 1970,
            "wtMonthFirstDay": 33660000,
            "wtMonthLastDay": 2625660000,
            "wtMonth": 0
        },
        {
            "workTimeId": 126,
            "wtDate": "2019-12-13",
            "wtStartTime": "2019-12-13T11:06:08.831Z",
            "wtEndTime": "2019-12-13T12:33:08.831Z",
            "wtComment": "123123",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 381,
            "taskName": "Python and Django",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-13T11:06:30.449Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575198368831,
            "wtMonthLastDay": 1577790368831,
            "wtMonth": 11
        },
        {
            "workTimeId": 127,
            "wtDate": "2019-12-15",
            "wtStartTime": "2019-12-15T11:55:55.332Z",
            "wtEndTime": "2019-12-15T12:33:55.332Z",
            "wtComment": "123123",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-15T11:58:00.583Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575201355332,
            "wtMonthLastDay": 1577793355332,
            "wtMonth": 11
        },
        {
            "workTimeId": 132,
            "wtDate": "2019-12-18",
            "wtStartTime": "2019-12-18T05:22:26.381Z",
            "wtEndTime": "2019-12-18T05:22:36.543Z",
            "wtComment": "TEST",
            "empId": 27,
            "empFirstName": "Rosen",
            "empLastName": "Dochev",
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-18T05:22:36.653Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575177746381,
            "wtMonthLastDay": 1577769746381,
            "wtMonth": 11
        },
        {
            "workTimeId": 1514,
            "wtDate": "2019-09-18",
            "wtStartTime": "2019-09-18T00:00:00.000Z",
            "wtEndTime": "2019-09-18T00:00:00.000Z",
            "wtComment": "Audit Log Application investigation",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-19T00:31:15.000Z",
            "lastUpdateDate": "2019-09-19T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1515,
            "wtDate": "2019-09-18",
            "wtStartTime": "2019-09-18T00:00:00.000Z",
            "wtEndTime": "2019-09-18T00:00:00.000Z",
            "wtComment": "Audit Log Application investigation",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-19T00:31:42.000Z",
            "lastUpdateDate": "2019-09-19T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1553,
            "wtDate": "2019-09-20",
            "wtStartTime": "2019-09-20T00:00:00.000Z",
            "wtEndTime": "2019-09-20T00:00:00.000Z",
            "wtComment": "Created new database schema. Changed configuration.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-21T06:24:28.000Z",
            "lastUpdateDate": "2019-09-21T00:00:00.000Z",
            "workedTimeMin": 260,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1554,
            "wtDate": "2019-09-20",
            "wtStartTime": "2019-09-20T00:00:00.000Z",
            "wtEndTime": "2019-09-20T00:00:00.000Z",
            "wtComment": "Modified initialization database script. ",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-21T06:28:16.000Z",
            "lastUpdateDate": "2019-09-21T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1533,
            "wtDate": "2019-09-20",
            "wtStartTime": "2019-09-20T00:00:00.000Z",
            "wtEndTime": "2019-09-20T00:00:00.000Z",
            "wtComment": "Audit Logging system investigation",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-21T02:29:34.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1724,
            "wtDate": "2019-09-20",
            "wtStartTime": "2019-09-20T00:00:00.000Z",
            "wtEndTime": "2019-09-20T00:00:00.000Z",
            "wtComment": "Conference call - walk-through webapp",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 243,
            "taskName": "Project management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-27T02:30:51.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 30,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1534,
            "wtDate": "2019-09-20",
            "wtStartTime": "2019-09-20T00:00:00.000Z",
            "wtEndTime": "2019-09-20T00:00:00.000Z",
            "wtComment": "Audit Logging system investigation",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-21T02:29:57.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1555,
            "wtDate": "2019-09-21",
            "wtStartTime": "2019-09-21T00:00:00.000Z",
            "wtEndTime": "2019-09-21T00:00:00.000Z",
            "wtComment": "Deployed and configured management console and mobile proxy.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-21T06:37:50.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 330,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1787,
            "wtDate": "2019-09-21",
            "wtStartTime": "2019-09-21T00:00:00.000Z",
            "wtEndTime": "2019-09-21T00:00:00.000Z",
            "wtComment": "Clearing DB, install retry.",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 444,
            "taskName": "Content Management System",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T05:14:46.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1788,
            "wtDate": "2019-09-21",
            "wtStartTime": "2019-09-21T00:00:00.000Z",
            "wtEndTime": "2019-09-21T00:00:00.000Z",
            "wtComment": "Clearing virtual machines",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 444,
            "taskName": "Content Management System",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T05:16:04.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 150,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1556,
            "wtDate": "2019-09-21",
            "wtStartTime": "2019-09-21T00:00:00.000Z",
            "wtEndTime": "2019-09-21T00:00:00.000Z",
            "wtComment": "Performed several tests with opera",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-21T06:39:26.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1789,
            "wtDate": "2019-09-21",
            "wtStartTime": "2019-09-21T00:00:00.000Z",
            "wtEndTime": "2019-09-21T00:00:00.000Z",
            "wtComment": "Checking setup",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 21,
            "taskName": "Support/Investigations",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T05:17:34.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1557,
            "wtDate": "2019-09-21",
            "wtStartTime": "2019-09-21T00:00:00.000Z",
            "wtEndTime": "2019-09-21T00:00:00.000Z",
            "wtComment": "Audit application requirements",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-22T04:19:20.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 300,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 2482,
            "wtDate": "2019-10-28",
            "wtStartTime": "2019-10-28T00:00:00.000Z",
            "wtEndTime": "2019-10-28T00:00:00.000Z",
            "wtComment": "Meeting",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 21,
            "taskName": "Support/Investigations",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T02:20:41.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1582,
            "wtDate": "2019-09-22",
            "wtStartTime": "2019-09-22T00:00:00.000Z",
            "wtEndTime": "2019-09-22T00:00:00.000Z",
            "wtComment": "Setting new PC",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-22T03:28:47.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1790,
            "wtDate": "2019-09-22",
            "wtStartTime": "2019-09-22T00:00:00.000Z",
            "wtEndTime": "2019-09-22T00:00:00.000Z",
            "wtComment": "Installing Oracle Applications",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 444,
            "taskName": "Content Management System",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T05:18:58.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 140,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1658,
            "wtDate": "2019-09-22",
            "wtStartTime": "2019-09-22T00:00:00.000Z",
            "wtEndTime": "2019-09-22T00:00:00.000Z",
            "wtComment": "Adding elements to exhibition website",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 422,
            "taskName": "New exhibition for 2012",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-24T02:29:18.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1791,
            "wtDate": "2019-09-22",
            "wtStartTime": "2019-09-22T00:00:00.000Z",
            "wtEndTime": "2019-09-22T00:00:00.000Z",
            "wtComment": "Certificate and metadata xml issue",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 444,
            "taskName": "Content Management System",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T05:20:52.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 170,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1580,
            "wtDate": "2019-09-22",
            "wtStartTime": "2019-09-22T00:00:00.000Z",
            "wtEndTime": "2019-09-22T00:00:00.000Z",
            "wtComment": "Configured and tested system",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-22T03:22:37.000Z",
            "lastUpdateDate": "2019-09-22T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1715,
            "wtDate": "2019-09-22",
            "wtStartTime": "2019-09-22T00:00:00.000Z",
            "wtEndTime": "2019-09-22T00:00:00.000Z",
            "wtComment": "Weekly Technical call",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-27T01:10:18.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1716,
            "wtDate": "2019-09-22",
            "wtStartTime": "2019-09-22T00:00:00.000Z",
            "wtEndTime": "2019-09-22T00:00:00.000Z",
            "wtComment": "Audit SD",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-27T01:10:52.000Z",
            "lastUpdateDate": "2019-09-27T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1743,
            "wtDate": "2019-09-23",
            "wtStartTime": "2019-09-23T00:00:00.000Z",
            "wtEndTime": "2019-09-23T00:00:00.000Z",
            "wtComment": "Testing Management Console final tests",
            "empId": 3,
            "empFirstName": "Victor",
            "empLastName": "Victorov",
            "taskId": 226,
            "taskName": "Quality Assurance",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-27T08:20:18.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1642,
            "wtDate": "2019-09-23",
            "wtStartTime": "2019-09-23T00:00:00.000Z",
            "wtEndTime": "2019-09-23T00:00:00.000Z",
            "wtComment": "Bugfix: Create new connector. Investigating documentation.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-24T11:47:16.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 270,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1668,
            "wtDate": "2019-09-23",
            "wtStartTime": "2019-09-23T00:00:00.000Z",
            "wtEndTime": "2019-09-23T00:00:00.000Z",
            "wtComment": "Modifying registration and deploying on production site",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 422,
            "taskName": "New exhibition for 2012",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-24T02:49:39.000Z",
            "lastUpdateDate": "2019-09-24T00:00:00.000Z",
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1792,
            "wtDate": "2019-09-23",
            "wtStartTime": "2019-09-23T00:00:00.000Z",
            "wtEndTime": "2019-09-23T00:00:00.000Z",
            "wtComment": "Portal moved to new IP",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 21,
            "taskName": "Support/Investigations",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T05:23:18.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 200,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1643,
            "wtDate": "2019-09-23",
            "wtStartTime": "2019-09-23T00:00:00.000Z",
            "wtEndTime": "2019-09-23T00:00:00.000Z",
            "wtComment": "Bugfix: Create new connector. ",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-24T11:48:56.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1717,
            "wtDate": "2019-09-23",
            "wtStartTime": "2019-09-23T00:00:00.000Z",
            "wtEndTime": "2019-09-23T00:00:00.000Z",
            "wtComment": "Audit SD",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-27T01:13:55.000Z",
            "lastUpdateDate": "2019-09-27T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1718,
            "wtDate": "2019-09-23",
            "wtStartTime": "2019-09-23T00:00:00.000Z",
            "wtEndTime": "2019-09-23T00:00:00.000Z",
            "wtComment": "Test coordination",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-27T01:17:08.000Z",
            "lastUpdateDate": "2019-09-27T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1647,
            "wtDate": "2019-09-24",
            "wtStartTime": "2019-09-24T00:00:00.000Z",
            "wtEndTime": "2019-09-24T00:00:00.000Z",
            "wtComment": "Bugfix: Investigation and workaround of the wrong language codes.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-24T01:47:55.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 300,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1648,
            "wtDate": "2019-09-24",
            "wtStartTime": "2019-09-24T00:00:00.000Z",
            "wtEndTime": "2019-09-24T00:00:00.000Z",
            "wtComment": "Bugfix: Create new connector. Investigating archiving webservice.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-24T01:49:02.000Z",
            "lastUpdateDate": "2019-09-24T00:00:00.000Z",
            "workedTimeMin": 210,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1719,
            "wtDate": "2019-09-24",
            "wtStartTime": "2019-09-24T00:00:00.000Z",
            "wtEndTime": "2019-09-24T00:00:00.000Z",
            "wtComment": "Conference call",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-27T01:18:05.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1793,
            "wtDate": "2019-09-24",
            "wtStartTime": "2019-09-24T00:00:00.000Z",
            "wtEndTime": "2019-09-24T00:00:00.000Z",
            "wtComment": "Portal sync issue",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 21,
            "taskName": "Support/Investigations",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T05:24:29.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 170,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1671,
            "wtDate": "2019-09-24",
            "wtStartTime": "2019-09-24T00:00:00.000Z",
            "wtEndTime": "2019-09-24T00:00:00.000Z",
            "wtComment": "Adding Facebook functionality",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 422,
            "taskName": "New exhibition for 2012",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-24T02:52:16.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1720,
            "wtDate": "2019-09-24",
            "wtStartTime": "2019-09-24T00:00:00.000Z",
            "wtEndTime": "2019-09-24T00:00:00.000Z",
            "wtComment": "Audit SD",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-27T01:19:28.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1794,
            "wtDate": "2019-09-27",
            "wtStartTime": "2019-09-27T00:00:00.000Z",
            "wtEndTime": "2019-09-27T00:00:00.000Z",
            "wtComment": "Portal synchronization issue",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 21,
            "taskName": "Support/Investigations",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T05:25:30.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1795,
            "wtDate": "2019-09-27",
            "wtStartTime": "2019-09-27T00:00:00.000Z",
            "wtEndTime": "2019-09-27T00:00:00.000Z",
            "wtComment": "Specs finalizing. Aligning architecture",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 361,
            "taskName": "Specs. write/review",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T05:27:39.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 140,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1744,
            "wtDate": "2019-09-27",
            "wtStartTime": "2019-09-27T00:00:00.000Z",
            "wtEndTime": "2019-09-27T00:00:00.000Z",
            "wtComment": "Audit SD",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-27T08:29:23.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1745,
            "wtDate": "2019-09-27",
            "wtStartTime": "2019-09-27T00:00:00.000Z",
            "wtEndTime": "2019-09-27T00:00:00.000Z",
            "wtComment": "Audit SD",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-27T08:29:43.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 13,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T15:55:00.000Z",
            "wtEndTime": "2019-11-25T18:19:00.000Z",
            "wtComment": "API CALL22",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 248,
            "taskName": "321213: Virtual links",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:02:37.517Z",
            "lastUpdateDate": "2019-11-27T20:35:55.777Z",
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572623700000,
            "wtMonthLastDay": 1575129300000,
            "wtMonth": 10
        },
        {
            "workTimeId": 139,
            "wtDate": "2021-01-15",
            "wtStartTime": "2021-01-15T13:45:00.000Z",
            "wtEndTime": "2021-01-15T15:45:00.000Z",
            "wtComment": "1111",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-07T13:45:53.542Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2021,
            "wtMonthFirstDay": 1609508700000,
            "wtMonthLastDay": 1612100700000,
            "wtMonth": 0
        },
        {
            "workTimeId": 26,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T19:40:58.900Z",
            "wtEndTime": "2019-11-25T19:41:14.672Z",
            "wtComment": "This is my new description, lets see",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 302,
            "taskName": "DB improvement",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T19:41:16.099Z",
            "lastUpdateDate": "2019-11-28T17:05:20.795Z",
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572637258900,
            "wtMonthLastDay": 1575142858900,
            "wtMonth": 10
        },
        {
            "workTimeId": 21,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T16:13:58.310Z",
            "wtEndTime": "2019-11-25T16:17:58.310Z",
            "wtComment": "With making empty",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 226,
            "taskName": "Quality Assurance",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:14:25.661Z",
            "lastUpdateDate": "2019-11-28T17:05:43.944Z",
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572624838310,
            "wtMonthLastDay": 1575130438310,
            "wtMonth": 10
        },
        {
            "workTimeId": 140,
            "wtDate": "2020-12-25",
            "wtStartTime": "2020-12-25T13:51:00.000Z",
            "wtEndTime": "2020-12-25T14:55:00.000Z",
            "wtComment": "125125125",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 125,
            "taskName": "Python",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-07T13:53:14.624Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1606830660000,
            "wtMonthLastDay": 1609422660000,
            "wtMonth": 11
        },
        {
            "workTimeId": 142,
            "wtDate": "2019-11-04",
            "wtStartTime": "2019-11-04T12:00:00.000Z",
            "wtEndTime": "2019-11-04T15:00:00.000Z",
            "wtComment": "Work, work",
            "empId": 24,
            "empFirstName": "Darja",
            "empLastName": "Gogunova",
            "taskId": 249,
            "taskName": "SVN merging",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-08T12:04:34.162Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572609600000,
            "wtMonthLastDay": 1575115200000,
            "wtMonth": 10
        },
        {
            "workTimeId": 19,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T16:11:42.684Z",
            "wtEndTime": "2019-11-25T17:00:42.684Z",
            "wtComment": "Reloading 3333333",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 244,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:12:20.057Z",
            "lastUpdateDate": "2019-11-29T18:13:31.526Z",
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572624702684,
            "wtMonthLastDay": 1575130302684,
            "wtMonth": 10
        },
        {
            "workTimeId": 22,
            "wtDate": "2019-11-25",
            "wtStartTime": "2019-11-25T16:15:39.581Z",
            "wtEndTime": "2019-11-25T17:18:39.581Z",
            "wtComment": "Brand new working",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 226,
            "taskName": "Quality Assurance",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T16:15:57.707Z",
            "lastUpdateDate": "2019-11-30T07:03:08.905Z",
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572624939581,
            "wtMonthLastDay": 1575130539581,
            "wtMonth": 10
        },
        {
            "workTimeId": 1747,
            "wtDate": "2019-09-28",
            "wtStartTime": "2019-09-28T00:00:00.000Z",
            "wtEndTime": "2019-09-28T00:00:00.000Z",
            "wtComment": "Audit SD and DB design",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-29T02:27:01.000Z",
            "lastUpdateDate": "2019-09-29T00:00:00.000Z",
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1748,
            "wtDate": "2019-09-28",
            "wtStartTime": "2019-09-28T00:00:00.000Z",
            "wtEndTime": "2019-09-28T00:00:00.000Z",
            "wtComment": "Audit SD and DB design",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-29T02:27:40.000Z",
            "lastUpdateDate": "2019-10-01T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1761,
            "wtDate": "2019-09-29",
            "wtStartTime": "2019-09-29T00:00:00.000Z",
            "wtEndTime": "2019-09-29T00:00:00.000Z",
            "wtComment": "Bugfix: Created simple signing application",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T11:45:08.000Z",
            "lastUpdateDate": "2019-09-30T00:00:00.000Z",
            "workedTimeMin": 230,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1753,
            "wtDate": "2019-09-29",
            "wtStartTime": "2019-09-29T00:00:00.000Z",
            "wtEndTime": "2019-09-29T00:00:00.000Z",
            "wtComment": "Review installed Oracle Applications",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 444,
            "taskName": "Content Management System",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T06:44:23.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 400,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1760,
            "wtDate": "2019-09-29",
            "wtStartTime": "2019-09-29T00:00:00.000Z",
            "wtEndTime": "2019-09-29T00:00:00.000Z",
            "wtComment": "Application presentation.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T11:27:20.000Z",
            "lastUpdateDate": "2019-09-30T00:00:00.000Z",
            "workedTimeMin": 100,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1762,
            "wtDate": "2019-09-29",
            "wtStartTime": "2019-09-29T00:00:00.000Z",
            "wtEndTime": "2019-09-29T00:00:00.000Z",
            "wtComment": "Bugfix: Created simple signing application",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T11:46:24.000Z",
            "lastUpdateDate": "2019-09-30T00:00:00.000Z",
            "workedTimeMin": 150,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1807,
            "wtDate": "2019-09-29",
            "wtStartTime": "2019-09-29T00:00:00.000Z",
            "wtEndTime": "2019-09-29T00:00:00.000Z",
            "wtComment": "Weekly technical Call",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-01T11:38:27.000Z",
            "lastUpdateDate": "2019-10-05T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1754,
            "wtDate": "2019-09-29",
            "wtStartTime": "2019-09-29T00:00:00.000Z",
            "wtEndTime": "2019-09-29T00:00:00.000Z",
            "wtComment": "Conf. call",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 444,
            "taskName": "Content Management System",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T06:45:16.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 100,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1836,
            "wtDate": "2019-09-29",
            "wtStartTime": "2019-09-29T00:00:00.000Z",
            "wtEndTime": "2019-09-29T00:00:00.000Z",
            "wtComment": " Audit SD",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-05T00:25:25.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 210,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1764,
            "wtDate": "2019-09-30",
            "wtStartTime": "2019-09-30T00:00:00.000Z",
            "wtEndTime": "2019-09-30T00:00:00.000Z",
            "wtComment": "Bugfix: Prepared and tested new application",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T11:59:21.000Z",
            "lastUpdateDate": "2019-09-30T00:00:00.000Z",
            "workedTimeMin": 110,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1763,
            "wtDate": "2019-09-30",
            "wtStartTime": "2019-09-30T00:00:00.000Z",
            "wtEndTime": "2019-09-30T00:00:00.000Z",
            "wtComment": "Bugfix: Integration tests",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-09-30T11:51:58.000Z",
            "lastUpdateDate": "2019-09-30T00:00:00.000Z",
            "workedTimeMin": 160,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1871,
            "wtDate": "2019-09-30",
            "wtStartTime": "2019-09-30T00:00:00.000Z",
            "wtEndTime": "2019-09-30T00:00:00.000Z",
            "wtComment": "Bugfix: Created simple archiving service client.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T05:37:27.000Z",
            "lastUpdateDate": "2019-10-06T00:00:00.000Z",
            "workedTimeMin": 210,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1808,
            "wtDate": "2019-09-30",
            "wtStartTime": "2019-09-30T00:00:00.000Z",
            "wtEndTime": "2019-09-30T00:00:00.000Z",
            "wtComment": "Introduction to new developers",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-01T11:41:39.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1809,
            "wtDate": "2019-09-30",
            "wtStartTime": "2019-09-30T00:00:00.000Z",
            "wtEndTime": "2019-09-30T00:00:00.000Z",
            "wtComment": "Recommendation review ",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-01T11:43:07.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 1872,
            "wtDate": "2019-10-01",
            "wtStartTime": "2019-10-01T00:00:00.000Z",
            "wtEndTime": "2019-10-01T00:00:00.000Z",
            "wtComment": "Bugfix: Investigating failed invoices.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T05:45:12.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1873,
            "wtDate": "2019-10-01",
            "wtStartTime": "2019-10-01T00:00:00.000Z",
            "wtEndTime": "2019-10-01T00:00:00.000Z",
            "wtComment": "Bugfix: Investigating error message",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T05:50:24.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1810,
            "wtDate": "2019-10-01",
            "wtStartTime": "2019-10-01T00:00:00.000Z",
            "wtEndTime": "2019-10-01T00:00:00.000Z",
            "wtComment": "Technical call",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-01T11:44:53.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1811,
            "wtDate": "2019-10-01",
            "wtStartTime": "2019-10-01T00:00:00.000Z",
            "wtEndTime": "2019-10-01T00:00:00.000Z",
            "wtComment": "Audit  DB and documentation update",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-01T11:45:21.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1847,
            "wtDate": "2019-10-04",
            "wtStartTime": "2019-10-04T00:00:00.000Z",
            "wtEndTime": "2019-10-04T00:00:00.000Z",
            "wtComment": "Generating HTML for Exhibition Invitation",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 422,
            "taskName": "New exhibition for 2012",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-05T01:23:54.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 330,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1874,
            "wtDate": "2019-10-04",
            "wtStartTime": "2019-10-04T00:00:00.000Z",
            "wtEndTime": "2019-10-04T00:00:00.000Z",
            "wtComment": "Investigating Str functions behavior. ",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T05:54:06.000Z",
            "lastUpdateDate": "2019-10-06T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1875,
            "wtDate": "2019-10-04",
            "wtStartTime": "2019-10-04T00:00:00.000Z",
            "wtEndTime": "2019-10-04T00:00:00.000Z",
            "wtComment": "Bugfix: modified transformations.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T06:00:29.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1834,
            "wtDate": "2019-10-04",
            "wtStartTime": "2019-10-04T00:00:00.000Z",
            "wtEndTime": "2019-10-04T00:00:00.000Z",
            "wtComment": "Audit development coordination;\n",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-05T00:21:03.000Z",
            "lastUpdateDate": "2019-10-06T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1848,
            "wtDate": "2019-10-04",
            "wtStartTime": "2019-10-04T00:00:00.000Z",
            "wtEndTime": "2019-10-04T00:00:00.000Z",
            "wtComment": "Generating HTML for Exhibition Invitation",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 422,
            "taskName": "New exhibition for 2012",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-05T01:24:17.000Z",
            "lastUpdateDate": "2019-10-06T00:00:00.000Z",
            "workedTimeMin": 170,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1835,
            "wtDate": "2019-10-04",
            "wtStartTime": "2019-10-04T00:00:00.000Z",
            "wtEndTime": "2019-10-04T00:00:00.000Z",
            "wtComment": "Audit development coordination; JIRA as a helpdesk investigation",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-05T00:21:50.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1851,
            "wtDate": "2019-10-05",
            "wtStartTime": "2019-10-05T00:00:00.000Z",
            "wtEndTime": "2019-10-05T00:00:00.000Z",
            "wtComment": "Fixing Exhibition Invitation",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 422,
            "taskName": "New exhibition for 2012",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T05:40:10.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 90,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2044,
            "wtDate": "2019-10-05",
            "wtStartTime": "2019-10-05T00:00:00.000Z",
            "wtEndTime": "2019-10-05T00:00:00.000Z",
            "wtComment": "Searching for int for file size",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T11:57:56.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1876,
            "wtDate": "2019-10-05",
            "wtStartTime": "2019-10-05T00:00:00.000Z",
            "wtEndTime": "2019-10-05T00:00:00.000Z",
            "wtComment": "Bugfix: message status is not set correctly",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T06:10:14.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2045,
            "wtDate": "2019-10-05",
            "wtStartTime": "2019-10-05T00:00:00.000Z",
            "wtEndTime": "2019-10-05T00:00:00.000Z",
            "wtComment": "Searching in server for int for file size",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T11:58:51.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 310,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1877,
            "wtDate": "2019-10-05",
            "wtStartTime": "2019-10-05T00:00:00.000Z",
            "wtEndTime": "2019-10-05T00:00:00.000Z",
            "wtComment": "Bugfix: message status is not set correctly",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T06:10:44.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1880,
            "wtDate": "2019-10-05",
            "wtStartTime": "2019-10-05T00:00:00.000Z",
            "wtEndTime": "2019-10-05T00:00:00.000Z",
            "wtComment": "Audit- updated SD and database ",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T11:08:13.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 360,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1878,
            "wtDate": "2019-10-06",
            "wtStartTime": "2019-10-06T00:00:00.000Z",
            "wtEndTime": "2019-10-06T00:00:00.000Z",
            "wtComment": "Bugfix: implementing appropriate response handling",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T06:19:53.000Z",
            "lastUpdateDate": "2019-10-06T00:00:00.000Z",
            "workedTimeMin": 230,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2046,
            "wtDate": "2019-10-06",
            "wtStartTime": "2019-10-06T00:00:00.000Z",
            "wtEndTime": "2019-10-06T00:00:00.000Z",
            "wtComment": "Replacing int with long for filesize, building version, testing",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:07:28.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 200,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2047,
            "wtDate": "2019-10-06",
            "wtStartTime": "2019-10-06T00:00:00.000Z",
            "wtEndTime": "2019-10-06T00:00:00.000Z",
            "wtComment": "Checking Web Client enhancements, estimating effort.",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:09:09.000Z",
            "lastUpdateDate": "2019-10-14T00:00:00.000Z",
            "workedTimeMin": 310,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1868,
            "wtDate": "2019-10-06",
            "wtStartTime": "2019-10-06T00:00:00.000Z",
            "wtEndTime": "2019-10-06T00:00:00.000Z",
            "wtComment": "Discussed archiving service.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T03:02:58.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 90,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1879,
            "wtDate": "2019-10-06",
            "wtStartTime": "2019-10-06T00:00:00.000Z",
            "wtEndTime": "2019-10-06T00:00:00.000Z",
            "wtComment": "Bugfix: implementing appropriate response handling",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T06:24:11.000Z",
            "lastUpdateDate": "2019-10-06T00:00:00.000Z",
            "workedTimeMin": 160,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1881,
            "wtDate": "2019-10-06",
            "wtStartTime": "2019-10-06T00:00:00.000Z",
            "wtEndTime": "2019-10-06T00:00:00.000Z",
            "wtComment": "Weekly technical meeting; Audit follow up with development",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-06T11:09:51.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 360,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2048,
            "wtDate": "2019-10-07",
            "wtStartTime": "2019-10-07T00:00:00.000Z",
            "wtEndTime": "2019-10-07T00:00:00.000Z",
            "wtComment": "Investigating - large file gets truncated",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:10:54.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 230,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2123,
            "wtDate": "2019-10-07",
            "wtStartTime": "2019-10-07T00:00:00.000Z",
            "wtEndTime": "2019-10-07T00:00:00.000Z",
            "wtComment": "Bugfix: added generate task",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T04:36:37.000Z",
            "lastUpdateDate": "2019-10-17T00:00:00.000Z",
            "workedTimeMin": 255,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2200,
            "wtDate": "2019-10-20",
            "wtStartTime": "2019-10-20T00:00:00.000Z",
            "wtEndTime": "2019-10-20T00:00:00.000Z",
            "wtComment": "Audit aggregation demo",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-20T07:58:32.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2049,
            "wtDate": "2019-10-07",
            "wtStartTime": "2019-10-07T00:00:00.000Z",
            "wtEndTime": "2019-10-07T00:00:00.000Z",
            "wtComment": "Searching stream handling in the code and fixing.",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:12:34.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 280,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2124,
            "wtDate": "2019-10-07",
            "wtStartTime": "2019-10-07T00:00:00.000Z",
            "wtEndTime": "2019-10-07T00:00:00.000Z",
            "wtComment": "Bugfix: Implementing basic connector.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T04:49:46.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 225,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1971,
            "wtDate": "2019-10-07",
            "wtStartTime": "2019-10-07T00:00:00.000Z",
            "wtEndTime": "2019-10-07T00:00:00.000Z",
            "wtComment": "Helpdesk investigation and configuration",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-11T09:12:30.000Z",
            "lastUpdateDate": "2019-10-11T00:00:00.000Z",
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1972,
            "wtDate": "2019-10-07",
            "wtStartTime": "2019-10-07T00:00:00.000Z",
            "wtEndTime": "2019-10-07T00:00:00.000Z",
            "wtComment": "Helpdesk  investigation and configuration",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-11T09:12:51.000Z",
            "lastUpdateDate": "2019-10-11T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2050,
            "wtDate": "2019-10-08",
            "wtStartTime": "2019-10-08T00:00:00.000Z",
            "wtEndTime": "2019-10-08T00:00:00.000Z",
            "wtComment": "Building new version, testing with large files",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:14:22.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 200,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2051,
            "wtDate": "2019-10-08",
            "wtStartTime": "2019-10-08T00:00:00.000Z",
            "wtEndTime": "2019-10-08T00:00:00.000Z",
            "wtComment": "Building new version, testing with large files",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:15:40.000Z",
            "lastUpdateDate": "2019-10-14T00:00:00.000Z",
            "workedTimeMin": 430,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2126,
            "wtDate": "2019-10-08",
            "wtStartTime": "2019-10-08T00:00:00.000Z",
            "wtEndTime": "2019-10-08T00:00:00.000Z",
            "wtComment": "Bugfix: Basic implementation of signing web service connector.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T04:57:19.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 220,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1897,
            "wtDate": "2019-10-08",
            "wtStartTime": "2019-10-08T00:00:00.000Z",
            "wtEndTime": "2019-10-08T00:00:00.000Z",
            "wtComment": "OS update, installing java, mysql, apache, tomcat.",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 243,
            "taskName": "Project management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-08T02:30:32.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1973,
            "wtDate": "2019-10-08",
            "wtStartTime": "2019-10-08T00:00:00.000Z",
            "wtEndTime": "2019-10-08T00:00:00.000Z",
            "wtComment": "Helpdesk investigation and configuration",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-11T09:15:03.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 200,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1974,
            "wtDate": "2019-10-08",
            "wtStartTime": "2019-10-08T00:00:00.000Z",
            "wtEndTime": "2019-10-08T00:00:00.000Z",
            "wtComment": "Helpdesk investigation and configuration",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-11T09:16:09.000Z",
            "lastUpdateDate": "2019-10-11T00:00:00.000Z",
            "workedTimeMin": 220,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2128,
            "wtDate": "2019-10-11",
            "wtStartTime": "2019-10-11T00:00:00.000Z",
            "wtEndTime": "2019-10-11T00:00:00.000Z",
            "wtComment": "Bugfix: Implemented connector for admin service.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T05:24:21.000Z",
            "lastUpdateDate": "2019-10-17T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2052,
            "wtDate": "2019-10-11",
            "wtStartTime": "2019-10-11T00:00:00.000Z",
            "wtEndTime": "2019-10-11T00:00:00.000Z",
            "wtComment": "Testing unlimited file support",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:26:19.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2053,
            "wtDate": "2019-10-11",
            "wtStartTime": "2019-10-11T00:00:00.000Z",
            "wtEndTime": "2019-10-11T00:00:00.000Z",
            "wtComment": "Increasing timeout, building version.",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:27:33.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 310,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2129,
            "wtDate": "2019-10-11",
            "wtStartTime": "2019-10-11T00:00:00.000Z",
            "wtEndTime": "2019-10-11T00:00:00.000Z",
            "wtComment": "Bugfix: New connector",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T05:32:10.000Z",
            "lastUpdateDate": "2019-10-17T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1975,
            "wtDate": "2019-10-11",
            "wtStartTime": "2019-10-11T00:00:00.000Z",
            "wtEndTime": "2019-10-11T00:00:00.000Z",
            "wtComment": "Audit - Additional requirements collection",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-11T09:18:42.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2130,
            "wtDate": "2019-10-11",
            "wtStartTime": "2019-10-11T00:00:00.000Z",
            "wtEndTime": "2019-10-11T00:00:00.000Z",
            "wtComment": "Bugfix: Added  logging, changed log messages.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T05:37:26.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1976,
            "wtDate": "2019-10-11",
            "wtStartTime": "2019-10-11T00:00:00.000Z",
            "wtEndTime": "2019-10-11T00:00:00.000Z",
            "wtComment": "Additional requirements collection",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-11T09:19:09.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2131,
            "wtDate": "2019-10-12",
            "wtStartTime": "2019-10-12T00:00:00.000Z",
            "wtEndTime": "2019-10-12T00:00:00.000Z",
            "wtComment": "Bugfix: Implemented application engine class",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T05:45:04.000Z",
            "lastUpdateDate": "2019-10-17T00:00:00.000Z",
            "workedTimeMin": 270,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2054,
            "wtDate": "2019-10-12",
            "wtStartTime": "2019-10-12T00:00:00.000Z",
            "wtEndTime": "2019-10-12T00:00:00.000Z",
            "wtComment": "Testing portal with large files",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:28:28.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2055,
            "wtDate": "2019-10-12",
            "wtStartTime": "2019-10-12T00:00:00.000Z",
            "wtEndTime": "2019-10-12T00:00:00.000Z",
            "wtComment": "Investigating for automatic solution",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:29:37.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 320,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2132,
            "wtDate": "2019-10-12",
            "wtStartTime": "2019-10-12T00:00:00.000Z",
            "wtEndTime": "2019-10-12T00:00:00.000Z",
            "wtComment": "Bugfix: Started implementing invoice processor",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T05:48:32.000Z",
            "lastUpdateDate": "2019-10-17T00:00:00.000Z",
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1993,
            "wtDate": "2019-10-12",
            "wtStartTime": "2019-10-12T00:00:00.000Z",
            "wtEndTime": "2019-10-12T00:00:00.000Z",
            "wtComment": "Audit general coordination",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T01:35:51.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 270,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2133,
            "wtDate": "2019-10-12",
            "wtStartTime": "2019-10-12T00:00:00.000Z",
            "wtEndTime": "2019-10-12T00:00:00.000Z",
            "wtComment": "Bugfix: Added create customer section functionality.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T05:53:37.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 90,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2134,
            "wtDate": "2019-10-13",
            "wtStartTime": "2019-10-13T00:00:00.000Z",
            "wtEndTime": "2019-10-13T00:00:00.000Z",
            "wtComment": "Bugfix: Implemented archiving functionality",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T06:12:36.000Z",
            "lastUpdateDate": "2019-10-17T00:00:00.000Z",
            "workedTimeMin": 270,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2056,
            "wtDate": "2019-10-13",
            "wtStartTime": "2019-10-13T00:00:00.000Z",
            "wtEndTime": "2019-10-13T00:00:00.000Z",
            "wtComment": "Implementing usage of apache common compress",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:30:30.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2057,
            "wtDate": "2019-10-13",
            "wtStartTime": "2019-10-13T00:00:00.000Z",
            "wtEndTime": "2019-10-13T00:00:00.000Z",
            "wtComment": "Building version, testing Portal with large file upload",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:31:44.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 450,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2135,
            "wtDate": "2019-10-13",
            "wtStartTime": "2019-10-13T00:00:00.000Z",
            "wtEndTime": "2019-10-13T00:00:00.000Z",
            "wtComment": "Bugfix: Implemented update  invoice functionality.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T06:17:12.000Z",
            "lastUpdateDate": "2019-10-17T00:00:00.000Z",
            "workedTimeMin": 270,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1994,
            "wtDate": "2019-10-13",
            "wtStartTime": "2019-10-13T00:00:00.000Z",
            "wtEndTime": "2019-10-13T00:00:00.000Z",
            "wtComment": "Technical meeting",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T01:37:18.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 1995,
            "wtDate": "2019-10-13",
            "wtStartTime": "2019-10-13T00:00:00.000Z",
            "wtEndTime": "2019-10-13T00:00:00.000Z",
            "wtComment": "Invoice  retrieval from the original file investigation",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T01:38:51.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 270,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2136,
            "wtDate": "2019-10-14",
            "wtStartTime": "2019-10-14T00:00:00.000Z",
            "wtEndTime": "2019-10-14T00:00:00.000Z",
            "wtComment": "Bugfix: Added web service",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T06:23:16.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 150,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2058,
            "wtDate": "2019-10-14",
            "wtStartTime": "2019-10-14T00:00:00.000Z",
            "wtEndTime": "2019-10-14T00:00:00.000Z",
            "wtComment": "Minor improvements",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 485,
            "taskName": "Unlimited file support",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T00:34:43.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 160,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2137,
            "wtDate": "2019-10-14",
            "wtStartTime": "2019-10-14T00:00:00.000Z",
            "wtEndTime": "2019-10-14T00:00:00.000Z",
            "wtComment": "Bugfix: Implemented simple web client",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T06:27:11.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2149,
            "wtDate": "2019-10-14",
            "wtStartTime": "2019-10-14T00:00:00.000Z",
            "wtEndTime": "2019-10-14T00:00:00.000Z",
            "wtComment": "Subscription list developing ",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 521,
            "taskName": "Newsletters and Polls",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-18T06:42:52.000Z",
            "lastUpdateDate": "2019-10-28T00:00:00.000Z",
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2138,
            "wtDate": "2019-10-14",
            "wtStartTime": "2019-10-14T00:00:00.000Z",
            "wtEndTime": "2019-10-14T00:00:00.000Z",
            "wtComment": "Bugfix: Testing the connector",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T06:32:20.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 270,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2081,
            "wtDate": "2019-10-14",
            "wtStartTime": "2019-10-14T00:00:00.000Z",
            "wtEndTime": "2019-10-14T00:00:00.000Z",
            "wtComment": "Audit - coordination",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T11:52:39.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2082,
            "wtDate": "2019-10-14",
            "wtStartTime": "2019-10-14T00:00:00.000Z",
            "wtEndTime": "2019-10-14T00:00:00.000Z",
            "wtComment": "Audit - User Interface Solution Design; ",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-14T11:53:24.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2243,
            "wtDate": "2019-10-15",
            "wtStartTime": "2019-10-15T00:00:00.000Z",
            "wtEndTime": "2019-10-15T00:00:00.000Z",
            "wtComment": "Adding 2 new banners to exhibition site",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 422,
            "taskName": "New exhibition for 2012",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-25T11:14:34.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2139,
            "wtDate": "2019-10-15",
            "wtStartTime": "2019-10-15T00:00:00.000Z",
            "wtEndTime": "2019-10-15T00:00:00.000Z",
            "wtComment": "Bugfix: Testing the connector",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T06:38:23.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2086,
            "wtDate": "2019-10-15",
            "wtStartTime": "2019-10-15T00:00:00.000Z",
            "wtEndTime": "2019-10-15T00:00:00.000Z",
            "wtComment": "Stress test simulation",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 21,
            "taskName": "Support/Investigations",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-15T09:52:58.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 130,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2140,
            "wtDate": "2019-10-15",
            "wtStartTime": "2019-10-15T00:00:00.000Z",
            "wtEndTime": "2019-10-15T00:00:00.000Z",
            "wtComment": "Bugfix: Testing the connector",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T06:43:16.000Z",
            "lastUpdateDate": "2019-10-17T00:00:00.000Z",
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2091,
            "wtDate": "2019-10-15",
            "wtStartTime": "2019-10-15T00:00:00.000Z",
            "wtEndTime": "2019-10-15T00:00:00.000Z",
            "wtComment": "UAT preparation meeting",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-16T01:30:30.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2141,
            "wtDate": "2019-10-15",
            "wtStartTime": "2019-10-15T00:00:00.000Z",
            "wtEndTime": "2019-10-15T00:00:00.000Z",
            "wtComment": "Created new  test environment. Setup of new instance.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T06:49:39.000Z",
            "lastUpdateDate": "2019-10-17T00:00:00.000Z",
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2092,
            "wtDate": "2019-10-15",
            "wtStartTime": "2019-10-15T00:00:00.000Z",
            "wtEndTime": "2019-10-15T00:00:00.000Z",
            "wtComment": "PC configuration",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-16T01:31:40.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2142,
            "wtDate": "2019-10-16",
            "wtStartTime": "2019-10-16T00:00:00.000Z",
            "wtEndTime": "2019-10-16T00:00:00.000Z",
            "wtComment": "Updated  applications and transformation on test environment. Performed tests. Found several unwanted exceptions in audit log functionality.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T06:56:38.000Z",
            "lastUpdateDate": "2019-10-17T00:00:00.000Z",
            "workedTimeMin": 270,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2150,
            "wtDate": "2019-10-16",
            "wtStartTime": "2019-10-16T00:00:00.000Z",
            "wtEndTime": "2019-10-16T00:00:00.000Z",
            "wtComment": "Creating DB structure, models and beans",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 521,
            "taskName": "Newsletters and Polls",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-18T06:44:03.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 470,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2143,
            "wtDate": "2019-10-16",
            "wtStartTime": "2019-10-16T00:00:00.000Z",
            "wtEndTime": "2019-10-16T00:00:00.000Z",
            "wtComment": "Installed and tested new connector on the test environment. ",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-17T06:59:02.000Z",
            "lastUpdateDate": "2019-10-17T00:00:00.000Z",
            "workedTimeMin": 270,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2144,
            "wtDate": "2019-10-16",
            "wtStartTime": "2019-10-16T00:00:00.000Z",
            "wtEndTime": "2019-10-16T00:00:00.000Z",
            "wtComment": "PC configuration",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-18T03:29:36.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 360,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2241,
            "wtDate": "2019-10-18",
            "wtStartTime": "2019-10-18T00:00:00.000Z",
            "wtEndTime": "2019-10-18T00:00:00.000Z",
            "wtComment": "Creating admin and sara_dev part",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 521,
            "taskName": "Newsletters and Polls",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-25T11:06:02.000Z",
            "lastUpdateDate": "2019-10-28T00:00:00.000Z",
            "workedTimeMin": 300,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2227,
            "wtDate": "2019-10-18",
            "wtStartTime": "2019-10-18T00:00:00.000Z",
            "wtEndTime": "2019-10-18T00:00:00.000Z",
            "wtComment": "Added creation date field ",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-25T07:32:18.000Z",
            "lastUpdateDate": "2019-10-25T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2234,
            "wtDate": "2019-10-18",
            "wtStartTime": "2019-10-18T00:00:00.000Z",
            "wtEndTime": "2019-10-18T00:00:00.000Z",
            "wtComment": "Modified java code in order set creation date fields",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-25T07:41:45.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2242,
            "wtDate": "2019-10-18",
            "wtStartTime": "2019-10-18T00:00:00.000Z",
            "wtEndTime": "2019-10-18T00:00:00.000Z",
            "wtComment": "Creating admin and sara_dev part",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 521,
            "taskName": "Newsletters and Polls",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-25T11:06:14.000Z",
            "lastUpdateDate": "2019-10-28T00:00:00.000Z",
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2201,
            "wtDate": "2019-10-18",
            "wtStartTime": "2019-10-18T00:00:00.000Z",
            "wtEndTime": "2019-10-18T00:00:00.000Z",
            "wtComment": "Audit General coordination",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-20T08:01:26.000Z",
            "lastUpdateDate": "2019-10-20T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2202,
            "wtDate": "2019-10-18",
            "wtStartTime": "2019-10-18T00:00:00.000Z",
            "wtEndTime": "2019-10-18T00:00:00.000Z",
            "wtComment": "Audit; SSL certs",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-20T08:03:25.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2235,
            "wtDate": "2019-10-19",
            "wtStartTime": "2019-10-19T00:00:00.000Z",
            "wtEndTime": "2019-10-19T00:00:00.000Z",
            "wtComment": "Reduced instances",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-25T07:46:56.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2237,
            "wtDate": "2019-10-19",
            "wtStartTime": "2019-10-19T00:00:00.000Z",
            "wtEndTime": "2019-10-19T00:00:00.000Z",
            "wtComment": "Reviewed and changed audit log",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-25T08:02:04.000Z",
            "lastUpdateDate": "2019-10-25T00:00:00.000Z",
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2238,
            "wtDate": "2019-10-19",
            "wtStartTime": "2019-10-19T00:00:00.000Z",
            "wtEndTime": "2019-10-19T00:00:00.000Z",
            "wtComment": "Investigating the issue with missing picture",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-25T08:05:38.000Z",
            "lastUpdateDate": "2019-10-25T00:00:00.000Z",
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2198,
            "wtDate": "2019-10-19",
            "wtStartTime": "2019-10-19T00:00:00.000Z",
            "wtEndTime": "2019-10-19T00:00:00.000Z",
            "wtComment": "Audit aggregation",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-20T07:53:20.000Z",
            "lastUpdateDate": "2019-10-20T00:00:00.000Z",
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2236,
            "wtDate": "2019-10-19",
            "wtStartTime": "2019-10-19T00:00:00.000Z",
            "wtEndTime": "2019-10-19T00:00:00.000Z",
            "wtComment": "Discussing functionality",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-25T07:58:32.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2199,
            "wtDate": "2019-10-19",
            "wtStartTime": "2019-10-19T00:00:00.000Z",
            "wtEndTime": "2019-10-19T00:00:00.000Z",
            "wtComment": "Audit aggregation",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-20T07:54:43.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2239,
            "wtDate": "2019-10-20",
            "wtStartTime": "2019-10-20T00:00:00.000Z",
            "wtEndTime": "2019-10-20T00:00:00.000Z",
            "wtComment": "Bugfix: Adding Custom Properties fields",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-25T08:12:03.000Z",
            "lastUpdateDate": "2019-10-25T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2240,
            "wtDate": "2019-10-20",
            "wtStartTime": "2019-10-20T00:00:00.000Z",
            "wtEndTime": "2019-10-20T00:00:00.000Z",
            "wtComment": "Discussing Opera testing tool",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-25T08:17:42.000Z",
            "lastUpdateDate": "2019-10-25T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2166,
            "wtDate": "2019-10-20",
            "wtStartTime": "2019-10-20T00:00:00.000Z",
            "wtEndTime": "2019-10-20T00:00:00.000Z",
            "wtComment": "Configuration",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-19T00:27:16.000Z",
            "lastUpdateDate": "2019-10-20T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2209,
            "wtDate": "2019-10-21",
            "wtStartTime": "2019-10-21T00:00:00.000Z",
            "wtEndTime": "2019-10-21T00:00:00.000Z",
            "wtComment": "Requirements",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-21T10:20:32.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2210,
            "wtDate": "2019-10-21",
            "wtStartTime": "2019-10-21T00:00:00.000Z",
            "wtEndTime": "2019-10-21T00:00:00.000Z",
            "wtComment": "Audit test and demo preparation",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-21T10:20:59.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2400,
            "wtDate": "2019-10-25",
            "wtStartTime": "2019-10-25T00:00:00.000Z",
            "wtEndTime": "2019-10-25T00:00:00.000Z",
            "wtComment": "Bugfix: modified audit log sql queries",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T08:25:34.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2313,
            "wtDate": "2019-10-25",
            "wtStartTime": "2019-10-25T00:00:00.000Z",
            "wtEndTime": "2019-10-25T00:00:00.000Z",
            "wtComment": "Creating new mailing",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 422,
            "taskName": "New exhibition for 2012",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-27T06:49:06.000Z",
            "lastUpdateDate": "2019-10-28T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2402,
            "wtDate": "2019-10-25",
            "wtStartTime": "2019-10-25T00:00:00.000Z",
            "wtEndTime": "2019-10-25T00:00:00.000Z",
            "wtComment": "Bugfix: Added additional fields",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T08:38:51.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2308,
            "wtDate": "2019-10-25",
            "wtStartTime": "2019-10-25T00:00:00.000Z",
            "wtEndTime": "2019-10-25T00:00:00.000Z",
            "wtComment": "Audit - coordination with development",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-27T00:55:17.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2440,
            "wtDate": "2019-10-25",
            "wtStartTime": "2019-10-25T00:00:00.000Z",
            "wtEndTime": "2019-10-25T00:00:00.000Z",
            "wtComment": "Conference call",
            "empId": 3,
            "empFirstName": "Victor",
            "empLastName": "Victorov",
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T00:09:23.000Z",
            "lastUpdateDate": "2019-10-28T00:00:00.000Z",
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2443,
            "wtDate": "2019-10-26",
            "wtStartTime": "2019-10-26T00:00:00.000Z",
            "wtEndTime": "2019-10-26T00:00:00.000Z",
            "wtComment": "Testing Audit application",
            "empId": 3,
            "empFirstName": "Victor",
            "empLastName": "Victorov",
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T00:22:40.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2314,
            "wtDate": "2019-10-26",
            "wtStartTime": "2019-10-26T00:00:00.000Z",
            "wtEndTime": "2019-10-26T00:00:00.000Z",
            "wtComment": "Sending new mailing",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 422,
            "taskName": "New exhibition for 2012",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-27T06:50:16.000Z",
            "lastUpdateDate": "2019-10-27T00:00:00.000Z",
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2403,
            "wtDate": "2019-10-26",
            "wtStartTime": "2019-10-26T00:00:00.000Z",
            "wtEndTime": "2019-10-26T00:00:00.000Z",
            "wtComment": "Bugfix: The new connector sets empty urls",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T08:41:52.000Z",
            "lastUpdateDate": "2019-10-28T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2441,
            "wtDate": "2019-10-26",
            "wtStartTime": "2019-10-26T00:00:00.000Z",
            "wtEndTime": "2019-10-26T00:00:00.000Z",
            "wtComment": "Meeting with management",
            "empId": 3,
            "empFirstName": "Victor",
            "empLastName": "Victorov",
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T00:13:06.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2444,
            "wtDate": "2019-10-26",
            "wtStartTime": "2019-10-26T00:00:00.000Z",
            "wtEndTime": "2019-10-26T00:00:00.000Z",
            "wtComment": "Testing Audit application",
            "empId": 3,
            "empFirstName": "Victor",
            "empLastName": "Victorov",
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T00:23:16.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2404,
            "wtDate": "2019-10-26",
            "wtStartTime": "2019-10-26T00:00:00.000Z",
            "wtEndTime": "2019-10-26T00:00:00.000Z",
            "wtComment": "Performed several tests",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T08:46:10.000Z",
            "lastUpdateDate": "2019-10-28T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2309,
            "wtDate": "2019-10-26",
            "wtStartTime": "2019-10-26T00:00:00.000Z",
            "wtEndTime": "2019-10-26T00:00:00.000Z",
            "wtComment": "Audit  - requirements collection ",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-27T00:56:32.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2442,
            "wtDate": "2019-10-26",
            "wtStartTime": "2019-10-26T00:00:00.000Z",
            "wtEndTime": "2019-10-26T00:00:00.000Z",
            "wtComment": "Demonstrating Audit application",
            "empId": 3,
            "empFirstName": "Victor",
            "empLastName": "Victorov",
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T00:20:26.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2310,
            "wtDate": "2019-10-26",
            "wtStartTime": "2019-10-26T00:00:00.000Z",
            "wtEndTime": "2019-10-26T00:00:00.000Z",
            "wtComment": "Audit QA tests coordination",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-27T00:57:07.000Z",
            "lastUpdateDate": "2019-10-27T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2390,
            "wtDate": "2019-10-27",
            "wtStartTime": "2019-10-27T00:00:00.000Z",
            "wtEndTime": "2019-10-27T00:00:00.000Z",
            "wtComment": "Maintaining newsletters jobs",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 521,
            "taskName": "Newsletters and Polls",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T06:56:43.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 335,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2445,
            "wtDate": "2019-10-27",
            "wtStartTime": "2019-10-27T00:00:00.000Z",
            "wtEndTime": "2019-10-27T00:00:00.000Z",
            "wtComment": "Testing Audit application",
            "empId": 3,
            "empFirstName": "Victor",
            "empLastName": "Victorov",
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T00:24:25.000Z",
            "lastUpdateDate": "2019-10-28T00:00:00.000Z",
            "workedTimeMin": 210,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2384,
            "wtDate": "2019-10-27",
            "wtStartTime": "2019-10-27T00:00:00.000Z",
            "wtEndTime": "2019-10-27T00:00:00.000Z",
            "wtComment": "Performed several tests",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-27T06:33:34.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2447,
            "wtDate": "2019-10-27",
            "wtStartTime": "2019-10-27T00:00:00.000Z",
            "wtEndTime": "2019-10-27T00:00:00.000Z",
            "wtComment": "Writing Report for found issues and open question ",
            "empId": 3,
            "empFirstName": "Victor",
            "empLastName": "Victorov",
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T00:27:46.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 30,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2446,
            "wtDate": "2019-10-27",
            "wtStartTime": "2019-10-27T00:00:00.000Z",
            "wtEndTime": "2019-10-27T00:00:00.000Z",
            "wtComment": "Testing Audit application",
            "empId": 3,
            "empFirstName": "Victor",
            "empLastName": "Victorov",
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T00:26:59.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2386,
            "wtDate": "2019-10-27",
            "wtStartTime": "2019-10-27T00:00:00.000Z",
            "wtEndTime": "2019-10-27T00:00:00.000Z",
            "wtComment": "Bugfix: Fixed exception ",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-27T07:28:00.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2391,
            "wtDate": "2019-10-27",
            "wtStartTime": "2019-10-27T00:00:00.000Z",
            "wtEndTime": "2019-10-27T00:00:00.000Z",
            "wtComment": "Fixing subscribers lists",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 521,
            "taskName": "Newsletters and Polls",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T06:57:10.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 145,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2388,
            "wtDate": "2019-10-27",
            "wtStartTime": "2019-10-27T00:00:00.000Z",
            "wtEndTime": "2019-10-27T00:00:00.000Z",
            "wtComment": "Audit Demo; Audit meeting with development and QA",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T01:39:12.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 210,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2389,
            "wtDate": "2019-10-27",
            "wtStartTime": "2019-10-27T00:00:00.000Z",
            "wtEndTime": "2019-10-27T00:00:00.000Z",
            "wtComment": "Audit issues follow up",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T01:40:47.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 180,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2461,
            "wtDate": "2019-10-28",
            "wtStartTime": "2019-10-28T00:00:00.000Z",
            "wtEndTime": "2019-10-28T00:00:00.000Z",
            "wtComment": "Updating production system",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T01:03:36.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2406,
            "wtDate": "2019-10-28",
            "wtStartTime": "2019-10-28T00:00:00.000Z",
            "wtEndTime": "2019-10-28T00:00:00.000Z",
            "wtComment": "Business review meeting.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T09:03:55.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2497,
            "wtDate": "2019-10-28",
            "wtStartTime": "2019-10-28T00:00:00.000Z",
            "wtEndTime": "2019-10-28T00:00:00.000Z",
            "wtComment": "Call Conf",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T06:46:59.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 60,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2469,
            "wtDate": "2019-10-28",
            "wtStartTime": "2019-10-28T00:00:00.000Z",
            "wtEndTime": "2019-10-28T00:00:00.000Z",
            "wtComment": "Updating  production system",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T01:24:17.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 120,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2470,
            "wtDate": "2019-10-28",
            "wtStartTime": "2019-10-28T00:00:00.000Z",
            "wtEndTime": "2019-10-28T00:00:00.000Z",
            "wtComment": "Updating  test system. Updated db schema and all mw modules.",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 124,
            "taskName": "R1 Development",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T01:25:09.000Z",
            "lastUpdateDate": "2019-10-28T00:00:00.000Z",
            "workedTimeMin": 240,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 2498,
            "wtDate": "2019-10-28",
            "wtStartTime": "2019-10-28T00:00:00.000Z",
            "wtEndTime": "2019-10-28T00:00:00.000Z",
            "wtComment": "Preparingrep for release",
            "empId": 2,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-10-28T06:49:19.000Z",
            "lastUpdateDate": null,
            "workedTimeMin": 300,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569888000000,
            "wtMonthLastDay": 1572480000000,
            "wtMonth": 9
        },
        {
            "workTimeId": 5,
            "wtDate": "2019-09-18",
            "wtStartTime": "2019-09-18T00:00:00.000Z",
            "wtEndTime": "2019-02-24T00:00:00.000Z",
            "wtComment": "test",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 6,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T09:17:14.498Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 9,
            "wtDate": "2019-09-18",
            "wtStartTime": "2019-09-18T00:00:00.000Z",
            "wtEndTime": "2019-11-08T00:00:00.000Z",
            "wtComment": "test12345",
            "empId": 5,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "taskId": 6,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-25T12:15:20.270Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 119,
            "wtDate": "2009-12-10",
            "wtStartTime": "2009-12-10T11:18:00.000Z",
            "wtEndTime": "2009-12-10T13:44:00.000Z",
            "wtComment": "AAA",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 126,
            "taskName": "Billing Systame v3.0",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-09T09:14:59.918Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2009,
            "wtMonthFirstDay": 1259666280000,
            "wtMonthLastDay": 1262258280000,
            "wtMonth": 11
        },
        {
            "workTimeId": 35,
            "wtDate": "2019-09-18",
            "wtStartTime": "2019-09-18T00:00:00.000Z",
            "wtEndTime": "2019-09-18T10:10:00.000Z",
            "wtComment": "My entry",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 123,
            "taskName": "Quality Assurance",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-27T06:24:51.196Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1567296000000,
            "wtMonthLastDay": 1569801600000,
            "wtMonth": 8
        },
        {
            "workTimeId": 121,
            "wtDate": "2019-12-10",
            "wtStartTime": "2019-12-10T06:46:25.876Z",
            "wtEndTime": "2019-12-10T08:00:25.876Z",
            "wtComment": "234234234234",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 381,
            "taskName": "Python and Django",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-10T06:46:55.580Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575182785876,
            "wtMonthLastDay": 1577774785876,
            "wtMonth": 11
        },
        {
            "workTimeId": 125,
            "wtDate": "2019-12-11",
            "wtStartTime": "2019-12-11T09:30:00.000Z",
            "wtEndTime": "2019-12-11T10:11:00.000Z",
            "wtComment": "asdas",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 381,
            "taskName": "Python and Django",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-11T09:30:53.107Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575192600000,
            "wtMonthLastDay": 1577784600000,
            "wtMonth": 11
        },
        {
            "workTimeId": 122,
            "wtDate": "2019-12-10",
            "wtStartTime": "2019-12-10T07:03:55.305Z",
            "wtEndTime": "2019-12-10T07:20:55.305Z",
            "wtComment": "test",
            "empId": 27,
            "empFirstName": "Rosen",
            "empLastName": "Dochev",
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-10T07:03:20.823Z",
            "lastUpdateDate": "2019-12-13T12:36:07.579Z",
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575183835305,
            "wtMonthLastDay": 1577775835305,
            "wtMonth": 11
        },
        {
            "workTimeId": 128,
            "wtDate": "2019-12-15",
            "wtStartTime": "2019-12-15T19:27:20.668Z",
            "wtEndTime": "2019-12-15T19:28:20.668Z",
            "wtComment": "ABC",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-15T19:28:36.108Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575228440668,
            "wtMonthLastDay": 1577820440668,
            "wtMonth": 11
        },
        {
            "workTimeId": 129,
            "wtDate": "2019-12-15",
            "wtStartTime": "2019-12-15T19:29:20.668Z",
            "wtEndTime": "2019-12-15T19:30:20.668Z",
            "wtComment": "anormal task",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-15T19:28:54.127Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575228560668,
            "wtMonthLastDay": 1577820560668,
            "wtMonth": 11
        },
        {
            "workTimeId": 42,
            "wtDate": "2019-10-23",
            "wtStartTime": "2019-10-23T17:22:00.000Z",
            "wtEndTime": "2019-10-23T19:00:00.000Z",
            "wtComment": "Previous month",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 126,
            "taskName": "Billing Systame v3.0",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-27T18:22:28.955Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569950520000,
            "wtMonthLastDay": 1572542520000,
            "wtMonth": 9
        },
        {
            "workTimeId": 130,
            "wtDate": "2019-12-15",
            "wtStartTime": "2019-12-15T19:30:20.668Z",
            "wtEndTime": "2019-12-15T19:31:20.668Z",
            "wtComment": "bbq",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-15T19:29:09.715Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575228620668,
            "wtMonthLastDay": 1577820620668,
            "wtMonth": 11
        },
        {
            "workTimeId": 54,
            "wtDate": "2019-12-02",
            "wtStartTime": "2019-12-02T13:37:31.132Z",
            "wtEndTime": "2019-12-02T13:38:31.132Z",
            "wtComment": "This is from timer",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 243,
            "taskName": "Project management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-02T13:38:03.186Z",
            "lastUpdateDate": "2019-12-04T08:34:15.857Z",
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575207451132,
            "wtMonthLastDay": 1577799451132,
            "wtMonth": 11
        },
        {
            "workTimeId": 131,
            "wtDate": "2019-12-15",
            "wtStartTime": "2019-12-15T19:32:20.668Z",
            "wtEndTime": "2019-12-15T19:33:20.668Z",
            "wtComment": "Boring task",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-15T19:29:29.471Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575228740668,
            "wtMonthLastDay": 1577820740668,
            "wtMonth": 11
        },
        {
            "workTimeId": 133,
            "wtDate": "2019-12-15",
            "wtStartTime": "2019-12-15T11:23:00.000Z",
            "wtEndTime": "2019-12-15T12:00:00.000Z",
            "wtComment": "TEST",
            "empId": 27,
            "empFirstName": "Rosen",
            "empLastName": "Dochev",
            "taskId": 549,
            "taskName": "QA - Tucows Audit",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-03T11:24:08.623Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575199380000,
            "wtMonthLastDay": 1577791380000,
            "wtMonth": 11
        },
        {
            "workTimeId": 141,
            "wtDate": "2021-01-14",
            "wtStartTime": "2021-01-14T13:56:00.000Z",
            "wtEndTime": "2021-01-14T14:55:00.000Z",
            "wtComment": "123123",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 221,
            "taskName": "MySQL Database",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-07T13:57:22.800Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2021,
            "wtMonthFirstDay": 1609509360000,
            "wtMonthLastDay": 1612101360000,
            "wtMonth": 0
        },
        {
            "workTimeId": 143,
            "wtDate": "2019-12-01",
            "wtStartTime": "2019-12-01T12:00:00.000Z",
            "wtEndTime": "2019-12-01T15:00:00.000Z",
            "wtComment": "Workm work",
            "empId": 24,
            "empFirstName": "Darja",
            "empLastName": "Gogunova",
            "taskId": 249,
            "taskName": "SVN merging",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-08T12:06:31.456Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575201600000,
            "wtMonthLastDay": 1577793600000,
            "wtMonth": 11
        },
        {
            "workTimeId": 144,
            "wtDate": "2019-12-15",
            "wtStartTime": "2019-12-15T12:00:00.000Z",
            "wtEndTime": "2019-12-15T15:00:00.000Z",
            "wtComment": "Work, work",
            "empId": 24,
            "empFirstName": "Darja",
            "empLastName": "Gogunova",
            "taskId": 249,
            "taskName": "SVN merging",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-08T12:07:15.397Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575201600000,
            "wtMonthLastDay": 1577793600000,
            "wtMonth": 11
        },
        {
            "workTimeId": 146,
            "wtDate": "2020-01-07",
            "wtStartTime": "2020-01-07T14:00:00.000Z",
            "wtEndTime": "2020-01-07T15:00:00.000Z",
            "wtComment": "Work, work",
            "empId": 24,
            "empFirstName": "Darja",
            "empLastName": "Gogunova",
            "taskId": 703,
            "taskName": "003-TASK",
            "taskProjectId": 167,
            "taskProjectName": "01-TEST",
            "createdDate": "2020-01-08T14:37:39.711Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577887200000,
            "wtMonthLastDay": 1580479200000,
            "wtMonth": 0
        },
        {
            "workTimeId": 44,
            "wtDate": "2019-11-28",
            "wtStartTime": "2019-11-28T10:19:00.000Z",
            "wtEndTime": "2019-11-28T11:00:00.000Z",
            "wtComment": "Testing item121212",
            "empId": 4,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "taskId": 361,
            "taskName": "Specs. write/review",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-11-28T16:19:08.985Z",
            "lastUpdateDate": "2019-11-30T07:03:39.356Z",
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572603540000,
            "wtMonthLastDay": 1575109140000,
            "wtMonth": 10
        },
        {
            "workTimeId": 147,
            "wtDate": "2019-12-18",
            "wtStartTime": "2019-12-18T14:00:00.000Z",
            "wtEndTime": "2019-12-18T15:00:00.000Z",
            "wtComment": "Work, work",
            "empId": 24,
            "empFirstName": "Darja",
            "empLastName": "Gogunova",
            "taskId": 249,
            "taskName": "SVN merging",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-08T14:38:53.495Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575208800000,
            "wtMonthLastDay": 1577800800000,
            "wtMonth": 11
        },
        {
            "workTimeId": 148,
            "wtDate": "2020-01-06",
            "wtStartTime": "2020-01-06T14:00:00.000Z",
            "wtEndTime": "2020-01-06T15:00:00.000Z",
            "wtComment": "Work, work",
            "empId": 24,
            "empFirstName": "Darja",
            "empLastName": "Gogunova",
            "taskId": 689,
            "taskName": "TEST",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-08T14:43:14.740Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577887200000,
            "wtMonthLastDay": 1580479200000,
            "wtMonth": 0
        },
        {
            "workTimeId": 149,
            "wtDate": "2019-12-20",
            "wtStartTime": "2019-12-20T09:00:00.000Z",
            "wtEndTime": "2019-12-20T10:00:00.000Z",
            "wtComment": "Work",
            "empId": 24,
            "empFirstName": "Darja",
            "empLastName": "Gogunova",
            "taskId": 249,
            "taskName": "SVN merging",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-08T15:16:26.984Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575190800000,
            "wtMonthLastDay": 1577782800000,
            "wtMonth": 11
        },
        {
            "workTimeId": 153,
            "wtDate": "2019-12-09",
            "wtStartTime": "2019-12-09T07:00:00.000Z",
            "wtEndTime": "2019-12-09T09:00:00.000Z",
            "wtComment": "Work, work",
            "empId": 24,
            "empFirstName": "Darja",
            "empLastName": "Gogunova",
            "taskId": 249,
            "taskName": "SVN merging",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-08T15:44:30.716Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575183600000,
            "wtMonthLastDay": 1577775600000,
            "wtMonth": 11
        },
        {
            "workTimeId": 154,
            "wtDate": "2019-12-31",
            "wtStartTime": "2019-12-31T16:00:00.000Z",
            "wtEndTime": "2019-12-31T18:00:00.000Z",
            "wtComment": "NY Work",
            "empId": 24,
            "empFirstName": "Darja",
            "empLastName": "Gogunova",
            "taskId": 701,
            "taskName": "001-TEST",
            "taskProjectId": 167,
            "taskProjectName": "01-TEST",
            "createdDate": "2020-01-08T15:45:54.208Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575216000000,
            "wtMonthLastDay": 1577808000000,
            "wtMonth": 11
        },
        {
            "workTimeId": 157,
            "wtDate": "2019-12-10",
            "wtStartTime": "2019-12-10T09:11:00.000Z",
            "wtEndTime": "2019-12-10T20:22:00.000Z",
            "wtComment": "Work",
            "empId": 24,
            "empFirstName": "Darja",
            "empLastName": "Gogunova",
            "taskId": 249,
            "taskName": "SVN merging",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-08T16:17:00.488Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575191460000,
            "wtMonthLastDay": 1577783460000,
            "wtMonth": 11
        },
        {
            "workTimeId": 158,
            "wtDate": "2020-01-09",
            "wtStartTime": "2020-01-09T07:57:44.714Z",
            "wtEndTime": "2020-01-09T08:00:44.714Z",
            "wtComment": "My task",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 703,
            "taskName": "003-TASK",
            "taskProjectId": 167,
            "taskProjectName": "01-TEST",
            "createdDate": "2020-01-09T07:58:12.896Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577865464714,
            "wtMonthLastDay": 1580457464714,
            "wtMonth": 0
        },
        {
            "workTimeId": 50,
            "wtDate": "2019-11-20",
            "wtStartTime": "2019-11-20T12:24:00.000Z",
            "wtEndTime": "2019-11-20T13:44:00.000Z",
            "wtComment": "in the past",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 444,
            "taskName": "Content Management System",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-02T12:29:11.917Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1572611040000,
            "wtMonthLastDay": 1575116640000,
            "wtMonth": 10
        },
        {
            "workTimeId": 160,
            "wtDate": "2020-01-09",
            "wtStartTime": "2020-01-09T08:04:08.946Z",
            "wtEndTime": "2020-01-09T08:06:08.946Z",
            "wtComment": "Two minutes task",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-09T08:04:29.102Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577865848946,
            "wtMonthLastDay": 1580457848946,
            "wtMonth": 0
        },
        {
            "workTimeId": 161,
            "wtDate": "2020-01-08",
            "wtStartTime": "2020-01-08T08:06:00.000Z",
            "wtEndTime": "2020-01-08T08:09:00.000Z",
            "wtComment": "Three minutes task",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-09T08:06:30.391Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577865960000,
            "wtMonthLastDay": 1580457960000,
            "wtMonth": 0
        },
        {
            "workTimeId": 51,
            "wtDate": "2019-12-02",
            "wtStartTime": "2019-12-02T12:25:26.316Z",
            "wtEndTime": "2019-12-02T12:30:26.316Z",
            "wtComment": "Presentation entry",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 6,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-02T13:06:31.090Z",
            "lastUpdateDate": "2019-12-02T13:06:51.816Z",
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575203126316,
            "wtMonthLastDay": 1577795126316,
            "wtMonth": 11
        },
        {
            "workTimeId": 175,
            "wtDate": "2020-01-10",
            "wtStartTime": "2020-01-10T15:01:16.476Z",
            "wtEndTime": "2020-01-10T15:01:22.635Z",
            "wtComment": "TEST",
            "empId": 27,
            "empFirstName": "Rosen",
            "empLastName": "Dochev",
            "taskId": 701,
            "taskName": "001-TEST",
            "taskProjectId": 167,
            "taskProjectName": "01-TEST",
            "createdDate": "2020-01-10T15:01:22.587Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577890876476,
            "wtMonthLastDay": 1580482876476,
            "wtMonth": 0
        },
        {
            "workTimeId": 162,
            "wtDate": "2020-01-09",
            "wtStartTime": "2020-01-09T09:20:04.690Z",
            "wtEndTime": "2020-01-09T09:21:04.690Z",
            "wtComment": "aaaa",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-09T09:20:17.392Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577870404690,
            "wtMonthLastDay": 1580462404690,
            "wtMonth": 0
        },
        {
            "workTimeId": 163,
            "wtDate": "2020-01-09",
            "wtStartTime": "2020-01-09T09:24:20.465Z",
            "wtEndTime": "2020-01-09T09:25:20.465Z",
            "wtComment": "asdasd",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-09T09:21:39.555Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577870660465,
            "wtMonthLastDay": 1580462660465,
            "wtMonth": 0
        },
        {
            "workTimeId": 165,
            "wtDate": "2020-01-09",
            "wtStartTime": "2020-01-09T09:27:51.775Z",
            "wtEndTime": "2020-01-09T09:28:51.775Z",
            "wtComment": "asd",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-09T09:28:05.320Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577870871775,
            "wtMonthLastDay": 1580462871775,
            "wtMonth": 0
        },
        {
            "workTimeId": 166,
            "wtDate": "2020-01-09",
            "wtStartTime": "2020-01-09T09:29:30.945Z",
            "wtEndTime": "2020-01-09T09:30:30.945Z",
            "wtComment": "ssss",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-09T09:29:39.643Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577870970945,
            "wtMonthLastDay": 1580462970945,
            "wtMonth": 0
        },
        {
            "workTimeId": 57,
            "wtDate": "2019-12-03",
            "wtStartTime": "2019-12-03T14:10:04.828Z",
            "wtEndTime": "2019-12-03T15:00:04.828Z",
            "wtComment": "hjj",
            "empId": 23,
            "empFirstName": "Eleonora",
            "empLastName": "Yaneva",
            "taskId": 245,
            "taskName": "42348: Fix file names in Japanese",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-03T14:10:21.823Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575209404828,
            "wtMonthLastDay": 1577801404828,
            "wtMonth": 11
        },
        {
            "workTimeId": 58,
            "wtDate": "2019-12-03",
            "wtStartTime": "2019-12-03T14:10:04.828Z",
            "wtEndTime": "2019-12-03T15:00:04.828Z",
            "wtComment": "hjj",
            "empId": 23,
            "empFirstName": "Eleonora",
            "empLastName": "Yaneva",
            "taskId": 245,
            "taskName": "42348: Fix file names in Japanese",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-03T14:52:37.470Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575209404828,
            "wtMonthLastDay": 1577801404828,
            "wtMonth": 11
        },
        {
            "workTimeId": 59,
            "wtDate": "2019-12-03",
            "wtStartTime": "2019-12-03T14:10:04.828Z",
            "wtEndTime": "2019-12-03T15:00:04.828Z",
            "wtComment": "hjj",
            "empId": 23,
            "empFirstName": "Eleonora",
            "empLastName": "Yaneva",
            "taskId": 4,
            "taskName": "DB Data Reduction",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-03T15:01:24.680Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575209404828,
            "wtMonthLastDay": 1577801404828,
            "wtMonth": 11
        },
        {
            "workTimeId": 60,
            "wtDate": "2019-12-03",
            "wtStartTime": "2019-12-03T14:10:04.828Z",
            "wtEndTime": "2019-12-03T15:00:04.828Z",
            "wtComment": "hjj",
            "empId": 23,
            "empFirstName": "Eleonora",
            "empLastName": "Yaneva",
            "taskId": 5,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-03T15:02:54.219Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575209404828,
            "wtMonthLastDay": 1577801404828,
            "wtMonth": 11
        },
        {
            "workTimeId": 61,
            "wtDate": "2019-12-03",
            "wtStartTime": "2019-12-03T14:10:04.828Z",
            "wtEndTime": "2019-12-03T15:00:04.828Z",
            "wtComment": "hjj",
            "empId": 22,
            "empFirstName": "Nelly",
            "empLastName": "Mincheva",
            "taskId": 5,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-03T15:04:52.950Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575209404828,
            "wtMonthLastDay": 1577801404828,
            "wtMonth": 11
        },
        {
            "workTimeId": 62,
            "wtDate": "2019-12-03",
            "wtStartTime": "2019-12-03T14:10:04.828Z",
            "wtEndTime": "2019-12-03T15:00:04.828Z",
            "wtComment": "hjj",
            "empId": 22,
            "empFirstName": "Nelly",
            "empLastName": "Mincheva",
            "taskId": 4,
            "taskName": "DB Data Reduction",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-03T15:05:12.265Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575209404828,
            "wtMonthLastDay": 1577801404828,
            "wtMonth": 11
        },
        {
            "workTimeId": 63,
            "wtDate": "2019-12-03",
            "wtStartTime": "2019-12-03T14:10:04.828Z",
            "wtEndTime": "2019-12-03T15:00:04.828Z",
            "wtComment": "hjj",
            "empId": 22,
            "empFirstName": "Nelly",
            "empLastName": "Mincheva",
            "taskId": 125,
            "taskName": "Python",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-03T15:06:23.924Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575209404828,
            "wtMonthLastDay": 1577801404828,
            "wtMonth": 11
        },
        {
            "workTimeId": 167,
            "wtDate": "2020-01-09",
            "wtStartTime": "2020-01-09T11:21:46.084Z",
            "wtEndTime": "2020-01-09T11:22:46.084Z",
            "wtComment": "1212222",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 702,
            "taskName": "002-TASK",
            "taskProjectId": 167,
            "taskProjectName": "01-TEST",
            "createdDate": "2020-01-09T11:21:56.325Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577877706084,
            "wtMonthLastDay": 1580469706084,
            "wtMonth": 0
        },
        {
            "workTimeId": 65,
            "wtDate": "2019-12-04",
            "wtStartTime": "2019-12-04T07:43:19.624Z",
            "wtEndTime": "2019-12-04T10:11:19.624Z",
            "wtComment": "SS",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 126,
            "taskName": "Billing Systame v3.0",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-04T07:43:36.725Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575186199624,
            "wtMonthLastDay": 1577778199624,
            "wtMonth": 11
        },
        {
            "workTimeId": 169,
            "wtDate": "2020-01-09",
            "wtStartTime": "2020-01-09T12:19:28.390Z",
            "wtEndTime": "2020-01-09T13:14:28.390Z",
            "wtComment": "11414",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 700,
            "taskName": "T001",
            "taskProjectId": 164,
            "taskProjectName": "R002",
            "createdDate": "2020-01-09T12:20:17.110Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577881168390,
            "wtMonthLastDay": 1580473168390,
            "wtMonth": 0
        },
        {
            "workTimeId": 81,
            "wtDate": "2019-10-03",
            "wtStartTime": "2019-10-03T14:10:04.828Z",
            "wtEndTime": "2019-10-03T15:00:04.828Z",
            "wtComment": "hjj",
            "empId": 22,
            "empFirstName": "Nelly",
            "empLastName": "Mincheva",
            "taskId": 4,
            "taskName": "DB Data Reduction",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-04T12:18:13.857Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569939004828,
            "wtMonthLastDay": 1572531004828,
            "wtMonth": 9
        },
        {
            "workTimeId": 82,
            "wtDate": "2019-10-03",
            "wtStartTime": "2019-10-03T15:00:04.828Z",
            "wtEndTime": "2019-10-03T15:10:04.828Z",
            "wtComment": "hjj",
            "empId": 22,
            "empFirstName": "Nelly",
            "empLastName": "Mincheva",
            "taskId": 4,
            "taskName": "DB Data Reduction",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-04T12:22:20.933Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569942004828,
            "wtMonthLastDay": 1572534004828,
            "wtMonth": 9
        },
        {
            "workTimeId": 83,
            "wtDate": "2019-10-03",
            "wtStartTime": "2019-10-03T14:00:04.828Z",
            "wtEndTime": "2019-10-03T14:10:04.828Z",
            "wtComment": "hjj",
            "empId": 22,
            "empFirstName": "Nelly",
            "empLastName": "Mincheva",
            "taskId": 4,
            "taskName": "DB Data Reduction",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-04T12:22:56.318Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569938404828,
            "wtMonthLastDay": 1572530404828,
            "wtMonth": 9
        },
        {
            "workTimeId": 85,
            "wtDate": "2019-10-03",
            "wtStartTime": "2019-10-03T10:00:04.828Z",
            "wtEndTime": "2019-10-03T18:10:04.828Z",
            "wtComment": "hjj",
            "empId": 22,
            "empFirstName": "Nelly",
            "empLastName": "Mincheva",
            "taskId": 243,
            "taskName": "Project management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-04T12:26:09.538Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1569924004828,
            "wtMonthLastDay": 1572516004828,
            "wtMonth": 9
        },
        {
            "workTimeId": 86,
            "wtDate": "2019-12-03",
            "wtStartTime": "2019-12-03T12:28:00.000Z",
            "wtEndTime": "2019-12-03T13:00:00.000Z",
            "wtComment": "For sorting",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 244,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-04T12:40:44.241Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575203280000,
            "wtMonthLastDay": 1577795280000,
            "wtMonth": 11
        },
        {
            "workTimeId": 96,
            "wtDate": "2019-12-05",
            "wtStartTime": "2019-12-05T09:40:14.374Z",
            "wtEndTime": "2019-12-05T15:00:14.374Z",
            "wtComment": "gfhgf",
            "empId": 23,
            "empFirstName": "Eleonora",
            "empLastName": "Yaneva",
            "taskId": 241,
            "taskName": "PHP",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-05T09:40:41.237Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575193214374,
            "wtMonthLastDay": 1577785214374,
            "wtMonth": 11
        },
        {
            "workTimeId": 164,
            "wtDate": "2020-01-09",
            "wtStartTime": "2020-01-09T09:26:40.126Z",
            "wtEndTime": "2020-01-09T09:27:40.126Z",
            "wtComment": "asdd",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 128,
            "taskName": "Project Management",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2020-01-09T09:27:02.483Z",
            "lastUpdateDate": "2020-01-10T13:22:28.988Z",
            "workedTimeMin": null,
            "wtYear": 2020,
            "wtMonthFirstDay": 1577870800126,
            "wtMonthLastDay": 1580462800126,
            "wtMonth": 0
        },
        {
            "workTimeId": 97,
            "wtDate": "2019-12-05",
            "wtStartTime": "2019-12-05T10:28:39.053Z",
            "wtEndTime": "2019-12-05T11:22:39.053Z",
            "wtComment": "New",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 123,
            "taskName": "Quality Assurance",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-05T10:28:53.448Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575196119053,
            "wtMonthLastDay": 1577788119053,
            "wtMonth": 11
        },
        {
            "workTimeId": 98,
            "wtDate": "2019-12-05",
            "wtStartTime": "2019-12-05T12:33:13.056Z",
            "wtEndTime": "2019-12-05T13:44:13.056Z",
            "wtComment": "123",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 5,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-05T10:34:05.717Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575203593056,
            "wtMonthLastDay": 1577795593056,
            "wtMonth": 11
        },
        {
            "workTimeId": 103,
            "wtDate": "2019-12-05",
            "wtStartTime": "2019-12-05T09:40:14.374Z",
            "wtEndTime": "2019-12-05T15:00:14.374Z",
            "wtComment": "Issue with Loader",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 1,
            "taskName": "1234: Fix \"too many open cursors\"",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-05T14:13:40.306Z",
            "lastUpdateDate": "2019-12-05T09:40:14.374Z",
            "workedTimeMin": 160,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575193214374,
            "wtMonthLastDay": 1577785214374,
            "wtMonth": 11
        },
        {
            "workTimeId": 104,
            "wtDate": "2019-12-05",
            "wtStartTime": "2019-12-05T09:40:14.374Z",
            "wtEndTime": "2019-12-05T15:00:14.374Z",
            "wtComment": "Issue with Loader",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 1,
            "taskName": "1234: Fix \"too many open cursors\"",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-05T14:13:55.187Z",
            "lastUpdateDate": "2019-12-05T09:40:14.374Z",
            "workedTimeMin": 160,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575193214374,
            "wtMonthLastDay": 1577785214374,
            "wtMonth": 11
        },
        {
            "workTimeId": 105,
            "wtDate": "2019-12-05",
            "wtStartTime": "2019-12-05T07:40:14.374Z",
            "wtEndTime": "2019-12-05T13:00:14.374Z",
            "wtComment": "Issue with Loader",
            "empId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "taskId": 1,
            "taskName": "1234: Fix \"too many open cursors\"",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-05T14:22:55.791Z",
            "lastUpdateDate": "2019-12-05T09:40:14.374Z",
            "workedTimeMin": 160,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575186014374,
            "wtMonthLastDay": 1577778014374,
            "wtMonth": 11
        },
        {
            "workTimeId": 106,
            "wtDate": "2019-12-05",
            "wtStartTime": "2019-12-05T17:23:44.660Z",
            "wtEndTime": "2019-12-05T18:00:44.660Z",
            "wtComment": "adadad",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 5,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-05T17:24:00.738Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575221024660,
            "wtMonthLastDay": 1577813024660,
            "wtMonth": 11
        },
        {
            "workTimeId": 108,
            "wtDate": "2019-12-05",
            "wtStartTime": "2019-12-05T19:00:54.740Z",
            "wtEndTime": "2019-12-05T19:01:54.740Z",
            "wtComment": "Test",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 5,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-05T18:05:33.222Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575226854740,
            "wtMonthLastDay": 1577818854740,
            "wtMonth": 11
        },
        {
            "workTimeId": 109,
            "wtDate": "2019-12-06",
            "wtStartTime": "2019-12-06T07:42:10.067Z",
            "wtEndTime": "2019-12-06T08:00:10.067Z",
            "wtComment": "AAA",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 126,
            "taskName": "Billing Systame v3.0",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-06T07:42:50.166Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575186130067,
            "wtMonthLastDay": 1577778130067,
            "wtMonth": 11
        },
        {
            "workTimeId": 112,
            "wtDate": "2019-12-06",
            "wtStartTime": "2019-12-06T08:49:07.998Z",
            "wtEndTime": "2019-12-06T13:00:07.998Z",
            "wtComment": "Test",
            "empId": 27,
            "empFirstName": "Rosen",
            "empLastName": "Dochev",
            "taskId": 261,
            "taskName": "New mailing functionality",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-06T08:50:10.628Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575190147998,
            "wtMonthLastDay": 1577782147998,
            "wtMonth": 11
        },
        {
            "workTimeId": 114,
            "wtDate": "2019-12-06",
            "wtStartTime": "2019-12-06T09:00:38.530Z",
            "wtEndTime": "2019-12-06T14:00:38.530Z",
            "wtComment": "asda",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 123,
            "taskName": "Quality Assurance",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-06T09:10:12.398Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575190838530,
            "wtMonthLastDay": 1577782838530,
            "wtMonth": 11
        },
        {
            "workTimeId": 115,
            "wtDate": "2019-12-06",
            "wtStartTime": "2019-12-06T13:00:29.018Z",
            "wtEndTime": "2019-12-06T14:00:29.018Z",
            "wtComment": "TEST",
            "empId": 27,
            "empFirstName": "Rosen",
            "empLastName": "Dochev",
            "taskId": 123,
            "taskName": "Quality Assurance",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-06T09:19:00.023Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575205229018,
            "wtMonthLastDay": 1577797229018,
            "wtMonth": 11
        },
        {
            "workTimeId": 117,
            "wtDate": "2019-12-06",
            "wtStartTime": "2019-12-06T09:42:00.000Z",
            "wtEndTime": "2019-12-06T11:22:00.000Z",
            "wtComment": "121212",
            "empId": 25,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "taskId": 5,
            "taskName": "Maintenance, minor enhancements",
            "taskProjectId": 3,
            "taskProjectName": "Tucows General Project",
            "createdDate": "2019-12-08T09:42:26.563Z",
            "lastUpdateDate": null,
            "workedTimeMin": null,
            "wtYear": 2019,
            "wtMonthFirstDay": 1575193320000,
            "wtMonthLastDay": 1577785320000,
            "wtMonth": 11
        }
    ]
}

const returnedWorktime: ApiResult<any>={
    messages: [],
    result: {        
    }
}

export const WorktimeGet: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /worktime/,
    method: 'GET'
}

export const WorktimePost: FakeRequest = {
    data: returnedWorktime,
    httpCode: 200,
    url: /worktime/,
    method: 'POST'
}

export const WorktimePut: FakeRequest = {
    data: returnedWorktime,
    httpCode: 200,
    url: /worktime/,
    method: 'PUT'
}

export const WorktimeDelete: FakeRequest = {
    data: returnedWorktime,
    httpCode: 200,
    url: /worktime/,
    method: 'DELETE'
}