import { Column } from "material-table";
import Customer from "../../../../models/Customer";
import { UseTranslationResponse } from 'react-i18next';
import { compare } from "../../../../utils/string";

const CustomerNameColumn = (translation: UseTranslationResponse): Column<Customer> => {
    return {
      title: translation.t('customerName'),
      field: 'customerName',
      cellStyle:{maxWidth: '400px'},
      customSort: (first: Customer, second: Customer) => {
        return compare(first.customerName, second.customerName);
      }
    };
};

export default CustomerNameColumn;
