import CalendarDayType from "../models/CalendarDayType";
import CalendarProject from "../models/CalendarProject";
import { StatusValues } from "../models/StatusValues";
import { EventType } from '../models/EventType';

const getContrast = function (hexcolor: string): string{
	const r = parseInt(hexcolor.substr(0,2),16);
	const g = parseInt(hexcolor.substr(2,2),16);
	const b = parseInt(hexcolor.substr(4,2),16);
	const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
	return (yiq >= 128) ? 'black' : 'white';
};

export const getTitle = (dayTypes: Array<CalendarDayType>, dayTypeId: EventType): string => {
  const matchType: CalendarDayType | undefined  = dayTypes.find((element) => element.dayTypeId === dayTypeId);

  if (matchType) {
    return matchType.dayTypeName;
  };

  return '';
}

export const getCalendarEventStyle = (dayTypes: Array<CalendarDayType>, dayTypeId: EventType, statusId: number) => {
  const matchType: CalendarDayType | undefined  = dayTypes.find((element) => element.dayTypeId === dayTypeId);

  if (matchType) {
    const dayTypeColor: string = statusId === StatusValues.PENDING ? '#' + matchType.dayTypePendingColorColor : '#' + matchType.dayTypeColor;
    let color: string = getContrast(matchType.dayTypeColor);

    return  {
      backgroundColor: dayTypeColor,
      color,
      borderColor: dayTypeColor
    };
  };

  return {
    backgroundColor: '#007AFF',
    color: getContrast('007AFF'),
    borderColor: '#007AFF'
  };
}

export const getCalendarTaskStyle = (calendarProject: CalendarProject) => {
  if (calendarProject.projectColor) {
    const prjColor: string = '#' + calendarProject.projectColor;
    const color: string = getContrast(calendarProject.projectColor);
    return  {
      backgroundColor: prjColor,
      borderColor: prjColor,
      color
    };
  };

  return {
    backgroundColor: '#3670B6',
    color: getContrast('3670B6'),
    borderColor: '#3670B6'
  };
}
