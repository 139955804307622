import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTitle, DialogContent, DialogContentText, Dialog, DialogActions, Button, Theme} from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import SaraDialogButtons from '../common/SaraDialogButtons';

const styles = (theme: Theme) =>
  createStyles({
   button: {
     marginBottom: 20,
     marginRight: 20
   }
  });

interface NotificationDialogProps  extends WithStyles<typeof styles>  {
  classes: any;
  open: boolean;
  title: string;
  content: string ;
  onClose: () => void;
  role?: string;
  onSubmit?: (event: any) => void;
};

const NotificationDialog: React.FC<NotificationDialogProps> = (props: NotificationDialogProps) => {
  
  const { t } = useTranslation();
  const dialogActions: JSX.Element = props.role === 'confirmation' ? 
      <SaraDialogButtons
        onPrimary={props.onSubmit? props.onSubmit: (event: any) => {}}
        onSecondary={props.onClose}
        primaryDisabled={false}
        primaryText={t('confirm')}
        secondaryText={t('cancel')}
    /> :

    <Button variant="contained" color="primary" className={props.classes.button} onClick={ props.onClose }>{t('cancel')}</Button>

  return (
    <Dialog
      open={props.open}
      maxWidth='xs'
      onClose={props.onClose }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      <DialogTitle id="alert-dialog-title">{ t(props.title) }</DialogTitle>
    
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{ t(props.content) }</DialogContentText>
      </DialogContent>

      <DialogActions>
        { dialogActions }
      </DialogActions>

    </Dialog>
  );
};

export default withStyles(styles)(NotificationDialog);