import genericApi from './genericApi';
import PrjAppGroupOversee from '../models/PrjAppGroupOversee';
import CreatePrjAppGroupOversee from '../models/CreatePrjAppGroupOversee';

const baseUrl: string = 'prj_app_group_oversee';

const prjAppGroupOverseeReqHandler = {
  loadPrjAppGroupOversees: (filterProjectId?: number, filterAppGroupId?: number): Promise<Array<PrjAppGroupOversee>> => {
    const filter: string = `${filterProjectId ? `&filterProjectId=${filterProjectId}` : ''}${filterAppGroupId ? `&filterAppGroupId=${filterAppGroupId}` : ''}`;
    return genericApi.get(`${baseUrl}?sort=appGroupId${filter}`);
  },
  loadPrjAppGroupOversee: (projectId: number, appGroupId: number): Promise<PrjAppGroupOversee> => {
    return genericApi.get(`${baseUrl}/${projectId}/${appGroupId}`);
  },
  createPrjAppGroupOversee: (payload: CreatePrjAppGroupOversee): Promise<PrjAppGroupOversee> => {
    return genericApi.post(baseUrl, payload);
  },
  deletePrjAppGroupOversee: (projectId: number, appGroupId: number): Promise<PrjAppGroupOversee> => {
    return genericApi.delete(`${baseUrl}/${projectId}/${appGroupId}`);
  },
};

export default prjAppGroupOverseeReqHandler;
