import { createStyles, DialogActions, Theme, WithStyles, withStyles } from '@material-ui/core';
import React, { Component, ReactNode, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApiResult } from '../../../api/apiResult';
import appGroupsReqHandler from '../../../api/appGroupsRemote';
import appUserGroupsReqHandler from '../../../api/appUserGroupsRemote';
import custAppGroupOverseeReqHandler from '../../../api/custAppGroupOverseeRemote';
import empAppGroupOverseeReqHandler from '../../../api/empAppGroupOverseeRemote';
import formViewControlsReqHandler from '../../../api/formViewControlsRemote';
import prjAppGroupOverseeReqHandler from '../../../api/prjAppGroupOverseeRemote';
import AppGroup from '../../../models/AppGroup';
import AppUser from '../../../models/AppUser';
import AppUserGroup from '../../../models/AppUserGroup';
import CustAppGroupOversee from '../../../models/CustAppGroupOversee';
import Customer from '../../../models/Customer';
import { DialogMode } from '../../../models/DialogMode';
import EmpAppGroupOversee from '../../../models/EmpAppGroupOversee';
import Employee from '../../../models/Employee';
import { FormViewAccess } from '../../../models/FormViewAccess';
import { FormViewCondition } from '../../../models/FormViewCondition';
import { FormViewControl } from '../../../models/FormViewControl';
import PrjAppGroupOversee from '../../../models/PrjAppGroupOversee';
import Project from '../../../models/Project';
import { AppGroupState } from '../../../store/reducers/appGroupsReducer';
import {
  getAppGroup,
  getAppGroupAppUsers,
  getAppGroupCustomers,
  getAppGroupPermissions,
  getAppGroupProjects,
  getAppGroupStaff,
  getOldState,
  SelectAppGroupState,
} from '../../../store/selectors/appGroupSelectors';
import SaraDialogButtons from '../../common/SaraDialogButtons';
import AppGroupDialog from './AppGroupDialog';
import CustomerDataContainer from './appGroupsCustomerData/CustomerDataContainer';
import FormViewsContainer from './appGroupsFormView/FormViewsContainer';
import ProjectAccessContainer from './appGroupsProjectAccess/ProjectAccessContainer';
import StaffAccessContainer from './appGroupsStaffAccess/StaffAccessContainer';
import UsersContainer from './appGroupsUsers/UsersContainer';
import * as deepEqual from 'fast-deep-equal';
import NotificationDialog from '../../notification/NotificationDialog';
import UpdateAppGroup from '../../../models/UpdateAppGroup';

const styles = (theme: Theme) => createStyles({});

interface AppGroupDialogContainerProps extends WithStyles<typeof styles>, WithTranslation {
  isOpen: boolean;
  dialogMode: DialogMode;
  setSnackbarText: (text: string) => void;
  appGroup: AppGroup;
  appUsers: Array<AppUser>;
  permissions: Record<string, { formViewControlId?: number; accessId: FormViewAccess }>;
  customers: Array<Customer>;
  projects: Array<Project>;
  staff: Array<Employee>;
  oldState: Partial<AppGroupState>;
  onClose: () => void;
  onSaved: () => void;
}

interface AppGroupDialogContainerState {
  validated: boolean;
  activeStep: number;
  showCloseWarning: boolean;
  isOpenNotification: boolean;
  notificationTitle: string;
  notificationContent: string;
}

type AppGroupRequest = Promise<
  AppGroup | AppUserGroup | FormViewControl | CustAppGroupOversee | PrjAppGroupOversee | EmpAppGroupOversee | void
>;

const APP_GROUPS_DIALOG_STEPS: Array<string> = ['users', 'formView', 'customerData', 'projectData', 'staffData'];

class AppGroupDialogContainer extends Component<AppGroupDialogContainerProps, AppGroupDialogContainerState> {
  state: AppGroupDialogContainerState = {
    validated: false,
    activeStep: 0,
    showCloseWarning: false,
    isOpenNotification: false,
    notificationTitle: '',
    notificationContent: '',
  };

  isValid(): boolean {
    return !!this.props.appGroup.appGroupName;
  }

  prepareAppGroupToInsert(): UpdateAppGroup {
    return {
      appGroupName: this.props.appGroup.appGroupName.trim(),
      appGroupDescr: this.props.appGroup.appGroupDescr ? this.props.appGroup.appGroupDescr.trim() : '',
    };
  }

  handleSave() {
    const appGroupToInsert: UpdateAppGroup = this.prepareAppGroupToInsert();
    appGroupsReqHandler
      .createAppGroup(appGroupToInsert)
      .then((appGroup: AppGroup) => {
        const appGroupRequests: Array<AppGroupRequest> = [];
        this.props.appUsers.forEach((user: AppUser) =>
          appGroupRequests.push(
            appUserGroupsReqHandler.createAppUserGroup({
              appUserId: user.appUserId,
              appGroupId: appGroup.appGroupId,
            })
          )
        );
        for (const formViewCd in this.props.permissions) {
          if (this.props.permissions[formViewCd]) {
            appGroupRequests.push(
              formViewControlsReqHandler.createFormViewControl({
                formViewCd: FormViewCondition[formViewCd as keyof typeof FormViewCondition],
                accessId: this.props.permissions[formViewCd].accessId,
                appGroupId: appGroup.appGroupId,
              })
            );
          }
        }
        this.props.customers.forEach((customer: Customer) =>
          appGroupRequests.push(
            custAppGroupOverseeReqHandler.createCustAppGroupOversee({
              customerId: customer.customerId,
              appGroupId: appGroup.appGroupId,
            })
          )
        );
        this.props.projects.forEach((prj: Project) =>
          appGroupRequests.push(
            prjAppGroupOverseeReqHandler.createPrjAppGroupOversee({
              projectId: prj.prjId,
              appGroupId: appGroup.appGroupId,
            })
          )
        );
        this.props.staff.forEach((empl: Employee) =>
          appGroupRequests.push(
            empAppGroupOverseeReqHandler.createEmpAppGroupOversee({
              empId: empl.empId,
              appGroupId: appGroup.appGroupId,
            })
          )
        );
        return Promise.all(appGroupRequests);
      })
      .then(() => {
        this.setState({ activeStep: 0, validated: false, showCloseWarning: false });
        this.props.onSaved();
      })
      .catch(error => this.handleError(error));
  }

  handleEdit(shouldClose: boolean, nextStep: boolean) {
    const stepToEdit = this.state.activeStep;
    let editRequests: Array<AppGroupRequest> = [];
    switch (stepToEdit) {
      case 0:
        if (
          this.isValid() &&
          this.props.oldState.appGroup &&
          (this.props.appGroup.appGroupName !== this.props.oldState.appGroup.appGroupName ||
            ((this.props.appGroup.appGroupDescr !== undefined) &&
              (this.props.oldState.appGroup.appGroupDescr !== undefined) &&
              this.props.appGroup.appGroupDescr.trim() !== this.props.oldState.appGroup.appGroupDescr.trim()))
        ) {
          editRequests.push(appGroupsReqHandler.updateAppGroup(this.props.appGroup.appGroupId, this.prepareAppGroupToInsert()));
        }
        if (this.props.oldState.appUsers) {
          const oldAppUsers = this.props.oldState.appUsers;
          const appUsersToAdd = this.props.appUsers.filter(user => !oldAppUsers.some(oldUser => oldUser.appUserId === user.appUserId));
          const appUsersToDel = oldAppUsers.filter(oldUser => !this.props.appUsers.some(user => user.appUserId === oldUser.appUserId));
          if (appUsersToAdd.length) {
            editRequests = editRequests.concat(
              appUsersToAdd.map(user =>
                appUserGroupsReqHandler.createAppUserGroup({
                  appUserId: user.appUserId,
                  appGroupId: this.props.appGroup.appGroupId,
                })
              )
            );
          }
          if (appUsersToDel.length) {
            editRequests = editRequests.concat(
              appUsersToDel.map(user => appUserGroupsReqHandler.deleteAppUserGroup(user.appUserId, this.props.appGroup.appGroupId))
            );
          }
        }
        break;
      case 1:
        const oldPermissions = this.props.oldState.permissions;
        let oldPermArray: Array<string> = [];
        if (oldPermissions && Object.keys(oldPermissions).length) {
          oldPermArray = Object.keys(oldPermissions);
        }
        const newPermissions = this.props.permissions;
        let newPermArray: Array<string> = [];
        if (newPermissions && Object.keys(newPermissions).length) {
          newPermArray = Object.keys(newPermissions);
        }
        const permissionsToAdd = newPermArray.filter(perm => !oldPermArray.includes(perm));
        const permissionsToDel = oldPermArray.filter(perm => !newPermArray.includes(perm));
        const permissionsToEdit = newPermArray.filter(perm => {
          if (oldPermissions) {
            return oldPermArray.includes(perm) && oldPermissions[perm].accessId !== newPermissions[perm].accessId;
          }
          return false;
        });
        if (permissionsToAdd.length) {
          editRequests = editRequests.concat(
            permissionsToAdd.map(formViewCd =>
              formViewControlsReqHandler.createFormViewControl({
                formViewCd: FormViewCondition[formViewCd as keyof typeof FormViewCondition],
                accessId: newPermissions[formViewCd].accessId,
                appGroupId: this.props.appGroup.appGroupId,
              })
            )
          );
        }
        if (permissionsToDel.length) {
          editRequests = editRequests.concat(
            permissionsToDel.map(formViewCd => {
              const controlId = oldPermissions ? oldPermissions[formViewCd].formViewControlId : 0;
              if (controlId) {
                return formViewControlsReqHandler.deleteFormViewControl(controlId);
              }
              return Promise.resolve();
            })
          );
        }
        if (permissionsToEdit.length && oldPermissions) {
          editRequests = editRequests.concat(
            permissionsToEdit.map(formViewCd => {
              const formViewControlId = oldPermissions[formViewCd].formViewControlId;
              return formViewControlsReqHandler.updateFormViewControl(formViewControlId ? formViewControlId : 0, {
                formViewCd: FormViewCondition[formViewCd as keyof typeof FormViewCondition],
                accessId: newPermissions[formViewCd].accessId,
                appGroupId: this.props.appGroup.appGroupId,
              });
            })
          );
        }
        break;
      case 2:
        if (this.props.oldState.customers) {
          const oldCustomers = this.props.oldState.customers;
          const customersToAdd = this.props.customers.filter(
            customer => !oldCustomers.some(oldCustomer => oldCustomer.customerId === customer.customerId)
          );
          const customersToDel = oldCustomers.filter(
            oldCustomer => !this.props.customers.some(customer => customer.customerId === oldCustomer.customerId)
          );
          if (customersToAdd.length) {
            editRequests = editRequests.concat(
              customersToAdd.map(customer =>
                custAppGroupOverseeReqHandler.createCustAppGroupOversee({
                  customerId: customer.customerId,
                  appGroupId: this.props.appGroup.appGroupId,
                })
              )
            );
          }
          if (customersToDel.length) {
            editRequests = editRequests.concat(
              customersToDel.map(customer =>
                custAppGroupOverseeReqHandler.deleteCustAppGroupOversee(customer.customerId, this.props.appGroup.appGroupId)
              )
            );
          }
        }
        break;
      case 3:
        if (this.props.oldState.projects) {
          const oldProjects = this.props.oldState.projects;
          const projectsToAdd = this.props.projects.filter(project => !oldProjects.some(oldProject => oldProject.prjId === project.prjId));
          const projectsToDel = oldProjects.filter(oldProject => !this.props.projects.some(project => project.prjId === oldProject.prjId));
          if (projectsToAdd.length) {
            editRequests = editRequests.concat(
              projectsToAdd.map(project =>
                prjAppGroupOverseeReqHandler.createPrjAppGroupOversee({
                  projectId: project.prjId,
                  appGroupId: this.props.appGroup.appGroupId,
                })
              )
            );
          }
          if (projectsToDel.length) {
            editRequests = editRequests.concat(
              projectsToDel.map(project =>
                prjAppGroupOverseeReqHandler.deletePrjAppGroupOversee(project.prjId, this.props.appGroup.appGroupId)
              )
            );
          }
        }
        break;
      case 4:
        const { employeesToAdd, employeesToDel } = this.generateStaffRequests();
        if (employeesToAdd.length) {
          editRequests = editRequests.concat(
            employeesToAdd.map(employee =>
              empAppGroupOverseeReqHandler.createEmpAppGroupOversee({
                empId: employee.empId,
                appGroupId: this.props.appGroup.appGroupId,
              })
            )
          );
        }
        if (employeesToDel.length) {
          editRequests = editRequests.concat(
            employeesToDel.map(employee =>
              empAppGroupOverseeReqHandler.deleteEmpAppGroupOversee(employee.empId, this.props.appGroup.appGroupId)
            )
          );
        }
        break;
    }
    Promise.all(editRequests)
      .then(response => {
        if (response.length) {
          this.props.setSnackbarText(this.props.t('userGroupUpdated'));
        }
        if (shouldClose) {
          this.setState({ showCloseWarning: true }, () => {
            this.handleClose();
          });
        }
        if (nextStep) {
          this.setState(prevState => ({ activeStep: prevState.activeStep + 1, showCloseWarning: false }));
        }
      })
      .catch(error => this.handleError(error));
  }

  handleError = (error: { response: { data: ApiResult<any> } }) => {
    this.setState({
      isOpenNotification: true,
      notificationTitle: 'unexpectedErrorMessageTitle',
      notificationContent: 'unexpectedErrorMessage',
    });
  };

  generateStaffRequests(): { employeesToAdd: Array<Employee>; employeesToDel: Array<Employee> } {
    let employeesToAdd: Array<Employee> = [];
    let employeesToDel: Array<Employee> = [];
    if (this.props.oldState.staff) {
      const oldStaff = this.props.oldState.staff;
      employeesToAdd = this.props.staff.filter(empl => !oldStaff.some(oldEmpl => oldEmpl.empId === empl.empId));
      employeesToDel = oldStaff.filter(oldEmpl => !this.props.staff.some(empl => empl.empId === oldEmpl.empId));
    }
    return { employeesToAdd, employeesToDel };
  }

  handlePrimary = () => {
    if (!this.isValid()) {
      this.setState({ validated: true });
      return;
    }
    if (this.state.activeStep === APP_GROUPS_DIALOG_STEPS.length - 1) {
      if (this.props.dialogMode === DialogMode.CREATE) {
        this.handleSave();
      } else {
        this.handleEdit(true, false);
      }
    } else {
      if (this.props.dialogMode === DialogMode.UPDATE) {
        this.handleEdit(false, true);
      } else {
        this.setState(prevState => ({ activeStep: prevState.activeStep + 1, showCloseWarning: false }));
      }
    }
  };

  handleSecondary = () => {
    if (this.state.activeStep === 0) {
      this.handleClose();
    } else {
      if (this.props.dialogMode === DialogMode.UPDATE) {
        this.handleEdit(false, false);
      }
      this.setState(prevState => ({ activeStep: prevState.activeStep - 1, showCloseWarning: false }));
    }
  };

  handleClose = () => {
    let hasChanges: boolean = false;
    switch (this.state.activeStep) {
      case 0:
        if (
          this.props.oldState.appGroup &&
          (this.props.oldState.appGroup.appGroupName !== this.props.appGroup.appGroupName ||
            this.props.oldState.appGroup.appGroupDescr !== this.props.appGroup.appGroupDescr)
        ) {
          hasChanges = true;
        }
        hasChanges = hasChanges || !deepEqual.default(this.props.oldState.appUsers, this.props.appUsers);
        break;
      case 1:
        hasChanges = hasChanges || !deepEqual.default(this.props.oldState.permissions, this.props.permissions);
        break;
      case 2:
        hasChanges = hasChanges || !deepEqual.default(this.props.oldState.customers, this.props.customers);
        break;
      case 3:
        hasChanges = hasChanges || !deepEqual.default(this.props.oldState.projects, this.props.projects);
        break;
      case 4:
        hasChanges = hasChanges || !deepEqual.default(this.props.oldState.staff, this.props.staff);
        break;
      default:
        break;
    }
    if (this.state.showCloseWarning || !hasChanges) {
      this.setState({ activeStep: 0, validated: false, showCloseWarning: false });
      this.props.onClose();
    } else {
      this.setState({ showCloseWarning: true });
    }
  };

  onClickLabel = (step: any) => {
    if (!this.isValid()) {
      this.setState({ validated: true });
      return;
    }
    if (this.props.dialogMode === DialogMode.UPDATE) {
      this.handleEdit(false, false);
    }

    const activeStep = APP_GROUPS_DIALOG_STEPS.findIndex(item => item === step);
    this.setState({ activeStep, showCloseWarning: false });
  };

  getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <UsersContainer validated={this.state.validated} dialogMode={this.props.dialogMode} onError={this.handleError} />;
      case 1:
        return <FormViewsContainer dialogMode={this.props.dialogMode} onError={this.handleError} />;
      case 2:
        return <CustomerDataContainer dialogMode={this.props.dialogMode} onError={this.handleError} />;
      case 3:
        return <ProjectAccessContainer dialogMode={this.props.dialogMode} onError={this.handleError} />;
      case 4:
        return <StaffAccessContainer dialogMode={this.props.dialogMode} onError={this.handleError} />;
      default:
        return null;
    }
  };

  getPrimaryButtonText = (): string => {
    if (this.state.activeStep === APP_GROUPS_DIALOG_STEPS.length - 1) {
      if (this.props.dialogMode === DialogMode.CREATE) {
        return this.props.t('save');
      } else {
        const { employeesToAdd, employeesToDel } = this.generateStaffRequests();
        if (employeesToAdd.length || employeesToDel.length) {
          return this.props.t('save');
        }
        return this.props.t('close');
      }
    } else {
      return this.props.t('next');
    }
  };

  closeNotificationDialog = (): void => {
    this.setState({ isOpenNotification: false, notificationTitle: '', notificationContent: '' });
  };

  render() {
    const isValid = this.isValid();
    const dialogActions: ReactNode = (
      <DialogActions>
        <SaraDialogButtons
          secondaryText={this.state.activeStep === 0 ? this.props.t('cancel') : this.props.t('back')}
          onSecondary={this.handleSecondary}
          primaryText={this.getPrimaryButtonText()}
          onPrimary={this.handlePrimary}
          primaryDisabled={!isValid}
        />
      </DialogActions>
    );

    return (
      <Fragment>
        <AppGroupDialog
          appGroup={this.props.appGroup}
          dialogMode={this.props.dialogMode}
          isOpen={this.props.isOpen}
          onClose={this.handleClose}
          dialogActions={dialogActions}
          activeStep={this.state.activeStep}
          onClickLabel={this.onClickLabel}
          steps={APP_GROUPS_DIALOG_STEPS}
          stepContent={this.getStepContent}
          showCloseWarning={this.state.showCloseWarning}
        />
        {this.state.isOpenNotification && (
          <NotificationDialog
            open={this.state.isOpenNotification}
            title={this.state.notificationTitle}
            content={this.state.notificationContent}
            onClose={this.closeNotificationDialog}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: SelectAppGroupState) => ({
  appGroup: getAppGroup(state),
  appUsers: getAppGroupAppUsers(state),
  permissions: getAppGroupPermissions(state),
  customers: getAppGroupCustomers(state),
  projects: getAppGroupProjects(state),
  staff: getAppGroupStaff(state),
  oldState: getOldState(state),
});

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(AppGroupDialogContainer)));
