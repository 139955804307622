import { LOGIN_FAIL, LOGIN_SUCCESS } from '../actions/actionsTypes';

interface Success { success: boolean | null };
interface Action { type: string };

const initState: Success = {
  success: null
};

export default function authReducer(state: Success = initState, action: Action) {

  switch (action.type) {
      
    case LOGIN_SUCCESS:
      return  { success: true };
    case LOGIN_FAIL:
      return { success: false }; 
    default:
      return state;
  }
}