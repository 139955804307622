import { Column } from 'material-table';
import React from 'react';
import CalendarEvent from '../../../../models/CalendarEvent';
import RequestStatusIndicator from '../RequestStatusIndicator';

export default (): Column<CalendarEvent> => ({
  title: '',
  field: 'statusId',
  cellStyle: { textAlign: 'center' },
  headerStyle: { textAlign: 'center' },
  width: 20,
  render: (rowData: CalendarEvent) => <RequestStatusIndicator status={rowData.statusId} />,
});
