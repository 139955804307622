import AppGroup from '../../models/AppGroup';
import AppUser from '../../models/AppUser';
import Customer from '../../models/Customer';
import Employee from '../../models/Employee';
import { FormViewAccess } from '../../models/FormViewAccess';
import Project from '../../models/Project';
import { AppGroupActionType } from './appGroupActionType';
import { AppGroupState } from '../reducers/appGroupsReducer';

export const enterAppGroupName = (appGroupName: string) => ({
  type: AppGroupActionType.ENTER_APP_GROUP_NAME,
  payload: {
    appGroupName,
  },
});

export const enterAppGroupDescription = (appGroupDescr: string) => ({
  type: AppGroupActionType.ENTER_APP_GROUP_DESCR,
  payload: {
    appGroupDescr,
  },
});

export const addAppUser = (appUser: AppUser) => ({
  type: AppGroupActionType.ADD_APP_USER,
  payload: {
    appUser,
  },
});

export const removeAppUser = (appUser: AppUser) => ({
  type: AppGroupActionType.REMOVE_APP_USER,
  payload: {
    appUser,
  },
});

export const changeGroupPermissions = (formViewName: string, accessLevel: FormViewAccess, formViewControlId?: number) => ({
  type: AppGroupActionType.CHANGE_GROUP_PERMISSION,
  payload: {
    formViewName,
    accessLevel,
    formViewControlId,
  },
});

export const addCustomer = (customer: Customer) => ({
  type: AppGroupActionType.ADD_CUSTOMER,
  payload: {
    customer,
  },
});

export const removeCustomer = (customer: Customer) => ({
  type: AppGroupActionType.REMOVE_CUSTOMER,
  payload: {
    customer,
  },
});

export const addProject = (project: Project) => ({
  type: AppGroupActionType.ADD_PROJECT,
  payload: {
    project,
  },
});

export const removeProject = (project: Project) => ({
  type: AppGroupActionType.REMOVE_PROJECT,
  payload: {
    project,
  },
});

export const addToStaff = (employee: Employee) => ({
  type: AppGroupActionType.ADD_TO_STAFF,
  payload: {
    employee,
  },
});

export const removeFromStaff = (employee: Employee) => ({
  type: AppGroupActionType.REMOVE_FROM_STAFF,
  payload: {
    employee,
  },
});

export const storeAppGroup = (appGroup: AppGroup) => ({
  type: AppGroupActionType.STORE_APP_GROUP,
  payload: {
    appGroup,
  },
});

export const clearAppGroup = () => ({
  type: AppGroupActionType.CLEAR_APP_GROUP,
});

export const clearAppGroupsState = () => ({
  type: AppGroupActionType.CLEAR_GROUP_STATE,
});

export const addToOldState = (oldState: Partial<AppGroupState>) => ({
  type: AppGroupActionType.ADD_TO_OLD_STATE,
  payload: {
    oldState,
  },
});

export const clearOldState = () => ({
  type: AppGroupActionType.CLEAR_OLD_STATE,
});
