import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import WorkItem from '../../../../models/WorkItem';
import { compare } from '../../../../utils/string';

const TaskColumn = (translation: UseTranslationResponse): Column<WorkItem> => ({
  title: translation.t('task'),
  field: 'taskName',
  customSort: (first: WorkItem, second: WorkItem) => compare(first.taskName, second.taskName),
  cellStyle: { minWidth: 100, width: '20%' },
});

export default TaskColumn;
