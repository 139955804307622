import React, { Component } from 'react';
import { connect } from 'react-redux';
import CustomerAppGroups from './CustomerAppGroups';
import AppGroup from '../../../../models/AppGroup';
import appGroupsReqHandler from '../../../../api/appGroupsRemote';
import { ApiResult } from '../../../../api/apiResult';
import { DialogMode } from '../../../../models/DialogMode';
import { CustomerActionType } from '../../../../store/actions/customerActionType';
import { bindActionCreators } from 'redux';
import { getCustomerAppGroups, getCustomer } from '../../../../store/selectors/customerSelectors';
import { addAppGroup, removeAppGroup, addToOldCustomersState } from '../../../../store/actions/customerActions';
import custAppGroupOverseeReqHandler from '../../../../api/custAppGroupOverseeRemote';
import { compare } from '../../../../utils/string';
import Customer from '../../../../models/Customer';
import CustAppGroupOversee from '../../../../models/CustAppGroupOversee';
import { CustomersState } from '../../../../store/reducers/customersReducer';

interface CustomerAppGroupsContainerProps {
  dialogMode: DialogMode;
  customer: Customer;
  authAppGroups: Array<AppGroup>;
  onError: (error: { response: { data: ApiResult<any> } }) => void;
  addAppGroup: (appGroup: AppGroup) => { type: CustomerActionType; payload: { appGroup: AppGroup } };
  removeAppGroup: (appGroup: AppGroup) => { type: CustomerActionType; payload: { appGroup: AppGroup } };
  addToOldCustomersState: (
    oldState: Partial<CustomersState>
  ) => { type: CustomerActionType; payload: { oldState: Partial<CustomersState> } };
  isMove?: boolean;
}

interface CustomerAppGroupsContainerState {
  isLoading: boolean;
  unauthAppGroups: Array<AppGroup>;
}

class CustomerAppGroupsContainer extends Component<CustomerAppGroupsContainerProps, CustomerAppGroupsContainerState> {
  state: CustomerAppGroupsContainerState = {
    isLoading: false,
    unauthAppGroups: [],
  };

  componentDidMount() {
    const appGroupsRequests: Array<Promise<any>> = [appGroupsReqHandler.loadAppGroups()];
    if (this.props.dialogMode !== DialogMode.CREATE && !this.props.authAppGroups.length) {
      appGroupsRequests.push(custAppGroupOverseeReqHandler.loadCustAppGroupOversees(this.props.customer.customerId));
    }
    this.setState({ isLoading: true }, () =>
      Promise.all(appGroupsRequests)
        .then(([appGroups, oversees]) => {
          const filteredAppGroups = appGroups.filter((group: AppGroup) => {
            if (oversees && oversees.length) {
              const isCustomererInGroup = !!oversees.find((oversee: CustAppGroupOversee) => oversee.appGroupId === group.appGroupId);
              if (isCustomererInGroup) {
                this.props.addAppGroup(group);
              }
            }
            return this.props.authAppGroups.findIndex(g => g.appGroupId === group.appGroupId) < 0;
          });
          this.props.addToOldCustomersState({ customerAppGroups: this.props.authAppGroups });
          const unauthAppGroups = this.sortAppGroups(filteredAppGroups);
          this.setState({ isLoading: false, unauthAppGroups });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          this.props.onError(error);
        })
    );
  }

  handleMove = (id: number, toAuthorized: boolean) => {
    if (this.props.dialogMode === DialogMode.VIEW) {
      return;
    }
    const appGroupsList = [...this.state.unauthAppGroups];
    if (toAuthorized) {
      let groupIndex = 0;
      const selectedAppGroup = appGroupsList.find((group, index) => {
        groupIndex = index;
        return group.appGroupId === id;
      });
      appGroupsList.splice(groupIndex, 1);
      if (selectedAppGroup) {
        this.props.addAppGroup(selectedAppGroup);
      }
    } else {
      const selectedAppGroup = this.props.authAppGroups.find(group => {
        return group.appGroupId === id;
      });
      if (selectedAppGroup) {
        this.props.removeAppGroup(selectedAppGroup);
        appGroupsList.push(selectedAppGroup);
      }
    }
    this.setState({ unauthAppGroups: this.sortAppGroups(appGroupsList) });
  };

  private sortAppGroups(appGroup: Array<AppGroup>): Array<AppGroup> {
    return appGroup.sort((first, second) => compare(first.appGroupName, second.appGroupName));
  }

  render() {
    return (
      <CustomerAppGroups
        dialogMode={this.props.dialogMode}
        isLoading={this.state.isLoading}
        unauthAppGroups={this.state.unauthAppGroups}
        authAppGroups={this.sortAppGroups(this.props.authAppGroups)}
        moveAppGroup={this.props.isMove ? this.handleMove : () => {}}
        readOnly={!this.props.isMove}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({ customer: getCustomer(state), authAppGroups: getCustomerAppGroups(state) });

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      addAppGroup,
      removeAppGroup,
      addToOldCustomersState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAppGroupsContainer);
