import { Grid } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SaraSelect, Lookup } from '../../saraSelect/SaraSelect';
import CalendarEvent from '../../../models/CalendarEvent';
import moment from 'moment';
import SaraDatePicker from '../../common/SaraDatePicker';
import { TextField } from '@material-ui/core';
import CalendarDateRange from '../../../models/CalendarDateRange';
import Moment from 'moment';

const styles = () =>
  createStyles({
    displayBtn: {
      width: 80,
    },
    buttonWrapper: {
      textAlign: 'end',
      marginTop: '8px',
    },
    empName: {
      display: 'flex',
      alignItems: 'flex-end',
      marginBottom: '5px',
      justifyContent: 'center',
    },
    textField: {
      width: '100%',
    },
  });

interface RequestFiltersProps extends WithStyles<typeof styles> {
  requestItem: CalendarEvent;
  handleChange: (event: any, field: string) => void;
  eventTypeLookups: Array<Lookup>;
  validated: boolean;
  isAdministratorGroup: boolean;
  calendarDateRanges: Array<CalendarDateRange>;
  todayDate: Moment.Moment;
}

const RequestFilters: React.FC<RequestFiltersProps> = (props: RequestFiltersProps) => {
  const { t } = useTranslation();

  const endDateValidationMessage = (): string | undefined => {
    if (props.requestItem.rqStartDate) {
      if (props.requestItem.rqEndDate) {
        if (moment(props.requestItem.rqStartDate) > moment(props.requestItem.rqEndDate)) {
          return t('endTimeShouldBeAfterStartTime');
        }
        if (!moment(props.requestItem.rqEndDate).isSame(props.requestItem.rqStartDate, 'year')) {
          return t('differentYearValidation');
        }
      }
    };
  };

  const disabled = (type: string): boolean => {
    if (props.isAdministratorGroup) {
      return false;
    }
    if ((type === 'startDate' || type === 'eventType') && props.requestItem.rqStartDate) {
      return Moment(props.requestItem.rqStartDate, 'YYYY-MM-DD').isBefore(props.todayDate);
    }
    if (type === 'endDate' && props.requestItem.rqEndDate) {
      return Moment(props.requestItem.rqEndDate, 'YYYY-MM-DD').isBefore(props.todayDate);
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} className={props.classes.empName}>
        {props.requestItem.empName}
      </Grid>
      <Grid item xs={6} md={3}>
        <SaraDatePicker
          label={t('startDate')}
          required
          minDate={props.calendarDateRanges[0].minDate}
          maxDate={props.calendarDateRanges[0].maxDate}
          value={props.requestItem.rqStartDate}
          onChange={(date, value) => {
            const isValid = date ? moment(date).isValid() : false;
            return isValid ? props.handleChange(date, 'startDate') : {};
          }}
          validated={props.validated && !props.requestItem.rqStartDate}
          disabled={disabled('startDate')}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <SaraDatePicker
          label={t('endDate')}
          required
          value={props.requestItem.rqEndDate}
          validated={props.validated && !props.requestItem.rqEndDate}
          minDate={props.calendarDateRanges[1].minDate}
          maxDate={props.calendarDateRanges[1].maxDate}
          onChange={(date, value) => {
            const isValid = date ? moment(date).isValid() : false;
            return isValid ? props.handleChange(date, 'endDate') : {};
          }}
          disabled={disabled('endDate')}
          error={!!endDateValidationMessage()}
          helperText={endDateValidationMessage()}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <SaraSelect
          validated
          label={t('eventType')}
          id="eventType-id"
          value={props.requestItem.eventTypeId}
          onChange={event => props.handleChange(event, 'selectedEventTypeId')}
          lookups={props.eventTypeLookups}
          readonly={disabled('eventType')}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <TextField
          label={t('comment')}
          id="notes"
          multiline
          className={props.classes.textField}
          // rowsMax="4"
          value={props.requestItem.notes}
          onChange={event => props.handleChange(event, 'notes')}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(RequestFilters);
