import FakeRequest from "./FakeRequest";
import { ConfigurationGet } from './ConfigurationRequest';
import { CustomersGet, CustomersPost, CustomersPut } from './CustomersRequest';
import { EmployeesGet, EmployeesPut } from './EmployeesRequest';
import { HeartbeatPost } from './HeartbeatRequest';
import { LoginPost, LogoutPost } from './LoginRequest';
import { OfficesGet } from './OfficesRequest';
import { ProjectsGet, ProjectsPost, ProjectsPut } from './ProjectsRequest';
import { TaskDelete, TaskPut, TasksGet, TasksPost } from './TasksRequest.ts';
import { WorktimePost, WorktimeDelete, WorktimeGet, WorktimePut } from './WorkTimeRequest';
import { ProjectStatusesGet } from './ProjectStatusesRequest';
import { AppGroupsGet, AppGroupsPost, AppGroupsPut } from './AppGroupsRequest';
import { FormViewsGet } from './FormViewsRequest'
import { FormViewUserControlsGet } from './FormViewUserControlsRequest'


const fakedRequests: Array<FakeRequest> = [
    ConfigurationGet,
    CustomersGet, CustomersPost, CustomersPut,
    EmployeesGet, EmployeesPut,
    HeartbeatPost,
    LoginPost, LogoutPost,
    OfficesGet,
    ProjectsGet, ProjectsPost, ProjectsPut,
    TaskDelete, TaskPut, TasksGet, TasksPost,
    WorktimePost, WorktimeDelete, WorktimeGet, WorktimePut,
    ProjectStatusesGet,
    AppGroupsGet, AppGroupsPost, AppGroupsPut,
    FormViewsGet,
    FormViewUserControlsGet
];

export default fakedRequests;