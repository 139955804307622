import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import WorkItem from '../../../../models/WorkItem';
import { formatDate } from '../../../../utils/dateTime';
import { compare } from '../../../../utils/string';

const DateColumn = (translation: UseTranslationResponse): Column<WorkItem> => ({
  title: translation.t('date'),
  field: 'wtStartTime',
  type: 'date',
  customSort: (first: WorkItem, second: WorkItem) => compare(first.wtStartTime, second.wtStartTime),
  render: (rowData: WorkItem) => formatDate(rowData.wtStartTime, 'DD/MM'),
  cellStyle: { minWidth: 75 },
});

export default DateColumn;
