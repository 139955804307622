import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import Employee from "../../../../models/Employee";
import { compare } from "../../../../utils/string";

const LastNameColumn = (translation: UseTranslationResponse): Column<Employee> => {
    return {
      title: translation.t('lastName'),
      field: 'empLastName',
      customSort: (first: Employee, second: Employee) => {
        return compare(first.empLastName, second.empLastName);
      }
    };
};

export default LastNameColumn;
