import React, { Component } from 'react';
import { connect } from 'react-redux';
import User from '../../models/User';
import { getUser, SelectUserState } from '../../store/selectors/userSelectors';
import LoggedInMenu from '../loggedInMenu/LoggedInMenu';

interface OfflinePageContainerProps {
  user: User;
}

interface OfflinePageContainerState {  
}

class OfflinePageContainer extends Component<OfflinePageContainerProps, OfflinePageContainerState> {
  state: OfflinePageContainerState = {
  };

  render() {
    return (
      <LoggedInMenu
        activePermissions={[]}
        realoadPermissions={() => {}}
        loggedInUsername={this.props.user.username}
      />
    );
  }
}

const mapStateToProps = (state: SelectUserState) => ({
  user: getUser(state),
});

export default connect(mapStateToProps)(OfflinePageContainer);
