import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import { ProjectWorkedTime } from '../../../../models/OvertimeReport';
import WorkedTimeColumn from './WorkedTime';
import ProjectColumn from './Project';

const columns = (translation: UseTranslationResponse): Column<ProjectWorkedTime>[] => {
  return [
    ProjectColumn(translation),
    WorkedTimeColumn(translation),
  ];
};

export default columns;
