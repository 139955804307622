import { Dialog, DialogContent, Step, StepButton, Stepper, Theme } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import AppGroup from '../../../models/AppGroup';
import { DialogMode } from '../../../models/DialogMode';
import SaraDialogTitle from '../../common/SaraDialogTitle';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      padding: '24px',
      height: '80%',
    },
  });

interface AppGroupDialogProps extends WithStyles<typeof styles> {
  appGroup: AppGroup;
  dialogMode: DialogMode;
  isOpen: boolean;
  dialogActions: ReactNode;
  activeStep: any;
  onClose: (event: any) => void;
  onClickLabel: (label: string) => void;
  steps: Array<string>;
  stepContent: Function;
  showCloseWarning: boolean;
}

const AppGroupDialog: React.FC<AppGroupDialogProps> = (props: AppGroupDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      aria-labelledby="-dialog-title"
      maxWidth="md"
      fullWidth={true}
      classes={{ paper: props.classes.dialog }}
    >
      <SaraDialogTitle
        onClose={props.onClose} 
        text={props.dialogMode !== DialogMode.CREATE ? props.appGroup.appGroupName : t('newAppGroupTitle')}
        showWarning={props.showCloseWarning}
        warning={t('closeAndDiscardWarning')}
      />
      <Stepper nonLinear activeStep={props.activeStep}>
        {props.steps.map((label: string) => {
          const stepProps: { completed?: boolean } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepButton onClick={() => props.onClickLabel(label)}>{t(label)}</StepButton>
            </Step>
          );
        })}
      </Stepper>
      <DialogContent style={{ paddingBottom: '10px' }}>
        <div>{props.stepContent(props.activeStep)}</div>
      </DialogContent>
      {props.dialogMode !== DialogMode.VIEW && props.dialogActions}
    </Dialog>
  );
};

export default withStyles(styles)(AppGroupDialog);
