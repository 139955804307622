import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Employee from '../../../models/Employee';
import { compare } from '../../../utils/string';
import SaraTable from '../../common/SaraTable';
import Columns from './columns';

interface EmployeesListProps {
  employees: Array<Employee>;
  isLoading: boolean;
  canReadTeam: boolean;
  canWriteTeam: boolean;
  onEditClick: (event: any, data: Employee) => void;
}

const EmployeesList: React.FC<EmployeesListProps> = (props: EmployeesListProps) => {
  const { t } = useTranslation();
  const actions = [];

  if (props.canReadTeam && !props.canWriteTeam) {
    actions.push({
      icon: () => <VisibilityIcon />,
      onClick: (event: any, data: Employee | Employee[]) => props.onEditClick(event, data as Employee),
      tooltip: t('view'),
    });
  }
  if (props.canWriteTeam) {
    actions.push({
      icon: () => <CreateIcon />,
      onClick: (event: any, data: Employee | Employee[]) => props.onEditClick(event, data as Employee),
      tooltip: t('edit'),
    });
  }

  return (
    <div className="tableWithActionsContainer">
      <SaraTable<Employee>
        isLoading={props.isLoading}
        items={props.employees.sort((first, second) =>
          compare(`${first.empFirstName} ${first.empLastName}`, `${second.empFirstName} ${second.empLastName}`)
        )}
        columns={Columns(useTranslation())}
        sorting
        isEditable={false}
        actions={actions}
      />
    </div>
  );
};

export default EmployeesList;
