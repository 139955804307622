import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import WorkItem from '../../../../models/WorkItem';
import { formatDate, totalSencondsSinceMidnight } from '../../../../utils/dateTime';

const EndTimeColumn = (translation: UseTranslationResponse): Column<WorkItem> => ({
  title: translation.t('to'),
  field: 'wtEndTime',
  type: 'date',
  render: (rowData: WorkItem) => formatDate(rowData.wtEndTime, 'HH:mm'),
  customSort: (first: WorkItem, second: WorkItem) =>
    totalSencondsSinceMidnight(first.wtEndTime) - totalSencondsSinceMidnight(second.wtEndTime),
  cellStyle: { minWidth: 75 },
});

export default EndTimeColumn;
