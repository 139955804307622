import { FormControl, MenuItem, Theme, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import DropdownTreeSelect, { TreeNode } from 'react-dropdown-tree-select';
import './treeMultiSelect.css';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import  ProjectsTree  from '../../../models/ProjectsTree';
import ChildTaskOfProjectsTree from '../../../models/ChildTaskOfProjectsTree';
import ChildEmployeeOfProjectsTree from '../../../models/ChildEmployeeOfProjectsTree';

const styles = (theme: Theme) =>
  createStyles({
    list: {
      marginTop: 30,
    },
  });

interface TreeMultiSelectContainerProps extends WithTranslation, WithStyles<typeof styles> {
  onChange: (selectedNodes: Array<TreeNode>, data: Array<ProjectsTree>, viewValue: Array<string>) => void;
  lookups: Array<ProjectsTree>;
  label: string;
  viewValue: Array<string>;
  disabled: boolean;
  setViewValue: (viewValue: Array<string>) => void;
  handleExpanded: (parent: ProjectsTree | ChildEmployeeOfProjectsTree) => boolean;
}

interface TreeMultiSelectContainerState {
  data: Array<ProjectsTree>;
}

class TreeMultiSelectContainer extends Component<TreeMultiSelectContainerProps,TreeMultiSelectContainerState > {
  
  constructor(props: TreeMultiSelectContainerProps) {
    super(props);
    this.state = {
      data: [],
    }
  }

  componentDidMount() {
    this.setState({ data: this.props.lookups});
  };

  componentDidUpdate( prevProps:TreeMultiSelectContainerProps ) {
    if (prevProps !== this.props) {
      this.setState({data: this.props.lookups});
    };
  };

  onChange = (currentNode: TreeNode, selectedNodes: Array<TreeNode>): void => {

    let nodes: Array<string> = selectedNodes.map((item: TreeNode) => item.label);
    const ids: Array<string> = currentNode._id.split('-');
    const id: Array<number> = ids.map((x: string) => Number(x));
    let node: any  = {};
    const data: Array<ProjectsTree> = this.state.data;

    if (id.length === 2) {
      node = data[id[1]];
      node.checked = currentNode.checked;
      if (node.checked) {
        this.treeCheck(data[id[1]], true, true);

      } else {
        this.treeCheck(data[id[1]], true, false);
      }
    };

    if (id.length === 3) {
      node = data[id[1]].children[id[2]];
      node.checked = currentNode.checked;
      
      if (node.checked ) {
        this.treeCheck(data[id[1]].children[id[2]], false, true);

        const differentValue: Array<string> | null = this.treeViewValueCheck(data[id[1]], nodes)
        
        nodes = differentValue ? differentValue : nodes;
        
      } else {
        data[id[1]].checked = false;
        this.treeCheck(data[id[1]].children[id[2]], false, false);
      };
      
      data[id[1]].expanded = this.props.handleExpanded(data[id[1]]);
    };

    if (id.length === 4) {
      node = data[id[1]].children[id[2]].children[id[3]];
      node.checked = currentNode.checked;

      if (node.checked ) {     

        const different: Array<string> | null = this.treeParentCheck(data[id[1]], data[id[1]].children[id[2]], nodes);
        
        nodes = different ? different : nodes;

      } else {
        data[id[1]].children[id[2]].checked = false;
        data[id[1]].checked = false;
      };

      data[id[1]].children[id[2]].expanded=this.props.handleExpanded(data[id[1]].children[id[2]]);
      data[id[1]].expanded=this.props.handleExpanded(data[id[1]]);
    };

    this.setState({ data })
    this.props.onChange(selectedNodes, this.state.data, nodes); 
  };

  treeCheck = (parent: ProjectsTree | ChildEmployeeOfProjectsTree, isNestedChild: boolean, checked: boolean) => {
    
    const child: Array<ChildEmployeeOfProjectsTree | ChildTaskOfProjectsTree> = parent.children;
   
    child.map((item: ChildEmployeeOfProjectsTree | ChildTaskOfProjectsTree) => item.checked = checked);

    if (isNestedChild ) {
      child.forEach((item: any) => {

        const children: Array<ChildTaskOfProjectsTree> | undefined = item.hasOwnProperty('children') ? item.children : undefined;

        if (children)
          children.map((item: ChildTaskOfProjectsTree) => item.checked = checked);
      });
    };
  };

  treeParentCheck = (project: ProjectsTree, employ: ChildEmployeeOfProjectsTree, nodes: Array<string>) => {

    const tasks: Array<ChildTaskOfProjectsTree> = employ.children;
    const unCheck: Array<ChildTaskOfProjectsTree> = tasks.filter((t: ChildTaskOfProjectsTree) => !t.checked);
    
    if (unCheck.length === 0) {
      employ.checked = true;

      const labels: Array<string> = tasks.map(((x: ChildTaskOfProjectsTree) => x.label));
      let different: Array<string> = nodes.filter((item: string) => !labels.includes(item));

      different.push(employ.label);

      const employs: Array<ChildEmployeeOfProjectsTree> = project.children;
      const unCheckEmploys: Array<ChildEmployeeOfProjectsTree> = employs.filter((e: any) => !e.checked);

      if (unCheckEmploys.length === 0) {
        project.checked = true;
        const labelEmploy:Array<string> = employs.map((x: ChildEmployeeOfProjectsTree) => x.label);
        different = different.filter((item: string) => !labelEmploy.includes(item));
        different.push(project.label)
      }

      return different;
    };
     return null; 
  };

  treeViewValueCheck = (project: ProjectsTree, vieValue: Array<string>) => {

    const employs: Array<ChildEmployeeOfProjectsTree> = project.children;
    const unCheckedEmploy: Array<ChildEmployeeOfProjectsTree> = employs.filter((x: ChildEmployeeOfProjectsTree) => !x.checked );
    
    if (unCheckedEmploy.length > 0 ) {
      return null;

    } else {
      project.checked= true;

      const labels: Array<string> = employs.map(((x: ChildEmployeeOfProjectsTree) => x.label));
      let different: Array<string> = vieValue.filter((item: string) => !labels.includes(item));

      employs.forEach((employ: ChildEmployeeOfProjectsTree) => {
        const tasks: Array<ChildTaskOfProjectsTree> = employ.children;
        const labelsTask: Array<string> = tasks.map((t: ChildTaskOfProjectsTree) => t.label);
        different = different.filter((item: string) => !labelsTask.includes(item));
      });
      
      different.push(project.label);
      return different
    };
  };

  render () {
    return (

      <FormControl fullWidth>

        <TextField
          label={ this.props.label }
          fullWidth
          select
          defaultValue="value"
          value="value"
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              classes: { paper: this.props.classes.list },
            },
          }}
          disabled={this.props.disabled}
        >
          <MenuItem value='value' style={{ display: 'none'}}>
            {this.props.viewValue.join(', ')}
          </MenuItem>
          <DropdownTreeSelect data={ this.state.data } onChange={ this.onChange } showDropdown='always' className="mdl"  />
        </TextField> 
      </FormControl>
    );
  }
};

export default withTranslation()(withStyles(styles)(TreeMultiSelectContainer))
