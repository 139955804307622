import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";

const returnedData: ApiResult<any> = {
    "messages": [],
    "result": [
        {
            "officeId": 1,
            "officeIds": null,
            "officeName": "ITCIS",
            "officeAddress": null,
            "officePhone": null,
            "officePhone2": null,
            "officeEmail": null,
            "locationId": 1,
            "locationName": "София",
            "canDelete": false
        }
    ]
}

export const OfficesGet: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /offices/,
    method: 'GET'
}