import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";

const returnedData: ApiResult<any> = {
    "messages": [],
    "result": [
        {
            "statusCD": "ACTIVE",
            "statusName": "Active"
        },
        {
            "statusCD": "PLANNED",
            "statusName": "Planned"
        },
        {
            "statusCD": "ON_HOLD",
            "statusName": "On Hold"
        },
        {
            "statusCD": "DONE",
            "statusName": "Done"
        }
    ]
}

export const ProjectStatusesGet: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /project_statuses/,
    method: 'GET'
}