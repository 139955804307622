import OfficeCalendarEvent from '../models/OfficeCalendarEvent';
import Moment from 'moment';

const OFFICE_CALENDAR_EVENT_ITEM: OfficeCalendarEvent = {
  officeId: 0,
  date: Moment().hours(0).minutes(0).seconds(0),
  isWorking : 1,
  dayDescription: '',
};

export { OFFICE_CALENDAR_EVENT_ITEM };
