import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";

const returnedData: ApiResult<any> = {
    "messages": [],
    "result": {
        "ldapUserMappedFields": "firstName,lastName,email,username"
    }
}

export const ConfigurationGet: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /configuration/,
    method: 'GET'

}