export const compare = (first?: string | null, second?: string | null) => {
    if (!!first && !!second) {
      return first.toLocaleLowerCase().localeCompare(second.toLocaleLowerCase());
    } else if (first === second) {
      return 0;
    }
    else if (first === null) {
        return 1;
    }
    else {
        return -1;
    }
}
