import { CheckCircleOutline, FlagOutlined, HighlightOffOutlined } from '@material-ui/icons';
import moment, { Moment } from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CalendarEvent from '../../../models/CalendarEvent';
import EditRowProps from '../../../models/EditRowProps';
import { EventType } from '../../../models/EventType';
import Permissions from '../../../models/Permissions';
import { StatusValues } from '../../../models/StatusValues';
import { approved } from '../../../utils/approved';
import SaraDeleteRow from '../../common/SaraDeleteRow';
import SaraTable from '../../common/SaraTable';
import { Lookup } from '../../saraSelect/SaraMultiSelect';
import Columns from './columns';
import EditRequestRow from './EditRequestRow';
import deepEqual from 'fast-deep-equal';

interface RequestsListProps {
  requestItems: Array<CalendarEvent>;
  onDelete: (eventItem: CalendarEvent) => Promise<void>;
  onUpdate: (eventItem: CalendarEvent, shouldUpdateVac: boolean) => Promise<void>;
  eventTypeLookups: Array<Lookup>;
  permissions: Permissions;
  isEdit: boolean;
  approved: (rowData: any) => void;
  reject: (rowData: any) => void;
  finalized: (rowData: any) => void;
  todayDate: Moment;
  isAdministratorGroup: boolean;
}

const RequestsList: React.FC<RequestsListProps> = (props: RequestsListProps) => {
  const { t } = useTranslation();

  const columns = Columns(useTranslation());

  const isDeletable = (rowData: CalendarEvent): boolean =>
    (props.isAdministratorGroup || !moment(rowData.rqStartDate, 'YYYY-MM-DD').isBefore(props.todayDate)) &&
    (rowData.statusId === StatusValues.PENDING || rowData.statusId === StatusValues.APPROVED);

  const shouldUpdateVac = (oldEvent: CalendarEvent, newEvent: CalendarEvent): boolean => {
    const hasPaidVac = newEvent.eventTypeId === EventType.PAID_VACATION || oldEvent.eventTypeId === EventType.PAID_VACATION;
    const hasChangedDate = newEvent.rqStartDate !== oldEvent.rqStartDate || newEvent.rqEndDate !== oldEvent.rqEndDate;
    return hasPaidVac || (hasChangedDate && hasPaidVac);
  };

  return (
    <div className="tableWithActionsContainerRequest">
      <SaraTable<CalendarEvent>
        isLoading={false}
        items={props.requestItems}
        columns={columns}
        sorting
        isEditable={
          props.isEdit
            ? rowData =>
                rowData.statusId === StatusValues.PENDING ||
                (rowData.statusId === StatusValues.APPROVED && !!props.permissions.extendedWrite)
            : false
        }
        isDeletable={isDeletable}
        deleteTooltip={(rowData: CalendarEvent) => (isDeletable(rowData) ? t('Delete') : t('requestIsClosedForDeletion'))}
        maxHeight="auto"
        onDelete={(rowData: CalendarEvent) => (rowData.statusId === StatusValues.PENDING ? props.onDelete(rowData) : Promise.resolve())}
        actionsColumnIndex={columns.length}
        actions={[
          (rowData: CalendarEvent) =>
            approved(props.permissions.canApprove, rowData.eventTypeId).canWrite && rowData.statusId === StatusValues.PENDING
              ? {
                  icon: () => <CheckCircleOutline />,
                  tooltip: `${t('approve')}`,
                  onClick: (event, rowData) => props.approved(rowData),
                }
              : {
                  icon: '',
                  onClick: () => {},
                  hidden: true,
                },

          (rowData: CalendarEvent) =>
            approved(props.permissions.canApprove, rowData.eventTypeId).canWrite && rowData.statusId === StatusValues.PENDING
              ? {
                  icon: () => <HighlightOffOutlined />,
                  tooltip: `${t('reject')}`,
                  onClick: (event, rowData) => props.reject(rowData),
                }
              : {
                  icon: '',
                  onClick: () => {},
                  hidden: true,
                },

          (rowData: CalendarEvent) =>
            approved(props.permissions.canApprove, rowData.eventTypeId).canWrite && rowData.statusId === StatusValues.APPROVED
              ? {
                  icon: () => <FlagOutlined />,
                  tooltip: `${t('finalize')}`,
                  onClick: (event, rowData) => props.finalized(rowData),
                }
              : {
                  icon: '',
                  onClick: () => {},
                  hidden: true,
                },
        ]}
        components={{
          EditRow: (erProps: EditRowProps<CalendarEvent>) => {
            if (erProps.mode === 'delete') {
              return (
                <SaraDeleteRow
                  columnsBefore={7}
                  confirmationMessage="deleteEventsConfirmation"
                  onCancel={() => erProps.onEditingCanceled('delete', erProps.data)}
                  onSave={() => erProps.onEditingApproved('delete', erProps.data, erProps.data)}
                />
              );
            } else {
              return (
                <EditRequestRow
                  requestItem={erProps.data}
                  onSave={(newValue: CalendarEvent): Promise<void> =>
                    props
                      .onUpdate(newValue, shouldUpdateVac(erProps.data, newValue))
                      .then(() => erProps.onEditingCanceled('update', erProps.data))
                  }
                  onCancel={() => erProps.onEditingCanceled('update', erProps.data)}
                  eventTypeLookups={props.eventTypeLookups}
                  isAdministratorGroup={props.isAdministratorGroup}
                />
              );
            }
          },
        }}
      />
    </div>
  );
};

// React.memo spares unnecessary rerenders on every row update
export default React.memo(RequestsList, (prevProps: RequestsListProps, nextProps: RequestsListProps) => deepEqual(prevProps, nextProps));
