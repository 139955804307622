import { AppBar, createStyles, List, ListItem, ListItemIcon, ListItemText, Theme, Toolbar, Typography, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore, Repeat, ExitToApp } from '@material-ui/icons';
import { withStyles, WithStyles } from '@material-ui/styles';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import logoImg from '../../assets/simpleLogo.svg';
import menuItems from '../../configs/menu';
import { FormViewCondition } from '../../models/FormViewCondition';
import { FormViewUserControl } from '../../models/FormViewUserControl';
import MenuItemModel from '../../models/MenuItemModel';
import EmployeeSearchContainer from '../employeeToolbar/EmployeeSearchContainer';
import ExportWorktimeIcon from '../employeeToolbar/ExportWorktimeIcon';
import NotFound from '../errorPages/NotFound';
import Unauthorized from '../errorPages/Unauthorized';
import OfflineContainer from '../errorPages/OfflineContainer';
import loggedInMenuStyles from './loggedInMenu.module.css';
import AppLoaderContainer from '../errorPages/AppLoaderContainer';

const styles = (theme: Theme) =>
  createStyles({
    appHeader: {
      backgroundColor: '#27385B',
    },
    titleContainer: {
      flexGrow: 1,
      marginLeft: 16,
      display: 'flex',
    },
    title: {
      marginTop: 'auto',
      marginBottom: 'auto',
      width: 174,
    },
    itemIcon: {
      minWidth: 40,
      color: 'white',
    },
    activeItem: {
      backgroundColor: '#2E5487',
    },
    toolbar: {
      paddingLeft: 16,
      paddingRight: 40,
    },
    childrenItemWrapper: {
      marginLeft: 40,
    },
  });

interface LoggedInMenuProps extends WithStyles<typeof styles> {
  activePermissions: Array<FormViewUserControl>;
  loggedInUsername: string;
  realoadPermissions: () => void;
  signOut?: (event: any) => void;
  isLoading?: boolean;
  handleClick?: (itemName: string) => void;
  itemClick?: any;
  changePassword?: () => void;
}

const LoggedInMenu: React.FC<LoggedInMenuProps> = (props: LoggedInMenuProps) => {
  const { t } = useTranslation();
  const hasExtendedWorktimePermission = props.activePermissions.find(
    permission => permission.formViewCd === FormViewCondition.WORK_TIME_EXTENDED
  );

  const hasWorkTimePermission = props.activePermissions.find(permission => permission.formViewCd === FormViewCondition.WORK_TIME);

  const getActiveMenuItems = (): Array<MenuItemModel> =>
    menuItems.filter(menuItem => {
      const isActive = props.activePermissions.find(vmi => vmi.formViewCd === menuItem.formViewCd);
      if (isActive && menuItem.children) {
        menuItem.children = menuItem.children.filter(childItem =>
          props.activePermissions.find(vmi => vmi.formViewCd === childItem.formViewCd)
        );
      }
      return isActive;
    });

  const mapMenuItemToElement = (item: MenuItemModel): JSX.Element => {
    if (!item.route) {
      return <Redirect exact key={item.label} from={item.link} to="/unauthorized" />;
    }
    const permission = props.activePermissions.find(vmi => vmi.formViewCd === item.formViewCd);
    if (permission) {
      const permissionAppGroup = props.activePermissions.find(permission => permission.formViewCd === FormViewCondition.APP_GROUP);
      const appGroupAccessId: number = permissionAppGroup ? permissionAppGroup.accessId : 0;
      if (item.formViewCd === FormViewCondition.WORK_TIME) {
        const extendedPermission = props.activePermissions.find(vmi => vmi.formViewCd === FormViewCondition.WORK_TIME_EXTENDED);

        return item.route({
          canRead: permission.accessId > 0,
          canWrite: permission.accessId === 2,
          extendedRead: extendedPermission ? extendedPermission.accessId > 0 : false,
          extendedWrite: extendedPermission ? extendedPermission.accessId === 2 : false,
          appGroupAccessId,
          hasWriteAccess: (permissionName: string): boolean => {
            const thePermission = props.activePermissions.find(perm => perm.formViewCd === permissionName);
            return !!thePermission && thePermission.accessId === 2;
          },
          hasReadAccess: (permissionName: string): boolean => {
            const thePermission = props.activePermissions.find(perm => perm.formViewCd === permissionName);
            return !!thePermission && thePermission.accessId > 0;
          },
        });
      }
      if (item.formViewCd === FormViewCondition.CALENDAR) {
        const extendedPermission = props.activePermissions.find(vmi => vmi.formViewCd === FormViewCondition.CALENDAR_EXTENDED);
        const approveBTPermission = props.activePermissions.find(vmi => vmi.formViewCd === FormViewCondition.APPROVE_BT);
        const approveHOPermission = props.activePermissions.find(vmi => vmi.formViewCd === FormViewCondition.APPROVE_HO);
        const approveSICKPermission = props.activePermissions.find(vmi => vmi.formViewCd === FormViewCondition.APPROVE_SICK);
        const approveVACPermission = props.activePermissions.find(vmi => vmi.formViewCd === FormViewCondition.APPROVE_VAC);

        return item.route({
          canRead: permission.accessId > 0,
          canWrite: permission.accessId === 2,
          extendedRead: extendedPermission ? extendedPermission.accessId > 0 : false,
          extendedWrite: extendedPermission ? extendedPermission.accessId === 2 : false,
          canApprove: {
            approveBTRead: approveBTPermission ? approveBTPermission.accessId > 0 : false,
            approveBTWrite: approveBTPermission ? approveBTPermission.accessId === 2 : false,
            approveHORead: approveHOPermission ? approveHOPermission.accessId > 0 : false,
            approveHOWrite: approveHOPermission ? approveHOPermission.accessId === 2 : false,
            approveSICKRead: approveSICKPermission ? approveSICKPermission.accessId > 0 : false,
            approveSICKWrite: approveSICKPermission ? approveSICKPermission.accessId === 2 : false,
            approveVACRead: approveVACPermission ? approveVACPermission.accessId > 0 : false,
            approveVACWrite: approveVACPermission ? approveVACPermission.accessId === 2 : false,
          },
          appGroupAccessId,
          hasWriteAccess: (permissionName: string): boolean => {
            const thePermission = props.activePermissions.find(perm => perm.formViewCd === permissionName);
            return !!thePermission && thePermission.accessId === 2;
          },
          hasReadAccess: (permissionName: string): boolean => {
            const thePermission = props.activePermissions.find(perm => perm.formViewCd === permissionName);
            return !!thePermission && thePermission.accessId > 0;
          },
        });
      }
      return item.route({
        canRead: permission.accessId > 0,
        canWrite: permission.accessId === 2,
        appGroupAccessId,
        hasWriteAccess: (permissionName: string): boolean => {
          const thePermission = props.activePermissions.find(perm => perm.formViewCd === permissionName);
          return !!thePermission && thePermission.accessId === 2;
        },
        hasReadAccess: (permissionName: string): boolean => {
          const thePermission = props.activePermissions.find(perm => perm.formViewCd === permissionName);
          return !!thePermission && thePermission.accessId > 0;
        },
      });
    }
    if (props.activePermissions.length === 0) {
      return item.route({
        canRead: false,
        canWrite: false,
        appGroupAccessId: 0,
        hasWriteAccess: (permissionName: string) => false,
        hasReadAccess: (permissionName: string) => false,
      });
    }
    return <Redirect exact key={item.label} from={item.link} to="/unauthorized" />;
  };

  const flatMenuItems = (menuItems: Array<MenuItemModel>): Array<MenuItemModel> =>
    menuItems.flatMap((item: MenuItemModel) => {
      if (item.children) {
        return item.children;
      } else {
        return item;
      }
    });

  const routes: Array<JSX.Element> = flatMenuItems(menuItems).map(mapMenuItemToElement);
  routes.push(<Route exact key="offline" path="/offline" component={OfflineContainer} />);
  routes.push(<Route exact key="unauthorized" path="/unauthorized" component={Unauthorized} />);

  if (props.activePermissions.length > 0) {
    routes.push(<Route key="notFound" component={NotFound} />);
  }

  const loadMenu: any = (menuItems: any) => {
    return menuItems.map((item: MenuItemModel) => {
      if (!item.children) {
        return (
          <ListItem
            button
            key={item.label}
            component={Link}
            to={item.link}
            onClick={props.realoadPermissions}
            className={window.location.pathname === item.link ? props.classes.activeItem : ''}
          >
            {item.icon && <ListItemIcon className={props.classes.itemIcon} children={item.icon}></ListItemIcon>}
            <ListItemText primary={t(item.label)} />
          </ListItem>
        );
      }
      return (
        <div key={item.label}>
          <ListItem
            button
            onClick={() => (props.handleClick ? props.handleClick(item.label) : null)}
            className={window.location.pathname === item.link ? props.classes.activeItem : ''}
          >
            {item.icon && <ListItemIcon className={props.classes.itemIcon} children={item.icon} />}
            <ListItemText primary={t(item.label)} />
            {props.itemClick[item.label] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={props.itemClick[item.label]} timeout="auto" unmountOnExit>
            <div className={props.classes.childrenItemWrapper}>{loadMenu(item.children)}</div>
          </Collapse>
        </div>
      );
    });
  };

  return (
    <Fragment>
      <AppBar className={props.classes.appHeader}>
        <Toolbar className={props.classes.toolbar}>
          <img src={logoImg} alt="logo" width="24" />
          <div className={props.classes.titleContainer}>
            <Typography variant="subtitle1" className={props.classes.title}>
              SARA
            </Typography>
            {hasExtendedWorktimePermission && <Route exact path="/" component={EmployeeSearchContainer} />}
          </div>
          {hasWorkTimePermission && <Route exact path="/" component={ExportWorktimeIcon} />}
          <div>{props.loggedInUsername}</div>
        </Toolbar>
      </AppBar>
      <div className={loggedInMenuStyles.pageWrapper}>
        <div className={loggedInMenuStyles.menuWrapper}>
          <List>{loadMenu(getActiveMenuItems())}</List>
          {props.signOut && (
            <List>
              <ListItem button onClick={props.changePassword}>
                <ListItemIcon className={props.classes.itemIcon}>
                  <Repeat />
                </ListItemIcon>
                <ListItemText primary={t('changePassword')} />
              </ListItem>
              <ListItem button onClick={props.signOut}>
                <ListItemIcon className={props.classes.itemIcon}>
                  <ExitToApp />
                </ListItemIcon>
                <ListItemText primary={t('signOut')} />
              </ListItem>
            </List>
          )}
        </div>
        <div className={loggedInMenuStyles.contentWrapper}>{props.isLoading ? <AppLoaderContainer /> : <Switch>{routes}</Switch>}</div>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(LoggedInMenu);
