import React from 'react';
import { Column } from 'material-table';
import WorkItem from '../../../../models/WorkItem';
import { totalSencondsSinceMidnight } from '../../../../utils/dateTime';
import { UseTranslationResponse } from 'react-i18next';
import moment from 'moment';

const endColumn = (translation: UseTranslationResponse, timeZone: string): Column<WorkItem> => {
  return {
    title: translation.t('end'),
    field: 'wtEndTime',
    type: 'date',
    render: (rowData: WorkItem) => {
      return (
        <span>
          {moment(rowData.wtEndTime)
            .tz(timeZone)
            .format('HH:mm')}
        </span>
      );
    },
    customSort: (first: WorkItem, second: WorkItem) => {
      return totalSencondsSinceMidnight(first.wtEndTime) - totalSencondsSinceMidnight(second.wtEndTime);
    },
  };
};

export default endColumn;
