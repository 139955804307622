import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  CircularProgress,
  createStyles,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  TableCell,
  TextField,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { withStyles, WithStyles } from '@material-ui/styles';
import Moment from 'moment';
import React, { Component, Fragment, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ApiErrorMessage, ApiWarningMessage } from '../../../api/apiMessage';
import { ApiResult } from '../../../api/apiResult';
import Task from '../../../models/Task';
import { WorkTimeModel } from '../../../models/WorkTimeModel';
import { ensureArrayContains } from '../../../utils/array';
import { getDuration, getDurationIncludingSeconds, convertBrowserTimeToApiFormat } from '../../../utils/dateTime';
import SaraDatePicker from '../../common/SaraDatePicker';
import MaterialIcon from '../../icons/IconsStyle';
import InfoSnackbar from '../../infoSnackbar/InfoSnackbar';
import { Lookup, SaraSelect } from '../../saraSelect/SaraSelect';
import SaraCheckCancelButtons from '../../common/SaraCheckCancelButtons';
import ProjectsSelector from '../common/ProjectsSelector';
import TimePicker from '../common/TimePicker';
import workTimeStyles from '../workTime.module.css';
import OverlapEntryWarning from './OverlapEntryWarning';
import WorkTimeModeSwitcher from './WorkTimeModeSwitcher';
import NotificationDialog from '../../notification/NotificationDialog';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Cookies from 'universal-cookie';
import moment from 'moment-timezone';
import { SelectUserState, getTimezone } from '../../../store/selectors/userSelectors';
import { connect } from 'react-redux';

const styles = (theme: Theme) =>
  createStyles({
    saveButton: {
      marginTop: 13,
    },
    saveButtonDisabled: {
      marginTop: 13,
      opacity: 0.6,
    },
    durationContainer: {
      textAlign: 'right',
    },
    durationHelper: {
      marginTop: '15px !important',
    },
    materialIcon: MaterialIcon,
    inlineEditButton: {
      padding: 12,
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    workTimeContainer: {
      display: 'flex',
    },
  });

const baseDate = new Date(0);

export enum RenderMode {
  WithTimer = 'WithTimer',
  InlineEdit = 'InlineEdit',
}

interface WorkTimeCookie {
  timerStart: string | null;
  timerMode: boolean;
  startTime: ParsableDate;
  endTime: ParsableDate;
  date: ParsableDate;
  projectId: number;
  description: string;
  taskId: number;
  expanded: boolean;
}

interface WorkTimeProps extends WithStyles<typeof styles>, WithTranslation {
  tasks: Array<Task>;
  onSave: (workItem: WorkTimeModel, suppressWarning: string | null) => Promise<void>;
  onCancel?: () => void;
  isLoading: boolean;
  onTasksReloadNeeded?: () => void;
  renderMode: RenderMode;
  initialModel?: WorkTimeModel;
  defaultProjectId?: number;
  defaultTaskId?: number;
  hasExtendedPermission: boolean;
  collapsable?: boolean;
  timeZoneId: string;
  rememberData: boolean;
  empId?: number;
  handleError?: (error: { response: { data: ApiResult<any> } }) => void;
}

interface WorkTimeState {
  startTime: ParsableDate;
  endTime: ParsableDate;
  date: ParsableDate;
  dateErrorMessage?: string;
  projectId: number;
  description: string;
  snackbarText?: string;
  validated: boolean;
  taskId: number;
  duration: string | null;
  warningConfirmationNeeded: boolean;
  tasks: Array<Task>;
  startTimeSelected: boolean;
  isOpenNotification: boolean;
  notificationTitle: string;
  notificationContent: string;
  timerMode: boolean;
  timerStart: Date | null;
  expanded: boolean;
}

const workTimeCookieName: string = 'SaraTimerCookie';

class InsertWorkTimeContainerComponent extends Component<WorkTimeProps, WorkTimeState> {
  blankState: WorkTimeState;
  timerId: any;

  constructor(props: WorkTimeProps) {
    super(props);
    this.blankState = {
      startTime: new Date(),
      endTime: null,
      date: new Date(),
      projectId: 0,
      description: '',
      validated: false,
      taskId: 0,
      duration: null,
      warningConfirmationNeeded: false,
      tasks: [],
      startTimeSelected: true,
      isOpenNotification: false,
      notificationTitle: '',
      notificationContent: '',
      timerMode: false,
      timerStart: null,
      expanded: true,
    };
    this.state = { ...this.blankState };
  }

  componentDidMount() {
    if (this.props.renderMode === RenderMode.InlineEdit) {
      if (this.props.initialModel) {
        this.setState({
          startTime: new Date(this.props.initialModel.startTime),
          endTime: new Date(this.props.initialModel.endTime),
          date: new Date(this.props.initialModel.startTime),
          duration: getDuration(this.props.initialModel.startTime, this.props.initialModel.endTime),
          description: this.props.initialModel.description,
          projectId: this.props.initialModel.projectId,
          taskId: this.props.initialModel.taskId,
          tasks: this.getFilteredTasks(this.props.initialModel.startTime),
        });
      }
      return;
    }

    this.timerId = setInterval(() => {
      if (this.state.timerMode && this.state.timerStart && this.state.timerStart !== null) {
        if (this.state.timerStart.getUTCDay() !== new Date().getUTCDay()) {
          this.setState({ startTime: null, duration: null });
        } else {
          this.setState({ duration: this.getDurationFromProps(null, null) });
        }
      }
    }, 1000);

    this.setSTateFromCookie();

    this.setState({ duration: this.getDurationFromProps(this.state.startTime, this.state.endTime) });
    document.addEventListener('keydown', this.keydownHandler);
  }

  setSTateFromCookie() {
    const workTimeCookie: WorkTimeCookie = new Cookies().get(workTimeCookieName);
    if (workTimeCookie && this.props.rememberData) {
      this.setState({
        date: workTimeCookie.date,
        description: workTimeCookie.description,
        projectId: workTimeCookie.projectId,
        taskId: workTimeCookie.taskId,
        startTime: workTimeCookie.startTime,
        endTime: workTimeCookie.endTime,
        timerStart: workTimeCookie.timerStart ? new Date(workTimeCookie.timerStart) : null,
        timerMode: workTimeCookie.timerMode,
        expanded: workTimeCookie.expanded,
      });
    }
  }

  componentDidUpdate(prevProps: WorkTimeProps, prevState: WorkTimeState) {
    if (!this.props.rememberData && this.props.empId !== prevProps.empId) {
      this.setState({ ...this.blankState });
    } else if (this.props.rememberData && this.props.empId !== prevProps.empId) {
      this.setSTateFromCookie();
    }

    if (
      prevProps.isLoading &&
      !this.props.isLoading &&
      this.props.defaultProjectId &&
      this.props.defaultTaskId &&
      this.state.projectId === 0 &&
      this.state.taskId === 0
    ) {
      this.setState({ taskId: this.props.defaultTaskId, projectId: this.props.defaultProjectId });
    }
    if (prevProps.tasks !== this.props.tasks) {
      this.setState({ tasks: this.getFilteredTasks(this.state.date) });
    }
    const workTimeCookie: WorkTimeCookie = new Cookies().get(workTimeCookieName);
    if (
      this.props.rememberData &&
      (!workTimeCookie ||
        prevState.timerMode !== this.state.timerMode ||
        prevState.timerStart !== this.state.timerStart ||
        prevState.startTime !== this.state.startTime ||
        prevState.endTime !== this.state.endTime ||
        prevState.description !== this.state.description ||
        prevState.projectId !== this.state.projectId ||
        prevState.taskId !== this.state.taskId ||
        prevState.date !== this.state.date ||
        prevState.expanded !== this.state.expanded)
    ) {
      const newCookie: WorkTimeCookie = {
        date: this.state.date,
        description: this.state.description,
        endTime: this.state.endTime,
        projectId: this.state.projectId,
        startTime: this.state.startTime,
        taskId: this.state.taskId,
        timerMode: this.state.timerMode,
        timerStart: this.state.timerStart !== null ? this.state.timerStart.toUTCString() : null,
        expanded: this.state.expanded,
      };
      new Cookies().set(workTimeCookieName, newCookie);
    }
  }

  getFilteredTasks(date: ParsableDate): Array<Task> {
    if (date === null) {
      return this.state.tasks;
    }
    const dateMoment = Moment(date);
    if (dateMoment.isValid()) {
      return this.props.tasks.filter(
        t =>
          t.taskStartDate &&
          t.taskEndDate &&
          Moment(t.taskStartDate).isValid() &&
          Moment(t.taskEndDate).isValid() &&
          Moment(t.taskStartDate) <= dateMoment.startOf('day') &&
          Moment(t.taskEndDate) >= dateMoment.startOf('day')
      );
    }
    return this.state.tasks;
  }

  componentWillUnmount() {
    if (this.props.renderMode === RenderMode.InlineEdit) {
      return;
    }
    clearInterval(this.timerId);
    document.removeEventListener('keydown', this.keydownHandler);
  }

  keydownHandler = (e: any) => {
    if (e.keyCode === 13 && e.ctrlKey) this.handleSave();
  };

  mergeValues(datePart: ParsableDate, timePart: Date | null): Date | null {
    if (!timePart || !datePart || datePart.toString() === 'Invalid Date' || !Moment(new Date(datePart.toString())).isValid()) {
      return null;
    }

    var date = new Date(datePart.toString());
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), timePart.getHours(), timePart.getMinutes(), 0);
  }

  getDurationFromProps(enteredStartTime: ParsableDate, enteredEndTime: ParsableDate, ignoreTimerMode: boolean = false): string | null {
    const defaultDuration = null;
    let endTime: Date | null = null;
    let startTime: Date | null = null;

    if (this.state.timerMode && !ignoreTimerMode) {
      if (this.state.timerStart != null) {
        endTime = new Date();
        startTime = this.state.timerStart;
      }
    } else {
      if (enteredEndTime == null) {
        return defaultDuration;
      }
      endTime = this.mergeValues(this.state.date, new Date(enteredEndTime as string));
      startTime = this.mergeValues(this.state.date, new Date(enteredStartTime as string));
    }

    return this.state.timerMode ? getDurationIncludingSeconds(startTime, endTime) : getDuration(startTime, endTime);
  }

  endTimeErrorText() {
    if (this.state.startTime && this.state.endTime && this.state.startTime.toString() !== 'Invalid Date') {
      const endDateTime = this.mergeValues(baseDate, new Date(this.state.endTime.toString()));
      const startDateTime = this.mergeValues(baseDate, new Date(this.state.startTime.toString()));
      if (endDateTime && startDateTime && endDateTime < startDateTime) {
        return this.props.t('endTimeShouldBeAfterStartTime');
      }
    }
    return null;
  }

  isEntryDurationValid(): boolean {
    return !this.state.timerMode || (!!this.state.timerStart && new Date().valueOf() - this.state.timerStart.valueOf() >= 60 * 1000);
  }

  durationHelperText() {
    if (this.state.validated && !this.isEntryDurationValid()) {
      return (
        <FormHelperText error className={this.props.classes.durationHelper}>
          {this.props.t('entryShouldBeAtLeastOneMinute')}
        </FormHelperText>
      );
    }
    return null;
  }

  isValid() {
    const invalidDate = 'Invalid Date';
    const startTimeValid = this.state.timerMode || (this.state.startTime && this.state.startTime.toString() !== invalidDate);
    const endTimeValid =
      this.state.timerMode || (this.state.endTime && this.state.endTime.toString() !== invalidDate && this.endTimeErrorText() == null);

    const validEntryDuration = this.isEntryDurationValid();

    return validEntryDuration && startTimeValid && endTimeValid && this.state.description && this.dateProjectAndTaskValid();
  }

  dateProjectAndTaskValid() {
    const invalidDate = 'Invalid Date';

    const dateValid =
      this.state.timerMode || (this.state.date && this.state.date.toString() !== invalidDate && this.getDateErrorMessage() === undefined);

    return dateValid && this.state.projectId && this.state.taskId;
  }

  getTime(date: Date): number {
    const enteredDate = new Date(this.state.date as string);
    enteredDate.setHours(date.getHours());
    enteredDate.setMinutes(date.getMinutes());
    enteredDate.setSeconds(0);
    enteredDate.setMilliseconds(0);
    return enteredDate.valueOf();
  }

  getTimerTime(date: Date): number {
    const momentDate: Moment.Moment = Moment(date)
      .add(1, 'minute')
      .set('seconds', 0)
      .set('milliseconds', 0);
    return momentDate.valueOf();
  }

  handleSave = () => {
    if (!this.isValid()) {
      const expanded = this.state.expanded || !this.dateProjectAndTaskValid();
      this.setState({ validated: true, expanded: expanded });
      return;
    }
    const workItem: WorkTimeModel = {
      description: this.state.description,
      taskId: Number(this.state.taskId),
      startTime:
        this.state.timerMode && this.state.timerStart
          ? this.getTimerTime(this.state.timerStart)
          : this.getTime(new Date(this.state.startTime as string)),
      endTime: this.state.timerMode ? this.getTimerTime(new Date()) : this.getTime(new Date(this.state.endTime as string)),
      workTimeId: this.props.initialModel ? this.props.initialModel.workTimeId : undefined,
      empId: this.props.initialModel ? this.props.initialModel.empId : undefined,
      projectId: this.state.projectId,
    };

    this.props
      .onSave(workItem, this.state.warningConfirmationNeeded ? ApiWarningMessage.overlapWorkingTime : null)
      .then(() => {
        if (this.props.renderMode !== RenderMode.InlineEdit) {
          const now = new Date();
          this.setState({ ...this.blankState, startTime: now, tasks: this.getFilteredTasks(now), startTimeSelected: true });
        }
      })
      .catch((error: { response: { data: ApiResult<any> } }) => {
        if (error.response.data.messages.find(x => x.message === ApiErrorMessage.overlappingWorkTimeSameCustomer)) {
          this.setState({
            taskId: workItem.taskId,
            projectId: workItem.projectId,
          });
          return;
        }
        if (
          error.response.data.messages.find(
            x => x.message === ApiErrorMessage.workTimeTaskPeriodConflict || x.message === ApiErrorMessage.workTimeForInactiveProject
          )
        ) {
          this.setState({ taskId: 0, projectId: 0 });
          this.openNotificationDialog('taskSelectionIsNoMoreValidTitle', 'taskSelectionIsNoMoreValid');
          if (this.props.onTasksReloadNeeded) {
            this.props.onTasksReloadNeeded();
          }
          return;
        }
        if (error.response.data.messages.find(x => x.message === ApiWarningMessage.overlapWorkingTime)) {
          this.setState({ taskId: workItem.taskId, projectId: workItem.projectId, warningConfirmationNeeded: true });
          return;
        }
        if (error.response.data.messages.find(x => x.message === ApiErrorMessage.accessDenied)) {
          this.setState({ taskId: 0, projectId: 0 });
          this.openNotificationDialog('accessDeniedTitle', 'accessDenied');
          return;
        }
        this.openNotificationDialog('unexpectedErrorMessageTitle', 'unexpectedErrorMessage');
      });
  };

  selectHelperText(value: number): ReactNode {
    if (this.state.validated && !value) {
      return <FormHelperText error>{this.props.t('fieldIsMandatory')}</FormHelperText>;
    }
    return null;
  }

  handleTimerStart = () => {
    this.setState({ duration: '00:00.00', timerMode: true, timerStart: new Date() });
  };

  renderInlineSaveButton(): ReactNode {
    return <SaraCheckCancelButtons onCheck={this.handleSave} onCancel={this.props.onCancel} />;
  }

  renderSaveButton(): ReactNode {
    if (this.props.renderMode === RenderMode.InlineEdit) {
      return;
    }
    let saveButton;
    if (this.state.timerMode && this.state.timerStart === null) {
      saveButton = (
        <Button variant="contained" color="primary" className={this.props.classes.saveButton} onClick={this.handleTimerStart} fullWidth>
          {this.props.t('start')}
        </Button>
      );
    } else {
      saveButton = (
        <Button
          variant="contained"
          color="primary"
          className={this.isValid() ? this.props.classes.saveButton : this.props.classes.saveButtonDisabled}
          onClick={this.handleSave}
          fullWidth
        >
          {this.props.t('add')}
        </Button>
      );
    }
    return (
      <Grid item xs={12} md={1}>
        {saveButton}
      </Grid>
    );
  }

  getDateRange(): { minDate: Moment.Moment; maxDate: Moment.Moment } {
    let minimumDay = Moment(new Date()).startOf('month');
    const maximumDay = Moment(new Date()).endOf('month');
    if (this.props.hasExtendedPermission) {
      minimumDay = Moment(new Date())
        .subtract(1, 'month')
        .startOf('month');
    }
    return {
      minDate: minimumDay,
      maxDate: maximumDay,
    };
  }

  getDateErrorMessage(): string | undefined {
    if (this.state.date && this.state.date.toString() !== 'Invalid Date') {
      let errorMessageKey: string | null = null;
      const dateValue: Moment.Moment = Moment(this.state.date);
      const dateRange = this.getDateRange();
      if (dateValue > dateRange.maxDate || dateValue < dateRange.minDate) {
        if (this.props.hasExtendedPermission) {
          errorMessageKey = 'thisAndPreviousMonthEntriesAllowed';
        } else {
          errorMessageKey = 'thisMonthEntriesAllowed';
        }
      }

      if (errorMessageKey !== null) {
        return this.props.t(errorMessageKey);
      } else {
        return undefined;
      }
    }
  }

  renderDateField() {
    if (!this.state.timerMode) {
      const dateRange = this.getDateRange();
      return (
        <SaraDatePicker
          label={this.props.t('date')}
          value={this.state.date}
          onHandleValidation={() => {
            const errorMessage = this.getDateErrorMessage();
            this.setState({ dateErrorMessage: errorMessage });
          }}
          onChange={date => {
            const filteredTasks = this.getFilteredTasks(date);
            let newTaskId = this.state.taskId;
            let newProjectId = this.state.projectId;
            let snackbarText;
            if (!filteredTasks.some(t => t.taskId === newTaskId)) {
              newTaskId = 0;
              newProjectId = 0;
              snackbarText = this.props.t('projectAndTaskDropdownsHaveBeenUpdated');
            }
            let convertedDate = date && Moment(convertBrowserTimeToApiFormat(date, this.props.timeZoneId)).toDate();
            this.setState({ date: convertedDate, tasks: filteredTasks, taskId: newTaskId, projectId: newProjectId, snackbarText });
          }}
          fullWidth
          required
          validated={this.state.validated}
          minDate={dateRange.minDate}
          maxDate={dateRange.maxDate}
          error={this.state.dateErrorMessage !== undefined}
          helperText={this.state.dateErrorMessage}
          selectOnFocus={true}
        />
      );
    }
    return <InputLabel style={{ marginTop: '19px' }}>{this.props.t('today')}</InputLabel>;
  }

  renderTimerSwitcher() {
    if (this.props.renderMode === RenderMode.InlineEdit) {
      return;
    }

    return (
      <div style={{ height: 48, display: 'flex', paddingLeft: 7 }}>
        <WorkTimeModeSwitcher
          className="iconWrapper"
          onSwitchToManual={() => {
            this.setState({
              duration: this.getDurationFromProps(this.state.startTime, this.state.endTime, true),
              validated: false,
              startTimeSelected: true,
              timerMode: false,
              timerStart: null,
            });
          }}
          onSwitchToTimer={() => {
            this.setState({ duration: null, validated: false, timerMode: true, timerStart: null });
          }}
          timerMode={this.state.timerMode}
          style={{ marginTop: '13px' }}
        />
        {this.props.collapsable && (
          <Tooltip title={this.props.t(this.state.expanded ? 'collapse' : 'expand')}>
            <span className={'iconWrapper'} style={{ marginTop: '13px' }}>
              {!this.state.expanded && (
                <ExpandMoreIcon className={this.props.classes.materialIcon} onClick={(event: any) => this.setState({ expanded: true })} />
              )}
              {this.state.expanded && (
                <ExpandLessIcon className={this.props.classes.materialIcon} onClick={(event: any) => this.setState({ expanded: false })} />
              )}
            </span>
          </Tooltip>
        )}
      </div>
    );
  }

  projectChangeHandler = (event: any) => {
    const projectId = Number.parseInt(event.target.value as string);

    const taskLookups: Array<Lookup> = [];
    this.state.tasks.forEach(x => {
      if (x.projectId === projectId) {
        taskLookups.push({ value: x.taskId, text: x.taskName });
      }
    });

    const taskId = taskLookups && taskLookups.length > 0 ? taskLookups[0].value : 0;
    this.setState({
      projectId,
      taskId,
    });
  };

  renderComponentOrLoader() {
    if (this.props.isLoading) {
      return (
        <div style={{ width: '100%', position: 'relative' }}>
          <CircularProgress color="primary" className="centeredProgress" />
        </div>
      );
    }

    const projectLookups: Array<Lookup> = [];
    this.state.tasks.forEach(x => {
      if (!projectLookups.some(l => l.value === x.projectId)) {
        projectLookups.push({
          value: x.projectId,
          text: x.projectName + (x.isProjectDuplicatedInList ? ' (' + x.projectSowCode + ')' : ''),
        });
      }
    });

    if (this.props.initialModel && this.props.initialModel.projectName) {
      ensureArrayContains(projectLookups, this.props.initialModel.projectId, this.props.initialModel.projectName);
    }

    let taskLookups: Array<Lookup> = [];
    this.state.tasks.forEach(x => {
      if (x.projectId === this.state.projectId) {
        taskLookups.push({ value: x.taskId, text: x.taskName });
      }
    });

    if (this.props.initialModel && this.props.initialModel.taskName) {
      ensureArrayContains(taskLookups, this.props.initialModel.taskId, this.props.initialModel.taskName);
    }

    const endTimeTimer = this.state.timerMode ? null : (
      <Grid item xs={6} md={2}>
        <TimePicker
          onChange={date =>
            this.setState(state => ({
              endTime: this.mergeValues(baseDate, date),
              duration: this.getDurationFromProps(state.startTime, date),
            }))
          }
          timerMode={this.state.timerMode}
          value={this.state.endTime}
          label={this.props.t('endTime')}
          errorText={this.endTimeErrorText()}
          validated={this.state.validated}
        />
      </Grid>
    );

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} libInstance={moment}>
        <Grid container spacing={1}>
          <Grid item xs={this.state.timerMode ? 12 : 6} md={this.state.timerMode ? 3 : 2}>
            <TimePicker
              onChange={date =>
                this.setState(state => ({
                  startTime: this.mergeValues(baseDate, date),
                  duration: this.getDurationFromProps(date, state.endTime),
                }))
              }
              timerMode={this.state.timerMode}
              value={this.state.startTime}
              label={
                !this.state.timerMode
                  ? this.props.t('startTime')
                  : this.state.timerStart == null
                  ? this.props.t('startTimer')
                  : Moment(this.state.timerStart).format('HH:mm')
              }
              validated={this.state.validated}
              select={this.state.startTimeSelected}
              onSelected={() => this.setState({ startTimeSelected: false })}
            />
          </Grid>
          {endTimeTimer}
          <Grid
            item
            xs={12}
            md={this.state.timerMode ? 2 : 1}
            className={this.props.classes.durationContainer}
            style={{ paddingTop: '23px' }}
          >
            <span>{this.state.duration}</span>
            {this.durationHelperText()}
          </Grid>
          <Grid item xs={12} md={this.state.expanded ? 7 : 6}>
            <TextField
              label={this.props.t('description')}
              fullWidth
              multiline
              rows={1}
              rowsMax={15}
              required
              value={this.state.description}
              inputProps={{ maxLength: 200 }}
              onChange={event =>
                this.setState({ description: event.target.value ? event.target.value.replace(/\t/g, '') : event.target.value })
              }
              error={this.state.validated && !this.state.description}
              helperText={this.state.validated && !this.state.description ? this.props.t('fieldIsMandatory') : null}
            />
          </Grid>
          {!this.state.expanded && this.renderSaveButton()}
          {this.state.expanded && (
            <Grid item xs={12} md={2}>
              {this.renderDateField()}
            </Grid>
          )}
          {this.state.expanded && (
            <Grid item xs={12} md={3}>
              <ProjectsSelector
                value={this.state.projectId}
                onChange={this.projectChangeHandler}
                validated={this.state.validated}
                lookups={projectLookups}
              />
            </Grid>
          )}
          {this.state.expanded && (
            <Grid item xs={12} md={this.props.renderMode === RenderMode.InlineEdit ? 7 : 6}>
              <SaraSelect
                validated={this.state.validated}
                label={this.props.t('task')}
                id="task-id"
                value={this.state.taskId}
                onChange={event => this.setState({ taskId: event.target.value as number })}
                lookups={taskLookups}
                required
              />
            </Grid>
          )}
          {this.state.expanded && this.renderSaveButton()}
        </Grid>
        <InfoSnackbar
          open={this.state.snackbarText !== undefined}
          onClose={() => this.setState({ snackbarText: undefined })}
          text={this.state.snackbarText ? this.state.snackbarText : ''}
        />
        <OverlapEntryWarning
          open={this.state.warningConfirmationNeeded}
          onClose={() => this.setState({ warningConfirmationNeeded: false })}
          onSubmit={this.handleSave}
        />
        <NotificationDialog
          open={this.state.isOpenNotification}
          title={this.state.notificationTitle}
          content={this.state.notificationContent}
          onClose={this.closeNotificationDialog}
        />
      </MuiPickersUtilsProvider>
    );
  }
  openNotificationDialog = (notificationTitle: string, notificationContent: string): void => {
    this.setState({ isOpenNotification: true, notificationTitle, notificationContent });
  };

  closeNotificationDialog = (): void => {
    this.setState({ isOpenNotification: false, notificationTitle: '', notificationContent: '' });
  };

  render() {
    if (this.props.renderMode === RenderMode.InlineEdit) {
      return (
        <Fragment>
          <TableCell colSpan={7} className="MuiTableCell-root MuiTableCell-alignLeft MuiTableCell-body">
            {this.renderComponentOrLoader()}
          </TableCell>
          <TableCell padding="none" key="key-actions-column" style={{ width: 84, padding: '0px 5px' }}>
            <div style={{ display: 'flex' }}>{this.renderInlineSaveButton()}</div>
          </TableCell>
        </Fragment>
      );
    }
    return (
      <Paper className={`${workTimeStyles.paper} ${this.props.classes.workTimeContainer}`}>
        {this.renderComponentOrLoader()}
        {this.renderTimerSwitcher()}
      </Paper>
    );
  }
}

const mapStateToProps = (state: SelectUserState) => {
  return {
    timeZoneId: getTimezone(state),
  };
};

export const InsertWorkTimeContainer = connect(mapStateToProps)(withTranslation()(withStyles(styles)(InsertWorkTimeContainerComponent)));
