import AppGroup from '../../models/AppGroup';
import AppUser from '../../models/AppUser';
import Customer from '../../models/Customer';
import Employee from '../../models/Employee';
import { FormViewAccess } from '../../models/FormViewAccess';
import Project from '../../models/Project';
import { AppGroupActionType } from '../actions/appGroupActionType';

export interface AppGroupState {
  appGroup: AppGroup;
  customers: Array<Customer>;
  appUsers: Array<AppUser>;
  permissions: Record<string, { formViewControlId?: number; accessId: FormViewAccess }>;
  projects: Array<Project>;
  staff: Array<Employee>;
  oldState: Partial<AppGroupState>;
}

const initialState: AppGroupState = {
  appGroup: {
    appGroupId: 0,
    appGroupName: '',
    appGroupDescr: '',
  },
  customers: [],
  appUsers: [],
  permissions: {},
  projects: [],
  staff: [],
  oldState: {},
};

export default (state = initialState, action: any): AppGroupState => {
  switch (action.type) {
    case AppGroupActionType.ENTER_APP_GROUP_NAME:
      return {
        ...state,
        appGroup: {
          ...state.appGroup,
          appGroupName: action.payload.appGroupName,
        },
      };
    case AppGroupActionType.ENTER_APP_GROUP_DESCR:
      return {
        ...state,
        appGroup: {
          ...state.appGroup,
          appGroupDescr: action.payload.appGroupDescr,
        },
      };
    case AppGroupActionType.ADD_APP_USER:
      return {
        ...state,
        appUsers: [...state.appUsers, action.payload.appUser],
      };
    case AppGroupActionType.REMOVE_APP_USER:
      return {
        ...state,
        appUsers: state.appUsers.filter(user => user.appUserId !== action.payload.appUser.appUserId),
      };
    case AppGroupActionType.ADD_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, action.payload.customer],
      };
    case AppGroupActionType.REMOVE_CUSTOMER:
      return {
        ...state,
        customers: state.customers.filter(cust => cust.customerId !== action.payload.customer.customerId),
      };
    case AppGroupActionType.ADD_PROJECT:
      return {
        ...state,
        projects: [...state.projects, action.payload.project],
      };
    case AppGroupActionType.REMOVE_PROJECT:
      return {
        ...state,
        projects: state.projects.filter(proj => proj.prjId !== action.payload.project.prjId),
      };
    case AppGroupActionType.ADD_TO_STAFF:
      return {
        ...state,
        staff: [...state.staff, action.payload.employee],
      };
    case AppGroupActionType.REMOVE_FROM_STAFF:
      return {
        ...state,
        staff: state.staff.filter(empl => empl.empId !== action.payload.employee.empId),
      };
    case AppGroupActionType.STORE_APP_GROUP:
      return {
        ...state,
        appGroup: {
          ...action.payload.appGroup,
        },
      };
    case AppGroupActionType.CLEAR_APP_GROUP:
      return {
        ...state,
        appGroup: initialState.appGroup
      };
    case AppGroupActionType.CHANGE_GROUP_PERMISSION:
      const permissions = { ...state.permissions };
      action.payload.accessLevel === 0
        ? delete permissions[action.payload.formViewName]
        : (permissions[action.payload.formViewName] = {
            ...permissions[action.payload.formViewName],
            accessId: action.payload.accessLevel,
            formViewControlId: action.payload.formViewControlId,
          });
      return {
        ...state,
        permissions: {
          ...permissions,
        },
      };
    case AppGroupActionType.ADD_TO_OLD_STATE:
      return {
        ...state,
        oldState: {
          ...state.oldState,
          ...action.payload.oldState,
        },
      };
    case AppGroupActionType:
      return {
        ...state,
        oldState: {},
      };
    case AppGroupActionType.CLEAR_GROUP_STATE:
      return initialState;
    default:
      return state;
  }
};
