import React, { Fragment } from 'react';
import { createStyles, Theme, Tooltip } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import MaterialIcon from '../icons/IconsStyle';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';

const styles = (theme: Theme) =>
  createStyles({
    materialIcon: MaterialIcon,
    saveIcon: { ...MaterialIcon, color: '#29BB9C' },
    saveButton: {
      '&:hover': {
        '& *': {
          color: '#FFF',
        },
        color: '#FFF',
        backgroundColor: '#29BB9C',
      },
    },
  });

interface SaraCheckCancelButtonsProps extends WithStyles<typeof styles> {
  onCheck?: () => void;
  onCancel?: () => void;
}

const SaraCheckCancelButtons: React.FC<SaraCheckCancelButtonsProps> = (props: SaraCheckCancelButtonsProps) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Tooltip title={t('cancel')}>
        <span>
          <IconButton size="medium" color="inherit" onClick={props.onCancel}>
            <CloseIcon className={props.classes.materialIcon} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t('save')}>
        <span>
          <IconButton className={props.classes.saveButton} size="medium" color="inherit" onClick={props.onCheck}>
            <DoneIcon color="inherit" className={props.classes.saveIcon} />
          </IconButton>
        </span>
      </Tooltip>
    </Fragment>
  );
};

export default withStyles(styles)(SaraCheckCancelButtons);
