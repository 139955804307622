import React from 'react';
import { Column } from 'material-table';
import WorkItem from '../../../../models/WorkItem';
import { totalSencondsSinceMidnight } from '../../../../utils/dateTime';
import { UseTranslationResponse } from 'react-i18next';
import moment from 'moment-timezone';

const startColumn = (translation: UseTranslationResponse, timeZone: string): Column<WorkItem> => {
  return {
    title: translation.t('start'),
    field: 'wtStartTime',
    type: 'date',
    render: (rowData: WorkItem) => {
      return (
        <span>
          {moment(rowData.wtStartTime)
            .tz(timeZone)
            .format('HH:mm')}
        </span>
      );
    },
    customSort: (first: WorkItem, second: WorkItem) => {
      return totalSencondsSinceMidnight(first.wtStartTime) - totalSencondsSinceMidnight(second.wtStartTime);
    },
  };
};

export default startColumn;
