import { Column } from "material-table";
import Task from "../../../../../../models/Task";
import { UseTranslationResponse } from "react-i18next";
import NameColumn from "./Name";
import StartDateColumn from "./StartDate";
import EndDateColumn from "./EndDate";
import AssigneeColumn from "./Assignee";    
import Employee from "../../../../../../models/Employee";

const columns = (translation: UseTranslationResponse, employees: Array<Employee>): Column<Task>[] => {
    return [
        NameColumn(translation),
        StartDateColumn(translation),
        EndDateColumn(translation),
        AssigneeColumn(translation, employees)
    ];
}

export default columns;