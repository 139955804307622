import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import WorkItem from '../../../../models/WorkItem';
import { compare } from '../../../../utils/string';

const DescriptionColumn = (translation: UseTranslationResponse): Column<WorkItem> => ({
  title: translation.t('comment'),
  field: 'wtComment',
  customSort: (first: WorkItem, second: WorkItem) => compare(first.wtComment, second.wtComment),
  cellStyle: { minWidth: 100, width: '20%' },
});

export default DescriptionColumn;
