import { CustomersState } from '../reducers/customersReducer';

export type SelectCustomersState = { customers: CustomersState };

export const getCustomer = ({ customers }: SelectCustomersState) => customers.customer;

export const getCustomerAppGroups = ({ customers }: SelectCustomersState) => customers.customerAppGroups;

export const getIsDirty = ({ customers }: SelectCustomersState) => customers.isDirty;

export const getIsValid = ({ customers }: SelectCustomersState) => customers.isValid;

export const getOldCustomersState = ({ customers }: SelectCustomersState) => customers.oldCustomersState
