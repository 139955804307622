import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Paper,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { compare } from '../../../utils/string';
import SaraTable from '../../common/SaraTable';
import OvertimeReport from '../../../models/OvertimeReport';

const styles = (theme: Theme) =>
  createStyles({
    reportsList: {
      marginTop: 23,
    },
    summaryHeader: {
      opacity: 0.87,
      '& .header': {
        width: '100%',
      },
      '& .summaryTitle': {
        width: '100%',
      },
      '& .overtimeNumber': {
        margin: '0px 10px',
        fontWeight: 700,
        textAlign: 'right',
        minWidth: 65,
        '&.greenText': {
          color: theme.palette.primary.main,
        },
        '&.redText': {
          color: theme.palette.error.main,
        },
      },
    },
    employeeRow: {
      opacity: 0.87,
      '& .empName': {
        width: '100%',
        fontWeight: 'bold',
        paddingLeft: 40,
      },
      '& .date': {
        width: '100%',
        fontWeight: 'bold',
      },
    },
    employeeDetailsRow: {
      '& .empHours': {
        minWidth: 120,
        textAlign: 'right',
        paddingRight: 70,
      },
    },
    projectName: {
      width: '100%',
      paddingLeft: 64,
    },
    taskName: {
      width: '100%',
      paddingLeft: 72,
    },
  });

interface SummaryOvertimeListProps extends WithStyles<typeof styles> {
  reports: Array<OvertimeReport>;
  showSummary: boolean;
}

const SummaryOvertimeList: React.FC<SummaryOvertimeListProps> = (props: SummaryOvertimeListProps) => {
  const { t } = useTranslation();
  const displayItems: Array<OvertimeReport> = [...props.reports.sort((first, second) => compare(first.empFullName, second.empFullName))];

  const summaryItems: any[] = Object.values(
    displayItems
      .map(report => ({
        key: `${Moment(report.startTime).format('DD/MM/YYYY')}_${Moment(report.endTime).format('DD/MM/YYYY')}`,
        report,
        startTime: Moment(report.startTime).format('DD/MM/YYYY'),
        endTime: Moment(report.endTime).format('DD/MM/YYYY'),
      }))
      .reduce((map: any, item: { key: string; startTime: string; endTime: string; report: OvertimeReport }) => {
        if (!map[item.key]) {
          map[item.key] = { startTime: item.startTime, endTime: item.endTime, reports: [] };
        }
        map[item.key].reports.push(item.report);
        return map;
      }, {})
  );

  const overtimeValue: number = props.reports.reduce((acc, total) => acc + total.overtime, 0);

  return (
    <Paper className={props.classes.reportsList}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={props.classes.summaryHeader}>
          <Typography className="header">{t('summary')}</Typography>
          <Typography>{t('overtime')}</Typography>
          <Typography className={`overtimeNumber ${overtimeValue >= 0 ? 'greenText' : 'redText'}`}>
            {overtimeValue > 0 ? '+' : ''}
            {overtimeValue.toFixed(2)}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <SaraTable<any>
            isLoading={false}
            items={summaryItems}
            columns={[
              { field: 'empFullName', cellStyle: { width: '100%' } },
              {
                field: 'totalHoursWorked',
                render: rowData => <span>{rowData.overtime.toFixed(2)}</span>,
                type: 'time',
                cellStyle: { minWidth: 0 },
              },
            ]}
            components={{
              Row: rowProps => (
                <Fragment>
                  <TableRow className={props.classes.employeeRow}>
                    <TableCell className="date">{`${t('from')} ${rowProps.data.startTime} ${t('to')}  ${rowProps.data.endTime}`}</TableCell>
                    <TableCell className="empHours"></TableCell>
                  </TableRow>
                  {rowProps.data.reports.map((report: any) => (
                    <Fragment key={report.empFullName}>
                      <TableRow className={props.classes.employeeRow}>
                        <TableCell className="empName">{report.empFullName}</TableCell>
                        <TableCell className="empHours"></TableCell>
                      </TableRow>
                      <Fragment>
                        <TableRow className={props.classes.employeeDetailsRow}>
                          <TableCell className={props.classes.projectName}>{t('workedTime')}</TableCell>
                          <TableCell className="empHours">{report.workedTimeTotal.toFixed(2)}</TableCell>
                        </TableRow>
                      </Fragment>
                      <Fragment>
                        <TableRow className={props.classes.employeeDetailsRow}>
                          <TableCell className={props.classes.projectName}>{t('monthlyBase')}</TableCell>
                          <TableCell className="empHours">{report.monthlyBase.toFixed(2)}</TableCell>
                        </TableRow>
                      </Fragment>
                      <Fragment>
                        <TableRow className={props.classes.employeeDetailsRow}>
                          <TableCell className={props.classes.projectName}>{t('overtime')}</TableCell>
                          <TableCell className="empHours">{report.overtime.toFixed(2)}</TableCell>
                        </TableRow>
                      </Fragment>
                      <Fragment>
                        <TableRow className={props.classes.employeeDetailsRow}>
                          <TableCell className={props.classes.projectName}>{t('doubleBilled')}</TableCell>
                          <TableCell className="empHours">{report.doubleBilled.toFixed(2)}</TableCell>
                        </TableRow>
                      </Fragment>
                    </Fragment>
                  ))}
                </Fragment>
              ),
            }}
            hideHeader
            isEditable={false}
            actionsCellStyle={{ position: 'inherit' }}
            maxHeight="auto"
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Paper>
  );
};

export default withStyles(styles)(SummaryOvertimeList);
