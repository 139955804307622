import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import WorkItem from '../../../../models/WorkItem';
import { formatDate, totalSencondsSinceMidnight } from '../../../../utils/dateTime';

const StartTimeColumn = (translation: UseTranslationResponse): Column<WorkItem> => ({
  title: translation.t('from'),
  field: 'wtStartTime',
  type: 'date',
  render: (rowData: WorkItem) => formatDate(rowData.wtStartTime, 'HH:mm'),
  customSort: (first: WorkItem, second: WorkItem) =>
    totalSencondsSinceMidnight(first.wtStartTime) - totalSencondsSinceMidnight(second.wtStartTime),
  cellStyle: { minWidth: 75 },
});

export default StartTimeColumn;
