import { FormViewControl } from '../models/FormViewControl';
import genericApi from './genericApi';
import { UpdateFormViewControl } from '../models/UpdateFormViewControl';

const baseUrl: string = 'form_view_controls';

const formViewControlsReqHandler = {
  loadFormViewControls: (): Promise<Array<FormViewControl>> => {
    return genericApi.get(`${baseUrl}?sort=appGroupId`);
  },
  loadFormViewControl: (id: number): Promise<FormViewControl> => {
    return genericApi.get(`${baseUrl}/${id}`);
  },
  createFormViewControl: (payload: UpdateFormViewControl): Promise<FormViewControl> => {
    return genericApi.post(baseUrl, payload);
  },
  updateFormViewControl: (id: number, payload: UpdateFormViewControl): Promise<FormViewControl> => {
    return genericApi.put(`${baseUrl}/${id}`, payload);
  },
  deleteFormViewControl: (id: number): Promise<FormViewControl> => {
    return genericApi.delete(`${baseUrl}/${id}`);
  },
};

export default formViewControlsReqHandler;
