import { Box, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Paper, Theme, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import WorkItem from '../../../models/WorkItem';
import SaraTable from '../../common/SaraTable';
import Columns from './columns';

const styles = (theme: Theme) =>
  createStyles({
    reportsList: {
      marginTop: 23,
    },
    employeeName: {
      width: '100%',
      opacity: 0.87,
    },
    totalHoursBox: {
      alignSelf: 'center',
      opacity: 0.87,
      textAlign: 'right'
    },
  });

interface ReportsListProps extends WithStyles<typeof styles> {
  employee: string;
  reports: Array<WorkItem>;
  isLoading: boolean;
  totalHoursWorked?: string;
  defaultExpanded?: boolean;
}

const ReportsList: React.FC<ReportsListProps> = (props: ReportsListProps) => (
  <Paper className={props.classes.reportsList}>
    <ExpansionPanel defaultExpanded={props.defaultExpanded}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} style={{ display: 'flex' }}>
        {props.employee && (
          <Fragment>
            <Typography className={props.classes.employeeName}>{props.employee}</Typography>
            <Box fontWeight="fontWeightBold" className={props.classes.totalHoursBox}>
              {props.totalHoursWorked}
            </Box>
          </Fragment>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ padding: 0 }}>
        <SaraTable<WorkItem>
          isLoading={props.isLoading}
          items={props.reports}
          columns={Columns(useTranslation())}
          sorting
          isEditable={false}
          actionsCellStyle={{ position: 'inherit' }}
          maxHeight='auto'
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </Paper>
);

export default withStyles(styles)(ReportsList);
