import React from 'react';
import { Grid, Typography, Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SaraTransferLists from '../../../common/SaraTransferLists';
import AppGroup from '../../../../models/AppGroup';
import { DialogMode } from '../../../../models/DialogMode';

const styles = (theme: Theme) =>
  createStyles({
    inaccList: {
      height: '22em',
      paddingLeft: 20,
    },
    accList: {
      height: '22em',
      marginLeft: -16,
    },
  });

interface AppGroupAccessProps extends WithStyles<typeof styles> {
  isLoading: boolean;
  assGroups: Array<AppGroup>;
  unassGroups: Array<AppGroup>;
  dialogMode: DialogMode;
  moveAppGroup: (id: number, toAuthorized: boolean) => void;
  readonly: boolean;
}

const AppGroupAccess: React.FC<AppGroupAccessProps> = (props: AppGroupAccessProps) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>{props.readonly ? t('readOnlyAssignAppGroupProjectAccess') : t('assignAppGroupDataAccess')}</Typography>
      </Grid>

      <Grid item container spacing={1}>
        <SaraTransferLists
          showSearchBar={props.dialogMode !== DialogMode.VIEW}
          leftItems={props.unassGroups.map((item: AppGroup) => {
            return { key: item.appGroupId, value: item, text: item.appGroupName };
          })}
          leftListStyle={props.classes.inaccList}
          rightItems={props.assGroups.map((item: AppGroup) => {
            return { key: item.appGroupId, value: item, text: item.appGroupName };
          })}
          rightListStyle={props.classes.accList}
          handleMove={(id, toAuthorized) => props.moveAppGroup(id, toAuthorized)}
          readonly={props.readonly}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AppGroupAccess);
