import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";

const returnedData: ApiResult<any> = {
    "messages": [],
    "result": [
        {
            "empId": 3,
            "officeId": 1,
            "empFirstName": "Victor",
            "empLastName": "Victorov",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": "1981-03-05",
            "empEmail": "victor.victorov@itcis.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": "2019-06-01",
            "endProbDate": "2019-07-01",
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 4.0,
            "appUserId": 6,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 4,
            "officeId": 1,
            "empFirstName": "Georgi",
            "empLastName": "Georgiev",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "georgi.georgiev@itcis.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": "2016-09-01",
            "endProbDate": "2016-10-01",
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 7,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 42,
            "officeId": 1,
            "empFirstName": "Pavel",
            "empLastName": "Pavlov",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "pavel.pavlov@itcis.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": "2019-01-20",
            "endProbDate": null,
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 4.0,
            "appUserId": 42,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 82,
            "officeId": 1,
            "empFirstName": "Kiril",
            "empLastName": "Kirilov",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "kiril.kirilov@itcis.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": null,
            "endProbDate": null,
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 84,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 83,
            "officeId": 1,
            "empFirstName": "Metodi",
            "empLastName": "Metodiev",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "metodi.metodie@itcis.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": "2019-03-12",
            "endProbDate": null,
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 85,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 181,
            "officeId": 1,
            "empFirstName": "Simeon",
            "empLastName": "Simeonov",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "simeon.simeonov@itcis.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": null,
            "endProbDate": null,
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 202,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 183,
            "officeId": 1,
            "empFirstName": "Todor",
            "empLastName": "Todorov",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "todor.todorov@itcis.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": null,
            "endProbDate": null,
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 204,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 19,
            "officeId": 1,
            "empFirstName": "Ivan",
            "empLastName": "Ivanov",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "ivan.ivanov@sciant.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": null,
            "endProbDate": null,
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 84,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 20,
            "officeId": 1,
            "empFirstName": "Test",
            "empLastName": "Test",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "test_mail@testmail.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": null,
            "endProbDate": null,
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 4.0,
            "appUserId": null,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 5,
            "officeId": 1,
            "empFirstName": "Test",
            "empLastName": "Testy",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "test_mail@testmail.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": null,
            "endProbDate": null,
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 4.0,
            "appUserId": null,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 2,
            "officeId": 1,
            "empFirstName": "Boris",
            "empLastName": "Borisov",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "boris.borisov@itcis.com",
            "empPhone": null,
            "empMobile": " 359 889 669611",
            "empHomeAddress": null,
            "startDate": "2019-03-28",
            "endProbDate": "2019-04-28",
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 3,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 27,
            "officeId": 1,
            "empFirstName": "Rosen",
            "empLastName": "Dochev",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "rosen.dochev@sciant.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": "2019-11-27",
            "endProbDate": "2019-11-27",
            "endDate": "2019-11-27",
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 34,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 28,
            "officeId": 1,
            "empFirstName": "Nelly",
            "empLastName": "Mincheva",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "nelly.mincheva@sciant.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": null,
            "endProbDate": null,
            "endDate": null,
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 35,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 26,
            "officeId": 1,
            "empFirstName": "Gergana",
            "empLastName": "Grudeva",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "gergana.grudeva@sciant.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": "2019-11-27",
            "endProbDate": "2019-11-27",
            "endDate": "2019-11-27",
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 33,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 23,
            "officeId": 1,
            "empFirstName": "Eleonora",
            "empLastName": "Yaneva",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "eleonora.yaneva@sciant.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": "2019-11-27",
            "endProbDate": "2019-11-27",
            "endDate": "2019-11-27",
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 30,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 25,
            "officeId": 1,
            "empFirstName": "David",
            "empLastName": "Gregor",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "david.gregor@sciant.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": "2019-11-27",
            "endProbDate": "2019-11-27",
            "endDate": "2019-11-27",
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 32,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 24,
            "officeId": 1,
            "empFirstName": "Darja",
            "empLastName": "Gogunova",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "darja.gogunova@sciant.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": "2019-11-27",
            "endProbDate": "2019-11-27",
            "endDate": "2019-11-27",
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 31,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 1,
            "officeId": 1,
            "empFirstName": "Angel_dev",
            "empLastName": "Angelov",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "angel.angelov@itcis.com",
            "empPhone": "11111",
            "empMobile": "22222",
            "empHomeAddress": "asdasdasd",
            "startDate": "2020-01-01",
            "endProbDate": "2020-01-04",
            "endDate": "2020-01-24",
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 9.0,
            "appUserId": 2,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        },
        {
            "empId": 22,
            "officeId": 1,
            "empFirstName": "Nelly",
            "empLastName": "Mincheva",
            "empFirstNameNl": null,
            "empLastNameNl": null,
            "empBirthDate": null,
            "empEmail": "nelly.mincheva@sciant.com",
            "empPhone": null,
            "empMobile": null,
            "empHomeAddress": null,
            "startDate": "2019-11-27",
            "endProbDate": "2019-11-27",
            "endDate": "2019-11-27",
            "isActive": 1,
            "officeName": "ITCIS",
            "dayWorkHours": 8.0,
            "appUserId": 29,
            "employeeIds": null,
            "canDelete": false,
            "groupIdOversee": null,
            "groupNameOversee": null,
            "groupIds": null,
            "groupNames": null
        }
    ]
}

export const EmployeesGet: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /employees/,
    method: 'GET'
}

export const EmployeesPut: FakeRequest = {
    data: {messages: [], result: null},
    httpCode: 200,
    url: /employees/,
    method: 'PUT'
}