import { combineReducers } from 'redux';
import appGroupsReducer from './appGroupsReducer';
import customersReducer from './customersReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import taskDraftReducer from './taskDraftReducer';

export const rootReducer = combineReducers({
  appGroups: appGroupsReducer,
  customers: customersReducer,
  auth: authReducer,
  user: userReducer,
  taskDraft: taskDraftReducer
});

export type RootState = ReturnType<typeof rootReducer>;
