import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApiResult } from '../../../../api/apiResult';
import FormViewsReqHandler from '../../../../api/formViewsRemote';
import formViewControlsReqHandler from '../../../../api/formViewControlsRemote';
import { DialogMode } from '../../../../models/DialogMode';
import { FormView } from '../../../../models/FormView';
import { AppGroupActionType } from '../../../../store/actions/appGroupActionType';
import { changeGroupPermissions, addToOldState } from '../../../../store/actions/appGroupActions';
import { getAppGroup, getAppGroupPermissions, getOldStatePermissions } from '../../../../store/selectors/appGroupSelectors';
import FormViews from './FormViews';
import { FormViewAccess } from '../../../../models/FormViewAccess';
import AppGroup from '../../../../models/AppGroup';
import { FormViewControl, PermissionControl } from '../../../../models/FormViewControl';
import { AppGroupState } from '../../../../store/reducers/appGroupsReducer';
import { compare } from '../../../../utils/string';

interface FormViewsContainerProps {
  dialogMode: DialogMode;
  appGroup: AppGroup;
  permissions: Record<string, PermissionControl>;
  oldPermissions?: Record<string, PermissionControl>;
  onError: (error: { response: { data: ApiResult<any> } }) => void;
  changeGroupPermissions: (
    formViewName: string,
    accessLevel: FormViewAccess,
    formViewControlId?: number,
  ) => { type: AppGroupActionType; payload: { formViewName: string; accessLevel: FormViewAccess } };
  addToOldState: (oldState: Partial<AppGroupState>) => { type: AppGroupActionType; payload: { oldState: Partial<AppGroupState> } };
}

interface FormViewsContainerState {
  formViews: Array<FormView>;
  isLoading: boolean;
}

class FormViewsContainer extends Component<FormViewsContainerProps, FormViewsContainerState> {
  state: FormViewsContainerState = {
    formViews: [],
    isLoading: false,
  };

  componentDidMount() {
    const formViewRequests: Array<Promise<any>> = [FormViewsReqHandler.loadFormViews()];
    if (this.props.dialogMode !== DialogMode.CREATE) {
      formViewRequests.push(formViewControlsReqHandler.loadFormViewControls());
    }
    this.setState({ isLoading: true }, () =>
      Promise.all(formViewRequests)
        .then(([formViews, formViewControls]) => {
          if (formViewControls) {
            formViewControls.forEach((formView: FormViewControl) => {
              if (formView.appGroupId === this.props.appGroup.appGroupId) {
                this.props.changeGroupPermissions(formView.formViewCd, formView.accessId, formView.formViewControlId);
              }
            });
            this.props.addToOldState({ permissions: this.props.permissions });
          }
          this.setState({ formViews: this.sortFormViews(formViews), isLoading: false });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          this.props.onError(error);
        })
    );
  }

  private sortFormViews(formViews: Array<FormView>): Array<FormView> {
    return formViews.sort((first, second) => compare(first.formViewCd, second.formViewCd));
  }

  onPermissionChange = (event: React.ChangeEvent<HTMLInputElement>, formViewName: string) => {
    if (this.props.dialogMode === DialogMode.VIEW) {
      return;
    }
    const accessLevel = Number(event.target.value);
    this.props.changeGroupPermissions(formViewName, accessLevel);
  };

  render() {
    return (
      <FormViews
        formViews={this.state.formViews}
        permissionsMap={this.props.permissions}
        isLoading={this.state.isLoading}
        handleChange={this.onPermissionChange}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  appGroup: getAppGroup(state),
  permissions: getAppGroupPermissions(state),
  oldPermissions: getOldStatePermissions(state),
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      changeGroupPermissions,
      addToOldState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormViewsContainer);
