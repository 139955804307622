import {
  Box, Dialog,


  DialogActions, DialogContent,




  DialogTitle,


  Divider, Grid,



  IconButton, TextField, Theme,




  Tooltip
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FlagOutlinedIcon from '@material-ui/icons/FlagOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import Schedule from '@material-ui/icons/Schedule';
import Today from '@material-ui/icons/Today';
import { createStyles, WithStyles, withStyles } from '@material-ui/styles';
import Moment from 'moment';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import CalendarDateRange from '../../../models/CalendarDateRange';
import CalendarDayType from '../../../models/CalendarDayType';
import CalendarEvent from '../../../models/CalendarEvent';
import Employee from '../../../models/Employee';
import Permissions from '../../../models/Permissions';
import { StatusValues } from '../../../models/StatusValues';
import User from '../../../models/User';
import SaraDatePicker from '../../common/SaraDatePicker';
import SaraDialogButtons from '../../common/SaraDialogButtons';
import RequestStatusIndicator from '../../requests/requestList/RequestStatusIndicator';
import { SaraSelect } from '../../saraSelect/SaraSelect';
import calendarEventDialogStyles from './calendarEventDialog.module.css';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      padding: '24px',
      height: '60%',
    },
    iconFieldContainer: {
      display: 'flex',
      '& > svg': {
        height: '100%',
        paddingTop: 10,
        marginRight: 15,
      },
      '& > .formTextField': {
        width: '100%',
      },
    },
    statusContainer: {
      display: 'flex',
      fontSize: '15px',
    },
    marginRight: {
      marginRight: 20,
    },
  });

interface CustomerDialogProps extends WithStyles<typeof styles> {
  isOpen: boolean;
  onClose: (event: any) => void;
  onSave: (event: any) => void;
  newEvent: CalendarEvent;
  handleChange: (event: any, field: string) => void;
  dayTypes: Array<CalendarDayType>;
  employees: Array<Employee>;
  primaryDisabled: Function;
  isEditing: boolean;
  permissions: Permissions;
  user: User;
  handleDelete: (event: any) => void;
  differentYear: Function;
  readOnly: boolean;
  openEditMode: (event: any) => void;
  isAdministratorGroup: boolean;
  calendarDateRanges: Array<CalendarDateRange>;
  todayDate: Moment.Moment;
}

const CustomerDialog: React.FC<CustomerDialogProps> = (props: CustomerDialogProps) => {
  const { t } = useTranslation();
  const editingDisabled: boolean =
    Boolean(props.newEvent.statusId === StatusValues.FINALIZED) ||
    (!props.permissions.extendedWrite && props.newEvent.statusId !== StatusValues.PENDING) ||
    (!props.permissions.extendedWrite && props.user.empId !== props.newEvent.empId);
  const isEndDateInvalid: boolean =
    props.newEvent.rqStartDate && props.newEvent.rqEndDate && props.newEvent.rqStartDate > props.newEvent.rqEndDate ? true : false;
  const titleText: string = props.readOnly ? t('viewRequest') : props.isEditing ? t('editRequest') : t('addNewRequest');
  const businessTrip: CalendarDayType | undefined = props.dayTypes.find(item => item.dayTypeName === 'businessTrip');
  const homeOffice: CalendarDayType | undefined = props.dayTypes.find(item => item.dayTypeName === 'homeOffice');
  const sickLeave: CalendarDayType | undefined = props.dayTypes.find(item => item.dayTypeName === 'sick');
  const paidVacation: CalendarDayType | undefined = props.dayTypes.find(item => item.dayTypeName === 'paidVacation');
  const nonPaidVacation: CalendarDayType | undefined = props.dayTypes.find(item => item.dayTypeName === 'nonPaidVacation');
  const canApprove: boolean =
    props.permissions.canApprove !== undefined &&
    ((props.permissions.canApprove.approveBTWrite && businessTrip !== undefined && props.newEvent.eventTypeId === businessTrip.dayTypeId) ||
      (props.permissions.canApprove.approveHOWrite && homeOffice !== undefined && props.newEvent.eventTypeId === homeOffice.dayTypeId) ||
      (props.permissions.canApprove.approveSICKWrite && sickLeave !== undefined && props.newEvent.eventTypeId === sickLeave.dayTypeId) ||
      (props.permissions.canApprove.approveVACWrite &&
        ((paidVacation !== undefined && props.newEvent.eventTypeId === paidVacation.dayTypeId) ||
          (nonPaidVacation !== undefined && props.newEvent.eventTypeId === nonPaidVacation.dayTypeId))));
  const approvingAllowed: boolean = props.newEvent.statusId === StatusValues.PENDING && canApprove;
  const finalizingAllowed: boolean = props.newEvent.statusId === StatusValues.APPROVED && canApprove;
  const getStatusText: Function = (): string => {
    switch (props.newEvent.statusId) {
      case 0:
        return t('pending');
      case 1:
        return t('approved');
      case 2:
        return t('rejected');
      case 3:
        return t('finalized');
    }
    return '';
  };

  const disabled = (type: string): boolean => {
    if (!props.readOnly && props.isAdministratorGroup) {
      return false;
    }
    if (!props.readOnly && (type === 'startDate' || type === 'eventType') && props.newEvent.rqStartDate) {
      return Moment(props.newEvent.rqStartDate, 'YYYY-MM-DD').isBefore(props.todayDate);
    }
    if (!props.readOnly && type === 'endDate' && props.newEvent.rqEndDate) {
      return Moment(props.newEvent.rqEndDate, 'YYYY-MM-DD').isBefore(props.todayDate);
    }
    return props.readOnly;
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      fullWidth={true}
      aria-labelledby="dialog-title"
      classes={{ paper: props.classes.dialog }}
    >
      <DialogTitle id="dialog-title">
        <div className={calendarEventDialogStyles.titleWrapper}>
          <Tooltip title={titleText}>
            <div className={calendarEventDialogStyles.titleText}>{titleText}</div>
          </Tooltip>
          <Box display="flex">
            {props.isEditing && props.readOnly && (
              <Fragment>
                {approvingAllowed && (
                  <Fragment>
                    <Tooltip title={t('approve')}>
                      <IconButton aria-label="approve" onClick={() => props.onSave('approve')}>
                        <CheckCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('reject')}>
                      <IconButton aria-label="reject" onClick={() => props.onSave('reject')}>
                        <HighlightOffOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Fragment>
                )}
                {finalizingAllowed && (
                  <Tooltip title={t('finalize')}>
                    <IconButton aria-label="finalize" onClick={() => props.onSave('finalize')}>
                      <FlagOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {!editingDisabled && (
                  <Fragment>
                    <Tooltip title={t('edit')}>
                      <IconButton aria-label="edit" onClick={props.openEditMode}>
                        <CreateIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('delete')}>
                      <span>
                        <IconButton
                          aria-label="delete"
                          disabled={!props.isAdministratorGroup && props.newEvent.rqStartDate && Moment(props.newEvent.rqStartDate, 'YYYY-MM-DD').isBefore(props.todayDate)}
                          onClick={props.handleDelete}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Fragment>
                )}
                {(approvingAllowed || finalizingAllowed || !editingDisabled) && (
                  <Box ml={1} mr={1}>
                    <Divider orientation="vertical" />
                  </Box>
                )}
              </Fragment>
            )}
            <Tooltip title={t('close')}>
              <IconButton aria-label="close" onClick={props.onClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </div>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} className={props.classes.statusContainer}>
            <div className={calendarEventDialogStyles.statusIndicator}>
              <RequestStatusIndicator status={props.newEvent.statusId} />
            </div>
            <div className={calendarEventDialogStyles.statusLabel}>{t('status')}:</div>
            <div>{getStatusText()}</div>
          </Grid>
          <Grid item xs={12} className={props.classes.iconFieldContainer}>
            <AccountCircle color="secondary" />
            <div className="formTextField">
              <SaraSelect
                validated={false}
                label={t('employee')}
                id="employee-id"
                value={props.newEvent.empId || 0}
                onChange={event => props.handleChange(event, 'empId')}
                lookups={props.employees.map((employee: Employee) => ({
                  value: employee.empId,
                  text: `${employee.empFirstName} ${employee.empLastName}`,
                }))}
                required
                readonly={!props.permissions.extendedWrite || props.readOnly || props.isEditing}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={props.classes.iconFieldContainer}>
            <Schedule color="secondary" />
            <Grid item sm={6} md={6} className={props.classes.marginRight}>
              <SaraDatePicker
                label={t('startDate')}
                required
                minDate={props.calendarDateRanges[0].minDate}
                maxDate={props.calendarDateRanges[0].maxDate}
                value={props.newEvent.rqStartDate ? props.newEvent.rqStartDate : undefined}
                onChange={event => props.handleChange(event, 'startDate')}
                validated={!props.newEvent.rqStartDate}
                fullWidth
                disabled={disabled('startDate')}
              />
            </Grid>
            <Grid item sm={6} md={6}>
              <SaraDatePicker
                label={t('endDate')}
                required
                minDate={props.calendarDateRanges[1].minDate}
                maxDate={props.calendarDateRanges[1].maxDate}
                value={props.newEvent.rqEndDate ? props.newEvent.rqEndDate : undefined}
                onChange={event => props.handleChange(event, 'endDate')}
                validated={!props.newEvent.rqEndDate}
                fullWidth
                disabled={disabled('endDate')}
                error={isEndDateInvalid || props.differentYear()}
                helperText={
                  isEndDateInvalid ? t('endDateShouldBeAfterStartDate') : props.differentYear() ? t('differentYearValidation') : ''
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={props.classes.iconFieldContainer}>
            <Today color="secondary" />
            <div className="formTextField">
              <SaraSelect
                validated={false}
                label={t('eventType')}
                id="event-type-id"
                value={props.newEvent.eventTypeId || 0}
                onChange={event => props.handleChange(event, 'typeId')}
                lookups={props.dayTypes
                  .filter((dayType: CalendarDayType) => dayType.dayTypeCategory !== 'IS_INTERNAL')
                  .map((dayType: CalendarDayType) => ({
                    text: t(dayType.dayTypeName),
                    value: dayType.dayTypeId,
                  }))}
                required
                readonly={disabled('eventType')}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={props.classes.iconFieldContainer}>
            <InsertDriveFile color="secondary" />
            <TextField
              label={t('additionalComment')}
              fullWidth
              rows={1}
              rowsMax={5}
              multiline
              value={props.newEvent.notes}
              onChange={event => props.handleChange(event, 'comment')}
              disabled={props.readOnly}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {!props.readOnly && (
        <DialogActions>
          <SaraDialogButtons
            secondaryText={t('cancel')}
            onSecondary={props.onClose}
            primaryText={props.isEditing ? t('save') : t('add')}
            onPrimary={props.onSave}
            primaryDisabled={props.primaryDisabled()}
          />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(CustomerDialog);
