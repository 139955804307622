import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";

const returnedData: ApiResult<any> = {
    messages: [],
    result: null
}

export const HeartbeatPost: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /heartbeat/,
    method: 'POST'
}