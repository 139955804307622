export enum AppGroupActionType {
  ADD_APP_USER = 'ADD_APP_USER',
  ADD_CUSTOMER = 'ADD_CUSTOMER',
  ADD_PROJECT = 'ADD_PROJECT',
  ADD_TO_OLD_STATE = 'ADD_TO_OLD_STATE',
  ADD_TO_STAFF = 'ADD_STAFF',
  CHANGE_GROUP_PERMISSION = 'CHANGE_GROUP_PERMISSION',
  CLEAR_APP_GROUP = 'CLEAR_APP_GROUP',
  CLEAR_GROUP_STATE = 'CLEAR_GROUP_STATE',
  CLEAR_OLD_STATE = 'CLEAR_OLD_STATE',
  ENTER_APP_GROUP_DESCR = 'ENTER_APP_GROUP_DESCR',
  ENTER_APP_GROUP_NAME = 'ENTER_APP_GROUP_NAME',
  REMOVE_APP_USER = 'REMOVE_APP_USER',
  REMOVE_CUSTOMER = 'REMOVE_CUSTOMER',
  REMOVE_FROM_STAFF = 'REMOVE_STAFF',
  REMOVE_PROJECT = 'REMOVE_PROJECT',
  STORE_APP_GROUP = 'STORE_APP_GROUP',
};
