import React, {Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import ChangePasswordModal from './ChangePasswordModal';
import appUsersRemote from '../../api/appUsersRemote';
import User from '../../models/User';
import { ApiErrorMessage } from '../../api/apiMessage';
import { ApiResult } from '../../api/apiResult';
import ChangePassword from '../../models/ChangePassword';

interface ChangePasswordContainerProps extends WithTranslation {
  open: boolean;
  onClose: () => void;
  passwordChanged: () => void;
  user: User;
  hiddenDialog: boolean;
}

interface ChangePasswordContainerState {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  showOldPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
  oldPasswordValidationMessage: string;
  newPasswordValidationMessage: string;
  confirmPasswordValidationMessage: string;
}

const INITIAL_STATE: ChangePasswordContainerState = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
  showOldPassword: false,
  showNewPassword: false,
  showConfirmPassword: false,
  oldPasswordValidationMessage: '',
  newPasswordValidationMessage: '',
  confirmPasswordValidationMessage: ''
}

class ChangePasswordContainer extends Component<ChangePasswordContainerProps, ChangePasswordContainerState> {
  state = INITIAL_STATE;

  onSubmit = (event: any): void => {
    if (this.validation()) {
      const data: ChangePassword= {
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword
      };
      appUsersRemote.changeAppUserPassword(this.props.user.username, data).then((resp: number) => {
        this.props.passwordChanged();
        this.setState(INITIAL_STATE);
      }).catch(this.handleError)}
  }

  handleError = (error: { response: { data: ApiResult<any> } }): void => {

    if (error.response.data.messages.find(x => x.message === ApiErrorMessage.changePasswordAuthenticationFailed)) {
      this.setState({oldPasswordValidationMessage: 'oldPasswordIncorrect' });

    } else if(error.response.data.messages.find(x=> x.message === ApiErrorMessage.changePasswordValidationFailed)) {
      this.setState({ newPasswordValidationMessage: 'newPasswordIsWrongFormat'});
    }
  };

  handleChange = (event: any, field: string): void => {
    const value: string = event.target.value;
    switch(field) {
      case 'oldPassword' : this.setState({oldPassword: value}); break;
      case 'newPassword': this.setState({ newPassword: value}); break;
      case 'confirmPassword' : this.setState({ confirmPassword: value }); break;
      default: break;
    }
  }
  handleClickShowPassword = (field: string): void => {
    switch(field) {
      case 'oldPassword' : this.setState({showOldPassword: !this.state.showOldPassword }); break;
      case 'newPassword': this.setState({ showNewPassword: !this.state.showNewPassword }); break;
      case 'confirmPassword' : this.setState({ showConfirmPassword: !this.state.showConfirmPassword }); break;
      default: break;
    }
  }

  validation = (): boolean => {
    return this.oldPasswordValidation() && this.newPasswordValidation() && this.confirmPasswordValidation();
  }

  oldPasswordValidation = (): boolean => {
    if(!this.state.oldPassword) {
      this.setState({oldPasswordValidationMessage: 'fieldIsMandatory' });
      return false;
    } else {
      this.setState({oldPasswordValidationMessage: '' });
      return true;
    }
   
  }

  newPasswordValidation = (): boolean => {
    if(!this.state.newPassword) {
      this.setState({newPasswordValidationMessage: 'fieldIsMandatory' });
      return false;
    } 
    if (this.state.newPassword && this.state.newPassword === this.state.oldPassword) {
      this.setState({newPasswordValidationMessage: 'newPasswordShouldBeDifferent'});
      return false;
    }
    this.setState({newPasswordValidationMessage: ''});
    return true;
  }

  confirmPasswordValidation = (): boolean => {
    if(!this.state.confirmPassword) {
      this.setState({confirmPasswordValidationMessage: 'fieldIsMandatory' });
      return false;
    }

    if (this.state.confirmPassword && this.state.newPassword !== this.state.confirmPassword) {
      this.setState({confirmPasswordValidationMessage: 'newPasswordAndConfirmPasswordNotMatch'});
      return false;
    }
    this.setState({confirmPasswordValidationMessage: ''});
    return true;
  }
  
  render () {
    return (
      <ChangePasswordModal 
        open={ this.props.open }
        onClose={ this.props.onClose }
        onSubmit={ this.onSubmit }
        oldPassword={ this.state.oldPassword }
        newPassword={ this.state.newPassword }
        confirmPassword={ this.state.confirmPassword }
        handleChange={ this.handleChange }
        showOldPassword={ this.state.showOldPassword }
        showNewPassword={ this.state.showNewPassword }
        showConfirmPassword={ this.state.showConfirmPassword }
        handleClickShowPassword={ this.handleClickShowPassword }
        oldPasswordValidationMessage={ this.state.oldPasswordValidationMessage }
        newPasswordValidationMessage={ this.state.newPasswordValidationMessage }
        confirmPasswordValidationMessage={ this.state.confirmPasswordValidationMessage }
        hiddenDialog={ this.props.hiddenDialog}
      />
    )
  };
};

export default withTranslation()(ChangePasswordContainer);