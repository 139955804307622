import { Column } from "material-table";
import { UseTranslationResponse } from "react-i18next";
import Project from "../../../../models/Project";
import SOWCodeColumn from "./SOWCode";
import ProjectNameColumn from "./ProjectName";
import CustomerNameColumn from "./CustomerName";
import StartDateColumn from "./StartDate";
import EndDateColumn from "./EndDate";
import StatusIndicatorColumn from "./StatusIndicator";

const columns = (translation: UseTranslationResponse): Column<Project>[] => {
    return [
        StatusIndicatorColumn(translation),
        SOWCodeColumn(translation),
        ProjectNameColumn(translation),
        CustomerNameColumn(translation),
        StartDateColumn(translation),
        EndDateColumn(translation)
    ];
}

export default columns;