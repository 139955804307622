import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Paper,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { compare } from '../../../utils/string';
import SaraTable from '../../common/SaraTable';
import EmployeeSummary from '../../../models/EmployeeSummary';
import TaskSummary from '../../../models/TaskSummary';
import SummaryProject from '../../../models/SummaryProject';

const styles = () =>
  createStyles({
    reportsList: {
      marginTop: 23,
    },
    summaryHeader: {
      opacity: 0.87,
      '& .summaryTitle': {
        width: '100%',
      },
      '& .totalHoursBox': {
        alignSelf: 'center',
        textAlign: 'right',
      },
    },
    employeeRow: {
      opacity: 0.87,
      '& .empName': {
        width: '100%',
        fontWeight: 'bold',
      },
      '& .empTotalHours': {
        fontWeight: 'bold',
        textAlign: 'right',
        paddingRight: 60,
      },
    },
    employeeDetailsRow: {
      '& .empHours': {
        minWidth: 120,
        textAlign: 'right',
        paddingRight: 59,
      },
    },
    projectName: {
      width: '100%',
      paddingLeft: 36,
    },
    taskName: {
      width: '100%',
      paddingLeft: 72,
    },
  });

interface SummaryListProps extends WithStyles<typeof styles> {
  employeeSummaries: Array<EmployeeSummary>;
  showSummary: boolean;
  showTask: boolean;
}

const SummaryList: React.FC<SummaryListProps> = (props: SummaryListProps) => {
  const { t } = useTranslation();
  const displayItems: Array<EmployeeSummary> = [
    ...props.employeeSummaries.sort((first, second) => compare(first.employee, second.employee)),
  ];
  displayItems.push({
    employee: t('total'),
    totalHoursWorked: props.employeeSummaries.reduce((acc: number, item: EmployeeSummary) => acc + item.totalHoursWorked, 0),
    projects: [],
  });

  return (
    <Paper className={props.classes.reportsList}>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={props.classes.summaryHeader}>
          <Typography className="summaryTitle">{t('summary')}</Typography>
          <Box fontWeight="fontWeightBold" className="totalHoursBox">
            {props.employeeSummaries.reduce((acc, total) => acc + total.totalHoursWorked, 0).toFixed(1)}
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ padding: 0 }}>
          <SaraTable<EmployeeSummary>
            isLoading={false}
            items={displayItems}
            columns={[
              { field: 'employee', cellStyle: { width: '100%' } },
              {
                field: 'totalHoursWorked',
                render: rowData => <span>{rowData.totalHoursWorked.toFixed(2)}</span>,
                type: 'time',
                cellStyle: { minWidth: 120 },
              },
            ]}
            components={{
              Row: rowProps => (
                <Fragment>
                  <TableRow className={props.classes.employeeRow}>
                    <TableCell className="empName">{rowProps.data.employee}</TableCell>
                    <TableCell className="empTotalHours">
                      {rowProps.data.totalHoursWorked.toFixed(rowProps.data.employee === 'Total' ? 1 : 2)}
                    </TableCell>
                  </TableRow>
                  {props.showSummary &&
                    rowProps.data.projects.map((project: SummaryProject) => (
                      <Fragment key={`k-${project.id}`}>
                        <TableRow key={project.id} className={props.classes.employeeDetailsRow}>
                          <TableCell className={props.classes.projectName}>{project.name}</TableCell>
                          <TableCell className="empHours">{project.totalHoursWorked.toFixed(2)}</TableCell>
                        </TableRow>
                        {props.showTask &&
                          !!project.task &&
                          project.task.map(
                            (t: TaskSummary) =>
                              t.empId === rowProps.data.empId && (
                                <TableRow key={t.taskId} className={props.classes.employeeDetailsRow}>
                                  <TableCell className={props.classes.taskName}>{t.taskName}</TableCell>
                                  <TableCell className="empHours">{t.totalHoursWorked.toFixed(2)}</TableCell>
                                </TableRow>
                              )
                          )}
                      </Fragment>
                    ))}
                </Fragment>
              ),
            }}
            hideHeader
            isEditable={false}
            actionsCellStyle={{ position: 'inherit' }}
            maxHeight="auto"
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Paper>
  );
};

export default withStyles(styles)(SummaryList);
