import axios from 'axios';
import { baseUrl } from '../api/genericApi';
import User from '../models/User';
import store from '../store';
import { loginFail, loginSuccess } from '../store/actions/authActions';
import { saveUser, saveUserNames, goOffline, setTimezone } from '../store/actions/userActions';

function handleLogin(): void {
  axios({
    method: 'get',
    url: baseUrl + 'who_am_i',
    withCredentials: true,
  })
    .then(res => {
      const user: User | undefined = {
        username: res.data.result.username,
        empId: res.data.result.empId,
        appUserId: res.data.result.appUserId,
        officeId: res.data.result.officeId
      };

      if (user) {
        localStorage.setItem('login', 'true');
        store.dispatch(saveUser(user));
        store.dispatch(loginSuccess());
        store.dispatch(saveUserNames(res.data.result.empFirstName, res.data.result.empLastName));
        store.dispatch(setTimezone(res.data.result.timeZoneId));
      }
    })
    .catch(error => {
      if (!error.response) {
        store.dispatch(goOffline());
        return;
      }
      localStorage.removeItem('login');
      store.dispatch(loginFail());
    });
}

export { handleLogin };
