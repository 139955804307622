import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LdapUser from '../../../models/LdapUser';
import SaraDialogButtons from '../../common/SaraDialogButtons';

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      padding: '24px 48px',
      height: '50%',
      width: '40%',
    },
    titleWrapper: {
      padding: 0,
      '& > h2': {
        padding: 0,
        fontWeight: 500,
      },
    },
    listContent: {
      padding: '8px 0',
    },
    dialogButtons: {
      padding: 0,
      marginTop: 24,
      '& > :first-child': {
        padding: 0,
      },
    },
  });

interface PendingUsersDialogProps extends WithStyles<typeof styles> {
  isDialogOpen: boolean;
  pendingUsers: Array<LdapUser>;
  selectedUser: LdapUser | null;
  onClose: () => void;
  onUserSelect: (selectedUid: string) => void;
  onUserCreate: () => void;
}

const PendingUsersDialog: React.FC<PendingUsersDialogProps> = (props: PendingUsersDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={props.isDialogOpen}
      aria-labelledby="dialog-title"
      maxWidth="md"
      fullWidth={false}
      classes={{ paper: props.classes.dialog }}
    >
      <DialogTitle classes={{ root: props.classes.titleWrapper }} id="dialog-title">
        {t('addPendingUsers')}
      </DialogTitle>
      <Typography variant="subtitle1" color="textSecondary">
        {t('pendingUsersDialogSubtitle')}
      </Typography>
      <DialogContent classes={{ root: props.classes.listContent }}>
        <List>
          <RadioGroup
            aria-label="pending-users"
            value={props.selectedUser ? props.selectedUser.uid : ''}
            onChange={event => props.onUserSelect(event.target.value)}
            name="pending-users"
          >
            {props.pendingUsers.map(user => (
              <FormControlLabel
                key={user.uid}
                value={user.uid}
                control={<Radio disableRipple disableFocusRipple disableTouchRipple size="small" color="primary" />}
                label={`${user.firstName} ${user.lastName}`}
              ></FormControlLabel>
            ))}
          </RadioGroup>
        </List>
      </DialogContent>
      <DialogActions className={props.classes.dialogButtons}>
        <SaraDialogButtons
          secondaryText={t('cancel')}
          onSecondary={props.onClose}
          primaryText={t('create')}
          onPrimary={props.onUserCreate}
          primaryDisabled={!props.selectedUser}
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(PendingUsersDialog);
