import React from 'react';
import { TableRow } from '@material-ui/core';
import CalendarEvent from '../../../models/CalendarEvent';
import UpdateRequestContainer from './UpdateRequestContainer';
import { Lookup } from '../../saraSelect/SaraMultiSelect';

interface EditRequestRowProps {
  requestItem: CalendarEvent;
  onSave: (newValue: CalendarEvent) => Promise<void>;
  onCancel: () => void;
  eventTypeLookups: Array<Lookup>;
  isAdministratorGroup: boolean;
}

const EditWorkTimeRow: React.FC<EditRequestRowProps> = (props: EditRequestRowProps) => (
  <TableRow className="MuiTableRow-root inlineEditRow">
    <UpdateRequestContainer
      requestItem={props.requestItem}
      onSave={props.onSave}
      onCancel={props.onCancel}
      eventTypeLookups={props.eventTypeLookups}
      isAdministratorGroup={props.isAdministratorGroup}
    />
  </TableRow>
);

export default EditWorkTimeRow;
