import { createStyles, withStyles, WithStyles } from '@material-ui/core';
import moment from 'moment';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ApiErrorMessage } from '../../api/apiMessage';
import { ApiResult } from '../../api/apiResult';
import appUserGroupsRemote from '../../api/appUserGroupsRemote';
import calendarReqHandler from '../../api/calendarRemote';
import employeesReqHandler from '../../api/employeesRemote';
import OfficesReqHandler from '../../api/officesRemote';
import CalendarDayType from '../../models/CalendarDayType';
import CalendarEvent from '../../models/CalendarEvent';
import Employee from '../../models/Employee';
import { EventType } from '../../models/EventType';
import Office from '../../models/Office';
import Permissions from '../../models/Permissions';
import { StatusValues } from '../../models/StatusValues';
import User from '../../models/User';
import { getTimezone, getUser, SelectUserState } from '../../store/selectors/userSelectors';
import { approved } from '../../utils/approved';
import { getTitle } from '../../utils/calendar';
import { CALENDAR_EVENT_ITEM } from '../../utils/calendarEventItem';
import { convertBrowserTimeToApiFormat, resetHours } from '../../utils/dateTime';
import { statusLookups } from '../../utils/statusLookup';
import NotificationDialog from '../notification/NotificationDialog';
import { Lookup } from '../saraSelect/SaraMultiSelect';
import AnnualPaidVacs from './annualPaidVac/AnnualPaidVac';
import RequestFilters from './requestFilter/RequestFilter';
import RequestList from './requestList/RequestList';

const styles = () =>
  createStyles({
    wrapper: {
      marginTop: 24,
    },
  });

interface RequestsContainerProps extends WithStyles<typeof styles>, WithTranslation {
  user: User;
  permissions: Permissions;
  timeZoneId: string;
}

interface RequestsContainerState {
  employeeLookups: Array<Lookup>;
  selectedEmployeeIds: Array<number>;
  selectedStatusIds: Array<number>;
  selectedYear: string;
  selectedEventTypeIds: Array<number>;
  eventTypeLookups: Array<Lookup>;
  selectedOfficeIds: Array<number>;
  officeLookups: Array<Lookup>;
  statusLookups: Array<Lookup>;
  requestItems: Array<CalendarEvent>;
  dayType: Array<any>;
  isOpenNotification: boolean;
  notificationTitle: string;
  notificationContent: string;
  snackbarText: string;
  remaining: number;
  initial: number;
  isPendingToApproval: boolean;
  isPeremptionToApproval: boolean;
  isViewOnlyPendingApproval: boolean;
  openConfirmationDialog: boolean;
  rowData: CalendarEvent;
  isAdministratorGroup: boolean;
}

class RequestsContainer extends Component<RequestsContainerProps, RequestsContainerState> {
  state: RequestsContainerState = {
    employeeLookups: [],
    selectedEmployeeIds: [],
    selectedStatusIds: [],
    selectedYear: new Date().getFullYear().toString(),
    selectedEventTypeIds: [],
    eventTypeLookups: [],
    selectedOfficeIds: [],
    officeLookups: [],
    statusLookups: [],
    requestItems: [],
    dayType: [],
    isOpenNotification: false,
    notificationTitle: '',
    notificationContent: '',
    snackbarText: '',
    remaining: 0,
    initial: 0,
    isPendingToApproval: false,
    isPeremptionToApproval: false,
    isViewOnlyPendingApproval: false,
    openConfirmationDialog: false,
    rowData: CALENDAR_EVENT_ITEM,
    isAdministratorGroup: false,
  };

  componentDidMount() {
    this.loadPeremptionToApproval();
    this.loadEmployees();
    this.loadOffice();
    this.loadDayType();
    this.loadStatus();
    this.loadEmpPaidVacs();
    this.loadIsAppUserGroupAdmin();
  }

  loadIsAppUserGroupAdmin = (): void => {
    // TODO: At a time this was created, no easy way from BE to retrieve `appGroupId`.
    appUserGroupsRemote
      .getIsAppUserGroupAdmin(this.props.user.appUserId, 1)
      .then(resp => this.setState({ isAdministratorGroup: resp.status && resp.status === 200 }));
  };

  loadEmpPaidVacs = (): void => {
    const empId: number = this.props.user.empId ? this.props.user.empId : 0;
    const year: string = moment(this.state.selectedYear)
      .year()
      .toString();
    const vacsRequest = [employeesReqHandler.getEmpRemainingVacs(empId, year), employeesReqHandler.getEmpAnnualPaidVacs(empId, year)];

    Promise.all(vacsRequest).then(([remainingReq, initialReq]) => {
      const remaining: any = remainingReq.length > 0 ? remainingReq[0].remainingDays : 0;
      const initial = initialReq.length > 0 ? initialReq[0].vacDaysNumber : 0;
      this.setState({ remaining, initial });
    });
  };

  loadEmployees = (): void => {
    employeesReqHandler.loadEmployees().then((resp: Array<Employee>) => {
      const employeeLookups = resp.map(
        (employee: Employee): Lookup => {
          return {
            text: `${employee.empFirstName} ${employee.empLastName}`,
            value: employee.empId,
          };
        }
      );
      let selectedEmployeeIds: any = [this.props.user.empId];

      this.setState({ employeeLookups, selectedEmployeeIds }, () => {
        this.loadEvents();
      });
    });
  };

  loadOffice = (): void => {
    OfficesReqHandler.loadOffices().then((resp: Array<Office>) => {
      if (resp.length) {
        const officeLookups = resp.map(
          (office: Office): Lookup => {
            return {
              text: office.officeName,
              value: office.officeId,
            };
          }
        );
        this.setState({ officeLookups });
      }
    });
  };

  loadDayType = (): void => {
    calendarReqHandler.loadDayTypes().then((resp: Array<CalendarDayType>) => {
      const eventTypeLookups = resp
        .filter((item: CalendarDayType) => !item.dayTypeCategory)
        .map(
          (type: CalendarDayType): Lookup => ({
            text: this.props.t(type.dayTypeName),
            value: type.dayTypeId,
          })
        );
      this.setState({ eventTypeLookups, dayType: resp });
    });
  };

  loadStatus = (): void => {
    const statusLookupMap = statusLookups.map((item: Lookup) => {
      return {
        text: this.props.t(item.text),
        value: item.value,
      };
    });
    this.setState({ statusLookups: statusLookupMap });
  };

  loadEvents = (): void => {
    const filterApproval: boolean = this.state.isViewOnlyPendingApproval;
    let startDate;
    let endDate;
    if (this.state.selectedYear) {
      const date = moment(this.state.selectedYear).year();
      startDate = moment({ y: date }).startOf('year');
      endDate = moment({ y: date }).endOf('year');
    }
    calendarReqHandler
      .loadEventsMultipleFilter(
        startDate,
        endDate,
        this.state.selectedOfficeIds,
        this.state.selectedEventTypeIds,
        this.state.selectedEmployeeIds,
        this.state.selectedStatusIds,
        'statusId'
      )
      .then((resp: Array<CalendarEvent>) => {
        let requestItems = [...resp];
        for (let item of requestItems) {
          let eventTypeName: string = getTitle(this.state.dayType, item.eventTypeId);
          item.eventTypeName = eventTypeName;
        }
        const filterApprovalItems = [];
        if (filterApproval) {
          for (let item of requestItems) {
            const { canWrite } = approved(this.props.permissions.canApprove, item.eventTypeId);
            if (canWrite) {
              filterApprovalItems.push(item);
            }
          }
        }
        if (filterApprovalItems.length) {
          this.setState({ requestItems: filterApprovalItems });
        } else {
          this.setState({ requestItems });
        }
      });
  };

  loadPeremptionToApproval = (): void => {
    const canApprove: any = this.props.permissions.canApprove;
    let isPeremptionToApproval = false;
    for (let k in canApprove) {
      if (k.toLowerCase().indexOf('write'.toLowerCase()) !== -1) {
        if (canApprove[k]) {
          isPeremptionToApproval = true;
          break;
        }
      }
    }
    this.setState({ isPeremptionToApproval }, () => this.checkPendingApproval());
  };

  handleChange = (event: any, field: string): void => {
    switch (field) {
      case 'selectedEmployeeIds':
        this.setState({
          selectedEmployeeIds: event.target.value as Array<number>,
        });
        break;
      case 'selectedStatusIds':
        this.setState({
          selectedStatusIds: event.target.value as Array<number>,
        });
        break;
      case 'selectedYear':
        this.setState(
          {
            selectedYear: event,
          },
          () => this.loadEmpPaidVacs()
        );
        break;
      case 'selectedEventTypeIds':
        this.setState({
          selectedEventTypeIds: event.target.value as Array<number>,
        });
        break;
      case 'selectedOfficeIds':
        this.setState({
          selectedOfficeIds: event.target.value as Array<number>,
        });
        break;
      default:
        break;
    }
  };

  filter = (): void => {
    this.setState({ isViewOnlyPendingApproval: false }, () => this.loadEvents());
  };

  handleDelete = (requestItem: CalendarEvent): Promise<void> =>
    calendarReqHandler
      .deleteCalendarEvent(requestItem.empCalEventReqId || 0)
      .then(() => {
        this.loadEvents();
        if (requestItem.eventTypeId === EventType.PAID_VACATION) {
          this.loadEmpPaidVacs();
        }
      })
      .catch(this.handleError);

  handleUpdate = (requestItem: CalendarEvent, shouldUpdateVacation: boolean) =>
    calendarReqHandler
      .editCalendarEvent(requestItem)
      .then(() => {
        this.loadEvents();
        if (shouldUpdateVacation) {
          this.loadEmpPaidVacs();
        }
      })
      .catch(this.handleError);

  isEdit = (): boolean => {
    if ((!this.props.permissions.extendedRead && this.props.permissions.canWrite) || this.props.permissions.extendedWrite) {
      return true;
    }
    return false;
  };

  handleError = (error: { response: { data: ApiResult<any> } }) => {
    let errorMessage = 'unexpectedErrorMessage';
    let errorMessageTitle = 'unexpectedErrorMessageTitle';
    if (error.response.data.messages.find(x => x.message === ApiErrorMessage.overlappingEmpCalEventReqSameEventType)) {
      errorMessageTitle = 'overlappingEmpCalEventReqSameEventTypeTitle';
      errorMessage = 'overlappingEmpCalEventReqSameEventType';
    }
    this.setState({ isOpenNotification: true, notificationTitle: errorMessageTitle, notificationContent: errorMessage });
  };

  closeNotificationDialog = (): void => {
    this.setState({ isOpenNotification: false, notificationTitle: '', notificationContent: '' });
  };

  approved = (rowData: CalendarEvent): void => {
    let body = { ...rowData };
    body.statusId = 1;
    calendarReqHandler
      .editCalendarEvent(body)
      .then(() => {
        this.loadEvents();
        this.checkPendingApproval();
      })
      .catch(this.handleError);
  };

  reject = (rowData: CalendarEvent) => {
    let body = { ...rowData };
    body.statusId = 2;
    calendarReqHandler
      .editCalendarEvent(body)
      .then(() => {
        this.loadEvents();
        this.checkPendingApproval();
      })
      .catch(this.handleError);
  };

  finalizeRequest = (): void => {
    const body: CalendarEvent = { ...this.state.rowData, statusId: StatusValues.FINALIZED };
    calendarReqHandler
      .editCalendarEvent(body)
      .then(() => {
        this.loadEvents();
        this.checkPendingApproval();
        this.closeConfirmation();
      })
      .catch(this.handleError);
  };

  previewPendingApproval = () => {
    this.setState(
      { selectedEmployeeIds: [], selectedStatusIds: [0], selectedEventTypeIds: [], selectedOfficeIds: [], isViewOnlyPendingApproval: true },
      () => {
        this.loadEvents();
      }
    );
  };

  checkPendingApproval = (): void => {
    if (this.state.isPeremptionToApproval) {
      calendarReqHandler.loadEventsMultipleFilter().then((resp: Array<CalendarEvent>) => {
        const pendingToApproval = resp.filter(item => item.statusId === 0);
        for (let item of pendingToApproval) {
          const { canWrite } = approved(this.props.permissions.canApprove, item.eventTypeId);
          if (canWrite) {
            this.setState({ isPendingToApproval: true });
            break;
          }
        }
      });
    }
  };

  sortFilter = (array: Array<Lookup>) => array.sort((first, second) => first.text.localeCompare(second.text));

  openConfirmation = (rowData: CalendarEvent) => {
    this.setState({ openConfirmationDialog: true, rowData });
  };

  closeConfirmation = () => {
    this.setState({ openConfirmationDialog: false, rowData: CALENDAR_EVENT_ITEM });
  };

  render() {
    return (
      <div className="pageWrapper">
        <AnnualPaidVacs year={this.state.selectedYear} remaining={this.state.remaining} initial={this.state.initial} />
        <div className={this.props.classes.wrapper}>
          <RequestFilters
            employeeLookups={this.sortFilter(this.state.employeeLookups)}
            selectedEmployeeIds={this.state.selectedEmployeeIds}
            statusLookups={this.sortFilter(this.state.statusLookups)}
            selectedStatusIds={this.state.selectedStatusIds}
            onChange={this.handleChange}
            filter={this.filter}
            selectedYear={this.state.selectedYear}
            selectedEventTypeIds={this.state.selectedEventTypeIds}
            eventTypeLookups={this.sortFilter(this.state.eventTypeLookups)}
            selectedOfficeIds={this.state.selectedOfficeIds}
            officeLookups={this.state.officeLookups}
            extendedRead={this.props.permissions.extendedRead}
            isPendingToApproval={this.state.isPendingToApproval}
            previewPendingApproval={this.previewPendingApproval}
            isPeremptionToApproval={this.state.isPeremptionToApproval}
          />
        </div>

        <div className={this.props.classes.wrapper}>
          <RequestList
            requestItems={this.state.requestItems}
            onDelete={this.handleDelete}
            onUpdate={this.handleUpdate}
            eventTypeLookups={this.state.eventTypeLookups}
            permissions={this.props.permissions}
            isEdit={this.isEdit()}
            approved={this.approved}
            reject={this.reject}
            finalized={this.openConfirmation}
            todayDate={moment(convertBrowserTimeToApiFormat(resetHours(new Date()), this.props.timeZoneId))}
            isAdministratorGroup={this.state.isAdministratorGroup}
          />
        </div>

        <NotificationDialog
          open={this.state.isOpenNotification}
          title={this.state.notificationTitle}
          content={this.state.notificationContent}
          onClose={this.closeNotificationDialog}
        />
        <NotificationDialog
          open={this.state.openConfirmationDialog}
          title={'confirmationTitleFinalized'}
          content={'confirmationTextFinalized'}
          onClose={this.closeConfirmation}
          role="confirmation"
          onSubmit={this.finalizeRequest}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: SelectUserState) => ({
  user: getUser(state),
  timeZoneId: getTimezone(state),
});

export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(RequestsContainer)));
