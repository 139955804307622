import { createMuiTheme } from '@material-ui/core/styles';

const SaraTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#29BB9C",
      light: '#29BB9C',
      dark: '#29BB9C',
      contrastText: '#fff',
    },
    error: {
      main: "#FD9728" 
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        fontSize: 14
      }
    },
    MuiTypography: {
      root: {
        fontSize: 14,
      },
    },
    MuiListItemText:{
      primary:{
        fontSize: 14
      }
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        color: '#fff'
      },
      containedPrimary: {
        backgroundColor: '#29BB9C'
      },
      textPrimary: {
        backgroundColor: "#29BB9C",
        color: '#fff'
      }
    },
    MuiInput: {
      underline: {
        '&:hover&:before': {
          borderBottom: '2px solid #8E8C92'
        }
      },
      root: {
        fontSize: 14
      }
    },
    MuiDivider: {
      middle: {
        marginTop: '16px',
        marginRight: '8px',
        marginBottom: '16px',
        marginLeft: '8px',
      }
    },
    MuiSvgIcon: {
      colorSecondary: {
        color: '#8E8C92'
      }
    },
    MuiToolbar:{
      regular:{
        height: 48,
        '@media (min-width: 600px)': {
          height: 48,
          minHeight: 48
        }
      }
    },
    MuiPopover: {
      paper: {
        maxHeight: '430px !important'
      }
    },
    MuiButtonBase: {
      root: {
        '&:hover&:before': {
          borderBottom: '2px solid #8E8C92',
          backgroundColor: 'transparent'
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        paddingLeft: 15
      }
    }
  }
});

export default SaraTheme;
