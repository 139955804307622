import { createStyles, Grid, TextField, Theme, Tooltip, Typography, WithStyles, withStyles } from '@material-ui/core';
import AccountBox from '@material-ui/icons/AccountBox';
import FlagOutlined from '@material-ui/icons/FlagOutlined';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import Settings from '@material-ui/icons/Settings';
import Stars from '@material-ui/icons/Stars';
import Moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Customer from '../../../../models/Customer';
import Employee from '../../../../models/Employee';
import Project from '../../../../models/Project';
import ProjectStatus from '../../../../models/ProjectStatus';
import { ProjectStatusCondition } from '../../../../models/ProjectStatusCondition';
import SaraDatePicker from '../../../common/SaraDatePicker';
import { Lookup, SaraSelect } from '../../../saraSelect/SaraSelect';
import { ensureArrayContains } from '../../../../utils/array';
import SaraColorPicker from '../../../common/saraColorPicker/SaraColorPicker';

const styles = (theme: Theme) =>
  createStyles({
    iconFieldContainer: {
      display: 'flex',
      '& > svg': {
        height: '100%',
        paddingTop: 9,
        marginRight: 6,
      },
      '& > .formTextField': {
        display: 'block',
        width: 'calc(100% - 30px)', //24px is the svg icon width and 6px margin
      },
    },
    longInput: {
      '& > div > input': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
  });

interface ProjectDescriptionProps extends WithStyles<typeof styles> {
  project: Project;
  handleChange: (event: any, field: string) => void;
  customers: Array<Customer>;
  statuses: Array<ProjectStatus>;
  employees: Array<Employee>;
  defaultPrjLead?: Lookup;
  defaultCustomer?: Lookup;
  validated: boolean;
  readonly: boolean;
  onBlurSow: () => void;
  isValidSowCode: boolean;
}

const ProjectDescription: React.FC<ProjectDescriptionProps> = (props: ProjectDescriptionProps) => {
  const { t } = useTranslation();

  const statusLookups: Array<Lookup> = props.statuses.map((item: ProjectStatus) => ({ value: item.statusCD, text: item.statusName }));
  const customerLookups: Array<Lookup> = props.customers.map((item: Customer) => ({ value: item.customerId, text: item.customerName }));
  const employeeLookups: Array<Lookup> = props.employees.map((item: Employee) => ({
    value: item.empId,
    text: `${item.empFirstName} ${item.empLastName}`,
  }));

  if (props.defaultPrjLead) {
    ensureArrayContains(employeeLookups, props.defaultPrjLead.value, props.defaultPrjLead.text);
  }
  if (props.defaultCustomer) {
    ensureArrayContains(customerLookups, props.defaultCustomer.value, props.defaultCustomer.text);
  }
  const endDateValidationMessage = (): string => {
    if (
      props.validated &&
      props.project.prjStartDate &&
      props.project.prjEndDate &&
      Moment(props.project.prjStartDate) > Moment(props.project.prjEndDate)
    ) {
      return t('endTimeShouldBeAfterStartTime');
    }
    return '';
  };

  const errorSowCode: boolean = (props.validated && !props.project.sowCode) || props.isValidSowCode;
  let helperTextSowCode: string | null = null;

  if (props.isValidSowCode) {
    helperTextSowCode = t('mustBeUnique');
  } else {
    if (props.validated && !props.project.sowCode) {
      helperTextSowCode = t('fieldIsMandatory');
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6">{t('project')}</Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={2} className={props.classes.iconFieldContainer}>
        <Settings color="secondary" />
        <Tooltip title={t('helperTextSowCode')}>
          <TextField
            className="formTextField"
            label={t('sow')}
            fullWidth
            value={props.project.sowCode}
            required
            onChange={event => props.handleChange(event, 'sowCode')}
            error={errorSowCode}
            helperText={helperTextSowCode}
            InputProps={{ disabled: props.readonly }}
            onBlur={props.onBlurSow}
            classes={{ root: props.classes.longInput }}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={4} className={props.classes.iconFieldContainer}>
        <Stars color="secondary" />
        <div className="formTextField">
          <SaraSelect
            validated={props.validated}
            label={t('customer')}
            id="customer-id"
            value={props.project.customerId || 0}
            onChange={event => props.handleChange(event, 'customerId')}
            lookups={customerLookups}
            required
            readonly={props.readonly}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={6} className={props.classes.iconFieldContainer}>
        <Tooltip title={props.project.prjName}>
          <TextField
            label={t('projectName')}
            fullWidth
            value={props.project.prjName}
            required
            onChange={event => props.handleChange(event, 'prjName')}
            inputProps={{ maxLength: 300 }}
            error={props.validated && !props.project.prjName}
            helperText={props.validated && !props.project.prjName ? t('fieldIsMandatory') : null}
            InputProps={{ disabled: props.readonly }}
            classes={{ root: props.classes.longInput }}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12} sm={6} md={4} className={props.classes.iconFieldContainer}>
        <AccountBox color="secondary" />
        <div className="formTextField">
          <SaraSelect
            validated={props.validated}
            label={t('projectLead')}
            id="project-lead-id"
            value={props.project.prjLead || 0}
            onChange={event => props.handleChange(event, 'prjLead')}
            lookups={employeeLookups}
            readonly={props.readonly}
            required
          />
        </div>
      </Grid>
      <Grid item xs={6} sm={3} md={2} className={props.classes.iconFieldContainer}>
        {props.readonly ? (
          <TextField
            label={t('startDate')}
            fullWidth
            value={Moment(props.project.prjStartDate).format('DD/MM/YYYY')}
            required
            InputProps={{ disabled: props.readonly }}
          />
        ) : (
          <SaraDatePicker
            label={t('startDate')}
            required
            value={props.project.prjStartDate ? props.project.prjStartDate : undefined}
            onChange={event => props.handleChange(event, 'prjStartDate')}
            validated={props.validated && !props.project.prjStartDate}
            fullWidth
          />
        )}
      </Grid>
      <Grid item xs={6} sm={3} md={2} className={props.classes.iconFieldContainer}>
        {props.readonly ? (
          <TextField
            label={t('endDate')}
            fullWidth
            value={props.project.prjEndDate ? Moment(props.project.prjEndDate).format('DD/MM/YYYY') : t('ongoing')}
            InputProps={{ disabled: props.readonly }}
          />
        ) : (
          <SaraDatePicker
            label={t('endDate')}
            value={props.project.prjEndDate ? props.project.prjEndDate : undefined}
            onChange={event => props.handleChange(event, 'prjEndDate')}
            minDate={props.project.prjStartDate}
            validated={props.validated}
            fullWidth
            error={!!endDateValidationMessage()}
            helperText={endDateValidationMessage()}
          />
        )}
      </Grid>
      <Grid item xs={6} sm={6} md={2} className={props.classes.iconFieldContainer}>
        <FlagOutlined color="secondary" />
        <div className="formTextField">
          <SaraSelect
            validated={props.validated}
            label={t('projectStatus')}
            id="status-id"
            value={props.project.statusCd || ProjectStatusCondition.ACTIVE}
            onChange={event => props.handleChange(event, 'statusCd')}
            lookups={statusLookups}
            required
            readonly={props.readonly}
          />
        </div>
      </Grid>
      <Grid item xs={6} sm={6} md={2} className={props.classes.iconFieldContainer}>
        <SaraColorPicker color={props.project.prjColor} handleChange={props.handleChange} readOnly={props.readonly} />
      </Grid>
      <Grid item xs={12} className={props.classes.iconFieldContainer}>
        <InsertDriveFile color="secondary" />
        <TextField
          className="formTextField"
          label={t('projectDescription')}
          fullWidth
          rows={1}
          rowsMax={5}
          multiline
          value={props.project.prjDescr}
          onChange={event => props.handleChange(event, 'prjDescr')}
          inputProps={{ maxLength: 2000 }}
          InputProps={{ disabled: props.readonly }}
          InputLabelProps={{ shrink: props.readonly ? true : undefined }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ProjectDescription);
