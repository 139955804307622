import { createStyles, IconButton, WithStyles, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EventIcon from '@material-ui/icons/Event';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const styles = () =>
  createStyles({
    buttonWrapper: {
      margin: '15px 0',
      '& > .iconButton': {
        padding: 0,
        '& .addIcon': {
          color: 'white',
          backgroundColor: '#29BB9C',
          borderRadius: '50%',
          width: 25,
          height: 25,
        },
        '& .eventIcon': {
          width: 20,
          height: 20,
          padding: 5,
        },
      },
      '& > .textStyle': {
        textTransform: 'initial',
        fontSize: 16,
        marginLeft: 10,
      },
    },
  });

interface AddNewButtonProps extends WithStyles<typeof styles> {
  onClick: (event: any) => void;
  text?: string;
  disabled?: boolean;
  isEventButton?: boolean;
}

const AddNewButton: React.FC<AddNewButtonProps> = (props: AddNewButtonProps) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={props.classes.buttonWrapper}>
        <IconButton disabled={props.disabled} className="iconButton" onClick={props.onClick}>
          {props.isEventButton ? <EventIcon className="addIcon eventIcon" /> : <AddIcon className="addIcon" />}
        </IconButton>
        <span className="textStyle">{props.text ? props.text : t('addNew')}</span>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(AddNewButton);
