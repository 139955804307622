import { createStyles, Grid, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppGroup from '../../../../models/AppGroup';
import { DialogMode } from '../../../../models/DialogMode';
import SaraTransferLists, { Lookup } from '../../../common/SaraTransferLists';

const styles = (theme: Theme) =>
  createStyles({
    inaccList: {
      height: '22em',
      paddingLeft: 20,
    },
    accList: {
      height: '22em',
      marginLeft: -16,
    },
  });

interface EmployeeAppGroupsProps extends WithStyles<typeof styles> {
  allAppGroups: Array<AppGroup>;
  authAppGroupIds: Array<number>;
  dialogMode: DialogMode;
  moveAppGroup: (id: number, toAuthorized: boolean) => void;
  label: string;
  readonly: boolean;
}

const EmployeeAppGroups: React.FC<EmployeeAppGroupsProps> = (props: EmployeeAppGroupsProps) => {
  const { t } = useTranslation();
  const unauthorized: Array<Lookup<AppGroup>> = [];
  const authorized: Array<Lookup<AppGroup>> = [];
  props.allAppGroups.forEach(appGroup => {
    const item: Lookup<AppGroup> = {
      key: appGroup.appGroupId,
      value: appGroup,
      text: appGroup.appGroupName,
    };
    if (props.authAppGroupIds.find(x => x === appGroup.appGroupId)) {
      authorized.push(item);
    } else {
      unauthorized.push(item);
    }
  });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>{props.label}</Typography>
      </Grid>
      <Grid item container spacing={1}>
        <SaraTransferLists
          showSearchBar={props.dialogMode !== DialogMode.VIEW}
          leftItems={unauthorized}
          leftHeader={t('unauthorized')}
          leftListStyle={props.classes.inaccList}
          rightItems={authorized}
          rightListStyle={props.classes.accList}
          rightHeader={t('authorized')}
          handleMove={(id, toAuthorized) => props.moveAppGroup(id, toAuthorized)}
          readonly={props.readonly}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(EmployeeAppGroups);
