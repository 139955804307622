import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleLogin } from '../../utils/authentication';
import moment from 'moment-timezone';
import LoadingPageContainer from '../errorPages/LoadingPageContainer';

interface PrivateRouteState {}

interface PrivateRouteProps {
  component: any;
  location: any;
  isAuthenticated: boolean;
  timezone: string;
}

class PrivateRoute extends Component<PrivateRouteProps, PrivateRouteState> {
  componentDidMount() {
    handleLogin();
    moment.tz.setDefault(this.props.timezone);
  }

  componentDidUpdate(prevProps: PrivateRouteProps, prevState: PrivateRouteState) {
    if (this.props.timezone !== prevProps.timezone) {
      moment.tz.setDefault(this.props.timezone);
    }
  }

  render() {
    const { component: Component, isAuthenticated, location, ...rest } = this.props;
    if (isAuthenticated !== null) {
      return (
        <Route
          {...rest}
          render={props =>
            isAuthenticated ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          }
        />
      );
    }
    return (<LoadingPageContainer />);
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    location: ownProps.path,
    isAuthenticated: state.auth.success,
    timezone: state.user.timezone,
  };
};

export default connect(mapStateToProps, null)(PrivateRoute);
