import genericApi from './genericApi';
import CustAppGroupOversee from '../models/CustAppGroupOversee';
import CreateCustAppGroupOversee from '../models/CreateCustAppGroupOversee';

const baseUrl: string = 'cust_app_group_oversee';

const custAppGroupOverseeReqHandler = {
  loadCustAppGroupOversees: (filterCustomerId?: number, filterAppGroupId?: number): Promise<Array<CustAppGroupOversee>> => {
    const filter: string = `${filterCustomerId ? `&filterCustomerId=${filterCustomerId}` : ''}${filterAppGroupId ? `&filterAppGroupId=${filterAppGroupId}` : ''}`;
    return genericApi.get(`${baseUrl}?sort=appGroupId${filter}`);
  },
  loadCustAppGroupOversee: (customerId: number, appGroupId: number): Promise<CustAppGroupOversee> => {
    return genericApi.get(`${baseUrl}/${customerId}/${appGroupId}`);
  },
  createCustAppGroupOversee: (payload: CreateCustAppGroupOversee): Promise<CustAppGroupOversee> => {
    return genericApi.post(baseUrl, payload);
  },
  deleteCustAppGroupOversee: (customerId: number, appGroupId: number): Promise<CustAppGroupOversee> => {
    return genericApi.delete(`${baseUrl}/${customerId}/${appGroupId}`);
  },
};

export default custAppGroupOverseeReqHandler;
