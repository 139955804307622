import React, { Component, ChangeEvent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { createStyles, InputAdornment, IconButton, Tooltip } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import EmployeeReqHandler from '../../api/employeesRemote';
import Employee from '../../models/Employee';
import { History, Location } from 'history';
import QueryString from 'query-string';
import { RenderSuggestionsContainerParams } from 'react-autosuggest';
import AutosuggestEmployeesContainer from '../autosuggestEmployees/AutosuggestEmployeesContainer';

const styles = () =>
  createStyles({
    searchFieldContainer: {
      marginTop: 3,
      marginBottom: 3,
      backgroundColor: '#445170',
      paddingTop: 5,
      paddingBottom: 3,
      paddingLeft: 3,
      paddingRight: 3,
      borderRadius: 5,
      width: 280,
    },
    searchInput: {
      color: 'white',
    },
    selctionContainer: {
      color: 'black',
      '& li': {
        margin: 0,
        padding: '10px 20px',
      },
      '& li:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },
  });

interface EmployeeSeachContainerProps extends WithStyles<typeof styles>, WithTranslation {
  history: History;
  location: Location;
}

interface EmployeeSeachContainerState {
  loaded: boolean;
  employees: Array<Employee>;
  selection?: Employee;
}

class EmployeeSeachContainer extends Component<EmployeeSeachContainerProps, EmployeeSeachContainerState> {
  state: EmployeeSeachContainerState = {
    employees: [],
    loaded: false,
  };

  componentDidMount() {
    EmployeeReqHandler.loadEmployees(true).then((employees: Array<Employee>) => {
      this.setState({ loaded: true, employees });
    });
  }

  getSelectionFromQueryParam(): Employee | undefined {
    let queryString = QueryString.parse(this.props.location.search) as { empId?: string };
    return this.state.employees.find(x => x.empId.toString() === queryString.empId);
  }

  componentDidUpdate(prevProps: EmployeeSeachContainerProps, prevState: EmployeeSeachContainerState) {
    if (prevProps.location.search !== this.props.location.search && !prevState.loaded && this.state.loaded) {
      this.setState({ selection: this.getSelectionFromQueryParam() });
    }
  }

  renderSuggestionsContainer(params: RenderSuggestionsContainerParams): React.ReactNode {
    return (
      <div {...params.containerProps} className={params.containerProps.className + ' ' + this.props.classes.selctionContainer}>
        {params.children}
      </div>
    );
  }

  render() {
    return (
      <AutosuggestEmployeesContainer
        placeholder={this.props.t('searchColleague')}
        renderSuggestionsContainer={this.renderSuggestionsContainer.bind(this)}
        employees={this.state.employees}
        addEmployee={(emp: Employee) => {
          if (emp) {
            this.props.history.push(`/?empId=${emp.empId}&empName=${emp.empFirstName}&timeZoneId=${emp.timeZoneId}`);
          } else {
            this.props.history.push('/');
          }
        }}
        renderInputComponent={inputProps => {
          let empName = this.state.selection ? this.state.selection.empFirstName + ' ' + this.state.selection.empLastName : '';
          if (this.state.selection) {
            return (
              <div className={this.props.classes.searchFieldContainer}>
                <TextField
                  {...inputProps}
                  fullWidth
                  inputRef={input => input && input.focus()}
                  variant="standard"
                  value={empName}
                  onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                    inputProps.onChange(event, { method: 'type', newValue: event.target.value })
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={this.props.t('clear')}>
                          <IconButton
                            size="small"
                            color="inherit"
                            onClick={() => {
                              this.setState({ selection: undefined });
                            }}
                          >
                            <CloseIcon style={{ fontSize: 15 }} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                    className: this.props.classes.searchInput,
                    readOnly: true,
                  }}
                />
              </div>
            );
          }
          return (
            <div className={this.props.classes.searchFieldContainer}>
              <TextField
                {...inputProps}
                fullWidth
                variant="standard"
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  inputProps.onChange(event, { method: 'type', newValue: event.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  className: this.props.classes.searchInput,
                }}
              />
            </div>
          );
        }}
      />
    );
  }
}

export default withTranslation()(withStyles(styles)(EmployeeSeachContainer));
