import { Column } from "material-table";
import WorkItem from "../../../../models/WorkItem";
import { UseTranslationResponse } from 'react-i18next';
import { compare } from "../../../../utils/string";

const descriptionColumn = (translation: UseTranslationResponse): Column<WorkItem> => {
    return {
      title: translation.t('description'),
      field: 'wtComment',
      customSort: (first: WorkItem, second: WorkItem) => {
        return compare(first.wtComment, second.wtComment);
      }
    };
};

export default descriptionColumn;
