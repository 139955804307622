import React from 'react';
import Moment from 'moment';
import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import CalendarEvent from "../../../../models/CalendarEvent";

const StartDateColumn = (translation: UseTranslationResponse): Column<CalendarEvent> => {
    return {
      title: translation.t('startDate'), 
      field: 'rqStartDate', 
      type: 'date',
      render: (rowData: CalendarEvent) => {
        return <span>{Moment(rowData.rqStartDate).format('DD/MM/YYYY')}</span>;
      },
      cellStyle: { minWidth: "75px" }
    };
};

export default StartDateColumn;