export const LOGIN_SUCCESS: string = 'LOGIN_SUCCESS';
export const LOGIN_FAIL: string = 'LOGIN_FAIL';

export const SAVE_USER: string = 'SAVE_USER';
export const REMOVE_USER: string = 'REMOVE_USER';
export const GO_OFFLINE: string = 'GO_OFFLINE';
export const GO_ONLINE: string = 'GO_ONLINE';

export const SAVE_USER_NAMES: string = 'SAVE_USER_NAMES';
export const REMOVE_USER_NAMES: string = 'REMOVE_USER_NAMES';

export const SET_TIMEZONE: string = 'SET_TIMEZONE';