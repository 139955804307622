import { Column } from 'material-table';
import React from 'react';
import { UseTranslationResponse } from 'react-i18next';
import Project from '../../../../models/Project';
import { ProjectStatusCondition } from '../../../../models/ProjectStatusCondition';
import StatusIndicator from '../../../common/StatusIndicator';

const StatusIndicatorColumn = (translation: UseTranslationResponse): Column<Project> => ({
  title: '',
  field: 'statusCd',
  cellStyle: { textAlign: 'center' },
  headerStyle: { textAlign: 'center' },
  width: 20,
  render: (rowData: Project) => (
    <StatusIndicator
      status={rowData.statusCd === ProjectStatusCondition.ACTIVE}
      activeLabel={translation.t('openProject')}
      inactiveLabel={translation.t('closedProject')}
    />
  ),
});

export default StatusIndicatorColumn;
