import { Column } from "material-table";
import WorkItem from "../../../../models/WorkItem";
import { UseTranslationResponse } from 'react-i18next';
import { compare } from "../../../../utils/string";

const projectColumn = (translation: UseTranslationResponse): Column<WorkItem> => {
    return {
      title: translation.t('project'),
      field: 'taskProjectId',
      render: (workItem: WorkItem) => workItem.taskProjectName,
      customSort: (first: WorkItem, second: WorkItem)=>{
        return compare(first.taskProjectName, second.taskProjectName);
      }
    };
};

export default projectColumn;
