import FakeRequest from "./FakeRequest";
import { ApiResult } from "../apiResult";

const returnedData: ApiResult<any> = {
    "messages": [],
    "result": [
        {
            "appGroupId": 1,
            "appGroupName": "Administrator",
            "appGroupDescr": "--",
            "canDelete": true,
            "formViewControls": [
              {
                  "formViewCd": "MENU_CUSTOMERS",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "MENU_TASKS",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "MY_PROJECT",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "MY_TASK",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "MENU_WORK_TIME",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "MENU_CALENDAR",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "CALENDAR_V",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "CALENDAR_H",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "EMP_ANNUAL_PAID_VAC",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "MENU_ADMINISTRATION",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "OFFICES",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "LOCATIONS",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "APP_CONFIG",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "APP_USER",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "APP_GROUP",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "APP_USER_GROUP",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "FORM_VIEW_CONTROL",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "CUST_COMPANIES_DATA_ACCESS",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "PROJECTS_DATA_ACCESS",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "MENU_REPORTS",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "REPORTS_BY_EMP",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "REPORTS_BY_PRJ",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "PROFILE_PREFERENCE",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "CUST_PERSON",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "CUST_OFFICE",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "CUST_COMPANIES",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "EMP_VAC_REQ",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "EMPLOYEES",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "PROJECT",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "TASK",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "WORK_TIME",
                  "formViewDescr": null,
                  "accessId": 2
              },
              {
                  "formViewCd": "REPORTS_BY_PRJ_SIMPLE",
                  "formViewDescr": null,
                  "accessId": 2
              }
          ]
        },
        {
            "appGroupId": 26,
            "appGroupName": "Tucows PL",
            "appGroupDescr": "PL of projects",
            "canDelete": true
        },
        {
            "appGroupId": 28,
            "appGroupName": "Tucows MW Dev",
            "appGroupDescr": "Developers for Tucows MW",
            "canDelete": true
        },
        {
            "appGroupId": 30,
            "appGroupName": "ITCIS Employee",
            "appGroupDescr": "General group",
            "canDelete": true
        },
        {
            "appGroupId": 32,
            "appGroupName": "Cemex Dev",
            "appGroupDescr": "Developers for Cemex",
            "canDelete": true
        },
        {
            "appGroupId": 34,
            "appGroupName": "Sorcim Customer",
            "appGroupDescr": null,
            "canDelete": true
        },
        {
            "appGroupId": 46,
            "appGroupName": "Mitel PL",
            "appGroupDescr": null,
            "canDelete": true
        },
        {
            "appGroupId": 66,
            "appGroupName": "Equifax Customer",
            "appGroupDescr": null,
            "canDelete": true
        },
        {
            "appGroupId": 6,
            "appGroupName": "5",
            "appGroupDescr": null,
            "canDelete": true
        },
        {
            "appGroupId": 7,
            "appGroupName": "5",
            "appGroupDescr": null,
            "canDelete": true
        },
        {
            "appGroupId": 8,
            "appGroupName": "TestGroup",
            "appGroupDescr": "TestGroupDescription..",
            "canDelete": true
        },
        {
            "appGroupId": 5,
            "appGroupName": "TestGroup",
            "appGroupDescr": "TestGroupDescription...",
            "canDelete": true
        },
        {
            "appGroupId": 10,
            "appGroupName": "TEST",
            "appGroupDescr": "TEST DESCR",
            "canDelete": true
        },
        {
            "appGroupId": 9,
            "appGroupName": "TEST",
            "appGroupDescr": "DESCR",
            "canDelete": true
        },
        {
            "appGroupId": 11,
            "appGroupName": "111",
            "appGroupDescr": "TEST",
            "canDelete": true
        },
        {
            "appGroupId": 12,
            "appGroupName": "123",
            "appGroupDescr": "David",
            "canDelete": true
        },
        {
            "appGroupId": 13,
            "appGroupName": "Very long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery",
            "appGroupDescr": "Very long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery long textVery l",
            "canDelete": true
        }
    ]
}

export const AppGroupsGet: FakeRequest = {
    data: returnedData,
    httpCode: 200,
    url: /app_groups/,
    method: 'GET'
}

export const AppGroupsPost: FakeRequest = {
    data: {messages: [],result: null},
    httpCode: 200,
    url: /app_groups/,
    method: 'POST'
}

export const AppGroupsPut: FakeRequest = {
    data: {messages: [],result: null},
    httpCode: 200,
    url: /app_groups/,
    method: 'PUT'
}
