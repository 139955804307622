import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    workItemTitle: {
      marginLeft: 15,
      '& h3': {
        color: '#333333',
        fontSize: '1.25rem',
        fontWeight: 600,
      },
    },
  });

interface WorkTimeTitleProps extends WithStyles<typeof styles> {
  employeeName?: string;
}

const WorkTimeTitle: React.FC<WorkTimeTitleProps> = (props: WorkTimeTitleProps) => {
  if (props.employeeName) {
    return (
      <div className={props.classes.workItemTitle}>
        <Trans i18nKey="differentWorkTimeTitle" parent={'h3'}>
          {{ name: props.employeeName }}
        </Trans>
        <Trans i18nKey="switchBackToMyWorkTime" parent={'h5'}>
          <Link to="/" />
        </Trans>
      </div>
    );
  }
  return (
    <div className={props.classes.workItemTitle}>
      <Trans i18nKey="yourWorkTimeReport" parent={'h3'} />
    </div>
  );
};

export default withStyles(styles)(WorkTimeTitle);
