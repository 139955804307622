import { Column } from "material-table";
import Task from "../../../../../../models/Task";
import { UseTranslationResponse } from 'react-i18next';
import { compare } from "../../../../../../utils/string";

const NameColumn = (translation: UseTranslationResponse): Column<Task> => {
    return {
      title: translation.t('taskName'),
      field: 'taskName',
      customSort: (first: Task, second: Task) => {
        return compare(first.taskName, second.taskName);
      }
    };
};

export default NameColumn;
