import { UserState } from '../reducers/userReducer';

export type SelectUserState = { user: UserState };

export const getUser = ({ user }: SelectUserState) => user.user;

export const isOffline = ({ user }: SelectUserState) => user.isOffline;

export const getUserNames = ({ user }: SelectUserState) => ({ firstName: user.firstName, lastname: user.lastName });

export const getTimezone = ({ user }: SelectUserState) => user.timezone;
