import genericApi from './genericApi';
import User from '../models/User';

const loginReqHandler = {
  login: (data: {username: string; password: string}): Promise<User> => {
    return genericApi.post('login', data);
  },
  logout: ():Promise<any> => {
    return genericApi.post('logout');
  }
};

export default loginReqHandler;