export const approved =(permissions: any, typeId: number) => {
  switch(typeId) {
    case 1: 
      return {
        canRead: permissions.approveVACRead,
        canWrite: permissions.approveVACWrite
      }
    case 10 : return {
      canRead: permissions.approveVACRead,
      canWrite: permissions.approveVACWrite
    }
    case 4: return {
      canRead: permissions.approveHORead,
      canWrite: permissions.approveHOWrite
    }
    case 11: return {
      canRead: permissions.approveSICKRead,
      canWrite: permissions.approveSICKWrite
    }
    case 2: return {
      canRead: permissions.approveBTRead,
      canWrite: permissions.approveBTWrite
    }
    default: return {
      canRead: false,
      canWrite: false
    }
  }
}