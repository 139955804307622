import { History } from 'history';
import React, { Component, Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import formViewUserControlsReqHandler from '../../api/formViewUserControlsRemote';
import heartBeatReqHandler from '../../api/heartBeatRemote';
import loginReqHandler from '../../api/loginRemote';
import menuItems from '../../configs/menu';
import { FormViewCondition } from '../../models/FormViewCondition';
import { FormViewUserControl } from '../../models/FormViewUserControl';
import User from '../../models/User';
import { logoutAction } from '../../store/actions/authActions';
import { removeUser, setTimezone } from '../../store/actions/userActions';
import { getUser, getUserNames, isOffline, SelectUserState } from '../../store/selectors/userSelectors';
import ChangePasswordContainer from '../changePassword/ChangePasswordContainer';
import InfoSnackbar from '../infoSnackbar/InfoSnackbar';
import LoggedInMenu from './LoggedInMenu';

interface LoggedInMenuProps extends WithTranslation {
  history: History;
  logoutAction: Function;
  removeUser: Function;
  storeTimezone: Function;
  user: User;
  isOffline: boolean;
  userFirstName: string;
  userLastName: string;
}

interface LoggedInMenuState {
  menuPermissions: Array<FormViewUserControl>;
  isLoading: boolean;
  itemClick: any;
  openModalChangePassword: boolean;
  snackbarText: string | undefined;
  hiddenDialog: boolean;
}

class LoggedInMenuContainer extends Component<LoggedInMenuProps, LoggedInMenuState> {
  state: LoggedInMenuState = {
    menuPermissions: [],
    isLoading: true,
    itemClick: {},
    openModalChangePassword: false,
    snackbarText: undefined,
    hiddenDialog: false,
  };

  timerId: any;

  componentDidMount() {
    this.timerId = setInterval(() => {
      heartBeatReqHandler.sendHeartBeat();
    }, 20000);
    this.loadPermissions();
  }

  loadPermissions = () => {
    formViewUserControlsReqHandler
      .loadFormViewUserControls(FormViewCondition.MENU_PERMISSIONS)
      .then((permissions: Array<FormViewUserControl>) => {
        const locationPath = this.props.history.location.pathname;
        const menuLinks: Array<string> = menuItems.flatMap(item => item.link || item.children!.map(itm => itm.link));
        if (!this.state.menuPermissions.length && menuLinks.includes(locationPath)) {
          const permittedFormViews: Array<FormViewCondition> = permissions.filter(perm => perm.accessId > 0).map(item => item.formViewCd);
          const permittedMenuLinks: Array<string> = menuItems
            .filter(item => permittedFormViews.find(formView => formView === item.formViewCd))
            .flatMap(item => {
              if (item.children && item.children.length) {
                return item.children.filter(itm => permittedFormViews.find(formView => formView === itm.formViewCd)).map(itm => itm.link);
              }
              return item.link;
            });
          if (permittedMenuLinks.length) {
            if (locationPath === '/') {
              this.pushNewLocation(permittedMenuLinks[0]);
              this.props.history.push(permittedMenuLinks[0]);
            } else if (permittedMenuLinks.includes(locationPath)) {
              this.pushNewLocation(locationPath);
              this.props.history.push(locationPath);
            }
          } else {
            this.props.history.push('/unauthorized');
          }
        }
        this.setState({ menuPermissions: permissions, isLoading: false });
      });
  };

  pushNewLocation = (location: string) => {
    if (['/calendar', '/requests', '/offices'].includes(location)) {
      this.expandCollapseMenuItem('schedule');
    }
    if (['/report_worktime', '/report_overtime'].includes(location)) {
      this.expandCollapseMenuItem('reports');
    }
  };

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidUpdate(prevProps: LoggedInMenuProps) {
    if (!prevProps.isOffline && this.props.isOffline) {
      this.props.history.push('/offline');
    }
  }

  signOut = (): void => {
    loginReqHandler.logout().then(() => {
      this.props.removeUser();
      this.props.logoutAction();
      this.props.storeTimezone('');
      this.props.history.push('/login');
    });
  };

  expandCollapseMenuItem = (item: string): void => {
    this.setState(prevState => ({ itemClick: { ...prevState.itemClick, [item]: !prevState.itemClick[item] } }));
  };

  handleCloseModal = (): void => {
    this.setState({ openModalChangePassword: false });
  };

  passwordChanged = (): void => {
    this.setState({ snackbarText: 'passwordChangedSuccessfully', hiddenDialog: true });
    setTimeout(() => {
      this.signOut();
    }, 2000);
  };

  render() {
    const activePermissions: Array<FormViewUserControl> = this.state.menuPermissions.filter(perm => perm.accessId > 0);
    return (
      <Fragment>
        <LoggedInMenu
          signOut={this.signOut}
          activePermissions={activePermissions}
          realoadPermissions={this.loadPermissions}
          loggedInUsername={`${this.props.userFirstName} ${this.props.userLastName}`}
          isLoading={this.state.isLoading}
          handleClick={this.expandCollapseMenuItem}
          itemClick={this.state.itemClick}
          changePassword={() => {
            this.setState({ openModalChangePassword: true });
          }}
        />
        {this.state.openModalChangePassword && (
          <ChangePasswordContainer
            open={this.state.openModalChangePassword}
            onClose={this.handleCloseModal}
            passwordChanged={this.passwordChanged}
            user={this.props.user}
            hiddenDialog={this.state.hiddenDialog}
          />
        )}
        <InfoSnackbar
          open={this.state.snackbarText !== undefined}
          onClose={() => this.setState({ snackbarText: undefined })}
          text={this.state.snackbarText ? this.props.t(this.state.snackbarText) : ''}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: SelectUserState) => ({
  user: getUser(state),
  isOffline: isOffline(state),
  userFirstName: getUserNames(state).firstName,
  userLastName: getUserNames(state).lastname,
});

const mapDispatchToProps = (dispatch: any) => ({
  logoutAction: () => dispatch(logoutAction()),
  removeUser: () => dispatch(removeUser()),
  storeTimezone: (timezone: string) => dispatch(setTimezone(timezone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LoggedInMenuContainer));
