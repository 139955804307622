import React, { Component } from 'react';
import Autosuggest, { InputProps, RenderSuggestionsContainer } from 'react-autosuggest';
import employeesReqHandler from '../../api/employeesRemote';
import Employee from '../../models/Employee';
import { withTranslation, WithTranslation } from 'react-i18next';
import './autosuggestEmployees.css';

interface AutosuggestEmployeesProps extends WithTranslation {
  addEmployee: Function;
  renderInputComponent?: (inputProps: InputProps<any>) => React.ReactNode;
  placeholder?: string;
  renderSuggestionsContainer?: RenderSuggestionsContainer;
  employees?: Array<Employee>;
};

interface AutosuggestEmployeesState {
  value: string;
  suggestions: Array<any>;
  items: Array<Employee>;
};

class AutosuggestEmployeesContainer extends Component<AutosuggestEmployeesProps, AutosuggestEmployeesState> {

  state: AutosuggestEmployeesState = {
    value: '',
    suggestions: [],
    items: []
  }

  componentDidMount() {
    if (!this.props.employees) {
      employeesReqHandler.loadEmployees().then((resp: Array<Employee>) => {
        this.setState({ items: resp });
      });
    }
  }

  getSuggestions = (value: any) => {
    const inputValue: string = value.value.trim().toLowerCase();
    const inputLength: number = inputValue.length;

    let items = this.props.employees ? this.props.employees : this.state.items;
    return inputLength === 0 ? [] : items.filter(item =>
      item.empFirstName.toLowerCase().slice(0, inputLength) === inputValue ||
      item.empLastName.toLowerCase().slice(0, inputLength) === inputValue ||
      item.empEmail.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  getSuggestionValue = (suggestion: Employee) => {
    this.props.addEmployee(suggestion);
    return '';
  };

  renderSuggestion = (suggestion: Employee, params: Autosuggest.RenderSuggestionParams) => (
    <div>
      {suggestion.empFirstName} {suggestion.empLastName}
    </div>
  );

  onChange = (event: any) => {
    this.setState({
      value: event.target.value || ''
    });
  };

  onSuggestionsFetchRequested = (value: any) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {

    const inputProps = {
      placeholder: this.props.placeholder ? this.props.placeholder : this.props.t('searchTeamMember'),
      value: this.state.value,
      onChange: this.onChange,
      onBlur: () => this.setState({ value: '' })
    };

    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        renderSuggestionsContainer={this.props.renderSuggestionsContainer}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={() => { }}
        renderInputComponent={this.props.renderInputComponent}
      />
    );
  }
};

export default withTranslation()(AutosuggestEmployeesContainer);
