import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApiResult } from '../../../../api/apiResult';
import empAppGroupOverseeReqHandler from '../../../../api/empAppGroupOverseeRemote';
import EmployeesReqHandler from '../../../../api/employeesRemote';
import AppGroup from '../../../../models/AppGroup';
import { DialogMode } from '../../../../models/DialogMode';
import EmpAppGroupOversee from '../../../../models/EmpAppGroupOversee';
import Employee from '../../../../models/Employee';
import { addToOldState, addToStaff, removeFromStaff } from '../../../../store/actions/appGroupActions';
import { AppGroupActionType } from '../../../../store/actions/appGroupActionType';
import { AppGroupState } from '../../../../store/reducers/appGroupsReducer';
import { getAppGroup, getAppGroupStaff } from '../../../../store/selectors/appGroupSelectors';
import { compare } from '../../../../utils/string';
import StaffAccess from './StaffAccess';

interface EmployeeAccessContainerProps {
  dialogMode: DialogMode;
  appGroup: AppGroup;
  appGroupStaff: Array<Employee>;
  onError: (error: { response: { data: ApiResult<any> } }) => void;
  addToStaff: (project: Employee) => { type: AppGroupActionType; payload: { employee: Employee } };
  removeFromStaff: (project: Employee) => { type: AppGroupActionType; payload: { employee: Employee } };
  addToOldState: (oldState: Partial<AppGroupState>) => { type: AppGroupActionType; payload: { oldState: Partial<AppGroupState> } };
}

interface EmployeeAccessContainerState {
  isLoading: boolean;
  inaccEmployees: Array<Employee>;
}

class EmployeeAccessContainer extends Component<EmployeeAccessContainerProps, EmployeeAccessContainerState> {
  state: EmployeeAccessContainerState = {
    isLoading: false,
    inaccEmployees: [],
  };

  componentDidMount() {
    const staffRequests: Array<Promise<any>> = [EmployeesReqHandler.loadEmployees(false, 'empFirstName')];
    if (this.props.dialogMode !== DialogMode.CREATE && !this.props.appGroupStaff.length) {
      staffRequests.push(empAppGroupOverseeReqHandler.loadEmpAppGroupOversees(0, this.props.appGroup.appGroupId));
    }
    this.setState({ isLoading: true }, () =>
      Promise.all(staffRequests)
        .then(([staff, oversees]) => {
          const filteredStaff = staff.filter((empl: Employee) => {
            if (oversees && oversees.length) {
              const isEmplInGroup = !!oversees.find((group: EmpAppGroupOversee) => group.empId === empl.empId);
              if (isEmplInGroup) {
                this.props.addToStaff(empl);
              }
            }
            return this.props.appGroupStaff.findIndex(e => e.empId === empl.empId) < 0;
          });
          this.props.addToOldState({ staff: this.props.appGroupStaff });
          const inaccEmployees = this.sortEmployees(filteredStaff);
          this.setState({ inaccEmployees, isLoading: false });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          this.props.onError(error);
        })
    );
  }

  handleMove = (id: number, toAuthorized: boolean) => {
    if (this.props.dialogMode === DialogMode.VIEW) {
      return;
    }
    const employeesList = [...this.state.inaccEmployees];
    if (toAuthorized) {
      let employeeIndex = 0;
      const selectedEmployee = employeesList.find((employee, index) => {
        employeeIndex = index;
        return employee.empId === id;
      });
      employeesList.splice(employeeIndex, 1);
      if (selectedEmployee) {
        this.props.addToStaff(selectedEmployee);
      }
    } else {
      const selectedEmployee = this.props.appGroupStaff.find(employee => {
        return employee.empId === id;
      });
      if (selectedEmployee) {
        this.props.removeFromStaff(selectedEmployee);
        employeesList.push(selectedEmployee);
      }
    }
    this.setState({ inaccEmployees: this.sortEmployees(employeesList) });
  };

  private sortEmployees(employees: Array<Employee>): Array<Employee> {
    return employees.sort((first, second) =>
      compare(`${first.empFirstName} ${first.empLastName}`, `${second.empFirstName} ${second.empLastName}`)
    );
  }

  render() {
    return (
      <StaffAccess
        dialogMode={this.props.dialogMode}
        isLoading={this.state.isLoading}
        inaccEmployees={this.state.inaccEmployees}
        accEmployees={this.sortEmployees(this.props.appGroupStaff)}
        moveEmployee={this.handleMove}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({ appGroup: getAppGroup(state), appGroupStaff: getAppGroupStaff(state) });

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      addToStaff,
      removeFromStaff,
      addToOldState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeAccessContainer);
