import { Tooltip } from '@material-ui/core';
import { Column } from "material-table";
import React from 'react';
import { UseTranslationResponse } from 'react-i18next';
import AppGroup from "../../../../models/AppGroup";
import { compare } from "../../../../utils/string";

const NameColumn = (translation: UseTranslationResponse): Column<AppGroup> => {
    return {
      cellStyle: { backgroundColor: '#fff'},
      headerStyle: { backgroundColor: '#fff', minWidth: '100px'},
      title: translation.t('appGroupName'),
      field: 'appGroupName',
      render: (rowData: AppGroup) => (<Tooltip title={rowData.appGroupDescr || ''}><span>{rowData.appGroupName}</span></Tooltip>),
      customSort: (first: AppGroup, second: AppGroup) => {
        return compare(first.appGroupName, second.appGroupName);
      }
    };
};

export default NameColumn;
