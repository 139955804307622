import React, { ReactNode } from 'react';
import { IconButton, Theme, createStyles, WithStyles, withStyles, DialogTitle, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const styles = (theme: Theme) =>
  createStyles({
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1.5rem',
      '& #title-text': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    warningWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1rem',
      color: theme.palette.error.main,
    },
  });

interface SaraDialogTitleProps extends WithStyles<typeof styles> {
  text: string;
  onClose: (event: any) => void;
  additionalIcon?: ReactNode;
  showWarning?: boolean;
  warning?: string;
}

const SaraDialogTitle: React.FC<SaraDialogTitleProps> = (props: SaraDialogTitleProps) => {
  const { t } = useTranslation();
  const warning = props.showWarning && <div className={props.classes.warningWrapper}>{props.warning}</div>;

  return (
    <DialogTitle id="dialog-title">
      <div className={props.classes.titleWrapper}>
        <Tooltip title={props.text}>
          <div id="title-text">{props.text}</div>
        </Tooltip>
        <div>
          {props.additionalIcon}
          <Tooltip title={t('close')}>
            <IconButton aria-label="close" onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      {warning}
    </DialogTitle>
  );
};

export default withStyles(styles)(SaraDialogTitle);
