import genericApi from './genericApi';
import moment, { Moment } from 'moment';
import CalendarDay from '../models/CalendarDay';
import CalendarDayType from '../models/CalendarDayType';
import CalendarEvent from '../models/CalendarEvent';
import CalendarProject from '../models/CalendarProject';
import { WorkingDay } from '../models/WorkingDay';
import OfficeCalendarEvent from '../models/OfficeCalendarEvent';

type CalendarEventBody = Partial<Omit<CalendarEvent, 'rqStartDate' | 'rqEndDate'>> & {
  rqStartDate: string;
  rqEndDate: string;
}

const calendarReqHandler = {
  loadHolidays: (startDate?: Moment, endDate?: Moment, officeId?: string): Promise<Array<CalendarDay>> => {
    let url: string = `calendar?isWorking=${WorkingDay.NONWORKING}`;

    if (startDate) {
      let formattedStartDate: string = startDate.format('YYYY-MM-DD');
      url += `&fromDate=${formattedStartDate}`;
    }
    if (endDate) {
      let formattedEndDate: string = endDate.format('YYYY-MM-DD');
      url += `&toDate=${formattedEndDate}`;
    }
    if (officeId) {
      url += `&filterOfficeId=${officeId}`;
    }

    return genericApi.get(url);
  },
  loadDayTypes: (): Promise<Array<CalendarDayType>> => genericApi.get('day_types'),
  createCalendarEvent: (data: CalendarEvent): Promise<Partial<CalendarEvent>> => {
    const event: CalendarEventBody = {
      empId: data.empId,
      rqStartDate: data.rqStartDate ? moment(data.rqStartDate).format('YYYY-MM-DD') : '',
      rqEndDate: data.rqEndDate ? moment(data.rqEndDate).format('YYYY-MM-DD') : '',
      eventTypeId: data.eventTypeId,
      notes: data.notes,
    };
    return genericApi.post('emp_cal_event_reqs', event);
  },
  editCalendarEvent: (data: CalendarEvent): Promise<Partial<CalendarEvent>> => {
    const body: CalendarEventBody = {
      empId: data.empId,
      rqStartDate: data.rqStartDate ? moment(data.rqStartDate).format('YYYY-MM-DD') : '',
      rqEndDate: data.rqEndDate ? moment(data.rqEndDate).format('YYYY-MM-DD') : '',
      notes: data.notes,
      eventTypeId: data.eventTypeId,
      statusId: data.statusId,
    };
    return genericApi.put(`emp_cal_event_reqs/${data.empCalEventReqId}`, body);
  },
  loadEventsMultipleFilter: (
    startDate?: Moment,
    endDate?: Moment,
    officeIds?: Array<number>,
    eventTypeIds?: Array<number>,
    empIds?: Array<number>,
    statusIds?: Array<number>,
    orderBy?: string,
    orderByType?: string
  ): Promise<Array<CalendarEvent>> => {
    let url: string = 'emp_cal_event_reqs?';
    if (startDate) {
      url += `filterStartDate=${startDate.format('YYYY-MM-DD')}T00:00:00.000Z`;
    }
    if (endDate) {
      url += `&filterEndDate=${endDate.format('YYYY-MM-DD')}T23:59:59.000Z`;
    }
    if (officeIds && officeIds.length) {
      url += `&filterOfficeIds=${officeIds.join(';')}`;
    }
    if (empIds && empIds.length) {
      url += `&filterByEmpIds=${empIds.join(';')}`;
    }
    if (eventTypeIds && eventTypeIds.length) {
      url += `&filterEventTypeIds=${eventTypeIds.join(';')}`;
    }
    if (statusIds && statusIds.length) {
      url += `&filterStatusIds=${statusIds.join(';')}`;
    }
    if (orderBy) {
      url += '&sort=' + orderBy + ',' + (orderByType ? orderByType : 'ASC');
    }
    return genericApi.get(url);
  },

  deleteCalendarEvent: (id: number): Promise<any> => genericApi.delete(`emp_cal_event_reqs/${id}`),
  loadCalendarTasks: (startDate?: Moment, endDate?: Moment, officeId?: string): Promise<Array<CalendarProject>> => {
    let url: string = 'emp_project_task_summaries?';
    if (startDate) {
      url += `filterStartDate=${startDate.format('YYYY-MM-DD')}T00:00:00.000Z`;
    }
    if (endDate) {
      url += `&filterEndDate=${endDate.format('YYYY-MM-DD')}T23:59:59.000Z`;
    }
    if (officeId) {
      url += `&filterOfficeId=${officeId}`;
    }
    return genericApi.get(url);
  },
  loadFirstWorkingDay: (startDate: Moment, endDate: Moment, officeId: number): Promise<Array<CalendarDay>> => {
    const formattedStartDate: string = startDate.format('YYYY-MM-DD');
    const formattedEndDate: string = endDate.format('YYYY-MM-DD');
    const url: string = `calendar?fromDate=${formattedStartDate}&toDate=${formattedEndDate}&sort=date&filterOfficeId=${officeId}&isWorking=${WorkingDay.WORKING}&pageNumber=1&pageSize=1`;
    return genericApi.get(url);
  },
  editOfficeCalendar: (data: OfficeCalendarEvent): Promise<any> =>
    genericApi.put(`calendar`, {
      officeId: data.officeId,
      date: data.date ? moment(data.date).format('YYYY-MM-DD') : '',
      isWorking: data.isWorking,
      dayDescription: data.dayDescription,
    }),
};

export default calendarReqHandler;
