import DateFnsUtils from '@date-io/date-fns';
import { Button, Checkbox, FormControlLabel, Grid, Paper, Theme, Link } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import Moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconExcel } from '../../../assets/icons8-microsoft-excel.svg';
import SaraDatePicker from '../../common/SaraDatePicker';
import { Lookup, SaraMultiSelect } from '../../saraSelect/SaraMultiSelect';
import TreeMultiSelectContainer from './TreeMultiSelect';
import { TreeNode } from 'react-dropdown-tree-select';
import ChildEmployeeOfProjectsTree from '../../../models/ChildEmployeeOfProjectsTree';
import ProjectsTree from '../../../models/ProjectsTree';

const styles = (theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    checkboxLabel: {
      marginBlockStart: '10px',
      marginInlineStart: 'auto',
    },
    checkBoxText: {
      fontSize: 12,
    },
    downloadButton: {
      color: '#29BB9C',
    },
    link: {
      color: 'white',
      width: 107,
    },
    displayBtn: {
      width: 107,
    },
    excelIcon: {
      width: '20px',
      height: '20px',
      '& path': {
        fill: '#29BB9C',
      },
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
    },
  });

interface ReportsFiltersProps extends WithStyles<typeof styles> {
  projectLookups: Array<Lookup>;
  selectedProjectIds: Array<number>;
  employeeLookups: Array<Lookup>;
  selectedEmployeeIds: Array<number>;
  startDate: string;
  endDate: string;
  showSummary: boolean;
  validated: boolean;
  dateErrorMessage: string;
  onChange: (event: any, field: string) => void;
  onDateValidation: () => void;
  onSave: () => void;
  pdfExportUrl: string;
  csvExportUrl: string;
  tasksDropdown: Array<ProjectsTree>;
  onChangeTask :(tasksId: Array<TreeNode>, data: Array<ProjectsTree>, viewValue: Array<string>) => void;
  setViewValue: (viewValue: Array<string>) => void;
  disabled: boolean;
  viewTaskValue: Array<string>;
  handleExpanded: (parent: ProjectsTree | ChildEmployeeOfProjectsTree) => boolean;
}

const ReportsFilters: React.FC<ReportsFiltersProps> = (props: ReportsFiltersProps) => {
  const { t } = useTranslation();
  return (
    <Paper style={{ padding: 15 }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container direction="column" spacing={3}>
          <Grid container item spacing={1}>
            <Grid item xs={12} md={4}>
              <SaraMultiSelect
                validated
                label={t('project')}
                id="project-id"
                value={props.selectedProjectIds}
                multiple
                onChange={event => props.onChange(event, 'selectedProjectIds')}
                lookups={props.projectLookups}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <SaraMultiSelect
                validated
                label={t('employee')}
                id="employee-id"
                value={props.selectedEmployeeIds}
                multiple
                onChange={event => props.onChange(event, 'selectedEmployeeIds')}
                lookups={props.employeeLookups}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TreeMultiSelectContainer
                label={t('task')}
                lookups={props.tasksDropdown}
                onChange={props.onChangeTask}
                setViewValue={props.setViewValue}
                disabled={props.disabled}
                viewValue={props.viewTaskValue}
                handleExpanded={props.handleExpanded}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={12} md={2}>
              <SaraDatePicker
                label={t('start')}
                required
                value={props.startDate}
                onChange={event => props.onChange(event, 'startDate')}
                validated={props.validated}
                fullWidth
                maxDate={props.endDate}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <SaraDatePicker
                label={t('end')}
                required
                onHandleValidation={props.onDateValidation}
                value={props.endDate}
                onChange={event => props.onChange(event, 'endDate')}
                validated={props.validated}
                fullWidth
                minDate={props.startDate}
                error={!!props.dateErrorMessage}
                helperText={props.dateErrorMessage}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <FormControlLabel
                className={props.classes.checkboxLabel}
                control={
                  <Checkbox
                    checked={props.showSummary}
                    onChange={event => props.onChange(event, 'showSummary')}
                    value="showSummary"
                    color="primary"
                    size="small"
                  />
                }
                classes={{ label: props.classes.checkBoxText }}
                label={t('showSummary')}
              />
            </Grid>
            <Grid container item spacing={1} xs={12} md={5} lg={6}>
              <Grid item xs={12} md={4} lg={2} className={props.classes.buttonContainer}>
                <Link classes={{ root: props.classes.link }} href={props.pdfExportUrl} underline="none" download>
                  <Button
                    fullWidth
                    disabled={
                      !props.startDate ||
                      !Moment(props.startDate).isValid() ||
                      !props.endDate ||
                      !Moment(props.endDate).isValid() ||
                      Moment(props.startDate).isAfter(Moment(props.endDate))
                    }
                    classes={{ root: props.classes.downloadButton }}
                    startIcon={<PictureAsPdfIcon />}
                  >
                    {t('download')}
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} md={4} lg={2} className={props.classes.buttonContainer}>
                <Link classes={{ root: props.classes.link }} href={props.csvExportUrl} underline="none" download>
                  <Button
                    fullWidth
                    disabled={
                      !props.startDate ||
                      !Moment(props.startDate).isValid() ||
                      !props.endDate ||
                      !Moment(props.endDate).isValid() ||
                      Moment(props.startDate).isAfter(Moment(props.endDate))
                    }
                    classes={{ root: props.classes.downloadButton }}
                    startIcon={<IconExcel className={props.classes.excelIcon} />}
                  >
                    {t('download')}
                  </Button>
                </Link>
              </Grid>
              <Grid item xs={12} md={4} lg={2} className={props.classes.buttonContainer}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={
                    !props.startDate ||
                    !Moment(props.startDate).isValid() ||
                    !props.endDate ||
                    !Moment(props.endDate).isValid() ||
                    Moment(props.startDate).isAfter(Moment(props.endDate))
                  }
                  onClick={props.onSave}
                  className={props.classes.displayBtn}
                >
                  {t('display')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

export default withStyles(styles)(ReportsFilters);
