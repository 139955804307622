import React from 'react';
import { Column } from "material-table";
import WorkItem from "../../../../models/WorkItem";
import { formatNumber, totalMinutesBetween, totalSecondsBetween } from "../../../../utils/dateTime";
import { UseTranslationResponse } from 'react-i18next';

const durationColumn = (translation: UseTranslationResponse): Column<WorkItem> => {
    return {
      title: translation.t('hours'),
      field: 'wtEndTime',
      render: (rowData: WorkItem) => {
        return <span>{formatNumber(totalMinutesBetween(rowData.wtStartTime, rowData.wtEndTime), 2)}</span>;
      },
      customSort: (first: WorkItem, second: WorkItem) => {
        let firstDuration = totalSecondsBetween(first.wtStartTime, first.wtEndTime);
        let secondDuration = totalSecondsBetween(second.wtStartTime, second.wtEndTime);
        return firstDuration - secondDuration;
      }
    };
};

export default durationColumn;