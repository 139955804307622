import moment, { Moment } from 'moment';
import React from 'react';
import Timeline, { ReactCalendarGroupRendererProps, TimelineItem } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import Office from '../../models/Office';
import officeStyles from './office.module.css';
import './timeline.css';
import { Tooltip } from '@material-ui/core';

interface OfficeProps {
  timeStart: number;
  timeEnd: number;
  holidays: Array<string>;
  offices: Array<Office>;
  calendarEventItems: Array<TimelineItem<any, any>>;
  onCanvasClick: (groupId: number, time: number, event: any) => void;
}

const TIME_STEPS = {
  second: 1,
  minute: 1,
  hour: 0,
  day: 1,
  month: 1,
  year: 1,
};

const OfficeCalendar: React.FC<OfficeProps> = (props: OfficeProps) => {
  const zoom: number = props.timeEnd - props.timeStart;

  const itemRenderer = (itemObject: any) => {
    let { item, itemContext, getItemProps } = itemObject;
    const backgroundColor: string = itemContext.selected ? item.style.backgroundColor + 'CC' : item.style.backgroundColor + 'B3';
    const borderColor: string = itemContext.selected ? item.style.borderColor + 'CC' : item.style.borderColor + 'B3';
    const color: string = item.style.color;
    const itemProps = { ...getItemProps({ style: { color, borderColor, backgroundColor } }) };
    delete itemProps.title;
    return (
      <div {...itemProps} className={officeStyles.eventItemWrapper}>
        <Tooltip title={itemContext.title}>
          <div className={officeStyles.eventItem}>{itemContext.title}</div>
        </Tooltip>
      </div>
    );
  };

  const groupRenderer = ({ group }: ReactCalendarGroupRendererProps) => (
    <div className="custom-group highlighted">
      <span>{group.title}</span>
    </div>
  );

  const verticalLineClassNamesForTime = (timeStart: any, timeEnd: any) => {
    const currentTimeStart: Moment = moment(timeStart);
    const currentTimeEnd: Moment = moment(timeEnd);

    for (let i: number = 0; i < props.holidays.length; i++) {
      const holiday: Moment = moment(props.holidays[i]);
      if (holiday.isSame(currentTimeStart, 'day') && holiday.isSame(currentTimeEnd, 'day')) {
        return ['holiday'];
      }
    }

    return [''];
  };

  const groups = props.offices.map(office => ({
    id: office.officeId,
    title: office.officeName,
  }));

  return (
    <div className={officeStyles.timelineWrapper}>
      {props.offices.length !== 0 && (
        <Timeline
          groups={groups}
          items={props.calendarEventItems}
          visibleTimeStart={props.timeStart}
          visibleTimeEnd={props.timeEnd}
          minZoom={zoom}
          maxZoom={zoom}
          stackItems={true}
          canMove={false}
          itemRenderer={itemRenderer}
          verticalLineClassNamesForTime={verticalLineClassNamesForTime}
          onCanvasClick={props.onCanvasClick}
          lineHeight={40}
          itemHeightRatio={0.5}
          timeSteps={TIME_STEPS}
          groupRenderer={groupRenderer}
          sidebarWidth={200}
        />
      )}
    </div>
  );
};

export default OfficeCalendar;
