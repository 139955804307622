import { Column } from "material-table";
import Customer from "../../../../models/Customer";
import { UseTranslationResponse } from 'react-i18next';
import { compare } from "../../../../utils/string";

const AccountManagerColumn = (translation: UseTranslationResponse): Column<Customer> => {
    return {
      title: translation.t('accountManager'),
      field: 'accountManagerName',
      customSort: (first: Customer, second: Customer) => {
        return compare(first.accountManagerName, second.accountManagerName);
      }
    };
};

export default AccountManagerColumn;
