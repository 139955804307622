import React from 'react';
import store from '../../store';
import { withStyles, WithStyles } from '@material-ui/styles';
import { History, Location } from 'history';
import QueryString from 'query-string';
import { createStyles, Theme, Tooltip, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GetAppIcon from '@material-ui/icons/GetApp';
import { exportUrl } from '../../api/workTimeRemote';
import User from '../../models/User';

const styles = (theme: Theme) =>
  createStyles({
    itemIcon: {
      color: 'white',
      marginRight: '18px',
    },
  });

interface ExportWorktimeIconProps extends WithStyles<typeof styles> {
  history: History;
  location: Location;
}

const ExportWorktimeIcon: React.FC<ExportWorktimeIconProps> = (props: ExportWorktimeIconProps) => {
  const { t } = useTranslation();
  const queryString = QueryString.parse(props.location.search) as { empId?: string | null };
  const user: User = store.getState().user.user;
  let employeeId: number | null = user.empId;
  if (queryString.empId) {
    employeeId = Number.parseInt(queryString.empId);
  }
  let csvExportUrl = '#';
  if (employeeId) {
    csvExportUrl = exportUrl(employeeId);
  }

  return (
    <div>
      <Tooltip title={t('export')}>
        <Link href={csvExportUrl} className={props.classes.itemIcon} download>
          <GetAppIcon />
        </Link>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(ExportWorktimeIcon);
