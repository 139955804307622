import { Column } from 'material-table';
import { UseTranslationResponse } from 'react-i18next';
import WorkItem from '../../../../models/WorkItem';
import { formatNumber, totalMinutesBetween, totalSecondsBetween } from '../../../../utils/dateTime';

const HoursColumn = (translation: UseTranslationResponse): Column<WorkItem> => ({
  title: translation.t('workedTime'),
  field: 'wtEndTime',
  type: 'time',
  render: (rowData: WorkItem) => formatNumber(totalMinutesBetween(rowData.wtStartTime, rowData.wtEndTime), 2),
  customSort: (first: WorkItem, second: WorkItem) =>
  totalSecondsBetween(first.wtStartTime, first.wtEndTime) - totalSecondsBetween(second.wtStartTime, second.wtEndTime),
  cellStyle: { minWidth: 120, textAlign: 'right', paddingRight: '60px' },
  headerStyle: {textAlign: 'right', paddingRight: '28px'}
});

export default HoursColumn;
