import { createStyles, IconButton, Paper, Theme, WithStyles, withStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ApiErrorMessage } from '../../api/apiMessage';
import { ApiResult } from '../../api/apiResult';
import projectsReqHandler from '../../api/projectsRemote';
import { DialogMode } from '../../models/DialogMode';
import Project from '../../models/Project';
import { ProjectStatusCondition } from '../../models/ProjectStatusCondition';
import AddNewButton from '../common/AddNewButton';
import InfoSnackbar from '../infoSnackbar/InfoSnackbar';
import NotificationDialog from '../notification/NotificationDialog';
import ProjectDialogContainer from './projectDialog/ProjectDialogContainer';
import ProjectsList from './projectsList/ProjectsList';

const emptyProjectData: Project = {
  prjId: 0,
  sowCode: '',
  prjName: '',
  prjDescr: '',
  prjLead: 0,
  customerId: 0,
  statusCd: ProjectStatusCondition.ACTIVE,
  prjColor: '',
};

const styles = (theme: Theme) =>
  createStyles({
    paperWithTable: {
      '& thead tr th': {
        borderRadius: 4,
      },
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    buttonWrapper: {
      margin: '15px 0px',
      '& > .visibilityIconButton': {
        padding: 0,
        '& .eyeIcon': {
          color: '#8E8C92',
          borderRadius: '50%',
          width: 25,
          height: 25,
        },
      },
      '& > .textStyle': {
        textTransform: 'initial',
        color: '#8E8C92',
        fontSize: 14,
        marginRight: 10,
      },
    },
  });

interface ProjectsProps extends WithStyles<typeof styles>, WithTranslation {
  canReadProject: boolean;
  canWriteProject: boolean;
  appGroupAccessId: number;
}

interface ProjectsState {
  projects: Array<Project>;
  isDialogOpen: boolean;
  isLoading: boolean;
  projectDetail: Project;
  dialogMode: DialogMode;
  snackbarText: string;
  isOpenNotification: boolean;
  notificationTitle: string;
  notificationContent: string;
  showClosed: boolean;
}

class ProjectsContainer extends Component<ProjectsProps, ProjectsState> {
  state: ProjectsState = {
    projects: [],
    isDialogOpen: false,
    isLoading: false,
    projectDetail: emptyProjectData,
    dialogMode: DialogMode.CREATE,
    snackbarText: '',
    isOpenNotification: false,
    notificationTitle: '',
    notificationContent: '',
    showClosed: true,
  };

  componentDidMount() {
    if (this.props.canReadProject) {
      this.refreshData();
    }
  }

  componentDidUpdate(prevProps: ProjectsProps) {
    if (prevProps.canReadProject !== this.props.canReadProject) {
      this.refreshData();
    }
  }

  refreshData() {
    this.setState({ isLoading: true, isDialogOpen: false, projectDetail: emptyProjectData }, () => {
      projectsReqHandler.loadProjects().then((resp: Array<Project>) => {
        this.setState({
          projects: resp.map(item => {
            const project: Project = {
              customerId: item.customerId,
              customerName: item.customerName,
              prjDescr: item.prjDescr || '',
              prjEndDate: item.prjEndDate,
              prjId: item.prjId,
              prjLead: item.prjLead,
              prjName: item.prjName,
              prjStartDate: item.prjStartDate,
              sowCode: item.sowCode,
              statusCd: item.statusCd,
              prjLeadFirstName: item.prjLeadFirstName,
              prjLeadLastName: item.prjLeadLastName,
              prjColor: item.prjColor,
            };
            return project;
          }),
          isLoading: false,
        });
      });
    });
  }

  openCreateProject = (): void => {
    this.setState({ isDialogOpen: true });
  };

  handleUpdate = (projectDetail: Project): void => {
    this.setState({ projectDetail });
  };

  closeCreateProject = (): void => {
    this.setState({ isDialogOpen: false });
  };

  deleteProject = (data: Project): void => {
    this.setState({ isLoading: true }, () => {
      projectsReqHandler
        .deleteProject(data.prjId)
        .then(() => {
          this.refreshData();
        })
        .catch((err: any) => {
          this.setState({ isLoading: false }, () => {
            this.handleError(err);
          });
        });
    });
  };

  handleError = (error: { response: { data: ApiResult<any> } }) => {
    const apiErrorMessage =
      error.response && error.response.data && error.response.data.messages
        ? error.response.data.messages.find(errMessage => !!ApiErrorMessage[errMessage.message])
        : null;
    const errorMessage = apiErrorMessage ? apiErrorMessage.message : 'unexpectedErrorMessage';
    const errorMessageTitle = apiErrorMessage ? apiErrorMessage.message + 'Title' : 'unexpectedErrorMessageTitle';
    this.setState({ isOpenNotification: true, notificationTitle: errorMessageTitle, notificationContent: errorMessage });
  };

  closeNotificationDialog = (): void => {
    this.setState({ isOpenNotification: false, notificationTitle: '', notificationContent: '' });
  };

  render() {
    return (
      <div className="pageWrapper">
        {this.props.canWriteProject ? (
          <div className={this.props.classes.buttonsWrapper}>
            <AddNewButton
              onClick={() => {
                this.setState({ isDialogOpen: true, dialogMode: DialogMode.CREATE });
              }}
            />
            <div className={this.props.classes.buttonWrapper}>
              <span className="textStyle">{this.state.showClosed ? this.props.t('hideClosed') : this.props.t('showClosed')}</span>
              <IconButton className="visibilityIconButton" onClick={() => this.setState({ showClosed: !this.state.showClosed })}>
                {this.state.showClosed ? <VisibilityOffIcon className="eyeIcon" /> : <VisibilityIcon className="eyeIcon" />}
              </IconButton>
            </div>
          </div>
        ) : null}
        <Paper className={this.props.classes.paperWithTable}>
          <ProjectsList
            canReadProject={this.props.canReadProject}
            canWriteProject={this.props.canWriteProject}
            isLoading={this.state.isLoading}
            projects={
              this.state.showClosed
                ? this.state.projects
                : this.state.projects.filter((project: Project) => project.statusCd === ProjectStatusCondition.ACTIVE)
            }
            onDetailsClick={
              this.props.canReadProject
                ? (event: any, data: Project | Project[]) => {
                    return this.setState({ projectDetail: data as Project, dialogMode: DialogMode.VIEW, isDialogOpen: true });
                  }
                : () => {}
            }
            onEditClick={
              this.props.canWriteProject
                ? (event: any, data: Project | Project[]) => {
                    return this.setState({ projectDetail: data as Project, dialogMode: DialogMode.UPDATE, isDialogOpen: true });
                  }
                : () => {}
            }
            onDelete={
              this.props.canWriteProject
                ? (data: Project) => {
                    this.deleteProject(data);
                    return Promise.resolve();
                  }
                : () => {
                    return Promise.resolve();
                  }
            }
          />
          <InfoSnackbar
            open={!!this.state.snackbarText}
            onClose={() => this.setState({ snackbarText: '' })}
            text={this.state.snackbarText}
          />
        </Paper>
        <ProjectDialogContainer
          isOpen={this.state.isDialogOpen}
          onClose={() => this.setState({ projectDetail: emptyProjectData, isDialogOpen: false }, () => this.refreshData())}
          showSnackbar={() =>
            this.setState({
              snackbarText:
                this.state.dialogMode === DialogMode.CREATE
                  ? this.props.t('projectCreatedSuccessfully')
                  : this.props.t('projectUpdatedSuccessfully'),
            })
          }
          project={this.state.projectDetail}
          dialogMode={this.state.dialogMode}
          appGroupAccessId={this.props.appGroupAccessId}
          onUpdate={this.handleUpdate}
          canReadProject={this.props.canReadProject}
          canWriteProject={this.props.canWriteProject}
        />
        {this.state.isOpenNotification && (
          <NotificationDialog
            open={this.state.isOpenNotification}
            title={this.state.notificationTitle}
            content={this.state.notificationContent}
            onClose={this.closeNotificationDialog}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(withStyles(styles)(ProjectsContainer));
