import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogMode } from '../../../../models/DialogMode';
import Project from '../../../../models/Project';
import SaraTransferLists, { Lookup } from '../../../common/SaraTransferLists';
import appGroupsStyles from '../../AppGroups.module.css';

interface ProjectAccessProps {
  isLoading: boolean;
  inaccProjects: Array<Project>;
  accProjects: Array<Project>;
  dialogMode: DialogMode;
  moveProject: (id: number, toAuthorized: boolean) => void;
}

const ProjectAccess: React.FC<ProjectAccessProps> = (props: ProjectAccessProps) => {
  const { t } = useTranslation();
  const listHeightStyle = props.dialogMode === DialogMode.VIEW ? appGroupsStyles.readonlyListHeight : appGroupsStyles.listHeight;

  const mapProjectItem = (item: Project): Lookup<Project> => {
    const isDuplicitName =
      props.inaccProjects.find(x => x.prjName === item.prjName && x.prjId !== item.prjId) ||
      props.accProjects.find(x => x.prjName === item.prjName && x.prjId !== item.prjId);
    return { key: item.prjId, value: item, text: isDuplicitName ? `${item.prjName} (${item.sowCode})` : item.prjName };
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography>{t('assignProjectDataAccess')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <SaraTransferLists
          showSearchBar={props.dialogMode !== DialogMode.VIEW}
          leftHeader={t('inaccessible')}
          leftListStyle={`${appGroupsStyles.inaccList} ${listHeightStyle}`}
          leftItems={props.inaccProjects.map(mapProjectItem)}
          rightHeader={t('accessible')}
          rightListStyle={`${appGroupsStyles.accList} ${listHeightStyle}`}
          rightItems={props.accProjects.map(mapProjectItem)}
          handleMove={(id, toAuthorized) => props.moveProject(id, toAuthorized)}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectAccess;
