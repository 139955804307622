import { Column } from "material-table";
import { UseTranslationResponse } from 'react-i18next';
import CalendarEvent from "../../../../models/CalendarEvent";

const workingDaysColumn = (translation: UseTranslationResponse): Column<CalendarEvent> => {
    return {
      title: translation.t('workingDays'),
      field: 'daysCount',
      customSort: (first: CalendarEvent, second: CalendarEvent) => {
        return first.daysCount -  second.daysCount 
      },
    };
};

export default workingDaysColumn;