import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Snackbar } from '@material-ui/core';
import deepEqual from 'fast-deep-equal';

interface InfoSnackbarProps {
  open: boolean;
  text: string;
  onClose?: () => void;
}

const InfoSnackbar: React.FC<InfoSnackbarProps> = (props: InfoSnackbarProps) => {
  const { t } = useTranslation();
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      key={`bottom,center`}
      open={props.open}
      autoHideDuration={5000}
      onClose={props.onClose}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<span id="message-id">{props.text}</span>}
      action={[
        <Button key="undo" variant="text" color="primary" size="small" onClick={props.onClose}>
          {t('dismiss')}
        </Button>,
      ]}
    />
  );
};

export default React.memo(InfoSnackbar, (prevProps: InfoSnackbarProps, nextProps: InfoSnackbarProps) => deepEqual(prevProps, nextProps));
