import React, { Component, ReactNode, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { createStyles, TableCell, Paper } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import MaterialIcon from '../../../../icons/IconsStyle';
import Employee from '../../../../../models/Employee';
import Task from '../../../../../models/Task';
import TaskModel from '../../../../../models/TaskModel';
import SaraCheckCancelButtons from '../../../../common/SaraCheckCancelButtons';
import { ApiResult } from '../../../../../api/apiResult';
import { clearTaskDraft, setTaskDraft } from '../../../../../store/actions/taskDraftActions';
import TaskForm from './TaskForm';
import Moment from 'moment';
import { Dispatch, bindActionCreators } from 'redux';

const styles = () =>
  createStyles({
    saveButton: {
      marginTop: 13,
    },
    saveButtonDisabled: {
      marginTop: 13,
      opacity: 0.6,
    },
    durationContainer: {
      textAlign: 'center',
    },
    materialIcon: MaterialIcon,
    inlineEditButton: {
      padding: 12,
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    paper: {
      padding: 15,
      marginTop: 15,
    },
    addButtonEnabled: {
      marginLeft: 10,
      marginTop: 10,
    },
    addButtonDisabled: {
      marginLeft: 10,
      marginTop: 10,
      opacity: 0.6,
    },
    addButtonHidden: {
      marginLeft: 10,
      visibility: 'hidden',
    },
  });

const inlineRenderMode = 'InlineEdit';

interface TaskProps extends WithStyles<typeof styles>, WithTranslation {
  task: Task;
  projectId: number;
  minDate?: string;
  maxDate?: string;
  employees: Array<Employee>;
  onAdd: (task: Task) => Promise<void>;
  onSave: (task: TaskModel) => Promise<void>;
  onCancel?: () => void;
  onError?: (error: { response: { data: ApiResult<any> } }) => void;
  index: number;
  isLoading: boolean;
  initialModel?: Task;
  isAddEnabled: boolean;
  renderMode: 'Normal' | 'InlineEdit';
  canReadTask: boolean;
  canWriteTask: boolean;
  empId: number;
  setTaskDraft: (task: Task) => void;
  clearTaskDraft: () => void;
}

interface TaskState {
  currentTask: Task;
  validated: boolean;
}

class InsertTaskContainer extends Component<TaskProps, TaskState> {
  blankState: TaskState;

  constructor(props: TaskProps) {
    super(props);
    this.blankState = {
      currentTask: {
        taskId: 0,
        taskName: '',
        projectId: 0,
        projectName: '',
        assignedBy: 0,
        taskAssignees: [],
      },
      validated: false,
    };
    if (props.task) {
      this.blankState = {
        currentTask: { ...props.task },
        validated: false,
      };
    }
    this.state = { ...this.blankState };
  }

  isValid = (task: Task) =>
    task.taskName &&
    task.taskStartDate &&
    task.taskEndDate &&
    task.taskEndDate >= task.taskStartDate &&
    (this.props.minDate && task.taskStartDate ? task.taskStartDate >= this.props.minDate : true) &&
    (this.props.minDate && task.taskEndDate ? task.taskEndDate >= this.props.minDate : true) &&
    (this.props.maxDate && task.taskStartDate ? task.taskStartDate <= this.props.maxDate : true) &&
    (this.props.maxDate && task.taskEndDate ? task.taskEndDate <= this.props.maxDate : true) &&
    task.taskAssignees &&
    task.taskAssignees.length > 0;

  handleSave() {
    const assignedBy: number | null = this.props.empId;
    const task: TaskModel = {
      taskId: this.props.task.taskId || 0,
      taskName: this.state.currentTask.taskName,
      taskStartDate: this.state.currentTask.taskStartDate?.toString() || '',
      taskEndDate: this.state.currentTask.taskEndDate?.toString() || '',
      projectId: this.props.task.projectId,
      projectName: this.props.task.projectName,
      assignedTo:
        this.state.currentTask.taskAssignees && this.state.currentTask.taskAssignees.length ? this.state.currentTask.taskAssignees[0] : 0,
      assignedBy: assignedBy || 0,
      statusCd: 'ACTIVE',
    };
    this.props
      .onSave(task)
      .then(() => {
        if (this.props.renderMode !== inlineRenderMode) {
          this.setState({ ...this.blankState });
        }
        this.props.clearTaskDraft();
      })
      .catch((error: { response: { data: ApiResult<any> } }) => (this.props.onError ? this.props.onError(error) : {}));
  }

  handleAdd = () => {
    if (this.props.canWriteTask) {
      if (!this.isValid(this.state.currentTask)) {
        this.setState({ validated: true });
      } else {
        this.props
          .onAdd(this.state.currentTask)
          .then(() => {
            if (this.props.renderMode !== inlineRenderMode) {
              const currentTask = this.props.task ? { ...this.props.task } : {
                taskId: 0,
                taskName: '',
                projectId: 0,
                projectName: '',
                assignedBy: 0,
                taskAssignees: [],
              }
              this.setState({
                currentTask,
                validated: false,
              });
            }
            this.props.clearTaskDraft();
          })
          .catch((error: { response: { data: ApiResult<any> } }) => (this.props.onError ? this.props.onError(error) : {}));
      }
    }
  };

  handleEdit = (value: any, field: string) => {
    const updatedTask: Task = { ...this.state.currentTask };
    switch (field) {
      case 'taskName':
        updatedTask.taskName = value;
        break;
      case 'taskStartDate':
      case 'taskEndDate':
        updatedTask[field] = Moment(value).isValid() ? Moment(value).format('YYYY-MM-DD') : value;
        break;
      case 'taskAssignees':
        updatedTask.taskAssignees = value;
        break;
    }
    this.setState(
      prevState => ({ currentTask: { ...prevState.currentTask, ...updatedTask } }),
      () => this.props.setTaskDraft(this.state.currentTask)
    );
  };

  render() {
    const taskForm: ReactNode = (
      <TaskForm
        handleAdd={this.handleAdd}
        handleEdit={this.handleEdit}
        task={this.state.currentTask}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        teamMembers={this.props.employees}
        isAddEnabled={this.props.isAddEnabled}
        index={this.props.index}
        validated={this.state.validated}
      />
    );

    if (this.props.renderMode === inlineRenderMode) {
      return (
        <Fragment>
          <TableCell colSpan={4} className="MuiTableCell-root MuiTableCell-alignLeft MuiTableCell-body">
            {taskForm}
          </TableCell>
          <TableCell padding="none" key="key-actions-column" style={{ width: 84, padding: '0px 5px' }}>
            <div style={{ display: 'flex' }}>
              <SaraCheckCancelButtons onCheck={this.handleSave.bind(this)} onCancel={this.props.onCancel} />
            </div>
          </TableCell>
        </Fragment>
      );
    }
    return <Paper className={this.props.classes.paper}>{taskForm}</Paper>;
  }
}

const mapStateToProps = (state: any) => ({
  empId: state.user.user.empId,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setTaskDraft,
      clearTaskDraft,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withStyles(styles)(InsertTaskContainer)));
