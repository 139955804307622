const local = {
  apiGateway: {
    protocol: '',
    url: '',
    port: '',
    fake: true,
  },
};

const dev = {
  apiGateway: {
    protocol: 'http',
    url: 'sara-dev',
    port: '9010',
    fake: false,
  },
};

const exp = {
  apiGateway: {
    protocol: 'http',
    url: 'sara-dev',
    port: '9050',
    fake: false,
  },
};

const qa = {
  apiGateway: {
    protocol: 'http',
    url: 'sara-dev',
    port: '9020',
    fake: false,
  },
};

const preprod = {
  apiGateway: {
    protocol: 'http',
    url: 'sara-dev',
    port: '9030',
    fake: false,
  },
};

const staging = {
  apiGateway: {
    protocol: 'http',
    url: 'sara-dev',
    port: '9060',
    fake: false,
  },
};

const prod = {
  apiGateway: {
    protocol: 'https',
    url: 'saraapi.sciant.com',
    port: '443',
    fake: false,
  },
};

export default process.env.REACT_APP_ENV === 'qa'
  ? qa
  : process.env.REACT_APP_ENV === 'local'
  ? local
  : process.env.REACT_APP_ENV === 'exp'
  ? exp
  : process.env.REACT_APP_ENV === 'prod'
  ? prod
  : process.env.REACT_APP_ENV === 'preprod'
  ? preprod
  : process.env.REACT_APP_ENV === 'staging'
  ? staging
  : dev;
