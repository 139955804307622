import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Routes from '../components/routes/Routes';
import store from '../store';
import './App.css';
import '../i18n/i18n';
import { ThemeProvider } from '@material-ui/core/styles';
import SaraTheme from "../components/theme/Theme";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { loginFail } from '../store/actions/authActions';
import moment from 'moment-timezone';

const App: React.FC = () => {

window.addEventListener('storage', e => {
  
  if (e.key === 'login') {
    const auth = localStorage.getItem('login')
    if(!auth) {
      localStorage.removeItem('login');
      store.dispatch(loginFail());
    } else  {
     window.location.reload();
   }
  }
});

  return (
    <Provider store={store}>
      <ThemeProvider theme={SaraTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
