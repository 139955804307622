export enum CustomerActionType {
  ADD_APP_GROUP = 'ADD_APP_GROUP',
  ADD_TO_OLD_CUSTOMERS_STATE = 'ADD_TO_OLD_CUSTOMERS_STATE',
  CLEAR_CUSTOMER = 'CLEAR_CUSTOMER',
  CLEAR_CUSTOMERS_STATE = 'CLEAR_CUSTOMER_STATE',
  CLEAR_OLD_CUSTOMERS_STATE = 'CLEAR_OLD_CUSTOMERS_STATE',
  REMOVE_APP_GROUP = 'REMOVE_APP_GROUP',
  STORE_CUSTOMER = 'STORE_CUSTOMER',
  SWITCH_DIRTY = 'SWITCH_DIRTY',
  SWITCH_VALID = 'SWITCH_VALID',
};
